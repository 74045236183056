import { useIsFocused } from '@react-navigation/core';
import React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  View,
} from 'react-native';
import { Searchbar, Surface } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { TabbedScreen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useWindowWidth } from '../hooks/useDimensions';
import { useSearchResults } from '../hooks/useSearchResults';
import { i18n } from '../locale';
import { hrefAsKeyExtractor } from '../utils/hrefAsKeyExtractor';
import { EmptyState } from './EmptyState';
import { PropertyCard } from './PropertyCard';
import { ApplauseProperty, useProperties } from './useProperties';

const EMPTY: ApplauseProperty['_links']['self'][] = [
  Object.freeze({ href: '#', search: '#' }),
];

export function PropertiesScreen() {
  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const {
    data: allData,
    refetch,
    isFetching,
    isLoading,
  } = useProperties({
    enabled: isFocused,
    notifyOnChangeProps: ['data', 'error', 'isLoading', 'isFetching'],
  });

  const columnCount = useWindowWidth() > 720 ? 2 : 1;
  const { data, query, setQuery } = useSearchResults(allData, 'properties');

  return (
    <TabbedScreen>
      <ScreenHeader title={i18n.t('properties.title')} />
      <Surface
        style={[
          tailwind(
            'z-10 justify-center self-center w-full flex-row bg-white pl-2'
          ),
          { elevation: 2 },
        ]}
      >
        <Searchbar
          value={query}
          onChangeText={setQuery}
          style={[
            tailwind('justify-center self-center max-w-3xl w-full'),
            {
              elevation: 0,
              borderRadius: 0,
              width: '100%',
              includeFontPadding: false,
              minHeight: 54,
              display:
                (allData?.properties._index.length || 0) > 6 ? 'flex' : 'none',
            },
          ]}
          placeholder={i18n.t('properties.fields.search.placeholder')}
        />
      </Surface>
      <FlatList
        key={`columns-${columnCount}`}
        style={tailwind('flex-1')}
        contentContainerStyle={[
          { minHeight: 300 },
          tailwind('max-w-3xl self-center w-full p-3'),
        ]}
        data={data.length === 0 ? EMPTY : data}
        renderItem={renderProperty}
        keyExtractor={hrefAsKeyExtractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
        horizontal={false}
        numColumns={columnCount}
      />
    </TabbedScreen>
  );
}

function renderProperty({
  item,
}: ListRenderItemInfo<ApplauseProperty['_links']['self']>) {
  if (item === EMPTY[0]) {
    return (
      <View
        style={[
          {
            flex: 1,
            width: '100%',
            height: '100%',
            minHeight: 300,
            flexShrink: 0,
            alignItems: 'center',
          },
        ]}
      >
        <EmptyState />
      </View>
    );
  }

  return (
    <View style={{ padding: 4, marginBottom: 4, flex: 1 }}>
      <PropertyCard href={item.href} placeholderTitle={item.search} />
    </View>
  );
}
