import { localeDateString, localeTimeString } from 'react-native-time-helpers';
import { i18n } from '../locale';

export function datetime(date: string | undefined): string | undefined {
  if (!date) {
    return undefined;
  }

  const parsed = new Date(date);

  return `${localeDateString(parsed)} ${localeTimeString(parsed, false)}`;
}

export function date(date: string | undefined): string | undefined {
  if (!date) {
    return undefined;
  }

  const parsed = new Date(`${date.split('T').shift()}T12:00:00Z`);

  return localeDateString(parsed);
}

export function humanizeTime(time: number) {
  if (time < 60) {
    return i18n.t('assignments.reaction_time.less_than_minute');
  }

  if (time < 5 * 60) {
    return i18n.t('assignments.reaction_time.less_than_five_minutes');
  }

  if (time < 60 * 60) {
    return i18n.t('assignments.reaction_time.less_than_hour', {
      minutes: Math.round(time / 60),
    });
  }

  if (time < 12 * 60 * 60) {
    return i18n.t('assignments.reaction_time.less_than_twelve_hours', {
      hours: Math.round(time / 60 / 60),
    });
  }

  if (time < 24 * 60 * 60) {
    return i18n.t('assignments.reaction_time.less_than_day');
  }

  return i18n.t('assignments.reaction_time.more');
}
