import { useIsFocused, useNavigation } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import React from 'react';
import { Card, List, useTheme } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { encode } from '../base';
import { APPLAUSE_APP_NAME } from '../config';
import { useColor } from '../hooks/useColor';
import {
  ApplauseConfiguration,
  useConfiguration,
} from '../hooks/useConfiguration';
import { i18n } from '../locale';
import { capitalize } from '../utils/capitalize';
import { datetime } from '../utils/date';
import { AssignmentAction } from './AssignmentAction';
import { AssignmentThumbnailIcon } from './AssignmentThumbnailIcon';
import { ApplauseAssignment, useAssignment } from './useAssignments';

export function AssignmentListItem({
  href,
  roundBottom,
  roundTop,
}: {
  href: string | undefined;
  roundTop?: boolean;
  roundBottom?: boolean;
}) {
  const tailwind = useTailwind();
  const enabled = useIsFocused();
  const { roundness } = useTheme();

  const { navigate } = useNavigation<any>();
  const { data: configuration } = useConfiguration({
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { data: assignment, error } = useAssignment(href, {
    enabled,
    notifyOnChangeProps: ['data', 'error'],
  });
  /*const { data: property } = useProperty(assignment?._links.property?.href, {
    enabled,
  });*/

  const red700 = useColor('text-red-700');
  const red600 = useColor('text-red-600');

  if (error) {
    const description =
      error instanceof FetchMediaError && error.response.status === 410
        ? i18n.t('assignments.states.errors.gone')
        : error.message;
    return (
      <Card
        style={[
          tailwind('p-4 bg-red-200'),
          {
            borderTopEndRadius: roundTop ? roundness : 0,
            borderTopStartRadius: roundTop ? roundness : 0,
            borderBottomEndRadius: roundBottom ? roundness : 0,
            borderBottomStartRadius: roundBottom ? roundness : 0,
            overflow: 'hidden',
          },
        ]}
        elevation={1}
      >
        <Card.Title
          title={i18n.t('assignments.states.errors.display')}
          titleVariant='titleMedium'
          titleNumberOfLines={2}
          subtitle={description}
          titleStyle={{ color: red700 }}
          subtitleStyle={{ color: red600, opacity: 0.8 }}
          subtitleNumberOfLines={12}
        />
      </Card>
    );
  }

  const categoryIcon = categoryIconFor(assignment);
  const statusIcon = statusIconFor(assignment);
  const title = titleFor(assignment);
  const description = descriptionFor(assignment, configuration);

  return (
    <Card
      style={[
        {
          borderTopEndRadius: roundTop ? roundness : 0,
          borderTopStartRadius: roundTop ? roundness : 0,
          borderBottomEndRadius: roundBottom ? roundness : 0,
          borderBottomStartRadius: roundBottom ? roundness : 0,
          overflow: 'hidden',
          backgroundColor: 'white',
        },
      ]}
      elevation={1}
    >
      <List.Item
        left={(props) => (
          <AssignmentThumbnailIcon
            icon={categoryIcon}
            image={assignment?._links.thumbnail?.href}
            props={props}
          />
        )}
        right={
          statusIcon
            ? (props) => <List.Icon icon={statusIcon} color={props.color} />
            : undefined
        }
        title={title}
        titleNumberOfLines={3}
        description={description}
        descriptionNumberOfLines={3}
        titleStyle={[
          tailwind('pb-0 mb-0'),
          description ? tailwind('pt-0') : tailwind('text-gray-400'),
          { includeFontPadding: false },
        ]}
        descriptionStyle={[
          tailwind('pt-0 mt-1'),
          { includeFontPadding: false },
        ]}
        style={[
          tailwind(backgroundColorStyleFor(assignment)),
          href ? {} : { opacity: 0.5 },
          { marginLeft: 6 }
        ]}
        disabled={!href}
        onPress={() =>
          href
            ? navigate('Assignment', {
                href: encode(href!),
              })
            : undefined
        }
      />
      <AssignmentAction data={assignment} style={{ marginLeft: 12, marginRight: 12, marginTop: 0 }} />
    </Card>
  );
}

function titleFor(assignment: ApplauseAssignment | undefined): string {
  if (!assignment) {
    return '...';
  }

  const {
    _links: {
      self: { status, slug },
      booking,
      property,
      service,
    },
  } = assignment;

  const reference = property?.reference || booking?.reference;

  const all = i18n.t('services.slugs') as unknown as Record<
    string,
    { label: string }
  >;

  return [
    `[${reference}]`,
    `${i18n.t(`assignments.states.status.${status || 'new'}`)}:`,
    service?.name ??
      (all[slug]
        ? all[slug].label.replace('{{app}}', APPLAUSE_APP_NAME)
        : capitalize(slug.replace(/_/g, ' '))),
  ]
    .filter(Boolean)
    .join(' ');
}

function descriptionFor(
  assignment: ApplauseAssignment | undefined,
  configuration: ApplauseConfiguration | undefined
): string | undefined {
  if (!assignment) {
    return ' ';
  }

  const {
    _links: {
      self: { status },
      service,
      owner,
    },
    date,
  } = assignment;

  if (status === 'cancelled') {
    return 'no longer necessary';
  }

  const manager = configuration?.capabilities.can_manage;

  // TODO hide time/date if not needstime/needsdate
  return [
    manager ? owner?.display_name : null,
    i18n.t('assignments.states.complete_by', {
      date: date ? datetime(date) : status === 'finished' ? '?' : '...',
    }),
  ]
    .filter(Boolean)
    .join(': ');
}

function categoryIconFor(assignment: ApplauseAssignment | undefined): string {
  if (!assignment) {
    return 'bed-king-outline';
  }

  const {
    _links: {
      self: { status },
    },
  } = assignment;
  if (status === 'started' || status === 'finished') {
    return 'bed-king';
  }

  return 'bed-king-outline';
}

function statusIconFor(
  assignment: ApplauseAssignment | undefined
): string | null {
  if (!assignment) {
    return null;
  }

  const {
    _links: {
      self: { status },
    },
  } = assignment;

  switch (status) {
    case 'new': {
      return null;
    }
    case 'claimed': {
      return null;
    }
    case 'started': {
      return 'timer-sand';
    }
    case 'paused': {
      return 'pause-circle-outline';
    }
    case 'finished': {
      return 'check';
    }
    case 'cancelled': {
      return 'cancel';
    }
  }

  return null;
}

function backgroundColorStyleFor(
  assignment: ApplauseAssignment | undefined
): string {
  if (!assignment) {
    return 'bg-white';
  }

  const {
    _links: {
      self: { status },
    },
  } = assignment;

  switch (status) {
    case 'finished': {
      return 'bg-green-50';
    }
    case 'cancelled': {
      return 'bg-gray-100';
    }
  }

  return 'bg-white';
}
