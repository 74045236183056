import { useNavigation, useRoute } from '@react-navigation/core';
import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { Button, HelperText } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { decode } from '../base';
import { DatePicker } from '../components/DatePicker';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import {
  propertyCacheKey,
  useProperty,
  useReserveProperty,
} from './useProperties';

export function ReservePropertyScreen() {
  useForceAuthenticated();

  const tailwind = useTailwind();
  const { navigate, canGoBack, goBack } = useNavigation<any>();
  const { href: encodedHref } = useRoute().params as { href: string };
  const href = decode(encodedHref);
  const locale = useLocale();
  const cacheKey = propertyCacheKey(href, locale);
  const { data: property } = useProperty(href, {
    notifyOnChangeProps: ['data'],
  });

  const { mutateAsync, error, isLoading } = useReserveProperty(
    property?._links.reserve.href,
    Array.isArray(cacheKey) ? [...cacheKey, 'reserve'] : `${cacheKey}.reserve`,
    {
      onSuccess: () => {
        if (canGoBack()) {
          goBack();
        } else {
          navigate('Tabs', { screen: 'Bookings', replace: true });
        }
      },
    }
  );

  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  // TODO form

  return (
    <Screen>
      <ScreenHeader title={i18n.t('property-reservation.title')} showBack />
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full px-6 pt-6'
        )}
      >
        <DatePicker
          label={i18n.t('property-reservation.fields.from.label')}
          value={from}
          onChange={setFrom}
        />
        <DatePicker
          label={i18n.t('property-reservation.fields.to.label')}
          value={to}
          onChange={setTo}
        />
        {error ? <HelperText type="error">{error.message}</HelperText> : null}
        <Button
          mode="contained"
          icon="check"
          loading={isLoading}
          disabled={isLoading || !from || !to}
          style={tailwind('self-end mt-4')}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
            paddingHorizontal: 6,
          }}
          onPress={() =>
            mutateAsync({
              reservation: { from: from!, to: to! },
            }).catch(() => {})
          }
        >
          {i18n.t('property-reservation.actions.reserve')}
        </Button>
      </ScrollView>
    </Screen>
  );
}
