import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useQuery } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type ApplauseEvent = {
  _links: {
    self: {
      href: string;
      type: string;
      i18n_type?: string;
    };
    event_source?: {
      href: string;
      name: string;
    };
    booking?: {
      href: string;
    };
    property?: {
      href: string;
    };
    assignment?: {
      href: string;
    };
  };
};

type ApplauseEventResponse = {
  event: ApplauseEvent;
};

export type ApplauseEventsIndex = {
  events: {
    _links: {
      self: { href: string };
    };
    _index: ApplauseEvent['_links']['self'][];
  };
};

export function useEvents({ enabled = true } = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_events');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseEventsIndex, FetchMediaError>(
    [locale, 'event', 'list'],
    {
      queryFn: async () =>
        fetchMedia(href!, {
          headers: {
            accept: [
              'application/vnd.bnbbutler.event.v2.index+json',
              'application/vnd.bnbbutler.event.v1.index+json; q=0.9',
            ].join(', '),
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),
            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
        }).then((response) => response as ApplauseEventsIndex),
      staleTime: 30 * 1000,
      enabled: !!auth.current?.['access-token'] && enabled && !!href,
    }
  );
}

export function eventCacheKey(href: string | null | undefined, locale: string) {
  return [locale, 'event', `${href?.split('/').pop() || '-'}`];
}

export function useEvent(
  href: string | null | undefined,
  { enabled = true } = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseEvent, FetchMediaError>(eventCacheKey(href, locale), {
    queryFn: async () =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.event.v2+json',
            'application/vnd.bnbbutler.event.v1+json; q=0.9',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
      })
        .then((response) => response as ApplauseEventResponse)
        .then(({ event }) => event),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 60 * 60 * 1000,
  });
}
