import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import merge from 'lodash.merge';

const SUPPORTED = ['en', 'nl', 'de', 'es', 'fr', 'id', 'it', 'pt'];

const translations = {
  nl: require('../assets/locales/nl.json'),
  en: require('../assets/locales/en.json'),
  fr: require('../assets/locales/fr.json'),
  de: require('../assets/locales/de.json'),
  pt: require('../assets/locales/pt.json'),
  es: require('../assets/locales/es.json'),
  id: require('../assets/locales/id.json'),
};

export const i18n = new I18n(translations);

i18n.defaultLocale = 'en';
i18n.enableFallback = true;
i18n.availableLocales = Object.keys(translations);

// Naive lookup. TODO: think about less specific look ups etc.
i18n.locale =
  Localization.getLocales().find(
    (locale) =>
      SUPPORTED.includes(locale.languageCode) ||
      SUPPORTED.includes(locale.languageTag)
  )?.languageCode ?? i18n.defaultLocale;

export function defineTranslations(
  translations: Record<string, Record<string, unknown>>
) {
  Object.keys(translations).forEach((language) => {
    // Ensure language exists
    const current = i18n.translations[language] ?? {};

    // Deep merge
    const next = merge(current, translations[language]);
    i18n.store({ [language]: next });
  });
}
