import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseAssignmentReview = {
  _links: {
    self: {
      href: string;
      completed: null | boolean;
    };
    report: {
      href: string;
    };
    reviewer: {
      href: string;
    };
  };
  approved: null | boolean;
  average_rating: string | number;
  _embedded: {
    key: string;
    rating: string | number;
    comment?: string | null;
    approved: boolean;
  }[];
};

type ApplauseAssignmentReviewResponse = {
  review: ApplauseAssignmentReview;
};

export type ApplauseAssignmentReviewSubmission = {
  _links: {
    report: {
      href: string;
    };
  };
  _embedded: {
    key: string;
    rating: string | number;
    comment?: string;
    approved?: boolean;
  }[];
};

export type ApplauseAssignmentReviews = {
  _links: {
    report: {
      href: string;
    };
    review: {
      href: string;
    };
  };
  _index: {
    href: string;
    completed: null | boolean;
  }[];
};

type ApplauseAssignmentReviewsResponse = {
  reviews: ApplauseAssignmentReviews;
};

export function useReviews(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseAssignmentReviews, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery([...cacheKey, 'reviews'] as QueryKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.review.v1.index+json'].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseAssignmentReviewsResponse)
        .then(({ reviews }) => reviews),
    enabled:
      enabled && Boolean(auth.current?.['access-token']) && Boolean(href),
    ...options,
  });
}

export function useReview(
  href: string | null | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseAssignmentReview, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(reviewCacheKey(href, locale), {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.review.v1+json'].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseAssignmentReviewResponse)
        .then(({ review }) => review),
    enabled:
      enabled && Boolean(auth.current?.['access-token']) && Boolean(href),
    ...options,
  });
}

export function reviewCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  // Href has `reviews` in it
  return [locale, 'assignment', ...(href?.split('/').slice(-3) || ['-'])];
}

export function useSubmitReview(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    onSettled,
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<
    ApplauseAssignmentReview,
    FetchMediaError,
    ApplauseAssignmentReviewSubmission,
    void
  > = {}
) {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const locale = useLocale();

  const mutationFn: MutationFunction<
    ApplauseAssignmentReview,
    ApplauseAssignmentReviewSubmission
  > = useCallback(
    async (submission) => {
      if (!href) {
        throw new Error('No href to submit review');
      }

      if (!auth.current?.['access-token']) {
        throw new Error('Not authenticated');
      }

      const response = await fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.review.v1+json'].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          contentType: 'application/vnd.bnbbutler.review.v1.submission+json',
          ...(auth.current as Record<string, string>),
        },
        method: 'POST',
        body: {
          review: submission,
        },
        debug: __DEV__,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      const { review } = response as ApplauseAssignmentReviewResponse;
      queryClient.setQueryData(
        reviewCacheKey(review._links.self.href, locale),
        review
      );

      return review;
    },
    [href, auth, locale]
  );

  return useMutation([...cacheKey, 'reviews', 'create'], mutationFn, {
    ...options,
    onSettled: async (data, error, variables, context) => {
      const specificKey = reviewCacheKey(data?._links.self.href, locale);

      await Promise.all([
        queryClient.cancelQueries([locale, 'event', 'list']),
        queryClient.cancelQueries(cacheKey),
        queryClient.cancelQueries([...cacheKey, 'reviews']),
        queryClient.cancelQueries(specificKey),
      ]);

      queryClient.setQueryData(specificKey, data);

      await Promise.all([
        queryClient.invalidateQueries([locale, 'event', 'list']),
        queryClient.invalidateQueries(cacheKey),
        queryClient.invalidateQueries([...cacheKey, 'reviews']),
        queryClient.invalidateQueries(specificKey),
      ]);

      if (onSettled) {
        return onSettled(data, error, variables, context);
      }
    },

    onSuccess: async (data, variables, context) => {
      const previousIndex = queryClient.getQueryData<ApplauseAssignmentReviews>(
        [...cacheKey, 'reviews']
      );

      if (previousIndex) {
        const nextIndex: ApplauseAssignmentReviews = {
          ...previousIndex,
          _index: [
            ...previousIndex._index,
            { href: data._links.self.href, completed: true },
          ],
        };

        queryClient.setQueryData<ApplauseAssignmentReviews>(
          [...cacheKey, 'reviews'],
          nextIndex
        );
      }

      if (onSuccess) {
        return onSuccess(data, variables, context);
      }
    },

    onError,
  });
}
