import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { Appbar } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';

export function StickyScreenHeader({ title }: { title: string | undefined }) {
  const { canGoBack, goBack, navigate } = useNavigation<any>();
  const tailwind = useTailwind();

  return (
    <Appbar
      style={[
        tailwind('justify-center self-center w-full flex-row'),
        {
          elevation: 0,
          maxWidth: 786,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: 'transparent',
        },
      ]}
    >
      <Appbar.BackAction
        color="white"
        onPress={() =>
          canGoBack()
            ? goBack()
            : navigate('Tabs', { screen: 'Dashboard', replace: true })
        }
      />
      <Appbar.Content
        title={title || ''}
        titleStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          color: 'white',
          textShadowColor: '#222',
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 1,
        }}
      />
    </Appbar>
  );
}
