import React, { Fragment } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Button, Portal } from 'react-native-paper';
import { QueryKey } from 'react-query';
import { useTailwind } from 'tailwind-rn';
import { COLOR_PRIMARY } from '../config';
import { useColor } from '../hooks/useColor';
import {
  ConfirmationDialogContent,
  useConfirmationDialog,
} from '../hooks/useConfirmationDialog';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { FinishAssignment } from './FinishAssignment';
import { FinishAssignmentWithReport } from './FinishAssignmentWithReport';
import { ReviewsAction } from './ReviewsAction';
import { useAction } from './useAction';
import { ApplauseAssignment, assignmentCacheKey } from './useAssignments';

export function AssignmentAction({
  data,
  withReviews,
  style,
}: {
  data: ApplauseAssignment | undefined;
  withReviews?: boolean;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const locale = useLocale();
  const baseCacheKey = assignmentCacheKey(data?._links.self.href, locale);

  if (!data) {
    return null;
  }

  if (data._links.claim) {
    return (
      <View
      style={[
        style,
        tailwind('mb-2'),
        { flexDirection: 'row', flexWrap: 'wrap' },
      ]}
    >
      <ClaimAssignment
        href={data._links.claim.href}
        baseCacheKey={baseCacheKey}
        style={[{marginRight: 4, marginTop: 0, flex: 1}, tailwind('mb-4')]}
      />
    </View>
    );
  }

  if (
    !data._links.start &&
    !data._links.pause &&
    !data._links.finish &&
    !data._links.cancel &&
    (!withReviews || !data._links.reviews)
  ) {
    return null;
  }

  return (
    <View
      style={[
        style,
        tailwind('mb-2'),
        { flexDirection: 'row', flexWrap: 'wrap' },
      ]}
    >
      {data._links.start ? (
        <StartAssignment
          key="start"
          href={data._links.start.href}
          baseCacheKey={baseCacheKey}
          style={[{ marginRight: 4, marginTop: 0, flex: 1 }, tailwind('mb-2')]}
        />
      ) : null}
      {data._links.pause ? (
        <PauseAssignment
          key="pause"
          href={data._links.pause.href}
          baseCacheKey={baseCacheKey}
          style={[{ marginRight: 4, marginTop: 0, flex: 1 }, tailwind('mb-2')]}
        />
      ) : null}
      {data._links.finish ? (
        data._links.report ? (
          <FinishAssignmentWithReport
            key="finish"
            href={data._links.report.href}
            finishHref={data._links.finish.href}
            baseCacheKey={baseCacheKey}
            style={[{ marginRight: 4, marginTop: 0, flex: 1 }, tailwind('mb-2')]}
          />
        ) : (
          <FinishAssignment
            key="finish"
            href={data._links.finish.href}
            baseCacheKey={baseCacheKey}
            style={[{ marginRight: 4, marginTop: 0, flex: 1 }, tailwind('mb-2')]}
          />
        )
      ) : null}
      {data._links.reviews && withReviews ? (
        <ReviewsAction
          key="reviews"
          href={data._links.reviews.href}
          baseCacheKey={baseCacheKey}
          style={[{ marginRight: 4, marginTop: 0, flex: 1 }, tailwind('mb-2')]}
        />
      ) : null}
      {data._links.cancel ? (
        <CancelAssignment
          key="cancel"
          href={data._links.cancel.href}
          baseCacheKey={baseCacheKey}
          style={[{ marginRight: 4, marginTop: 0, flex: 1 }, tailwind('mb-2')]}
        />
      ) : null}
    </View>
  );
}

function ClaimAssignment({
  href,
  baseCacheKey,
  style,
}: {
  href: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const { mutateAsync: claim, isLoading } = useAction(href, baseCacheKey);

  return (
    <Button
      disabled={isLoading}
      loading={isLoading}
      mode="contained"
      color={COLOR_PRIMARY}
      style={[tailwind('self-start mb-4 mt-4'), style]}
      labelStyle={{
        includeFontPadding: false,
        textAlignVertical: 'center',
        paddingHorizontal: 0,
      }}
      onPress={() => claim()}
    >
      {i18n.t('assignments.actions.claim')}
    </Button>
  );
}

function StartAssignment({
  href,
  baseCacheKey,
  style,
}: {
  href: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const { mutateAsync: start, isLoading } = useAction(href, baseCacheKey);

  return (
    <Button
      disabled={isLoading}
      loading={isLoading}
      mode="contained"
      color={COLOR_PRIMARY}
      style={[tailwind('self-start mb-4 mt-4'), style]}
      labelStyle={{
        includeFontPadding: false,
        textAlignVertical: 'center',
        paddingHorizontal: 0,
      }}
      onPress={() => start()}
    >
      {i18n.t('assignments.actions.start')}
    </Button>
  );
}

function PauseAssignment({
  href,
  baseCacheKey,
  style,
}: {
  href: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const { mutateAsync: pause, isLoading } = useAction(href, baseCacheKey);

  return (
    <Button
      disabled={isLoading}
      loading={isLoading}
      mode="contained"
      color={COLOR_PRIMARY}
      style={[tailwind('self-start mb-4 mt-4'), style]}
      labelStyle={{
        includeFontPadding: false,
        textAlignVertical: 'center',
        paddingHorizontal: 0,
      }}
      onPress={() => pause()}
    >
      {i18n.t('assignments.actions.pause')}
    </Button>
  );
}

function CancelAssignment({
  href,
  baseCacheKey,
  style,
}: {
  href: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const { mutateAsync: cancel, isLoading } = useAction(href, baseCacheKey);
  const [isConfirming, requestCancelConfirmation, ConfirmationDialog] =
    useConfirmationDialog(
      i18n.t(
        'assignments.confirmations.cancel'
      ) as unknown as ConfirmationDialogContent,
      {
        onConfirm: cancel,
      }
    );

  return (
    <Fragment>
      <Button
        disabled={isLoading}
        loading={isLoading || isConfirming}
        mode="contained"
        color={COLOR_PRIMARY}
        style={[tailwind('self-start mb-4 mt-4'), style]}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 0,
        }}
        onPress={requestCancelConfirmation}
      >
        {i18n.t('assignments.actions.cancel')}
      </Button>
      <Portal>
        <ConfirmationDialog />
      </Portal>
    </Fragment>
  );
}
