import * as Clipboard from 'expo-clipboard';
import { createURL as makeUrl } from 'expo-linking';
import * as ExpoNotifications from 'expo-notifications';
import {
  getPermissionsAsync,
  requestPermissionsAsync,
} from 'expo-notifications';
import { fetchMedia } from 'fetch-media';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Button, Card, Paragraph, useTheme } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';
import { useIsMounted } from 'use-is-mounted';
import { EXPERIENCE_SLUG, EXPO_SLUG } from '../config';
import { useAuthHeaders } from '../hooks/useAuth';
import { useConfiguration } from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';

const SHOULD_DEBUG = __DEV__;

const IS_SUPPORTED = Platform.select({
  web: false,
  default: true,
});

const lastIsPermitted: { value: string | undefined } = {
  value: 'undetermined',
};

const canRequestAgain: { value: boolean | undefined } = {
  value: undefined,
};

export function Notifications(): null | JSX.Element {
  const auth = useAuthHeaders();
  const locale = useLocale();
  const { data } = useConfiguration({ notifyOnChangeProps: ['data'] });
  const { roundness } = useTheme();
  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16

  const [hasChecked, setHasChecked] = useState(false);
  const [isPermitted, setPermitted] = useState<string | undefined>(
    lastIsPermitted.value
  );
  const [currentPushToken, setCurrentPushToken] = useState<string | undefined>(
    undefined
  );
  const isMounted = useIsMounted();

  const doEnableNotifications = useCallback(() => {
    requestPermissionsAsync()
      .then(({ status, canAskAgain }) => {
        lastIsPermitted.value = status;
        canRequestAgain.value = canAskAgain;

        if (isMounted.current) {
          setPermitted(status);
          setHasChecked(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setHasChecked(false);
        }

        console.error(err);
      });
  }, [isMounted]);

  /*const renderBannerImage = useCallback(({ size }) => {
    return <Image style={{ width: size, height: size }} source={require('../../assets/notification.png')} />
  }, [])*/

  // Effect to get the token
  useEffect(() => {
    if (isPermitted === 'undetermined') {
      getPermissionsAsync()
        .then(({ status, canAskAgain }) => {
          lastIsPermitted.value = status;
          canRequestAgain.value = canAskAgain;

          if (isMounted.current) {
            setPermitted(status);
            setHasChecked(true);
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            setHasChecked(true);
          }

          console.error(err);
        });
      return;
    }

    if (
      (isPermitted === 'granted' || Platform.OS === 'android') &&
      !currentPushToken &&
      IS_SUPPORTED
    ) {
      ExpoNotifications.getExpoPushTokenAsync()
        .then((result) => isMounted.current && setCurrentPushToken(result.data))
        .catch(console.warn);
    }

    return () => {};
  }, [isMounted, isPermitted, setCurrentPushToken, currentPushToken]);

  // Effect to store the token
  useEffect(() => {
    if (
      (isPermitted === 'granted' || Platform.OS === 'android') &&
      currentPushToken &&
      data &&
      auth
    ) {
      fetchMedia(data?._links.my_push_tokens.href, {
        headers: {
          accept: 'text/html',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),
          contentType:
            'application/vnd.bnbbutler.notification-token.v1.token+json',
          ...(auth as Record<string, string>),
        },
        method: 'POST',
        body: {
          token: {
            token: currentPushToken,
            experience_id: EXPERIENCE_SLUG,
          },
        },
      }).catch((error) => __DEV__ && console.error(error));
    }
  }, [auth, data, currentPushToken, isPermitted, locale]);

  // This means that we don't know yet
  if (isPermitted === undefined || hasChecked === false) {
    if (!SHOULD_DEBUG) {
      return null;
    }
  }

  if (isPermitted !== 'granted' && IS_SUPPORTED && Platform.OS !== 'android') {
    return (
      <Fragment>
        <Card
          onPress={doEnableNotifications}
          style={{
            borderRadius: mobileView ? 0 : roundness,
            paddingHorizontal: 6,
            paddingBottom: 12,
            paddingTop: 6,
          }}
        >
          <Card.Title
            title={i18n.t('push-notifications.title')}
            subtitle={i18n.t('push-notifications.subtitle')}
            titleVariant='titleMedium'
          />

          <Card.Content>
            <Paragraph>{i18n.t('push-notifications.explainer')}</Paragraph>
          </Card.Content>
          <Card.Actions>
            <Button
              onPress={doEnableNotifications}
              mode="contained"
              style={{ marginLeft: 'auto' }}
              icon="bell"
              disabled={!canRequestAgain}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {i18n.t('push-notifications.actions.enable')}
            </Button>
          </Card.Actions>
        </Card>
      </Fragment>
    );
  }

  if (!SHOULD_DEBUG) {
    return null;
  }

  if (!IS_SUPPORTED) {
    return null;
  }

  if (!currentPushToken) {
    return (
      <Card
        onPress={doEnableNotifications}
        style={{
          borderRadius: mobileView ? 0 : roundness,
          paddingHorizontal: 6,
          paddingBottom: 12,
          paddingTop: 6,
        }}
      >
        <Card.Title
          title={`DEBUG: ${i18n.t('push-notifications.title')}`}
          subtitle={isPermitted}
          titleVariant='titleMedium'
        />

        <Card.Content>
          <Paragraph>
            {JSON.stringify({
              hasAuth: !!auth,
              hasConfig: !!data,
              hasToken: !!currentPushToken,
              isPermitted,
              isSupported: IS_SUPPORTED,
              os: Platform.OS,
            })}
          </Paragraph>
        </Card.Content>
        <Card.Actions>
          <Button
            onPress={doEnableNotifications}
            mode="contained"
            style={{ marginLeft: 'auto' }}
            icon="bell"
            disabled={!canRequestAgain}
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
          >
            {i18n.t('push-notifications.actions.enable')}
          </Button>
        </Card.Actions>
      </Card>
    );
  }

  return (
    <Fragment>
      <Card
        style={{
          borderRadius: mobileView ? 0 : roundness,
          paddingHorizontal: 6,
          paddingBottom: 12,
          paddingTop: 6,
        }}
      >
        <Card.Title
          title="Push notifications enabled"
          subtitle={`...and you're in DEV mode ${
            __DEV__ ? '__DEV__' : ''
          } / ${EXPO_SLUG}`}
          titleVariant='titleMedium'
        />
        <Card.Content>
          <Paragraph>
            Allow push notifications to get updates about last-minute changes to
            the programme, important personal information, or reminders for
            important events you're participating in.
          </Paragraph>
        </Card.Content>

        <Card.Actions>
          <Button
            icon="bell"
            onPress={() => {
              ExpoNotifications.scheduleNotificationAsync({
                content: {
                  title: 'Test notification',
                  body: 'This is an example of a local notification.',
                  data: {
                    title: 'In-app test notification',
                    body: 'This is an example of a local notification.',
                  },
                },
                trigger: { seconds: 2 },
              });
            }}
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            style={{ flex: 1, marginHorizontal: 4 }}
            mode="contained"
          >
            Local
          </Button>

          <Button
            icon="bell-ring"
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              ExpoNotifications.scheduleNotificationAsync({
                content: {
                  title: 'Test notification',
                  body: 'This is an example of a local notification.',
                  data: {
                    title: 'In-app test notification',
                    body: 'This is an example of a local notification.',
                    action: 'Show info',
                    url: makeUrl('/knowledge-base'),
                  },
                },
                trigger: null,
              });
            }}
            style={{ flex: 1, marginHorizontal: 4 }}
            mode="contained"
          >
            Present
          </Button>

          <Button
            icon="content-copy"
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
            onPress={() => {
              Clipboard.setString(currentPushToken);

              console.log(currentPushToken);
            }}
            style={{ marginLeft: 'auto' }}
          >
            Push token
          </Button>
        </Card.Actions>
      </Card>
    </Fragment>
  );
}
