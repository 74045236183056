import { useIsFocused } from '@react-navigation/native';
import { FetchMediaError } from 'fetch-media';
import React, {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Image,
  Keyboard,
  KeyboardEvent,
  Platform,
  ScrollView,
  StyleProp,
  TextInput as NativeTextInput,
  View,
  ViewStyle,
} from 'react-native';
import {
  ActivityIndicator,
  Button,
  Caption,
  Dialog,
  Headline,
  HelperText,
  List,
  Paragraph,
  Portal,
  RadioButton,
  TextInput,
} from 'react-native-paper';
import { QueryKey } from 'react-query';
import { useTailwind } from 'tailwind-rn';
import { COLOR_PRIMARY } from '../config';
import {
  ConfirmationDialogContent,
  useConfirmationDialog,
} from '../hooks/useConfirmationDialog';
import { i18n } from '../locale';
import { keyToTitle } from './FinishAssignmentWithReport';
import { ReviewVideo } from './ReviewVideo';
import { ApplauseAssignmentReport, useReport } from './useReport';
import {
  ApplauseAssignmentReview,
  ApplauseAssignmentReviewSubmission,
  useReview,
  useReviews,
  useSubmitReview,
} from './useReviews';

type ReviewReference = { href: string; completed: boolean | null };

type ReviewAction =
  | {
      type: 'init';
      reviews: ReviewReference[];
      canReview: boolean;
    }
  | { type: 'reviewItem'; item: string | undefined }
  | { type: 'activeReview'; item: string | undefined }
  | { type: 'startReviewing' }
  | { type: 'back' }
  | { type: 'show' }
  | { type: 'hide' }
  | { type: 'reviewed' };

type ReviewState = {
  canReview: boolean;
  reviews: ReviewReference[];
  showing: boolean;
  activeReview: undefined | string;
  reviewing: boolean;
  reviewingItem: string | undefined;
};

function reviewReducer(state: ReviewState, action: ReviewAction): ReviewState {
  switch (action.type) {
    case 'init': {
      // If not yet showing, just store the metadata
      if (!state.showing) {
        return {
          ...state,
          reviews: action.reviews,
          canReview: action.canReview,
        };
      }

      return {
        ...state,
        reviews: action.reviews,
        canReview: action.canReview,

        // Auto start reviewing if nothing else to show
        reviewing: action.canReview && action.reviews.length === 0,

        // Auto start showing review if nothing else to show
        activeReview:
          !action.canReview && action.reviews.length === 1
            ? action.reviews[0].href
            : undefined,
      };
    }

    case 'back': {
      // Closed review item
      if (state.reviewingItem) {
        return { ...state, reviewingItem: undefined };
      }

      // Closed draft review
      if (state.reviewing) {
        // Auto-hide if there are no reviews to show.
        return {
          ...state,
          reviewing: false,
          showing: state.reviews.length > 0,
        };
      }

      // Closed active review
      if (state.activeReview) {
        // Auto-hide if there are no other reviews to show or no review pending
        return {
          ...state,
          activeReview: undefined,
          showing: state.reviews.length > 1 || state.canReview,
        };
      }

      return {
        ...state,
        showing: false,
      };
    }

    case 'show': {
      return {
        ...state,
        showing: true,

        // Start draft review if no reviews to show
        reviewing: state.canReview && state.reviews.length === 0,
        activeReview:
          !state.canReview && state.reviews.length === 1
            ? state.reviews[0].href
            : undefined,
      };
    }

    case 'hide': {
      return {
        ...state,
        showing: false,

        // Reset all the fields
        reviewing: false,
        reviewingItem: undefined,
        activeReview: undefined,
      };
    }

    case 'reviewItem': {
      return {
        ...state,

        reviewingItem: action.item,
      };
    }

    case 'activeReview': {
      return {
        ...state,

        activeReview: action.item,
      };
    }

    case 'reviewed': {
      return {
        ...state,

        reviewingItem: undefined,
        activeReview: undefined,
        reviewing: false,
      };
    }

    case 'startReviewing': {
      return {
        ...state,

        reviewingItem: undefined,
        activeReview: undefined,
        reviewing: true,
      };
    }
  }

  return state;
}

const INITIAL_STATE: ReviewState = {
  showing: false,
  activeReview: undefined,
  reviewing: false,
  reviewingItem: undefined,
  reviews: [],
  canReview: false,
};

const NO_REVIEWS: ReviewReference[] = [];

export function ReviewsAction({
  href,
  baseCacheKey,
  style,
  label = 'Review',
}: {
  href: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
  label?: string;
}) {
  const tailwind = useTailwind();
  const scrollViewRef = useRef<ScrollView | null>(null);
  const [state, dispatch] = useReducer(reviewReducer, INITIAL_STATE);

  const isFocused = useIsFocused();

  const { data, isLoading } = useReviews(href, baseCacheKey, {
    enabled: isFocused,
  });

  const canReview =
    Boolean(data?._links.review) && Boolean(data?._links.report?.href);

  const reviews = useMemo(
    () =>
      (data?._index ?? NO_REVIEWS)
        .filter(({ completed }) => completed !== false)
        .filter(
          (needle, index, self) =>
            self.findIndex((hay) => hay.href === needle.href) === index
        ),
    [data]
  );

  useEffect(() => {
    if (!reviews) {
      return;
    }

    dispatch({ type: 'init', reviews, canReview });
  }, [reviews, canReview]);

  return (
    <Fragment>
      <Button
        disabled={isLoading || state.showing}
        loading={isLoading}
        mode="contained"
        color={COLOR_PRIMARY}
        style={[tailwind('self-start mb-4 mt-4'), style]}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 0,
        }}
        onPress={() => dispatch({ type: 'show' })}
      >
        {label}
      </Button>
      <Portal>
        <Dialog
          visible={state.showing}
          onDismiss={() => dispatch({ type: 'hide' })}
          dismissable={!isLoading}
          style={tailwind('max-w-xl self-center w-full')}
        >
          <Dialog.Title>{i18n.t('reviews.title')}</Dialog.Title>
          <Dialog.ScrollArea
            style={{
              maxHeight: Platform.OS === 'web' ? 'min(80vh, 800px)' : '80%',
              minHeight: 200,
              paddingHorizontal: 0,
            }}
          >
            <ScrollView
              ref={scrollViewRef}
              contentContainerStyle={{
                paddingHorizontal: 0,
                paddingVertical: 0,
              }}
            >
              {state.reviewing &&
              data?._links.report?.href &&
              data?._links.review?.href ? (
                <DraftReview
                  scrollViewRef={scrollViewRef}
                  submitHref={data._links.review.href}
                  reportHref={data._links.report.href}
                  baseCacheKey={baseCacheKey}
                  reviewingItem={state.reviewingItem}
                  setReviewingItem={(next: string | undefined) =>
                    dispatch({ type: 'reviewItem', item: next })
                  }
                  onReviewed={() => {
                    dispatch({ type: 'reviewed' });
                  }}
                />
              ) : null}
              {!state.reviewing && state.activeReview ? (
                <ActiveReview
                  href={state.activeReview}
                  baseCacheKey={baseCacheKey}
                  reviewingItem={state.reviewingItem}
                  setReviewingItem={(next: string | undefined) =>
                    dispatch({ type: 'reviewItem', item: next })
                  }
                />
              ) : null}
              {!state.reviewing && !state.activeReview ? (
                <ReviewList
                  items={reviews}
                  onSelect={(next: string) =>
                    dispatch({ type: 'activeReview', item: next })
                  }
                  onStartReviewing={
                    state.canReview
                      ? () => dispatch({ type: 'startReviewing' })
                      : undefined
                  }
                />
              ) : null}
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions style={tailwind('py-4')}>
            <Button
              mode="text"
              color={COLOR_PRIMARY}
              disabled={isLoading}
              loading={isLoading}
              onPress={() => dispatch({ type: 'back' })}
              style={tailwind('mr-2')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {state.reviewing
                ? i18n.t('actions.cancel')
                : i18n.t('actions.back')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}

function DraftReview({
  scrollViewRef,
  submitHref,
  reportHref,
  baseCacheKey,
  reviewingItem,
  onReviewed,
  setReviewingItem: setReviewingItem_,
}: {
  scrollViewRef: RefObject<ScrollView | null>;
  reviewingItem: string | undefined;
  submitHref: string | undefined;
  reportHref: string;
  baseCacheKey: QueryKey;
  onReviewed(): void;
  setReviewingItem(next: string | undefined): void;
}) {
  const [reviewData, setReviewData] =
    useState<ApplauseAssignmentReviewSubmission>(() => ({
      _links: { report: { href: reportHref } },
      _embedded: [],
    }));

  const {
    mutateAsync: review,
    isLoading: isSubmitting,
    error: submissionError,
  } = useSubmitReview(submitHref, baseCacheKey, {
    onSuccess: () => onReviewed(),
    onError: (error) => {
      if (error instanceof FetchMediaError) {
        if (error.response.status === 409) {
          onReviewed();
        }
      }
    },
  });
  const {
    data: report,
    isLoading,
    error,
  } = useReport(reportHref, baseCacheKey);

  const hasOnlyOneItem = report?._embedded.length === 1;
  const shouldApproveIndividually = report?.cleaning;
  const isReadyToSubmit =
    report && report._embedded.length <= reviewData._embedded.length;

  const setReviewingItem = useCallback(
    (next: string | undefined) => {
      setReviewingItem_(next);

      setTimeout(() => {
        if (!scrollViewRef.current || !report) {
          return;
        }

        if (next !== undefined) {
          scrollViewRef.current.scrollTo({ y: 0 });
          return;
        }

        const firstItem = report._embedded.findIndex((item) =>
          reviewData._embedded.every(
            (reviewItem) => reviewItem.key !== item.key
          )
        );

        if (firstItem !== -1) {
          scrollViewRef.current.scrollTo({ y: 72 * firstItem });
        } else {
          scrollViewRef.current.scrollToEnd();
        }
      }, 0);
    },
    [setReviewingItem_, report, scrollViewRef, reviewData]
  );

  useEffect(() => {
    if (hasOnlyOneItem) {
      setReviewingItem(report!._embedded[0].key);
    }
  }, [hasOnlyOneItem]);

  const [isConfirming, requestReviewConfirmation, ConfirmationDialog] =
    useConfirmationDialog(
      i18n.t(
        'assignments.confirmations.review'
      ) as unknown as ConfirmationDialogContent,
      {
        onConfirm: () => {
          review(reviewData);
        },
      }
    );

  const [spacer, setSpacer] = useState(0);

  useEffect(() => {
    function _keyboardDidShow(event: KeyboardEvent) {
      setSpacer(event.endCoordinates.height - 48 - 64);
    }

    function _keyboardDidHide(event: KeyboardEvent) {
      setSpacer(0);
    }

    const willShow = Keyboard.addListener('keyboardWillShow', _keyboardDidShow);
    const willHide = Keyboard.addListener('keyboardWillHide', _keyboardDidHide);

    // cleanup function
    return () => {
      willShow.remove();
      willHide.remove();
    };
  }, [setSpacer]);

  if (!report && error) {
    return (
      <HelperText
        type="error"
        style={{
          marginHorizontal: 12,
          marginTop: 8,
          includeFontPadding: false,
        }}
      >
        {error.message}
      </HelperText>
    );
  }

  if (isLoading || !report) {
    return <ActivityIndicator style={{ marginTop: 16 }} />;
  }

  if (!report.completed) {
    return (
      <HelperText
        type="error"
        style={{
          marginHorizontal: 12,
          marginTop: 8,
          includeFontPadding: false,
        }}
      >
        {}
      </HelperText>
    );
  }

  return (
    <View style={{ paddingVertical: 8 }}>
      {submissionError ? (
        <HelperText
          type="error"
          style={{ includeFontPadding: false, marginHorizontal: 12 }}
        >
          {submissionError.message}
        </HelperText>
      ) : null}
      {report._embedded.map((item) => {
        return reviewingItem === undefined || reviewingItem === item.key ? (
          <ReviewItem
            data={reviewData._embedded.find(
              (embedded) => embedded.key === item.key
            )}
            key={item.key}
            item={item}
            selected={reviewingItem === item.key}
            shouldApproveIndividually={Boolean(shouldApproveIndividually)}
            onSelect={() => {
              setReviewingItem(item.key);
            }}
            onReview={(data) => {
              setReviewData((prev) => {
                const nextEmbedded = prev._embedded.slice();
                const index = nextEmbedded.findIndex(
                  (embedded) => embedded.key === item.key
                );
                if (index === -1) {
                  nextEmbedded.push({ key: item.key, ...data });
                } else {
                  nextEmbedded.splice(index, 1, { key: item.key, ...data });
                }

                return {
                  ...prev,
                  _embedded: nextEmbedded,
                };
              });

              setReviewingItem(undefined);
            }}
          />
        ) : null;
      })}

      {reviewingItem === undefined ? (
        <Button
          mode="contained"
          disabled={!isReadyToSubmit || isSubmitting || isConfirming}
          loading={isSubmitting || isConfirming}
          onPress={requestReviewConfirmation}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
          style={{
            marginTop: 8,
            marginRight: 'auto',
            marginLeft: 16,
            marginBottom: 16,
          }}
          icon="send-check"
        >
          {i18n.t('reviews.draft.actions.submit')}
        </Button>
      ) : null}

      <View style={{ height: spacer }} />

      <Portal>
        <ConfirmationDialog />
      </Portal>
    </View>
  );
}

function ReviewItem({
  selected,
  shouldApproveIndividually,
  item,
  data,
  onSelect,
  onReview,
}: {
  selected: boolean;
  shouldApproveIndividually: boolean;
  item: ApplauseAssignmentReport['_embedded'][number];
  data: ApplauseAssignmentReviewSubmission['_embedded'][number] | undefined;
  onSelect(): void;
  onReview(data: {
    comment?: string;
    rating: string | number;
    approved?: boolean;
  }): void;
}) {
  const [comment, setComment] = useState<string | null>(null);
  const [rating, setRating] = useState<number | null>(null);
  const [approved, setApproved] = useState<boolean | null>(null);
  const ratingRef = useRef<null | NativeTextInput>(null);

  useEffect(() => {
    if (data) {
      setComment(data.comment ?? null);

      setRating(
        typeof data.rating === 'string'
          ? Number(data.rating)
          : data.rating ?? null
      );

      setApproved(shouldApproveIndividually ? data.approved ?? null : null);
    }
  }, [data]);

  if (!selected && data) {
    return (
      <List.Item
        style={{ paddingHorizontal: 16 }}
        title={keyToTitle(item.key)}
        titleStyle={{
          color: '#065f46',
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        onPress={onSelect}
        right={(props) => <List.Icon icon="check" color="#10b981" />}
      />
    );
  }

  if (!selected) {
    return (
      <List.Item
        style={{ paddingHorizontal: 16 }}
        title={keyToTitle(item.key)}
        titleStyle={{ includeFontPadding: false, textAlignVertical: 'center' }}
        right={(props) => <List.Icon icon="arrow-right" color={props.color} />}
        onPress={onSelect}
      />
    );
  }

  return (
    <Fragment>
      <ReportItem key={item.key} item={item} />
      <List.Subheader
        style={{
          marginTop: 16,
          paddingHorizontal: 24,
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
      >
        {i18n.t('reviews.draft.header')}
      </List.Subheader>
      <TextInput
        label={
          approved === false
            ? i18n.t('reviews.draft.comment_label_required')
            : i18n.t('reviews.draft.comment_label')
        }
        multiline
        style={{
          marginTop: 8,
          includeFontPadding: false,
          textAlignVertical: 'center',
          marginHorizontal: 24,
          maxHeight: 200,
        }}
        autoCapitalize="sentences"
        value={comment || ''}
        onChangeText={setComment}
        onSubmitEditing={() => ratingRef.current?.focus()}
      />
      <TextInput
        ref={ratingRef}
        label={i18n.t('reviews.draft.rating_label')}
        keyboardType="number-pad"
        style={{
          marginTop: 8,
          includeFontPadding: false,
          textAlignVertical: 'center',
          marginHorizontal: 24,
          maxWidth: 200,
          marginBottom: 8,
        }}
        value={rating ? String(rating) : ''}
        onChangeText={(n) =>
          n === ''
            ? setRating(null)
            : setRating(Math.max(1, Math.min(10, Number(n))))
        }
      />
      {shouldApproveIndividually ? (
        <RadioButton.Group
          onValueChange={(value) => setApproved(value === 'approved')}
          value={approved === null ? '' : approved ? 'approved' : 'disapproved'}
        >
          <RadioButton.Item
            label={i18n.t('reviews.draft.approve')}
            value="approved"
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
              paddingHorizontal: 12,
            }}
          />
          <RadioButton.Item
            label={i18n.t('reviews.draft.disapprove')}
            value="disapproved"
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
              paddingHorizontal: 12,
            }}
          />
        </RadioButton.Group>
      ) : null}
      {(comment || '').length === 0 && approved === false ? (
        <HelperText type="error">
          {i18n.t('reviews.draft.comment_not_optional')}
        </HelperText>
      ) : null}
      <Button
        mode="contained"
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        style={{
          marginHorizontal: 24,
        }}
        disabled={
          rating === null ||
          (shouldApproveIndividually && approved === null) ||
          (approved === false && !comment)
        }
        onPress={() =>
          onReview({
            comment: comment || undefined,
            rating: rating!,
            approved: approved === null ? undefined : approved,
          })
        }
      >
        {i18n.t('actions.continue')}
      </Button>
    </Fragment>
  );
}

function ReportItem({
  item,
}: {
  item: ApplauseAssignmentReport['_embedded'][number];
}) {
  return (
    <View key={item.key}>
      <Headline
        style={{
          paddingHorizontal: 24,
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
      >
        {keyToTitle(item.key)}
      </Headline>
      {item._links?.media ? (
        <Fragment>
          <Paragraph
            style={{
              marginHorizontal: 24,
              includeFontPadding: false,
              textAlignVertical: 'center',
              marginTop: 8,
              marginBottom: 16,
            }}
          >
            {item.note
              ? i18n.t('reviews.completed.proof_with_note')
              : i18n.t('reviews.completed.proof_without_note')}
            <Paragraph
              style={{
                includeFontPadding: false,
                textAlignVertical: 'center',
                color: '#63270b',
                fontStyle: 'italic',
              }}
            >
              {item.note}
            </Paragraph>
          </Paragraph>
          {item._links.media.type.startsWith('video') ? (
            <View style={{ marginHorizontal: 24 }}>
              <ReviewVideo href={item._links.media.href} />
            </View>
          ) : item._links.media.type.startsWith('image') ? (
            <View style={{ marginHorizontal: 24 }}>
              <ReviewImage href={item._links.media.href} />
            </View>
          ) : (
            <HelperText
              type="error"
              style={{
                marginHorizontal: 12,
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {i18n.t('reviews.states.proof_not_previewable')}
            </HelperText>
          )}
        </Fragment>
      ) : null}
    </View>
  );
}

function ActiveReview({
  href,
  baseCacheKey,
  reviewingItem,
  setReviewingItem,
}: {
  href: string | undefined;
  baseCacheKey: QueryKey;
  reviewingItem: string | undefined;
  setReviewingItem(next: string | undefined): void;
}) {
  const { data: review } = useReview(href);
  const { data: report } = useReport(review?._links.report.href, baseCacheKey);

  const hasOnlyOneItem = review && review._embedded.length === 1;

  return (
    <Fragment>
      {report && review
        ? review._embedded.map((item) => {
            return reviewingItem === undefined || reviewingItem === item.key ? (
              <ReviewedItem
                key={item.key}
                item={item}
                reportItem={report._embedded.find(
                  (reportItem) => reportItem.key === item.key
                )}
                selected={reviewingItem === item.key || Boolean(hasOnlyOneItem)}
                onSelect={() => {
                  setReviewingItem(item.key);
                }}
              />
            ) : null;
          })
        : null}
    </Fragment>
  );
}

function ReviewedItem({
  item,
  reportItem,
  selected,
  onSelect,
}: {
  item: ApplauseAssignmentReview['_embedded'][number];
  reportItem: ApplauseAssignmentReport['_embedded'][number] | undefined;
  selected: boolean;
  onSelect(): void;
}) {
  if (!reportItem) {
    return null;
  }

  if (!selected) {
    return (
      <List.Item
        style={{ paddingHorizontal: 16 }}
        title={keyToTitle(item.key)}
        titleStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          color: item.approved ? '#065f46' : '#991b1b',
        }}
        description={i18n.t('reviews.completed.rating', {
          rating: Number(item.rating).toFixed(1),
        })}
        descriptionStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        right={(props) => (
          <List.Icon
            icon="arrow-right"
            color={item.approved ? '#10b981' : '#dc2626'}
          />
        )}
        onPress={onSelect}
      />
    );
  }

  return (
    <View style={{ marginTop: 16 }}>
      <ReportItem key={item.key} item={reportItem} />
      {item.comment ? (
        <Paragraph
          style={{
            marginTop: 16,
            includeFontPadding: false,
            textAlignVertical: 'center',
            marginHorizontal: 24,
          }}
        >
          {item.comment}
        </Paragraph>
      ) : null}
      <List.Item
        title="Rating"
        titleStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          marginHorizontal: 8,
        }}
        right={() => (
          <Caption
            style={{
              includeFontPadding: false,
              textAlignVertical: 'center',
              fontSize: 16,
              marginVertical: 6,
              marginHorizontal: 16,
            }}
          >
            {Number(item.rating).toFixed(1)}
          </Caption>
        )}
      />
      <List.Item
        title={
          item.approved
            ? i18n.t('reviews.states.approved')
            : i18n.t('reviews.states.disapproved')
        }
        titleStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          marginHorizontal: 8,
        }}
      />
    </View>
  );
}

function ReviewList({
  items,
  onStartReviewing,
  onSelect,
}: {
  items: { href: string }[] | undefined;
  onStartReviewing?(): void;
  onSelect(next: string): void;
}) {
  if (!items) {
    return <SubmitReviewListItem onStartReviewing={onStartReviewing} />;
  }

  return (
    <Fragment>
      <SubmitReviewListItem onStartReviewing={onStartReviewing} />
      {items.map((item) => {
        return (
          <ReviewListItem
            key={item.href}
            href={item.href}
            onSelect={() => onSelect(item.href)}
          />
        );
      })}
    </Fragment>
  );
}

function SubmitReviewListItem({
  onStartReviewing,
}: {
  onStartReviewing?(): void;
}) {
  if (!onStartReviewing) {
    return null;
  }

  return (
    <Fragment>
      <List.Item
        style={{ paddingLeft: 16 }}
        onPress={onStartReviewing}
        title={i18n.t('reviews.draft.review_title')}
        description={i18n.t('reviews.draft.review_description')}
        titleStyle={{ includeFontPadding: false, textAlignVertical: 'center' }}
        descriptionStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        right={() => <List.Icon icon="chevron-right" />}
      />
    </Fragment>
  );
}

function ReviewListItem({
  href,
  onSelect,
}: {
  href: string;
  onSelect(): void;
}) {
  const { data: review, isLoading } = useReview(href);

  if (isLoading) {
    return (
      <Fragment>
        <List.Item
          left={() => <ActivityIndicator style={{ marginTop: 16 }} />}
          title=" "
        />
      </Fragment>
    );
  }

  if (!review) {
    return null;
  }

  if (review.average_rating === null) {
    return null;
  }

  const rating = review.average_rating
    ? Number(review?.average_rating).toFixed(1)
    : '?';
  return (
    <Fragment>
      <List.Item
        style={{ paddingLeft: 16 }}
        onPress={onSelect}
        title={i18n.t('reviews.completed.review_title', { rating })}
        titleStyle={{ includeFontPadding: false, textAlignVertical: 'center' }}
        description={
          review.approved === null
            ? undefined
            : review.approved
            ? i18n.t('reviews.states.approved')
            : i18n.t('reviews.states.disapproved')
        }
        descriptionStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        right={() => <List.Icon icon="chevron-right" />}
      />
    </Fragment>
  );
}

function ReviewImage({ href }: { href: string }) {
  return (
    <Image
      source={{ uri: href }}
      style={{
        width: 300,
        height: 300,
        backgroundColor: '#222',
        borderRadius: 5,
      }}
      resizeMode="contain"
    />
  );
}
