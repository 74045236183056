import { Platform } from 'react-native';
import { deviceYearClass, deviceName } from 'expo-device';
import Color from 'color';

export const DOMAIN_WHITELABEL = process.env.APPLAUSE_DOMAIN_WHITELABEL;
export const DOMAIN_APPLAUSE = process.env.APPLAUSE_DOMAIN;

export const NATIVE_DOMAIN = DOMAIN_WHITELABEL || DOMAIN_APPLAUSE;

export const INTERNAL_PREFIXES = [DOMAIN_APPLAUSE, DOMAIN_WHITELABEL]
  .filter(Boolean)
  .map((domain) => 'https://' + domain)
  .map((domain) => domain.toLocaleUpperCase());

export const COLOR_ANDROID_ICON = process.env.EXPO_ANDROID_ICON_COLOR;
export const COLOR_PRIMARY = process.env.EXPO_PRIMARY_COLOR!;
export const COLOR_SPLASH = process.env.EXPO_SPLASH_COLOR;
export const COLOR_BACKGROUND = process.env.EXPO_BACKGROUND_COLOR!;
export const COLOR_APPBAR_SURFACE =
  process.env.APPLAUSE_APPBAR_COLOR || '#FFFFFF';
export const COLOR_PRIMARY_DARK = new Color(COLOR_PRIMARY)
  .darken(0.15)
  .string();
export const COLOR_TEXT_ON_PRIMARY = new Color(COLOR_PRIMARY).isDark()
  ? '#FFFFFF'
  : '#111111';

export const EXPO_IDENTIFIER = process.env.EXPO_IDENTIFIER!;
export const EXPO_VERSION = process.env.EXPO_VERSION!;
export const EXPO_SLUG = process.env.EXPO_SLUG!;
export const EXPO_NAME = process.env.EXPO_NAME!;
export const APPLAUSE_ENDPOINT = process.env.APPLAUSE_ENDPOINT!;
export const APPLAUSE_APP_NAME = process.env.APPLAUSE_APP_NAME!;
export const SPLASH_RESIZE_MODE = process.env.EXPO_SPLASH_RESIZE_MODE;
export const EXPO_BUILD_NUMBER = process.env.EXPO_BUILD_NUMBER!;

export const EXPERIENCE_SLUG = `@applause/${EXPO_SLUG || 'unknown'}`;
export const SENTRY_DSN = process.env.SENTRY_DSN!;

const likeness = `${Platform.OS} ${Platform.Version} like ${
  deviceName || 'NoName'
}`.replace(/[^-._A-z0-9]/g, '');
export const USER_AGENT = `${EXPO_IDENTIFIER}/${EXPO_VERSION} (${Platform.OS}; ${deviceYearClass}, ${likeness}) Expo/EAS (React-Native; Slug ${EXPO_SLUG}; Build ${EXPO_BUILD_NUMBER})`;
