import { useIsFocused, useNavigation } from '@react-navigation/core';
import React, { Fragment, useCallback, useMemo } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  StyleSheet,
} from 'react-native';
import { Card, Divider, List, useTheme } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { AssignmentListItem } from '../assignments/AssignmentListItem';
import { AssignmentThumbnailIcon } from '../assignments/AssignmentThumbnailIcon';
import { encode } from '../base';
import { BookingListItem } from '../bookings/BookingListItem';
import { ApplauseBooking, useBooking } from '../bookings/useBookings';
import { Notifications } from '../components/Notifications';
import { TabbedScreen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { APPLAUSE_APP_NAME } from '../config';
import { useConfiguration } from '../hooks/useConfiguration';
import { i18n } from '../locale';
import { useProperties } from '../properties/useProperties';
import { hrefAsKeyExtractor } from '../utils/hrefAsKeyExtractor';
import {
  ApplauseEvent,
  ApplauseEventsIndex,
  useEvent,
  useEvents,
} from './useEvents';

const EMPTY: ApplauseEvent['_links']['self'][] = [];

export function DashboardScreen() {
  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const { data, refetch, isLoading, isFetching } = useEvents({
    enabled: isFocused,
  });

  const items = data?.events._index || EMPTY;

  return (
    <TabbedScreen>
      <ScreenHeader title={i18n.t('dashboard.title')} />
      <FlatList
        style={tailwind('flex-1')}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full pt-4 pb-4'
        )}
        data={items}
        renderItem={useMemo(
          () => makeRenderEvent(items.length),
          [items.length]
        )}
        keyExtractor={hrefAsKeyExtractor}
        ListHeaderComponent={Heading}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
      />
      <GetService />
    </TabbedScreen>
  );
}

function Heading() {
  const tailwind = useTailwind();
  const { navigate } = useNavigation<any>();
  const { data: configuration } = useConfiguration();
  const {
    colors: { surface, shadow },
  } = useTheme();
  return (
    <Fragment>
      <List.Subheader
        style={[
          tailwind('px-6'),
          { includeFontPadding: false, textAlignVertical: 'center' },
        ]}
      >
        {i18n.t('dashboard.sections.quick-actions.title')}
      </List.Subheader>
      <Card
        style={[
          tailwind('mb-2'),
          {
            borderRadius: 4,
            borderWidth: StyleSheet.hairlineWidth,
            borderStyle: 'solid',
            backgroundColor: surface,
            borderColor: shadow,
          },
        ]}
        elevation={0}
      >
        {configuration?.capabilities.can_book ? (
          <List.Item
            style={{ paddingLeft: 12 }}
            left={(props) => <List.Icon color={props.color} icon="beach" />}
            title={i18n.t('dashboard.actions.book', { app: APPLAUSE_APP_NAME })}
            titleStyle={{ fontSize: 14 }}
            onPress={() => navigate('BookingAreas')}
          />
        ) : null}
        {configuration?.capabilities.can_own ? <BookOwnPropertyAction /> : null}
        {configuration?.capabilities.can_manage ? null : (
          <List.Item
            style={{ paddingLeft: 12 }}
            left={(props) => <List.Icon color={props.color} icon="chat" />}
            title={i18n.t('dashboard.actions.chat')}
            titleStyle={{ fontSize: 14 }}
            onPress={() => navigate('Chat')}
          />
        )}
      </Card>
      <Notifications />
      <List.Subheader
        style={[
          tailwind('px-6 mt-4'),
          { includeFontPadding: false, textAlignVertical: 'center' },
        ]}
      >
        {i18n.t('dashboard.sections.recent-events.title')}
      </List.Subheader>
    </Fragment>
  );
}

function BookOwnPropertyAction() {
  const { navigate } = useNavigation<any>();
  const { data, isLoading } = useProperties();

  return (
    <List.Item
      disabled={isLoading}
      style={{ paddingLeft: 12 }}
      left={(props) => <List.Icon color={props.color} icon="calendar" />}
      title={i18n.t('dashboard.actions.stay')}
      titleStyle={{ fontSize: 14 }}
      onPress={() =>
        data?.properties?._index?.length === 1
          ? navigate('ReserveProperty', {
              href: encode(data.properties._index[0].href),
            })
          : navigate('Properties')
      }
    />
  );
}

function GetService(): JSX.Element | null {
  return null;
  /*
  return (
    <MotiView
      from={{ translateY: 72 }}
      animate={{ translateY: 0 }}
      style={[tailwind('relative'), { height: 72 }]}
    >
      <MotiView
        style={[
          tailwind('absolute bottom-0 left-0 right-0 bg-white'),
          { height: 72 + 24 },
        ]}
        from={{ translateY: 72 + 24 }}
        animate={{ translateY: 24 }}
      >
        <Divider style={{ height: StyleSheet.hairlineWidth }} />
        <View style={tailwind('py-4')}>
          <Button
            mode="contained"
            compact
            uppercase={false}
            style={{
              alignSelf: 'center',
            }}
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
              paddingHorizontal: 6,
            }}
            icon="arrow-up"
            disabled
            onPress={() => {}}
          >
            {i18n.t('dashboard.actions.upgrade')}
          </Button>
        </View>
      </MotiView>
    </MotiView>
  );
  */
}

function makeRenderEvent(count: number) {
  return function renderEvent({
    item,
    index,
  }: ListRenderItemInfo<ApplauseEvent['_links']['self']>) {
    return (
      <EventListItem
        {...item}
        isFirst={index === 0}
        isLast={index === count - 1}
      />
    );
  };
}

function EventListItem({
  href,
  type,
  i18n_type,
  isFirst,
  isLast,
}: ApplauseEvent['_links']['self'] & {
  isLast: boolean;
  isFirst: boolean;
}) {
  const enabled = useIsFocused();
  const { data } = useEvent(href, { enabled });
  const { roundness } = useTheme();

  switch (type) {
    case 'assignment': {
      return (
        <Fragment>
          <AssignmentListItem
            href={data?._links.assignment?.href}
            roundTop={isFirst}
            roundBottom={isLast}
          />
          {isLast ? null : (
            <Divider style={{ height: StyleSheet.hairlineWidth }} />
          )}
        </Fragment>
      );
    }

    case 'booking': {
      if (i18n_type === 'new_reservation') {
        return (
          <Fragment>
            <BookingListItem
              href={data?._links.booking?.href}
              roundTop={isFirst}
              roundBottom={isLast}
            />

            {isLast ? null : (
              <Divider style={{ height: StyleSheet.hairlineWidth }} />
            )}
          </Fragment>
        );
      }
    }
  }

  return (
    <Card
      elevation={1}
      style={{
        borderTopStartRadius: isFirst ? roundness : 0,
        borderTopEndRadius: isFirst ? roundness : 0,
        borderBottomStartRadius: isLast ? roundness : 0,
        borderBottomEndRadius: isLast ? roundness : 0,
        overflow: 'hidden',
      }}
    >
      {data ? (
        <GenericEventListItem data={data} i18n_type={i18n_type} type={type} />
      ) : (
        <List.Item title=" " style={{ height: 72 }} />
      )}
      <Divider style={{ height: StyleSheet.hairlineWidth }} />
    </Card>
  );
}

function GenericEventListItem({
  data,
  i18n_type,
  type,
}: {
  data: ApplauseEvent;
} & Pick<
  ApplauseEventsIndex['events']['_index'][number],
  'i18n_type' | 'type'
>) {
  const tailwind = useTailwind();

  const { navigate } = useNavigation<any>();
  const onPress = useCallback(() => {
    if (data._links.assignment) {
      return navigate('Assignment', {
        href: encode(data._links.assignment.href),
      });
    }

    if (data._links.booking) {
      return navigate('Booking', {
        href: encode(data._links.booking.href),
      });
    }

    if (data._links.property) {
      return navigate('Property', {
        href: encode(data._links.property.href),
      });
    }

    return null;
  }, [data]);

  const hasLink = Boolean(
    data._links.assignment || data._links.booking || data._links.property
  );

  if (data._links.booking) {
    return (
      <BookingEventListItem
        type={type}
        data={data}
        i18n_type={i18n_type}
        event_source={data._links.event_source?.name}
        onPress={hasLink ? onPress : undefined}
      />
    );
  }

  return (
    <List.Item
      left={(props) => <List.Icon icon={iconFor(type)} color={props.color} />}
      title={data._links.event_source?.name ?? i18n.t(`events.${i18n_type}`)}
      style={tailwind('bg-white')}
      onPress={hasLink ? onPress : undefined}
    />
  );
}

function BookingEventListItem({
  type,
  data,
  i18n_type,
  event_source,
  onPress,
}: {
  data: ApplauseEvent;
  onPress: undefined | (() => void);
} & Pick<
  ApplauseEventsIndex['events']['_index'][number],
  'i18n_type' | 'type'
> &
  Partial<{ event_source: string }>) {
  const enabled = useIsFocused();
  const { data: configuration } = useConfiguration();
  const { data: booking } = useBooking(data._links.booking?.href, {
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const isManager = !!configuration?.capabilities.can_manage;
  const tailwind = useTailwind();

  return (
    <List.Item
      left={(props) => (
        <AssignmentThumbnailIcon
          icon={iconFor(type)}
          image={booking?._links.thumbnail?.href}
          props={props}
        />
      )}
      title={titleForBooking(booking, { i18n_type, event_source })}
      titleNumberOfLines={2}
      titleStyle={{ includeFontPadding: false }}
      description={descriptionForBooking(booking, { i18n_type, event_source })}
      descriptionStyle={[tailwind('pt-0 mt-1'), { includeFontPadding: false }]}
      style={tailwind('bg-white')}
      onPress={onPress}
    />
  );
}

function titleForBooking(
  data: ApplauseBooking | undefined,
  {
    i18n_type,
    event_source,
  }: Partial<{ i18n_type: string; event_source: string }>
) {
  if (!data) {
    return '...';
  }

  return [
    data?._links.property?.reference
      ? `[${data?._links.property?.reference}]`
      : null,
    data?._links.booking_user?.display_name
      ? `${data?._links.booking_user?.display_name}`
      : null,
  ]
    .filter(Boolean)
    .join('  ');
}

function descriptionForBooking(
  data: ApplauseBooking | undefined,
  {
    i18n_type,
    event_source,
  }: Partial<{ i18n_type: string; event_source: string }>
) {
  return event_source ?? i18n.t(`events.${i18n_type}`);
}

function iconFor(type: string): string {
  switch (type) {
    case 'booking': {
      return 'credit-card-outline';
    }
    case 'property': {
      return 'home-city-outline';
    }
    case 'assignment': {
      return 'bed-king-outline';
    }
    case 'user': {
      return 'account-outline';
    }
    case 'usergroup': {
      return 'account-group-outline';
    }
    default: {
      return 'information-outline';
    }
  }
}
