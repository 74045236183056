import { useState, useEffect } from 'react';
import { InteractionManager } from 'react-native';

let timeshift: number = 0;

export function setTimeshift(shift: number) {
  timeshift = shift;
}

export function setTime(time: number) {
  return setTimeshift(new Date(time).getTime() - new Date().getTime());
}

export function getCurrentTime() {
  return new Date().getTime() + timeshift;
}

export function useTimestamp({ every }: { every: number }) {
  const [timestamp, setTimestamp] = useState(() => getCurrentTime());

  useEffect(() => {
    let active = true;

    const timeout = setTimeout(
      () => active && setTimestamp(() => getCurrentTime()),
      every
    );

    return () => {
      active = false;
      clearTimeout(timeout);
    };
  }, [timestamp]);

  return timestamp;
}

export function useInaccurateTimestamp({ every }: { every: number }): number {
  const [timestamp, setTimestamp] = useState(() => getCurrentTime());

  useEffect(() => {
    let active = true;

    // Doesn't update the timestamp until interactions are done
    const timeout = setTimeout(() => {
      InteractionManager.runAfterInteractions(
        () => active && setTimestamp(() => getCurrentTime())
      );
    }, every);

    return (): void => {
      active = false;
      clearTimeout(timeout);
    };
  }, [timestamp]);

  return timestamp;
}
