import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export function useAction(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    onSettled,
    ...options
  }: UseMutationOptions<void, FetchMediaError, void, void> = {}
) {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const locale = useLocale();

  const mutationFn: MutationFunction<void, void> = useCallback(
    async (formData) => {
      if (!href) {
        throw new Error('No href for claim assignment');
      }

      if (!auth.current?.['access-token']) {
        throw new Error('Not authenticated');
      }

      await fetchMedia(href!, {
        headers: {
          accept: 'text/html',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          // contentType: 'application/vnd.bnbbutler.booking.v1.check-in',
          ...(auth.current as Record<string, string>),
        },
        method: 'POST',
        // body: formData,
        debug: __DEV__,
      });
    },
    [href, auth, locale]
  );

  return useMutation(cacheKey, mutationFn, {
    ...options,
    onSettled: async (data, error, variables, context) => {
      await Promise.all([
        queryClient.cancelQueries([locale, 'assignment', 'list']),
        queryClient.cancelQueries([locale, 'event', 'list']),
        queryClient.cancelQueries(cacheKey),
      ]);

      await Promise.all([
        queryClient.invalidateQueries([locale, 'assignment', 'list']),
        queryClient.invalidateQueries([locale, 'event', 'list']),
        queryClient.invalidateQueries(cacheKey),
      ]);

      onSettled && onSettled(data, error, variables, context);
    },
  });
}
