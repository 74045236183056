import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useMemo } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  View,
} from 'react-native';
import { ActivityIndicator, Card, List } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { decode, encode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { RouteParamList } from '../navigation/routes';
import { hrefAsKeyExtractor } from '../utils/hrefAsKeyExtractor';
import { BrandItem, BrandsGrid } from './BrandsGrid';
import { UpgradeBrandScreen } from './UpgradeBrandScreen';
import { UpgradeCategoryScreen } from './UpgradeCategoryScreen';
import { categoryCacheKey, useCategory } from './useCategory';
import {
  ApplauseService,
  ApplauseServicesResponse,
  useServices,
} from './useServices';

type UpgradeRoute = RouteProp<RouteParamList, 'Upgrade'>;
type UpgradeNavigation = StackNavigationProp<RouteParamList, 'Upgrade'>;

export function UpgradeScreen() {
  useForceAuthenticated();

  const {
    href: encodedHref,
    brand: filterBrandHref,
    category: filterCategoryHref,
  } = useRoute<UpgradeRoute>().params;

  if (filterCategoryHref) {
    return (
      <UpgradeCategoryScreen href={encodedHref} category={filterCategoryHref} />
    );
  }

  if (filterBrandHref) {
    return <UpgradeBrandScreen href={encodedHref} brand={filterBrandHref} />;
  }

  return <UpgradeMenuScreen href={encodedHref} />;
}

function UpgradeMenuScreen({ href: encodedHref }: { href: string }) {
  const href = decode(encodedHref);
  const isFocused = useIsFocused();
  const tailwind = useTailwind();
  const locale = useLocale();

  const {
    data: services,
    refetch,
    isFetching,
    isLoading,
  } = useServices(href, [locale, href, 'service', 'list'], {
    enabled: isFocused,
    notifyOnChangeProps: ['data', 'isFetching', 'isLoading'],
  });

  const brands = useBrands(services?.services);
  const categories = useCategories(services?.services);
  const groups = useGroups(categories, brands);

  return (
    <Screen>
      <ScreenHeader title={i18n.t('service-request.title')} showBack />

      <FlatList
        style={tailwind('flex-1')}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full p-4 pt-0 pb-6'
        )}
        data={groups}
        renderItem={renderUpgradeGroup}
        keyExtractor={extractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
      />
    </Screen>
  );
}

function extractor<TItem extends UpgradeGroup>(item: TItem, index: number) {
  if (item.type === 'empty' || item.type === 'brands') {
    return item.type;
  }

  if (item.type === 'header') {
    return item.label;
  }

  return hrefAsKeyExtractor(item, index);
}

type UpgradeGroup =
  | {
      type: 'category';
      href: string;
      name: string;
    }
  | {
      type: 'brands';
      brands: BrandItem[];
    }
  | {
      type: 'header';
      label: string;
    }
  | {
      type: 'empty';
    };

function renderUpgradeGroup({ item }: ListRenderItemInfo<UpgradeGroup>) {
  return <Group {...item} />;
}

function Group(item: UpgradeGroup) {
  switch (item.type) {
    case 'empty': {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 200,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    }

    case 'header': {
      return (
        <List.Subheader
          style={{
            marginTop: 8,
            paddingLeft: 0,
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
        >
          {item.label}
        </List.Subheader>
      );
    }

    case 'brands': {
      return <BrandsGrid brands={item.brands} />;
    }

    case 'category': {
      return <CategoryButton href={item.href} name={item.name} />;
    }

    default: {
      return null;
    }
  }
}

function CategoryButton({
  href,
  name,
}: Omit<UpgradeGroup & { type: 'category' }, 'type'>) {
  const isFocused = useIsFocused();
  const locale = useLocale();

  const { push } = useNavigation<UpgradeNavigation>();
  const { href: encodedHref } = useRoute<UpgradeRoute>().params;

  const { data } = useCategory(href, categoryCacheKey(href, locale), {
    notifyOnChangeProps: ['data'],
    enabled: isFocused,
  });

  const category = data?.category;
  const icon = category?._links.icon?.href;

  return (
    <Card
      style={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 8,
        marginBottom: 12,
        backgroundColor: '#222',
      }}
      onPress={() =>
        push('Upgrade', {
          href: encodedHref,
          category: encode(href),
          brand: undefined,
        })
      }
    >
      {icon ? (
        <Card.Cover source={{ uri: icon }} />
      ) : (
        <View style={{ height: 200 }} />
      )}
      <LinearGradient
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          overflow: 'hidden',
          borderRadius: 8,
        }}
        colors={['#22222200', '#22222288']}
        locations={[0.5, 1]}
      />
      <Card.Title
        title={name}
        style={{ position: 'absolute', bottom: 0 }}
        titleStyle={{
          color: '#f5f5f5',
          textShadowColor: '#222',
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 1,
        }}
        titleVariant='titleMedium'
      />
    </Card>
  );
}

function useGroups(
  categories?: NonNullable<ApplauseService['_links']['category']>[] | undefined,
  brands?: NonNullable<ApplauseService['_links']['brand']>[] | undefined
) {
  return useMemo((): UpgradeGroup[] => {
    const results: UpgradeGroup[] = [];

    if (categories && categories.length > 0) {
      results.push({
        type: 'header',
        label: i18n.t('services.headers.categories'),
      });

      results.push(
        ...categories.map((category) => ({
          type: 'category' as const,
          ...category,
        }))
      );
    }

    if (brands && brands.length > 0) {
      results.push({
        type: 'header',
        label: i18n.t('services.headers.brands'),
      });

      results.push({
        type: 'brands',
        brands,
      });
    }

    if (brands && categories && results.length === 0) {
      results.push({ type: 'empty' });
    }

    return results;
  }, [categories, brands]);
}

function useCategories(
  services: ApplauseServicesResponse['services'] | undefined
) {
  return useMemo(() => {
    const result: Record<
      string,
      NonNullable<ApplauseService['_links']['category']>
    > = {};

    services?._embedded.forEach(({ service }) => {
      if (service._links.category) {
        result[service._links.category.href] = service._links.category;
      }
    });

    return Object.values(result)
      .filter(Boolean)
      .sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
          return a.order - b.order;
        }

        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }

        return a.href.localeCompare(b.href);
      });
  }, [services]);
}

function useBrands(services: ApplauseServicesResponse['services'] | undefined) {
  return useMemo(() => {
    const result: Record<
      string,
      NonNullable<ApplauseService['_links']['brand']>
    > = {};

    services?._embedded.forEach(({ service }) => {
      if (service._links.brand) {
        result[service._links.brand.href] = service._links.brand;
      }
    });

    return Object.values(result)
      .filter(Boolean)
      .sort((a, b) => (a!.name ?? a.href).localeCompare(b!.name ?? b.href));
  }, [services]);
}
