import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseBrand = {
  _links: {
    self: {
      href: string;
    };
    logo?: {
      href: string;
    };
  };

  reaction_time?: number;
  rating?: number;

  name: string;
  about?: string;
};

export type ApplauseBrandResponse = {
  brand: ApplauseBrand;
};

export function useBrand(
  href: string | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseBrandResponse, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(cacheKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.brand.v3+json',
            'application/vnd.bnbbutler.brand.v2+json; q=0.9',
            'application/vnd.bnbbutler.brand.v1+json; q=0.8',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      }).then((response) => response as ApplauseBrandResponse),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...options,
  });
}

export function brandCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'brand', `${href?.split('/').pop() || '-'}`];
}
