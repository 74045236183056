import { useIsFocused, useRoute } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as Clipboard from 'expo-clipboard';
import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  View,
} from 'react-native';
import {
  Button,
  Caption,
  Card,
  Checkbox,
  Dialog,
  Divider,
  FAB,
  HelperText,
  List,
  Paragraph,
  Portal,
  TextInput,
  Title,
  ThemeProvider,
  useTheme,
} from 'react-native-paper';
import Animated from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { QueryKey } from 'react-query';
import { useTailwind } from 'tailwind-rn';
import { useIsMounted } from 'use-is-mounted';
import { ReviewsAction } from '../assignments/ReviewsAction';
import {
  assignmentCacheKey,
  useAssignment,
  useBookingAssignments,
} from '../assignments/useAssignments';
import { decode, encode } from '../base';
import { DocumentAttachment } from '../components/DocumentAttachment';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { COLOR_PRIMARY } from '../config';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useColor } from '../hooks/useColor';
import { useWindowWidth } from '../hooks/useDimensions';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { RouteParamList } from '../navigation/routes';
import { PropertyCard } from '../properties/PropertyCard';
import { ApplauseProperty, useProperty } from '../properties/useProperties';
import { Sentry } from '../sentry';
import { datetime } from '../utils/date';
import { openExternalUrl } from '../utils/useOpenUrl';
import { ApplauseBooking, bookingCacheKey, useBooking } from './useBookings';
import { useAcceptCheckIn, useCheckIn, useCheckOut } from './useCheckIn';
import { useGuests, useUser } from './useUsers';
import { MD2_BASE_THEME, MD2_DARK_BASE_THEME } from '../constants/Theming';
import useColorScheme from '../hooks/useColorScheme';

export function BookingScreen() {
  useForceAuthenticated();

  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const { href: encodedHref } = useRoute().params as {
    href: string;
    image?: string;
  };
  const locale = useLocale();
  const href = decode(encodedHref);
  const baseCacheKey = bookingCacheKey(href, locale);

  const { data, isLoading } = useBooking(href, {
    enabled: isFocused,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
  });
  const insets = useSafeAreaInsets();

  const title = i18n.t('bookings.show.title');
  const isLarge = useWindowWidth() > 768;
  const hasCheckedOut = !!data?.dates?.checked_out;

  return (
    <Screen>
      <ScreenHeader title={isLoading ? 'Retrieving...' : title} showBack />
      <ScrollView
        style={{ flex: 1, backgroundColor: 'transparent' }}
        contentContainerStyle={[
          tailwind(`max-w-3xl w-full self-center ${isLarge ? 'mt-4' : ''}`),
          { paddingBottom: 64 },
        ]}
      >
        <Card
          style={[
            { minHeight: 92 },
            tailwind('w-full mb-4'),
            isLarge ? {} : { borderRadius: 0 },
          ]}
        >
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View
              style={{
                flex: 1,
                minWidth: 200,
              }}
            >
              <CheckIn
                data={data}
                baseCacheKey={baseCacheKey}
                propertyHref={data?._links.property?.href}
                documentHref={data?._links.document?.href}
                assignmentsHref={data?._links.assignments?.href}
                note={data?.note}
              />
            </View>
            <View
              style={{
                flex: 1,
                minWidth: 200,
              }}
            >
              <CheckOut
                data={data}
                baseCacheKey={baseCacheKey}
                assignmentsHref={data?._links.assignments?.href}
              />
            </View>
          </View>
        </Card>

        <GuestsCard
          href={data?._links.guests.href}
          count={data?._links.guests.count ?? 0}
        />

        <BookingCard data={data} />

        <PropertyData href={data?._links.property?.href} />

        <View
          style={[{ maxWidth: 364 }, tailwind('self-center w-full mb-4 px-4')]}
        >
          <PropertyCard href={data?._links.property?.href} />
        </View>
      </ScrollView>
      {hasCheckedOut ? null : (
        <Animated.View
          style={[
            {
              position: 'absolute',
              bottom: 16 + insets.bottom,
              right: 16 + insets.right,
            },
          ]}
        >
          <BookingUpgradeAction href={data?._links.services.href} />
        </Animated.View>
      )}
    </Screen>
  );
}

function CheckIn({
  data,
  baseCacheKey,
  propertyHref,
  documentHref,
  assignmentsHref,
  note,
}: {
  data: ApplauseBooking | undefined;
  baseCacheKey: QueryKey;
  propertyHref: string | undefined;
  documentHref: string | undefined;
  assignmentsHref: string | undefined;
  note: string | null | undefined;
}) {
  const tailwind = useTailwind();
  const hasCheckedIn = !!data?.dates.checked_in;
  const hasCheckInData =
    !hasCheckedIn && !!data?.dates.check_in && data._links.check_in;
  const hasAcceptCheckInData =
    hasCheckedIn && !!data?.dates.checked_in && data._links.accept;
  const canMaybeRateHouseKeeping =
    Boolean(data?.dates.checked_in) && assignmentsHref;

  return (
    <View style={tailwind('px-4 my-2')}>
      <Caption>{hasCheckedIn ? 'Checked-in' : 'Check-in'}</Caption>
      <Title>
        {datetime(data?.dates.checked_in || data?.dates.check_in) ||
          i18n.t('bookings.states.tbd')}{' '}
      </Title>
      {hasCheckInData ? (
        <CheckInAction
          date={data!.dates.check_in}
          href={data!._links.check_in!.href}
          baseCacheKey={baseCacheKey}
          propertyHref={propertyHref}
        />
      ) : null}
      {hasAcceptCheckInData ? (
        <AcceptCheckInAction
          href={data!._links.accept!.href}
          baseCacheKey={baseCacheKey}
          documentHref={documentHref}
          note={note}
        />
      ) : null}
      {canMaybeRateHouseKeeping ? (
        <RateHouseKeepingPreAction href={assignmentsHref!} />
      ) : null}
    </View>
  );
}

function AcceptCheckInAction({
  href,
  baseCacheKey,
  documentHref,
  note,
}: {
  href: string;
  baseCacheKey: QueryKey;
  documentHref: string | undefined;
  note: string | null | undefined;
}) {
  const tailwind = useTailwind();
  const {
    colors: { primary, secondary: accent },
  } = useTheme();
  const isMountedRef = useIsMounted();
  const [isAccepting, setAccepting] = useState(false);
  const { mutateAsync, isLoading, error } = useAcceptCheckIn(
    href,
    baseCacheKey
  );

  return (
    <Fragment>
      <Button
        mode="contained"
        disabled={isLoading}
        loading={isLoading}
        icon="check-all"
        onPress={() => {
          setAccepting(true);
        }}
        style={tailwind('mt-2 mb-3 self-start')}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 6,
        }}
      >
        {i18n.t('bookings.actions.accept-check-in')}
      </Button>
      <Portal>
        <Dialog
          visible={isAccepting}
          onDismiss={() => setAccepting(false)}
          dismissable={!isLoading}
          style={tailwind('max-w-xl self-center w-full')}
        >
          <Dialog.Title>
            {i18n.t('bookings.accept-check-in.title')}
          </Dialog.Title>
          <Dialog.ScrollArea style={{ maxHeight: 400, paddingHorizontal: 0 }}>
            <ScrollView
              contentContainerStyle={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <Button
                icon="passport"
                mode="contained"
                disabled={!documentHref}
                onPress={() =>
                  documentHref && openExternalUrl(documentHref, primary)
                }
                style={tailwind('mb-2')}
                labelStyle={{
                  includeFontPadding: false,
                  textAlignVertical: 'center',
                  paddingHorizontal: 6,
                }}
              >
                {documentHref
                  ? i18n.t('bookings.actions.passport.see')
                  : i18n.t('bookings.states.no-passport')}
              </Button>
              <Paragraph>{note || i18n.t('bookings.states.no-note')}</Paragraph>
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions style={tailwind('py-4')}>
            <Button
              mode="text"
              color={accent}
              disabled={isLoading}
              loading={isLoading}
              onPress={() => {
                setAccepting(false);
              }}
              style={tailwind('mr-2')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {i18n.t('actions.cancel')}
            </Button>
            <Button
              mode="contained"
              loading={isLoading}
              icon="check-all"
              onPress={() => {
                mutateAsync()
                  .then(() => isMountedRef.current && setAccepting(false))
                  .catch(() => {});
              }}
              style={tailwind('mr-1')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
                paddingHorizontal: 6,
              }}
            >
              {i18n.t('bookings.actions.accept-check-in')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}

function CheckInAction({
  date,
  href,
  baseCacheKey,
  propertyHref,
}: {
  date: string;
  href: string;
  propertyHref: string | undefined;
  baseCacheKey: QueryKey;
}) {
  const tailwind = useTailwind();
  const { mutateAsync, isLoading, error } = useCheckIn(href, baseCacheKey);
  const [isCheckingIn, setCheckingIn] = useState(false);
  const [hasHouseRulesAccepted, setHouseRulesAccepted] = useState(false);

  const isMountedRef = useIsMounted();
  const [formData, setFormData] = useState<FormData | null>(null);
  const noteRef = useRef('');

  const [billingCompanyName, setBillingCompanyName] = useState('');
  const [billingCompanyVatId, setBillingCompanyVatId] = useState('');
  const contactAddressRef = useRef('');
  const billingAddressRef = useRef('');
  const [copyContactAddress, toggleCopyContactAddress] = useReducer(
    (prev) => !prev,
    false
  );

  const { top, bottom, left, right } = useSafeAreaInsets();

  return (
    <Fragment>
      <Button
        mode="contained"
        disabled={isLoading || isCheckingIn}
        loading={isLoading || isCheckingIn}
        icon="check"
        style={tailwind('mt-2 mb-3 mx-2')}
        onPress={() => setCheckingIn(true)}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 6,
        }}
      >
        {i18n.t('bookings.actions.check-in')}
      </Button>
      {/* @ts-ignore */}
      <Portal>
        <Dialog
          visible={isCheckingIn}
          onDismiss={() => setCheckingIn(false)}
          dismissable={!isLoading}
          style={[
            tailwind('max-w-xl w-full h-full'),
            {
              marginTop: top,
              marginBottom: bottom,
              marginLeft: left,
              marginRight: right,
              maxHeight: 600,
            },
          ]}
        >
          <Dialog.Title>{i18n.t('bookings.check-in.title')}</Dialog.Title>
          <Dialog.ScrollArea style={{ flex: 1, paddingHorizontal: 0, overflow: 'scroll' }}>
            <KeyboardAvoidingView behavior="padding">
              <ScrollView
                contentContainerStyle={{
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                }}
              >
                <HouseRules href={propertyHref} />
                <AcceptHouseRules
                  checked={hasHouseRulesAccepted}
                  onChanged={(next) => setHouseRulesAccepted(next)}
                />
                <DocumentAttachment
                  onChanged={setFormData}
                  title={
                    formData
                      ? i18n.t('bookings.actions.passport.change')
                      : i18n.t('bookings.actions.passport.add')
                  }
                  icon="passport"
                  formDataName="identification"
                />
                <Address
                  label={i18n.t('bookings.fields.contact_address.label')}
                  onChanged={(next) => {
                    contactAddressRef.current = next;
                  }}
                />
                <Divider style={{ marginVertical: 16 }} />
                <TextInput
                  label={i18n.t('bookings.fields.company_name.label')}
                  value={billingCompanyName}
                  onChangeText={setBillingCompanyName}
                  style={{
                    includeFontPadding: false,
                  }}
                />
                {billingCompanyName ? (
                  <TextInput
                    label={i18n.t('bookings.fields.company_vat.label')}
                    value={billingCompanyVatId}
                    onChangeText={setBillingCompanyVatId}
                    style={{ marginTop: 8, includeFontPadding: false }}
                  />
                ) : null}
                <View
                  style={{
                    borderColor: useColor('text-gray-500'),
                    borderWidth: 1,
                    borderRadius: useTheme().roundness,
                    marginTop: 8,
                  }}
                >
                  <Checkbox.Item
                    label={i18n.t('bookings.fields.copy_address.label')}
                    status={copyContactAddress ? 'checked' : 'unchecked'}
                    onPress={() => toggleCopyContactAddress()}
                  />
                </View>
                {copyContactAddress ? null : (
                  <Address
                    label={i18n.t('bookings.fields.billing_address.label')}
                    onChanged={(next) => {
                      billingAddressRef.current = next;
                    }}
                    required={!!billingCompanyName}
                  />
                )}
                <Divider style={{ marginVertical: 16 }} />
                <Note
                  onChanged={(next) => {
                    noteRef.current = next;
                  }}
                />
              </ScrollView>
            </KeyboardAvoidingView>
          </Dialog.ScrollArea>
          {error && !isLoading ? (
            <HelperText type="error" style={tailwind('m-4')}>
              {error.message}
            </HelperText>
          ) : null}
          <Dialog.Actions style={tailwind('py-4')}>
            <Button
              mode="text"
              color={useTheme().colors.secondary}
              disabled={isLoading}
              loading={isLoading}
              onPress={() => {
                setCheckingIn(false);
              }}
              style={tailwind('mr-2')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {i18n.t('actions.cancel')}
            </Button>
            <Button
              mode="contained"
              disabled={!formData || !hasHouseRulesAccepted}
              loading={isLoading}
              icon="check"
              onPress={() => {
                const nextFormData = formData!;

                const billingAddress = copyContactAddress
                  ? contactAddressRef.current
                  : billingAddressRef.current;

                if (billingCompanyName) {
                  if (!billingAddress || !billingCompanyVatId) {
                    Alert.alert(
                      'Oops! Missing information',
                      'You must fill in the billing address and VAT ID or remove the company name.'
                    );
                    console.error(
                      'You must fill in the billing address and VAT ID or remove the company name.'
                    );
                    return;
                  }
                }

                if (noteRef.current) {
                  nextFormData.append('note', noteRef.current);
                }

                if (contactAddressRef.current) {
                  nextFormData.append(
                    'contact_address',
                    contactAddressRef.current
                  );
                } else {
                  Alert.alert(
                    'Oops! Missing information',
                    'Please fill in an address.'
                  );
                  return;
                }

                if (billingAddress) {
                  nextFormData.append('billing_address', billingAddress);
                }

                if (billingCompanyName) {
                  nextFormData.append(
                    'billing_company_name',
                    billingCompanyName
                  );
                }

                if (billingCompanyVatId) {
                  nextFormData.append(
                    'billing_company_vat_id',
                    billingCompanyVatId
                  );
                } else if (billingCompanyName) {
                  Alert.alert(
                    'Oops! Missing information',
                    'Please add a VAT ID or remove the business name'
                  );
                  return;
                }

                mutateAsync(formData!)
                  .then(() => isMountedRef.current && setCheckingIn(false))
                  .catch((error) => {
                    Sentry.captureException(error);
                  });
              }}
              style={tailwind('mr-1')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
                paddingHorizontal: 6,
              }}
            >
              {i18n.t('bookings.actions.check-in')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}

function HouseRules({ href }: { href: string | undefined }) {
  const { data: property } = useProperty(href, {
    notifyOnChangeProps: ['data'],
  });

  if (!href || !property || !property.details.house_rules) {
    return null;
  }

  return (
    <Paragraph style={{ includeFontPadding: false }}>
      {property.details.house_rules}
    </Paragraph>
  );
}

function AcceptHouseRules({
  checked,
  onChanged,
}: {
  checked: boolean;
  onChanged(next: boolean): void;
}) {
  return (
    <View
      style={{
        borderColor: useColor('text-gray-500'),
        borderWidth: 1,
        borderRadius: useTheme().roundness,
        marginBottom: 8,
      }}
    >
      <Checkbox.Item
        label={i18n.t('bookings.fields.accept.label')}
        status={checked ? 'checked' : 'unchecked'}
        onPress={() => onChanged(!checked)}
      />
    </View>
  );
}

function Note({ onChanged }: { onChanged(next: string): void }) {
  useLocale();

  return (
    <TextInput
      multiline
      numberOfLines={5}
      onChangeText={onChanged}
      label={i18n.t('bookings.fields.note.label')}
      placeholder={i18n.t('bookings.fields.note.placeholder')}
      style={{
        maxHeight: 180,
        includeFontPadding: false,
      }}
    />
  );
}

function Address({
  onChanged,
  required,
  label,
}: {
  onChanged(next: string): void;
  required?: boolean;
  label: string;
}) {
  useLocale();

  return (
    <TextInput
      multiline
      numberOfLines={3}
      onChangeText={onChanged}
      label={label}
      placeholder={i18n.t('bookings.fields.address.placeholder')}
      style={{
        maxHeight: 180,
        marginTop: 8,
        includeFontPadding: false,
      }}
    />
  );
}

function CheckOut({
  data,
  baseCacheKey,
  assignmentsHref,
}: {
  data: ApplauseBooking | undefined;
  baseCacheKey: QueryKey;
  assignmentsHref: string | undefined;
}) {
  const tailwind = useTailwind();
  const hasCheckOutData = data?._links.check_out;
  const canMaybeRateHouseKeeping =
    Boolean(assignmentsHref) && Boolean(data?.dates.checked_out);
  return (
    <View style={tailwind('px-4 my-2')}>
      <Caption>
        {data?.dates.checked_out
          ? i18n.t('bookings.states.checked_out')
          : i18n.t('bookings.actions.check-out')}
      </Caption>
      <Title>
        {datetime(data?.dates.checked_out || data?.dates.check_out) ||
          i18n.t('bookings.states.tbd')}{' '}
      </Title>
      {hasCheckOutData ? (
        <CheckOutAction
          href={data!._links.check_out!.href}
          baseCacheKey={baseCacheKey}
        />
      ) : null}
      {canMaybeRateHouseKeeping ? (
        <RateHouseKeepingPostAction href={assignmentsHref!} />
      ) : null}
    </View>
  );
}

function CheckOutAction({
  href,
  baseCacheKey,
}: {
  href: string;
  baseCacheKey: QueryKey;
}) {
  const tailwind = useTailwind();
  const { mutateAsync, isLoading } = useCheckOut(href, baseCacheKey);

  return (
    <Fragment>
      <Button
        mode="contained"
        disabled={isLoading}
        loading={isLoading}
        icon="check"
        style={tailwind('mt-2 mb-3 self-start')}
        onPress={() => mutateAsync()}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 6,
        }}
      >
        {i18n.t('bookings.actions.check-out')}
      </Button>
    </Fragment>
  );
}

function BookingUpgradeAction({ href }: { href: string | undefined }) {
  const { push } =
    useNavigation<StackNavigationProp<RouteParamList, 'Booking'>>();

  return (
    <Fragment>
      <FAB
        color="black"
        theme={{
          colors: {
            accent: COLOR_PRIMARY,
          },
        }}
        icon="arrow-up"
        style={[href ? {} : { opacity: 0.5 }]}
        disabled={!href}
        onPress={() => push('Upgrade', { href: encode(href!) })}
        label={i18n.t('bookings.actions.upgrade')}
        uppercase={false}
      />
    </Fragment>
  );
}

function PropertyData({ href }: { href: string | undefined }) {
  const isFocused = useIsFocused();
  const { data } = useProperty(href, {
    enabled: isFocused,
    notifyOnChangeProps: ['data'],
  });

  return <PropertyWifi data={data} />;
}

function PropertyWifi({ data }: { data: ApplauseProperty | undefined }) {
  const tailwind = useTailwind();

  if (!data?.details.wifi_name) {
    return null;
  }

  return (
    <View style={tailwind('max-w-3xl w-full self-center mb-4')}>
      <List.Subheader
        style={[
          tailwind('pb-2'),
          { includeFontPadding: false, textAlignVertical: 'center' },
        ]}
      >
        {i18n.t('properties.sections.wifi.title')}
      </List.Subheader>
      <Card elevation={1} style={tailwind('mb-2')}>
        <InfoListItem
          icon="wifi"
          title={data?.details.wifi_name}
          accessibilityLabel={i18n.t('properties.sections.wifi.labels.name')}
        />
        {data?.details.wifi_password ? (
          <InfoListItem
            icon="wifi-strength-3-lock"
            title={data?.details.wifi_password}
            accessibilityLabel={i18n.t(
              'properties.sections.wifi.labels.password'
            )}
          />
        ) : null}
      </Card>
    </View>
  );
}

function InfoListItem({
  icon,
  title,
  accessibilityLabel,
}: {
  icon: string;
  title: string;
  accessibilityLabel?: string;
}) {
  return (
    <List.Item
      left={({ style: { marginVertical, ...style }, color }) => (
        <List.Icon
          icon={icon as any}
          color={color}
          style={[style, { marginLeft: 0, paddingLeft: 16 }]}
        />
      )}
      accessibilityLabel={
        accessibilityLabel ? `${accessibilityLabel}: ${title}` : undefined
      }
      title={title}
      titleNumberOfLines={3}
      titleStyle={{ marginLeft: 8, paddingLeft: Platform.OS === 'web' ? 8 : 0 }}
      style={{ flex: 1, width: '100%', paddingHorizontal: 8 }}
    />
  );
}

function GuestsCard({
  href,
  count,
}: {
  href: string | undefined;
  count: number;
}) {
  const tailwind = useTailwind();
  const enabled = useIsFocused();
  const { data } = useGuests(href, { enabled, notifyOnChangeProps: ['data'] });

  if (!href || !data || !data.guests._index) {
    return null;
  }

  return (
    <Fragment>
      <List.Subheader
        style={[
          tailwind('pb-2'),
          { includeFontPadding: false, textAlignVertical: 'center' },
        ]}
      >
        {i18n.t('bookings.sections.guests.title', {
          count: Math.max(count, data.guests._index.length),
        })}
      </List.Subheader>
      <Card elevation={1} style={tailwind('mb-2')}>
        {data.guests._index.map(({ href: userHref }) => (
          <GuestListItem key={userHref} href={userHref} />
        ))}
      </Card>
    </Fragment>
  );
}

function GuestListItem({ href }: { href: string }) {
  const enabled = useIsFocused();
  const { data } = useUser(href, { enabled });

  return (
    <List.Item
      left={(props) => <List.Icon icon="bag-personal" color={props.color} />}
      title={data?.display_name}
    />
  );
}

function BookingCard({ data }: { data: ApplauseBooking | undefined }) {
  const tailwind = useTailwind();

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <List.Subheader
        style={[
          tailwind('pb-2'),
          { includeFontPadding: false, textAlignVertical: 'center' },
        ]}
      >
        {i18n.t('bookings.sections.booking.title')}
      </List.Subheader>
      <Card elevation={1} style={tailwind('mb-4')}>
        <InfoListItem
          icon="pound-box"
          title={`${data._links.self.reference || '-'} (${data.status})`}
          accessibilityLabel={i18n.t(
            'bookings.sections.booking.labels.reference'
          )}
        />
        <InfoListItem
          icon="cash"
          title={i18n.t('bookings.sections.booking.display.costs_exclusive', {
            currency: 'EUR',
            price: data.price
              ? i18n.numberToCurrency(Number(data.price), {
                  precision: 2,
                  stripInsignificantZeros: false,
                  unit: '',
                })
              : '-',
          })}
          accessibilityLabel={i18n.t('bookings.sections.booking.labels.costs')}
        />
        <InfoListItem
          icon="cash-multiple"
          title={i18n.t('bookings.sections.booking.display.costs_inclusive', {
            currency: 'EUR',
            price: data.total_price
              ? i18n.numberToCurrency(Number(data.total_price), {
                  precision: 2,
                  stripInsignificantZeros: false,
                  unit: '',
                })
              : '-',
          })}
          accessibilityLabel={i18n.t('bookings.sections.booking.labels.costs')}
        />
        <InfoListItem
          icon="weather-night"
          title={i18n.t('bookings.states.nights', { count: nights(data) })}
          accessibilityLabel={i18n.t('bookings.sections.booking.labels.nights')}
        />
        <BookingAssignments data={data} />
        <DeepLink booking={data} />
      </Card>
    </Fragment>
  );
}

function BookingAssignments({ data }: { data: ApplauseBooking | undefined }) {
  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();
  const { data: assignments } = useBookingAssignments(
    data?._links.assignments?.href,
    { notifyOnChangeProps: ['data'] }
  );

  if (!assignments?.assignments._index.length) {
    return null;
  }

  return (
    <List.Item
      left={({ style: { marginVertical, ...style }, color }) => (
        <List.Icon
          icon="bed-king"
          color={color}
          style={[style, { marginLeft: 0, paddingLeft: 16 }]}
        />
      )}
      title={i18n.t('bookings.sections.assignments.label', {
        count: assignments.assignments._index.length,
      })}
      titleNumberOfLines={3}
      titleStyle={{ marginLeft: 8, paddingLeft: Platform.OS === 'web' ? 8 : 0 }}
      style={{ flex: 1, width: '100%', paddingHorizontal: 8 }}
      onPress={() =>
        navigate('BookingAssignments', {
          href: encode(data!._links.self.href),
        })
      }
      right={(props) => (
        <Button
          style={{ marginTop: 12 }}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
          onPress={() =>
            navigate('BookingAssignments', {
              href: encode(data!._links.self.href),
            })
          }
        >
          View
        </Button>
      )}
    />
  );
}

function RateHouseKeepingPreAction({ href }: { href: string }) {
  const isFocused = useIsFocused();
  const tailwind = useTailwind();
  const locale = useLocale();

  const { data: assignments } = useBookingAssignments(href, {
    notifyOnChangeProps: ['data'],
    enabled: Boolean(href) && isFocused,
  });

  const assignmentLink = assignments?.assignments._index.find(
    (link) => link.slug === 'pre_checkin_cleaning'
  );

  const { data: assignment } = useAssignment(assignmentLink?.href, {
    notifyOnChangeProps: ['data'],
    enabled: Boolean(assignmentLink) && isFocused,
  });

  if (!assignment || !assignment._links.reviews) {
    return null;
  }

  const baseCacheKey = assignmentCacheKey(assignment._links.self.href, locale);

  return (
    <ReviewsAction
      href={assignment._links.reviews.href}
      baseCacheKey={baseCacheKey}
      label="Rate housekeeping"
      style={[tailwind('mt-2 mb-3 self-start'), { minWidth: 200 }]}
    />
  );
}

function RateHouseKeepingPostAction({ href }: { href: string }) {
  const isFocused = useIsFocused();
  const tailwind = useTailwind();
  const locale = useLocale();

  const { data: assignments } = useBookingAssignments(href, {
    notifyOnChangeProps: ['data'],
    enabled: Boolean(href) && isFocused,
  });

  const assignmentLink = assignments?.assignments._index.find(
    (link) => link.slug === 'post_checkout_cleaning'
  );

  const { data: assignment } = useAssignment(assignmentLink?.href, {
    notifyOnChangeProps: ['data'],
    enabled: Boolean(assignmentLink) && isFocused,
  });

  if (!assignment || !assignment._links.reviews) {
    return null;
  }

  const baseCacheKey = assignmentCacheKey(assignment._links.self.href, locale);

  return (
    <ReviewsAction
      href={assignment._links.reviews.href}
      baseCacheKey={baseCacheKey}
      label="Rate housekeeping"
      style={[tailwind('mt-2 mb-3 self-start'), { minWidth: 200 }]}
    />
  );
}

function DeepLink({
  booking,
}: {
  booking: ApplauseBooking | null | undefined;
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied]);

  if (!booking || !booking._links.deeplink) {
    return null;
  }

  return (
    <View>
      <Button
        style={{ marginLeft: 24, marginBottom: 16, marginRight: 24 }}
        labelStyle={{ includeFontPadding: false }}
        icon={copied ? 'check' : 'content-copy'}
        mode="contained"
        disabled={copied}
        onPress={() =>
          Clipboard.setStringAsync(booking!._links.deeplink!.href)
            .then(() => setCopied(true))
            .catch(() => {})
        }
      >
        {copied ? 'Copied share link' : 'Share link'}
      </Button>
    </View>
  );
}

export function nights(data: ApplauseBooking) {
  const date1 = new Date(data.dates.check_in);
  const date2 = new Date(data.dates.check_out);

  date1.setHours(12, 0, 0, 0);
  date2.setHours(12, 0, 0, 0);

  // See if the date was on the same day, if so, no nights
  if (date1.getTime() === date2.getTime()) {
    return 0;
  }

  let nights = 0;

  while (date1.getTime() < date2.getTime()) {
    nights += 1;
    date1.setTime(date1.getTime() + 24 * 3600 * 1000);
    date1.setHours(12, 0, 0, 0);
  }

  return nights;
}
