import { useIsFocused, useNavigation } from '@react-navigation/core';
import React from 'react';
import { Image, View } from 'react-native';
import { Caption, Card, Title } from 'react-native-paper';
import { localeDateString } from 'react-native-time-helpers';
import { encode } from '../base';
import {
  ApplauseConfiguration,
  useConfiguration,
} from '../hooks/useConfiguration';
import { i18n } from '../locale';
import { ApplauseProperty, useProperty } from '../properties/useProperties';
import { nights } from './BookingScreen';
import { ApplauseBooking, useBooking } from './useBookings';

export function BookingCard({
  href,
  placeholderTitle,
}: {
  href: string | null | undefined;
  placeholderTitle?: string;
}) {
  const enabled = useIsFocused();
  const { navigate } = useNavigation<any>();

  const { data: configuration } = useConfiguration({
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { data: booking, error: bookingError } = useBooking(href, {
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { data: property, error: propertyError } = useProperty(
    booking?._links.property?.href,
    {
      enabled,
      notifyOnChangeProps: ['data'],
    }
  );

  const canUsePlaceholder = !!bookingError && !!propertyError;

  const name =
    titleFor(property, booking) ||
    (canUsePlaceholder ? placeholderTitle : '...');
  const subtitle = subtitleFor(property, booking, configuration);

  const image = property?.images.square[0] || property?.images.large[0];

  const reference = booking ? booking._links.self.reference : null;

  const fromDateString = booking?.dates.checked_in || booking?.dates.check_in;
  const toDateString = booking?.dates.checked_out || booking?.dates.check_out;

  const sameYear = true;
  const from = fromDateString
    ? localeDateString(new Date(fromDateString), false, !sameYear)
    : i18n.t('bookings.states.tbd');
  const to = toDateString
    ? localeDateString(new Date(toDateString), false, true)
    : i18n.t('bookings.states.tbd');

  return (
    <Card
      elevation={1}
      onPress={
        href
          ? () => {
              navigate('Booking', { href: encode(href) });
            }
          : undefined
      }
      style={{
        flex: 1,
        width: '100%',
        maxWidth: '100%',
        height: 300,
        flexBasis: 300,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#b3aea7',
      }}
    >
      <Image
        source={
          image
            ? { uri: image, width: 400, height: 300 }
            : require('../../assets/images/placeholders/property.jpg')
        }
        style={{ width: '100%', height: 200 }}
        resizeMode="cover"
      />
      <View
        style={{
          flex: 1,
          width: '100%',
          backgroundColor: 'white',
          justifyContent: 'space-between',
        }}
      >
        <Caption
          style={{ paddingHorizontal: 16, marginTop: 8, marginBottom: 0 }}
        >
          {from} - {to}
        </Caption>
        <Card.Title
          title={name}
          titleStyle={{
            fontSize: 17,
          }}
          subtitleStyle={{
            marginBottom: 2,
            marginRight: 12,
          }}
          titleNumberOfLines={1}
          subtitle={(subtitle || '')?.replace(/\\u0026/g, '&')}
          subtitleNumberOfLines={2}
        />
      </View>

      {reference ? (
        <Title
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 6,
            paddingBottom: 6,
            lineHeight: 18,
            backgroundColor: '#22222250',
            borderBottomRightRadius: 8,
            minHeight: 32,
            minWidth: undefined,
            flexShrink: 1,
            alignSelf: 'flex-start',
            color: '#f5f5f5',
            textShadowColor: '#222',
            textShadowOffset: { width: 1, height: 1 },
            textShadowRadius: 1,
            includeFontPadding: false,
            textAlignVertical: 'center',
            fontSize: 12,
          }}
        >
          {reference}
        </Title>
      ) : null}
    </Card>
  );
}

function titleFor(
  property: ApplauseProperty | undefined,
  booking: ApplauseBooking | undefined
): string {
  if (!booking) {
    return '';
  }

  if (!property) {
    return booking._links.self.reference || '';
  }

  if (!booking._links.self.reference) {
    if (!property._links.self.reference) {
      return property.city;
    }

    return `[${property._links.self.reference}] ${property.city}`;
  }

  return `[${
    property._links.self.reference || booking._links.self.reference
  }] ${property.city}`;
}

function subtitleFor(
  property: ApplauseProperty | undefined,
  booking: ApplauseBooking | undefined,
  configuration: ApplauseConfiguration | undefined
): string {
  const isManager =
    configuration?.capabilities.can_manage ||
    configuration?.capabilities.can_own;

  if (!property) {
    return ' ';
  }

  return [
    isManager ? booking?._links.booking_user?.display_name : null,
    booking
      ? i18n.t('bookings.states.nights', { count: nights(booking) })
      : null,
  ]
    .filter(Boolean)
    .join(' @ ');
}
