import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { Platform } from 'react-native';
import { Portal } from 'react-native-paper';
import { useIsColorDark } from 'use-color-luminance';
import { BottomTabParamList } from '../../types';
import { AssignmentsScreen } from '../assignments/AssignmentsScreen';
import { BookingsScreen } from '../bookings/BookingsScreen';
import { ChatScreen } from '../chat/ChatScreen';
import { COLOR_APPBAR_SURFACE, COLOR_PRIMARY } from '../config';
import { DashboardScreen } from '../dashboard/DashboardScreen';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useColor } from '../hooks/useColor';
import { useConfiguration } from '../hooks/useConfiguration';
import { i18n } from '../locale';
import { PropertiesStack } from '../properties/PropertiesStack';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  useForceAuthenticated();

  const activeColor = COLOR_PRIMARY;
  const surfaceDark = useIsColorDark(COLOR_APPBAR_SURFACE);
  const inactiveColor = useColor(
    surfaceDark ? 'text-gray-300' : 'text-gray-500'
  );

  const { data, error } = useConfiguration({
    notifyOnChangeProps: ['data', 'error'],
  });

  if (!data && error) {
    // TODO: show error
    return null;
  }

  return (
    <Portal.Host>
      <BottomTab.Navigator
        initialRouteName="Dashboard"
        screenOptions={{
          tabBarActiveTintColor: activeColor,
          tabBarInactiveTintColor: inactiveColor,
          tabBarStyle: {
            backgroundColor: COLOR_APPBAR_SURFACE,
            paddingBottom: 8,
          },
          tabBarShowLabel: false,
          headerShown: false,
        }}
      >
        <BottomTab.Screen
          name="Dashboard"
          component={DashboardScreen}
          options={{
            title: `${i18n.t('dashboard.title')} · James BnBButler`,
            tabBarLabel: i18n.t('dashboard.tab.label'),
            tabBarIcon: ({ color, focused }) => (
              <MaterialCommunityIcons
                name={focused ? 'view-dashboard' : 'view-dashboard-outline'}
                color={color}
                size={26}
              />
            ),
          }}
        />
        {data?.capabilities.can_book || data?.capabilities.can_own ? (
          <BottomTab.Screen
            name="Bookings"
            component={BookingsScreen}
            options={{
              title: `${i18n.t('bookings.list.title')} · James BnBButler`,
              tabBarLabel: i18n.t('bookings.tab.label'),
              tabBarIcon: ({ color, focused }) => (
                <MaterialCommunityIcons
                  name={focused ? 'credit-card' : 'credit-card-outline'}
                  color={color}
                  size={26}
                />
              ),
            }}
          />
        ) : null}
        {data?.capabilities.can_own ? (
          <BottomTab.Screen
            name="Properties"
            component={PropertiesStack}
            options={{
              title: `${i18n.t('properties.title')} · James BnBButler`,
              tabBarLabel: i18n.t('properties.tab.label'),
              tabBarIcon: ({ color, focused }) => (
                <MaterialCommunityIcons
                  name={focused ? 'home-city' : 'home-city-outline'}
                  color={color}
                  size={26}
                />
              ),
            }}
          />
        ) : null}
        {data?.capabilities.can_service ? (
          <BottomTab.Screen
            name="Services"
            component={AssignmentsScreen}
            options={{
              title: `${i18n.t('services.title')} · James BnBButler`,
              tabBarLabel: i18n.t('services.tab.label'),
              tabBarIcon: ({ color, focused }) => (
                <MaterialCommunityIcons
                  name={focused ? 'bed-king' : 'bed-king-outline'}
                  color={color}
                  size={26}
                />
              ),
            }}
          />
        ) : null}
        {!data?.capabilities.can_manage ? (
          <BottomTab.Screen
            name="Chat"
            component={ChatScreen}
            options={{
              title: `${i18n.t('chat.title')} · James BnBButler`,
              tabBarLabel: i18n.t('chat.tab.label'),
              tabBarIcon: ({ color, focused }) => (
                <MaterialCommunityIcons
                  name={focused ? 'chat' : 'chat-outline'}
                  color={color}
                  size={26}
                />
              ),
            }}
          />
        ) : null}
      </BottomTab.Navigator>
    </Portal.Host>
  );
}
