import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dialog, Paragraph } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { useIsMounted } from 'use-is-mounted';
import { COLOR_PRIMARY } from '../config';
import { i18n } from '../locale';

export type ConfirmationDialogContent = {
  title: string;
  positive: string;
  negative: string;
  body?: string;
  description?: string;
};

export function useConfirmationDialog(
  content: ConfirmationDialogContent,
  { onConfirm }: { onConfirm: () => void | Promise<void> }
): [boolean, () => void, () => JSX.Element] {
  const tailwind = useTailwind();
  const accentColor = COLOR_PRIMARY;
  const [isConfirming, setConfirming] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const isMountedRef = useIsMounted();

  const confirm = useCallback(() => setConfirming(true), []);

  const ElementType = useMemo(() => {
    return function ConfirmationDialog() {
      return (
        <Dialog
          visible={isConfirming}
          onDismiss={() => setConfirming(false)}
          dismissable={!isProcessing}
          style={tailwind('max-w-xl self-center w-full')}
        >
          <Dialog.Title numberOfLines={3}>{content.title}</Dialog.Title>
          {content.body ?? content.description ? (
            <Dialog.Content>
              <Paragraph>{content.body ?? content.description}</Paragraph>
            </Dialog.Content>
          ) : null}
          <Dialog.Actions style={tailwind('py-4')}>
            <Button
              mode="text"
              color={accentColor}
              loading={isProcessing}
              disabled={isProcessing}
              onPress={() => {
                setConfirming(false);
              }}
              style={tailwind('mr-2')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              {content.negative ?? i18n.t('actions.cancel')}
            </Button>
            <Button
              mode="contained"
              loading={isProcessing}
              disabled={isProcessing}
              icon="check"
              onPress={() => {
                Promise.resolve()
                  .then(() => {
                    setProcessing(true);
                    return onConfirm();
                  })
                  .then(() => {
                    if (isMountedRef.current) {
                      setProcessing(false);
                      setConfirming(false);
                    }
                  })
                  .catch(() => {});
              }}
              style={tailwind('mr-1')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
                paddingHorizontal: 6,
              }}
            >
              {content.positive ?? i18n.t('assignments.actions.finish')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      );
    };
  }, [content, isConfirming, isProcessing, onConfirm]);

  return [isConfirming, confirm, ElementType];
}
