import { useIsFocused } from '@react-navigation/native';
import { FetchMediaError } from 'fetch-media';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Keyboard,
  KeyboardEvent,
  Platform,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  HelperText,
  IconButton,
  List,
  Paragraph,
  Portal,
  TextInput,
} from 'react-native-paper';
import { QueryKey, useQueryClient } from 'react-query';
import { useTailwind } from 'tailwind-rn';
import { DocumentAttachment } from '../components/DocumentAttachment';
import { useSnackbar } from '../components/SnackbarProvider';
import { COLOR_PRIMARY } from '../config';
import {
  ConfirmationDialogContent,
  useConfirmationDialog,
} from '../hooks/useConfirmationDialog';
import { i18n } from '../locale';
import { useFinish } from './useFinish';
import {
  ApplauseAssignmentReport,
  useReport,
  useSubmitReport,
} from './useReport';

export function FinishAssignmentWithReport({
  href,
  finishHref,
  baseCacheKey,
  style,
}: {
  href: string;
  finishHref: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const accentColor = COLOR_PRIMARY;
  const { show } = useSnackbar();
  const queryClient = useQueryClient();

  const [isFinishing, setFinishing] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>();
  const [isAllChecked, setAllChecked] = useState(true);
  const scrollViewRef = useRef<ScrollView | null>(null);

  const {
    data: report,
    isLoading,
    error,
  } = useReport(href, baseCacheKey, {
    refetchInterval: isFinishing ? 15 * 1000 : false,
    enabled: isFocused,
  });

  const hasOnlyOneItem = report?._embedded.length === 1;

  const {
    mutateAsync: finish,
    error: finishError,
    isLoading: finishing,
  } = useFinish(finishHref, baseCacheKey, {
    onSuccess: () => show(i18n.t('assignments.finish.notification')),
  });

  useEffect(() => {
    if (hasOnlyOneItem) {
      setSelectedItem(report?._embedded[0].key);
    }
  }, [hasOnlyOneItem]);

  const attemptFinish = useCallback(() => {
    const formData = new FormData();
    formData.append('_', '');

    finish(formData).catch((error) => {
      if (error instanceof FetchMediaError) {
        if (error.response.status === 422) {
          setFinishing(false);
          queryClient.invalidateQueries(baseCacheKey);
          show(i18n.t('assignments.finish.notification'));
          return;
        }
      }

      throw error;
    });
  }, [finish, show, i18n]);

  const [isConfirming, requestConfirmFinish, ConfirmationDialog] =
    useConfirmationDialog(
      i18n.t(
        'assignments.confirmations.finish'
      ) as unknown as ConfirmationDialogContent,
      {
        onConfirm: attemptFinish,
      }
    );

  return (
    <Fragment>
      <Button
        disabled={isLoading || isFinishing || isConfirming}
        loading={isLoading || isConfirming}
        mode="contained"
        style={[tailwind('self-start mb-4 mt-4'), style]}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 0,
        }}
        onPress={() => setFinishing(true)}
      >
        {i18n.t('assignments.actions.finish')}
      </Button>

      <Portal>
        <Dialog
          visible={isFinishing}
          onDismiss={() => setFinishing(false)}
          dismissable={!isLoading}
          style={tailwind('max-w-xl self-center w-full')}
        >
          <Wrapper>
            <Dialog.Title
              style={{ includeFontPadding: false, textAlignVertical: 'center' }}
            >
              {selectedItem && !hasOnlyOneItem && Platform.OS === 'web' ? (
                <IconButton
                  icon="arrow-left"
                  onPress={() => setSelectedItem(undefined)}
                  style={{ margin: -8, marginRight: 8 }}
                />
              ) : null}
              {selectedItem === undefined || hasOnlyOneItem
                ? i18n.t('assignments.finish.title')
                : keyToTitle(selectedItem)}
            </Dialog.Title>
            <Dialog.ScrollArea
              style={{
                maxHeight: 400,
                paddingHorizontal: 0,
              }}
            >
              <ScrollView
                ref={scrollViewRef}
                contentContainerStyle={{
                  paddingHorizontal: 8,
                  paddingVertical: 8,
                }}
              >
                <Paragraph
                  style={{
                    includeFontPadding: false,
                    textAlignVertical: 'center',
                    paddingHorizontal: 16,
                    paddingVertical: 12,
                  }}
                >
                  {i18n.t('assignments.finish.subtitle')}
                </Paragraph>
                {report?._embedded.map((item) =>
                  selectedItem === undefined || selectedItem === item.key ? (
                    <ReportItem
                      required={report.required}
                      item={item}
                      key={item.key}
                      baseCacheKey={baseCacheKey}
                      selected={selectedItem === item.key}
                      onSelect={() => setSelectedItem(item.key)}
                      onSubmitted={() => {
                        if (hasOnlyOneItem) {
                          attemptFinish();
                          //requestConfirmFinish();
                        } else {
                          setSelectedItem(undefined);
                        }

                        setTimeout(() => {
                          if (!scrollViewRef.current) {
                            return;
                          }

                          const firstItem = report._embedded.findIndex(
                            (item) => !item.submitted
                          );
                          if (firstItem !== -1) {
                            scrollViewRef.current.scrollTo({
                              y: 72 * firstItem,
                            });
                          } else {
                            scrollViewRef.current.scrollToEnd();
                          }
                        }, 0);
                      }}
                    />
                  ) : null
                )}
                {report?.cleaning ? (
                  <ReportCheckListItem
                    setAllChecked={setAllChecked}
                    active={selectedItem === undefined}
                  />
                ) : null}
              </ScrollView>
            </Dialog.ScrollArea>
            <Dialog.Actions style={tailwind('py-4')}>
              {finishError ? (
                <HelperText type="error">{finishError.message}</HelperText>
              ) : null}
              <Button
                mode="text"
                color={accentColor}
                disabled={isLoading}
                loading={isLoading}
                onPress={
                  selectedItem === undefined || hasOnlyOneItem
                    ? () => {
                        setFinishing(false);
                      }
                    : () => setSelectedItem(undefined)
                }
                style={tailwind('mr-2')}
                labelStyle={{
                  includeFontPadding: false,
                  textAlignVertical: 'center',
                }}
              >
                {selectedItem === undefined
                  ? i18n.t('actions.cancel')
                  : i18n.t('actions.back')}
              </Button>

              <Button
                mode="contained"
                color={accentColor}
                disabled={
                  isLoading ||
                  !report?.completed ||
                  finishing ||
                  !isAllChecked ||
                  isConfirming
                }
                loading={isLoading || finishing || isConfirming}
                onPress={attemptFinish /*requestConfirmFinish*/}
                style={tailwind('mr-2')}
                labelStyle={{
                  includeFontPadding: false,
                  textAlignVertical: 'center',
                }}
              >
                {i18n.t('assignments.actions.finish')}
              </Button>
            </Dialog.Actions>
          </Wrapper>
        </Dialog>
      </Portal>
      <Portal>
        <ConfirmationDialog />
      </Portal>
    </Fragment>
  );
}

const CHECKS = [
  'heater',
  'plants_water',
  'toilet_paper',
  'towel_sticker',
  'trash_replaced',
  'keys',
  'lights',
  'windows',
  'trash',
  'linen',
];

function ReportCheckListItem({
  active,
  setAllChecked,
}: {
  active: boolean;
  setAllChecked(next: boolean): void;
}) {
  const [checked, setChecked] = useState(() => CHECKS.map(() => false));
  const allChecked = checked.every(Boolean);

  useEffect(() => {
    setAllChecked(allChecked);
  }, [allChecked]);

  if (!active) {
    return null;
  }
  return (
    <Fragment>
      <Divider />
      {CHECKS.map((key, index) => (
        <Checkbox.Item
          key={key}
          label={i18n.t(`assignments.finish.checklist.${key}`)}
          status={checked[index] ? 'checked' : 'unchecked'}
          onPress={() =>
            setChecked((prev) => {
              const copy = [...prev];
              copy[index] = !copy[index];
              return copy;
            })
          }
        />
      ))}
    </Fragment>
  );
}

function ReportItem({
  item,
  baseCacheKey,
  required,
  selected,
  onSelect,
  onSubmitted,
}: {
  item: ApplauseAssignmentReport['_embedded'][number];
  baseCacheKey: QueryKey;
  selected: boolean;
  required: boolean;
  onSelect: () => void;
  onSubmitted: (result: ApplauseAssignmentReport) => void;
}) {
  const {
    mutateAsync: finish,
    isLoading: isSubmitting,
    error: submissionError,
  } = useSubmitReport(item._links?.submit?.href, baseCacheKey, {
    onSuccess: (result) => onSubmitted(result),
  });
  const [formData, setFormData] = useState<FormData | null>(null);
  const title = keyToTitle(item.key);
  const [note, setNote] = useState(() => item.note);

  const [spacer, setSpacer] = useState(0);

  useEffect(() => {
    function _keyboardDidShow(event: KeyboardEvent) {
      setSpacer(event.endCoordinates.height - 48 - 64);
    }

    function _keyboardDidHide(event: KeyboardEvent) {
      setSpacer(0);
    }

    const willShow = Keyboard.addListener('keyboardWillShow', _keyboardDidShow);
    const willHide = Keyboard.addListener('keyboardWillHide', _keyboardDidHide);

    // cleanup function
    return () => {
      willShow.remove();
      willHide.remove();
    };
  }, [setSpacer]);

  if (item.submitted) {
    return (
      <List.Item
        title={title}
        titleStyle={{
          color: '#065f46',
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        right={(props) => <List.Icon icon="check" color="#10b981" />}
      />
    );
  }

  if (!selected) {
    return (
      <List.Item
        title={title}
        titleStyle={{ includeFontPadding: false, textAlignVertical: 'center' }}
        right={(props) => <List.Icon icon="arrow-right" color={props.color} />}
        onPress={onSelect}
      />
    );
  }

  const isAnyMedia = false; // item.key === 'completion';
  const i18nKey = isAnyMedia ? 'completion_media' : 'completion_video';

  return (
    <View style={{ paddingHorizontal: 16 }}>
      <DocumentAttachment
        onChanged={setFormData}
        title={
          formData
            ? i18n.t(`assignments.actions.${i18nKey}.change`)
            : i18n.t(`assignments.actions.${i18nKey}.add`)
        }
        icon={isAnyMedia ? 'camera-plus' : 'video'}
        formDataKey="media"
        kind={isAnyMedia ? 'either' : 'video'}
      />
      {isAnyMedia ? (
        <HelperText type="info" style={{ marginBottom: 8 }}>
          {i18n.t(`assignments.fields.${i18nKey}.helper`)}
        </HelperText>
      ) : null}
      <TextInput
        label={i18n.t('assignments.finish.note_label')}
        value={note || ''}
        onChangeText={setNote}
        multiline
        autoCapitalize="sentences"
        style={{ includeFontPadding: false, maxHeight: 200 }}
      />

      <Button
        style={{ marginTop: 16, marginRight: 'auto' }}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
        mode="contained"
        loading={isSubmitting}
        onPress={() => {
          const realFormData = formData || new FormData();

          realFormData.append('_', '');

          if (note) {
            realFormData.append('note', note);
          }

          finish(realFormData);
        }}
        disabled={(!formData && required) || isSubmitting}
      >
        {i18n.t('assignments.actions.save')}
      </Button>

      {submissionError ? (
        <HelperText type="error">{submissionError.message}</HelperText>
      ) : null}

      {spacer === 0 ? null : <View style={{ height: 64 }} />}
    </View>
  );
}

function Wrapper({ children }: { children: React.ReactNode }) {
  if (Platform.OS !== 'ios') {
    return <Fragment>{children}</Fragment>;
  }

  return <Fragment>{children}</Fragment>;

  /*
  return (
    <KeyboardAvoidingView enabled behavior="position" style={{ flex: 1 }}>
      {children}
    </KeyboardAvoidingView>
  );
  */
}

export function keyToTitle(key: string) {
  const lookup = key.replace(/_([0-9]+)/, '');
  const n = (key.match(/_([0-9]+)/) || { 1: undefined })[1];

  console.log(key, lookup, n);

  return i18n.t(`assignments.finish.items.${lookup}`, {
    defaultValue: `${key[0].toLocaleUpperCase()}${key
      .slice(1)
      .replace(/_/g, ' ')}`,
    n,
  });
}
