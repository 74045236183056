import { YellowBox } from 'react-native';

YellowBox.ignoreWarnings([
  // react-query will spawn a timer to clear the cache. The implementation of
  // setTimeout on Android means that the timer *won't run* if the app is
  // backgrounded, but instead, runs when its foregrounded again. This is fine
  // for react-query.
  //
  // When new libraries are added, this warning should be turned off and in
  // order to investigate if the library yields this warning, set cacheTime to a
  // small number.
  'Setting a timer for a long period of time',

  //
  'Your project is accessing the following APIs from a deprecated global rather than a module import: Constants (expo-constants)',
]);
