import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseAssignmentReport = {
  _links: {
    self: {
      href: string;
    };
  };
  cleaning: boolean;
  completed: boolean;
  required: boolean;
  _embedded: [
    {
      _links?: {
        submit?: {
          href: string;
        };
        media?: {
          href: string;
          type: string;
        };
      };
      key: string;
      note: null | string;
      submitted: boolean;
    }
  ];
};

type ApplauseAssignmentReportResponse = {
  report: ApplauseAssignmentReport;
};

export function useReport(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseAssignmentReport, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery([...cacheKey, 'report'] as QueryKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.report.v1+json'].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseAssignmentReportResponse)
        .then(({ report }) => report),
    enabled:
      enabled && Boolean(auth.current?.['access-token']) && Boolean(href),
    ...options,
  });
}

export function useSubmitReport(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    onSettled,
    ...options
  }: UseMutationOptions<
    ApplauseAssignmentReport,
    FetchMediaError,
    FormData,
    void
  > = {}
) {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const locale = useLocale();

  const mutationFn: MutationFunction<ApplauseAssignmentReport, FormData> =
    useCallback(
      async (formData) => {
        if (!href) {
          throw new Error('No href to submit report');
        }

        if (!auth.current?.['access-token']) {
          throw new Error('Not authenticated');
        }

        const response = await fetchMedia(href!, {
          headers: {
            accept: ['application/vnd.bnbbutler.report.v1+json'].join(', '),
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'POST',
          body: formData,
          debug: __DEV__,
          disableFormData: true,
          disableFormUrlEncoded: true,
        });

        const { report } = response as ApplauseAssignmentReportResponse;
        queryClient.setQueryData([...cacheKey, 'report'], report);

        return report;
      },
      [href, auth, locale]
    );

  return useMutation(cacheKey, mutationFn, {
    ...options,
    onSettled: async (data, error, variables, context) => {
      await Promise.all([
        queryClient.cancelQueries([locale, 'event', 'list']),
        queryClient.cancelQueries(cacheKey),
        queryClient.cancelQueries([...cacheKey, 'report']),
      ]);

      await Promise.all([
        queryClient.invalidateQueries([locale, 'event', 'list']),
        queryClient.invalidateQueries(cacheKey),
        queryClient.invalidateQueries([...cacheKey, 'report']),
      ]);

      onSettled && onSettled(data, error, variables, context);
    },
  });
}
