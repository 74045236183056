import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type ChatReply = {
  message: {
    text: string;
  };
};

export type ChatMessage = {
  _links: {
    self: {
      href: string;
    };
  };
  author: {
    role: 'user' | 'admin';
  };
  body: string;
  created_at: string;
};

export type ChatMessageResponse = {
  message: ChatMessage;
};

export type ChatMessageCollection = {
  messages: {
    _links: {
      self: { href: string };
    };
    _embedded: ChatMessage[];
  };
};

export function useChats({ enabled = true } = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_messages');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ChatMessageCollection, FetchMediaError>(
    [locale, 'intercom', 'messages'],
    {
      queryFn: async () =>
        fetchMedia(href!, {
          headers: {
            accept: 'application/vnd.bnbbutler.message.v1.collection+json',
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
        }).then((response) => response as ChatMessageCollection),
      enabled: !!auth.current?.['access-token'] && enabled && !!href,
      refetchInterval: 10 * 1000,
    }
  );
}

export function useSendChat(href: string | null | undefined) {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const locale = useLocale();
  const cacheKey = [locale, 'intercom', 'messages'];

  const sendMessage = useCallback(
    async (message: string) => {
      if (!href) {
        return Promise.reject(
          new Error(`Requires href, actual ${typeof href}`)
        );
      }
      const body: ChatReply = {
        message: {
          text: `<p>${message}</p>`,
        },
      };

      const response = await fetchMedia(href, {
        headers: {
          accept: 'application/vnd.bnbbutler.message.v1+json',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          contentType:
            'application/vnd.bnbbutler.message.v1.reply+json; charset=utf-8',
          ...(auth.current as Record<string, string>),
        },
        method: 'POST',
        body,
      });

      return response as ChatMessageResponse;
    },
    [href, locale]
  );

  return useMutation<
    ChatMessageResponse,
    FetchMediaError,
    string,
    { snapshot: ChatMessageCollection | null }
  >(sendMessage, {
    onMutate: async (message) => {
      // Cancel any outgoing refetches (so they don't overwrite the optimistic
      // update)
      await queryClient.cancelQueries(cacheKey);

      // Snapshot the previous value
      const snapshot =
        queryClient.getQueryData<ChatMessageCollection | null>(cacheKey) ||
        null;

      const newMessage: ChatMessage = {
        _links: {
          self: { href: '' },
        },
        author: {
          role: 'user',
        },
        body: `<p style="opacity: 0.7;">${message}</p>`,
        created_at: new Date().toISOString(),
      };

      // Optimistically update to the new value
      queryClient.setQueryData(
        cacheKey,
        (
          old: ChatMessageCollection | null | undefined
        ): ChatMessageCollection => {
          const nextEmbedded = (old?.messages._embedded || []).concat(
            newMessage
          );

          if (!old) {
            return {
              messages: {
                _links: { self: { href: '' } },
                _embedded: nextEmbedded,
              },
            };
          }

          return {
            ...old,
            messages: {
              ...old.messages,
              _embedded: nextEmbedded,
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { snapshot };
    },

    onSuccess: (newMessage, variables, context) => {},

    onError: (error, __, context) => {
      console.error(error.message);

      // If the mutation fails, use the context returned from onMutate to roll
      // back to the previous value. Invalidation refreshes automatically
      queryClient.setQueryData(cacheKey, context?.snapshot);
    },

    onSettled: async (newMessage, error, variables, context) => {
      await queryClient.cancelQueries(cacheKey);
      // Always refetch after error or success:
      await queryClient.invalidateQueries(cacheKey);
    },
  });
}
