import { LinkingOptions } from '@react-navigation/native';
import {
  addEventListener as addLinkingEventListener,
  createURL as makeUrl,
  getInitialURL as getExpoInitialUrl,
} from 'expo-linking';
import { FetchMediaError } from 'fetch-media';
import { INTERNAL_PREFIXES } from '../config';
import { useIsAuthenticated } from '../hooks/useAuth';
import { useConfiguration } from '../hooks/useConfiguration';
import { RouteParamList } from './routes';

const prefix = makeUrl('/');

const prefixes = [prefix].filter(Boolean).concat(INTERNAL_PREFIXES) as string[];

const linking: LinkingOptions<RouteParamList> = {
  prefixes,

  // Custom function to get the URL which was used to open the app
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await getExpoInitialUrl();

    if (url !== null) {
      return url;
    }

    return undefined;
  },

  // Custom function to subscribe to incoming links
  subscribe(listener: (url: string) => void) {
    // First, you may want to do the default deep link handling
    const onReceiveURL = ({ url }: { url: string }) => {
      console.debug('[url] received url', url);
      listener(url);
    };

    /* setLocalDeeplinkListener((url) => {
      listener(url);
      return Promise.resolve(true);
    });*/

    // Listen to incoming links from deep linking
    const subscription = addLinkingEventListener('url', onReceiveURL);

    return () => {
      // Clean up the event listeners
      subscription.remove();
      // setLocalDeeplinkListener(null);
    };
  },

  config: {
    screens: {
      Tabs: {
        path: '',
        screens: {
          Dashboard: 'dashboard',
          Bookings: 'bookings',
          Properties: 'properties',
          Services: 'services',
          Chat: 'chat',
        },
      },

      Assignment: 'assignments/:href',
      BookingAreas: 'bookings/new',
      BookingExperience: 'bookings/new/:name/:url',
      BookingAssignments: 'bookings/:href/assignments',
      Booking: 'bookings/:href',
      PropertyAssignments: 'properties/:href/assignments',
      Property: 'properties/:href',
      Service: 'services/:href',

      Upgrade: 'upgrade/:href',
      RequestService: 'request/:href',
      ReserveProperty: 'reserve/:href',

      Settings: 'settings',

      Login: 'login',
      Registration: 'registration',
      Onboarding: 'onboarding',

      NotFound: '*',
    },
  },
} as const;

export function useLinking():
  | LinkingOptions<RouteParamList>
  | FetchMediaError
  | undefined {
  const isAuthenticated = useIsAuthenticated();
  const { data, error, isLoading } = useConfiguration({
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  // In case of an error, allow error to be on non-authenticated
  if (error) {
    if (isAuthenticated) {
      return error;
    }

    if (!isLoading) {
      return linking;
    }
  }

  // Return the data if linking
  if (data) {
    return linking;
  }

  // Only don't if loading
  if (isLoading) {
    return undefined;
  }

  return linking;
}

export const config = linking.config!;
