import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type ApplauseBooking = {
  _links: {
    self: {
      href: string;
      status: string;
      reference?: string | null;
      search?: string;
    };
    assignments?: {
      href: string;
    };
    accept?: {
      href: string;
    };
    booking_user?: {
      href: string;
      display_name?: string;
    };
    check_in?: {
      href: string;
    };
    check_out?: {
      href: string;
    };
    document?: {
      href: string;
    };
    guests: {
      href: string;
      count?: number;
    };
    property?: {
      href: string;
      reference?: string | null;
    };
    services: {
      href: string;
    };
    thumbnail?: {
      href: string;
    };
    deeplink?: {
      href: string;
    };
  };
  status: string;
  dates: {
    check_in: string;
    check_out: string;
    accepted: null | string;
    checked_in: null | string;
    checked_out: null | string;
  };
  note: null | string;
  price?: number | null;
  total_price?: null | number;
};

type ApplauseBookingResponse = {
  booking: ApplauseBooking;
};

export type ApplauseBookingsIndex = {
  bookings: {
    _links: {
      self: {
        href: string;
        status: string;
        reference?: string | null;
        search?: string;
      };
    };
    _index: ApplauseBooking['_links']['self'][];
  };
};

export function useBookings({ enabled = true } = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_bookings');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseBookingsIndex, FetchMediaError>(
    [locale, 'booking', 'list'],
    {
      queryFn: async () =>
        fetchMedia(href!, {
          headers: {
            accept: [
              'application/vnd.bnbbutler.booking.v3.index+json',
              'application/vnd.bnbbutler.booking.v2.index+json; q=0.8',
              'application/vnd.bnbbutler.booking.v1.index+json; q=0.7',
            ].join(', '),
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
        }).then((response) => response as ApplauseBookingsIndex),
      enabled: !!auth.current?.['access-token'] && enabled && !!href,
      staleTime: 30 * 1000,
    }
  );
}

export function bookingCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'booking', `${href?.split('/').pop() || '-'}`];
}

export function useBooking(
  href: string | null | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseBooking, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(bookingCacheKey(href, locale), {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.booking.v5+json',
            'application/vnd.bnbbutler.booking.v4+json; q=0.9',
            'application/vnd.bnbbutler.booking.v3+json; q=0.8',
            'application/vnd.bnbbutler.booking.v2+json; q=0.7',
            'application/vnd.bnbbutler.booking.v1+json; q=0.6',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseBookingResponse)
        .then(({ booking }) => booking),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    ...options,
  });
}
