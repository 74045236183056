import { useIsFocused } from '@react-navigation/core';
import Color from 'color';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  StyleSheet,
  TextInput as NativeTextInput,
  View,
} from 'react-native';
import {
  ActivityIndicator,
  Divider,
  HelperText,
  Paragraph,
  TextInput,
} from 'react-native-paper';
import Animated, {
  runOnUI,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useTailwind } from 'tailwind-rn';
import { HTMLContent } from '../components/HTMLContent';
import { TabbedScreen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import {
  COLOR_APPBAR_SURFACE,
  COLOR_PRIMARY,
  COLOR_TEXT_ON_PRIMARY,
} from '../config';
import { useColor } from '../hooks/useColor';
import { useDimensions } from '../hooks/useDimensions';
import { i18n } from '../locale';
import { selfAsKeyExtractor } from '../utils/selfAsKeyExtractor';
import { ChatMessage, useChats, useSendChat } from './useChats';

export function ChatScreen() {
  const tailwind = useTailwind();
  const isFocused = useIsFocused();

  const { data: chats, isLoading } = useChats({ enabled: isFocused });
  const hasInitiallyScrolled = useRef(false);
  const width = useDimensions().width;
  const contentWidth = (width - 16) * 0.7;

  const listRef = useRef<FlatList | null>(null);

  useLayoutEffect(() => {
    if (isLoading || !chats) {
      return;
    }

    const timeout = setTimeout(() => {
      if (chats.messages._embedded.length > 0) {
        listRef.current?.scrollToIndex({
          animated: true,
          index: chats.messages._embedded.length - 1,
          viewPosition: 0,
        });
      }

      hasInitiallyScrolled.current = true;
    }, 250);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  return (
    <TabbedScreen>
      <ScreenHeader title={i18n.t('chat.title')} />
      {isLoading ? (
        <ActivityIndicator size="large" style={tailwind('my-4')} />
      ) : null}
      <FlatList
        ref={listRef}
        style={tailwind('flex-1')}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full pt-4 pb-8'
        )}
        data={chats?.messages._embedded || []}
        renderItem={(props) => renderChatMessage({ ...props, contentWidth })}
        keyExtractor={selfAsKeyExtractor}
        onScrollToIndexFailed={() =>
          setTimeout(() => listRef.current?.scrollToEnd({ animated: true }), 50)
        }
        onContentSizeChange={() => {
          if (!hasInitiallyScrolled.current) {
            return;
          }

          setTimeout(
            () => listRef.current?.scrollToEnd({ animated: true }),
            50
          );
        }}
      />
      <SendMessage href={chats?.messages._links.self.href} />
    </TabbedScreen>
  );
}

function renderChatMessage({
  item,
  contentWidth,
}: ListRenderItemInfo<ChatMessage> & { contentWidth: number }) {
  return (
    <PureChatMessage
      contentWidth={contentWidth}
      isSelf={item.author.role !== 'admin'}
    >
      {item.body}
    </PureChatMessage>
  );
}

function PureChatMessage_({
  contentWidth,
  isSelf,
  children,
}: {
  contentWidth: number;
  isSelf: boolean;
  children: string;
}) {
  const tailwind = useTailwind();

  return (
    <View
      style={[
        {
          maxWidth: '70%',
          alignSelf: isSelf ? 'flex-end' : 'flex-start',
          backgroundColor: COLOR_PRIMARY,
        },
        tailwind('mx-3 px-1 py-1 mt-2 rounded-md'),
      ]}
    >
      <HTMLContent
        renderers={{
          p: (
            htmlAttribs,
            children,
            convertedCSSStyles,
            { key, ...passProps }
          ) => (
            <Paragraph
              key={key}
              style={[
                convertedCSSStyles,
                { paddingHorizontal: 4, color: COLOR_TEXT_ON_PRIMARY },
              ]}
            >
              {children}
            </Paragraph>
          ),
        }}
        tagsStyles={{
          img: { overflow: 'hidden', borderRadius: 3 },
        }}
        contentWidth={contentWidth}
      >
        {children}
      </HTMLContent>
    </View>
  );
}
const PureChatMessage = React.memo(PureChatMessage_);

function SendMessage({
  href,
}: {
  href: string | null | undefined;
}): JSX.Element {
  const { mutateAsync: sendChat, error } = useSendChat(href);
  const inputRef = useRef<NativeTextInput | null>(null);
  const [nextMessage, setNextMessage] = useState('');
  const focused = useIsFocused();
  const tailwind = useTailwind();
  const gray600 = useColor('text-gray-600');

  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    inputRef.current?.setNativeProps({ style: { outline: 'none' } });
  }, [inputRef]);

  const submitMessage = (message: string) => {
    if (!message) {
      return;
    }

    sendChat(message).catch(() => {});

    inputRef.current?.clear();
    setNextMessage('');
  };

  const minHeight = useSharedValue(76);

  useEffect(() => {
    runOnUI(() => {
      minHeight.value = withSpring(error ? 106 : 76);
    })();
  }, [error, minHeight]);

  const heightStyle = useAnimatedStyle(() => {
    return { minHeight: minHeight.value };
  }, [minHeight]);

  const innerHeightStyle = useAnimatedStyle(() => {
    return { minHeight: minHeight.value, transform: [{ translateY: 0 }] };
  });

  return (
    <Animated.View style={[tailwind('relative'), heightStyle]}>
      {focused ? (
        <Animated.View
          style={[{ backgroundColor: COLOR_APPBAR_SURFACE }, innerHeightStyle]}
        >
          <Divider
            style={{
              height: StyleSheet.hairlineWidth,
              backgroundColor: new Color(COLOR_APPBAR_SURFACE).isDark()
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, .12)',
            }}
          />
          <View style={[tailwind('max-w-3xl self-center w-full px-2')]}>
            <TextInput
              ref={inputRef}
              enablesReturnKeyAutomatically
              mode="outlined"
              placeholder={i18n.t('chat.fields.message.placeholder')}
              value={nextMessage}
              multiline
              onSubmitEditing={(e) => {
                submitMessage(e.nativeEvent.text);
              }}
              onChangeText={setNextMessage}
              style={[
                {
                  height: undefined,
                  maxHeight: 200,
                },
              ]}
              right={
                <TextInput.Icon
                  icon="send"
                  color={gray600}
                  accessibilityLabel={i18n.t('chat.actions.send')}
                  onPress={() => {
                    submitMessage(nextMessage);
                  }}
                />
              }
            />
            <HelperText type="error" visible={!!error}>
              {error?.message || null}
            </HelperText>
          </View>
          <Divider
            style={{
              height: StyleSheet.hairlineWidth,
              backgroundColor: new Color(COLOR_APPBAR_SURFACE).isDark()
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, .12)',
            }}
          />
        </Animated.View>
      ) : null}
    </Animated.View>
  );
}
