import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type ApplauseProperty = {
  _links: {
    self: {
      href: string;
      search: string;
      reference?: string;
    };
    assignments: {
      href: string;
    };
    bookings: {
      href: string;
    };
    booking_engine_url?: {
      href: string;
    };
    links?: {
      href: string;
    };
    owners: {
      href: string;
    };
    reservations: {
      href: string;
    };
    reserve: {
      href: string;
    };
    services: {
      href: string;
    };
  };
  title: string;
  description: null | string;
  address: string;
  city: string;
  images: {
    square: string[];
    large: string[];
    darkened: string[];
  };
  features: {
    has_garden: boolean;
    has_terras: boolean;
    has_parking: boolean;
    has_pool?: boolean;
    has_sauna?: boolean;
    has_garbage_room?: boolean;
    bedrooms: number;
    bathrooms: number;
    square_meters: number;
    key_count: number;
  };
  details: {
    name_residence: null | string;
    name_doorbell: null | string;
    room_raccoon_id: null | string;
    wifi_name: null | string;
    wifi_password: null | string;
    house_rules: null | string;
  };
};

type ApplausePropertyResponse = {
  property: ApplauseProperty;
};

export type ApplausePropertiesIndex = {
  properties: {
    _links: {
      self: { href: string };
    };
    _index: ApplauseProperty['_links']['self'][];
  };
};

export type ApplausePropertyReservation = {
  reservation: {
    from: string;
    to: string;
  };
};

export function useProperties({
  enabled = true,
  ...options
}: UseQueryOptions<ApplausePropertiesIndex, FetchMediaError> = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_properties');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery([locale, 'property', 'list'] as QueryKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.property.v3.index+json',
            'application/vnd.bnbbutler.property.v2.index+json; q=0.9',
            'application/vnd.bnbbutler.property.v1.index+json; q=0.8',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      }).then((response) => response as ApplausePropertiesIndex),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 30 * 1000, // 30 seconds
    ...options,
  });
}

export function propertyCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'property', `${href?.split('/').pop() || '-'}`];
}

export function useProperty(
  href: string | null | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseProperty, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();
  const queryKey = propertyCacheKey(href, locale);

  return useQuery<ApplauseProperty, FetchMediaError>(queryKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.property.v3+json',
            'application/vnd.bnbbutler.property.v2+json; q=0.9',
            'application/vnd.bnbbutler.property.v1+json; q=0.8',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplausePropertyResponse)
        .then(({ property }) => property),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...options,
  });
}

export function useReserveProperty(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    onSettled,
    ...options
  }: UseMutationOptions<
    void,
    FetchMediaError,
    ApplausePropertyReservation,
    void
  > = {}
) {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const locale = useLocale();

  const mutationFn: MutationFunction<void, ApplausePropertyReservation> =
    useCallback(
      async (variables) => {
        if (!href) {
          throw new Error('No href for property reservation');
        }

        if (!auth.current?.['access-token']) {
          throw new Error('Not authenticated');
        }

        await fetchMedia(href!, {
          headers: {
            accept: 'application/vnd.bnbbutler.reservation.v1+json',
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            contentType:
              'application/vnd.bnbbutler.property.v1.reservation+json',
            ...(auth.current as Record<string, string>),
          },
          method: 'POST',
          body: variables,
          debug: __DEV__,
        });
      },
      [href, auth, locale]
    );

  return useMutation(cacheKey, mutationFn, {
    ...options,
    onSettled: async (data, error, variables, context) => {
      await Promise.all([
        queryClient.cancelQueries([locale, 'event', 'list']),
        queryClient.cancelQueries([locale, 'reservation', 'list']),
        queryClient.cancelQueries(cacheKey),
      ]);

      await Promise.all([
        queryClient.invalidateQueries([locale, 'event', 'list']),
        queryClient.invalidateQueries([locale, 'reservation', 'list']),
        queryClient.invalidateQueries(cacheKey),
      ]);

      onSettled && onSettled(data, error, variables, context);
    },
  });
}
