import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useQuery } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type ApplauseArea = {
  _links: {
    self: {
      href: string;
    };
  };
  name: string;
  url: string;
  processed_image: string;
};

export type ApplauseAreaCollection = {
  areas: {
    _links: {
      self: {
        href: string;
      };
    };
    _embedded: ApplauseArea[];
  };
};

export function useAreas({ enabled = true } = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'areas');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseAreaCollection, FetchMediaError>(
    [locale, 'area', 'list'],
    {
      queryFn: async () =>
        fetchMedia(href!, {
          headers: {
            accept: 'application/vnd.bnbbutler.host-area.v1.collection+json',
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
        }).then((response) => response as ApplauseAreaCollection),
      enabled: !!auth.current?.['access-token'] && enabled && !!href,
    }
  );
}
