import {
  getCameraPermissionsAsync,
  getMediaLibraryPermissionsAsync,
  getPendingResultAsync,
  ImagePickerResult,
  ImagePickerSuccessResult,
  launchCameraAsync,
  launchImageLibraryAsync,
  MediaTypeOptions,
  requestCameraPermissionsAsync,
  requestMediaLibraryPermissionsAsync,
} from 'expo-image-picker';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Alert, Linking, Platform } from 'react-native';
import { Button, Menu } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { useIsMounted } from 'use-is-mounted';
import { i18n } from '../locale';

function convertToBlobFile(image: string) {
  return fetch(image).then((res) => res.blob());
}

export function DocumentAttachment({
  title,
  icon,
  kind = 'image',
  formDataKey = 'documents[]',
  formDataName = 'document',
  onChanged,
}: {
  icon: string | undefined;
  title: string;
  kind?: 'image' | 'video' | 'either';
  formDataKey?: string;
  formDataName?: string;
  onChanged(next: null | FormData): void;
}) {
  const [isPickingOption, togglePicking] = useReducer((prev) => !prev, false);

  const tailwind = useTailwind();
  const isMountedRef = useIsMounted();
  const [isFileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    getPendingResultAsync().then((values) => {
      if (!values || values.length === 0) {
        return;
      }
      const value = values[0];
      if (Object.prototype.hasOwnProperty.call(value, 'code')) {
        return; // error
      }

      const result = value as ImagePickerResult;
      if (result.canceled) {
        return;
      }

      processResult(pickResult(result), {
        onChanged,
        isMountedRef,
        formDataKey,
        formDataName,
        defaultType: kind === 'either' ? 'video' : kind,
      });
    });
  }, [formDataKey, formDataName, kind]);

  const pick = useCallback(
    async (type: 'image' | 'video' | 'either') => {
      if (Platform.OS !== 'web') {
        setFileLoading(true);
      }

      let permission = await getMediaLibraryPermissionsAsync(false);

      if (!permission.granted && Platform.OS === 'ios') {
        if (permission.canAskAgain) {
          permission = await requestMediaLibraryPermissionsAsync(false);
        }
      }

      if (!isMountedRef.current) {
        return;
      }

      if (
        !permission.granted &&
        !permission.canAskAgain &&
        Platform.OS === 'ios'
      ) {
        Alert.alert(
          'Permissions not granted',
          'You have denied permissions in the past. Fix this in iOS settings for this app.'
        );
        setFileLoading(false);
        return;
      }

      let result = await launchImageLibraryAsync({
        quality: 1,
        allowsEditing: true,
        base64: Platform.OS === 'web',
        mediaTypes: kindToMediaTypes(type),
      });

      if (!result || result.canceled) {
        if (Platform.OS === 'android') {
          const results = await getPendingResultAsync();
          if (
            results &&
            results.length !== 0 &&
            !Object.prototype.hasOwnProperty.call(results[0], 'code')
          ) {
            result = results[0] as ImagePickerResult;
          }
        }
      }

      setFileLoading(false);

      if (!result || result.canceled) {
        return;
      }

      processResult(pickResult(result), {
        onChanged,
        isMountedRef,
        formDataKey,
        formDataName,
        defaultType: type === 'either' ? 'video' : type,
      });
    },
    [isMountedRef, formDataKey, formDataName]
  );

  const openCamera = useCallback(
    async (type: 'image' | 'video' | 'either') => {
      if (Platform.OS !== 'web') {
        setFileLoading(true);
      }

      let permission = await getCameraPermissionsAsync();

      if (!permission.granted && Platform.OS !== 'web') {
        if (permission.canAskAgain) {
          permission = await requestCameraPermissionsAsync();
        }
      }

      if (!isMountedRef.current) {
        return;
      }

      if (!permission.granted && Platform.OS !== 'web') {
        Alert.alert(
          'Permissions not granted',
          'You have denied permissions in the past. Fix this in system settings for this app.',
          [{ onPress: Linking.openSettings, text: 'Open settings' }]
        );
        setFileLoading(false);
        return;
      }

      let result = await launchCameraAsync({
        quality: 1,
        allowsEditing: true,
        base64: Platform.OS === 'web',
        mediaTypes: kindToMediaTypes(type),
      });

      if (!result || result.canceled) {
        if (Platform.OS === 'android') {
          const results = await getPendingResultAsync();
          if (
            results &&
            results.length !== 0 &&
            !Object.prototype.hasOwnProperty.call(results[0], 'code')
          ) {
            result = results[0] as ImagePickerResult;
          }
        }
      }

      setFileLoading(false);

      if (!result || result.canceled) {
        return;
      }

      processResult(pickResult(result), {
        onChanged,
        isMountedRef,
        formDataKey,
        formDataName,
        defaultType: type === 'either' ? 'video' : type,
      });
    },
    [isMountedRef, formDataKey, formDataName]
  );

  return (
    <Menu
      visible={isPickingOption}
      onDismiss={() => {
        togglePicking();
        setFileLoading(false);
      }}
      anchor={
        <Button
          mode="contained"
          icon={icon}
          style={tailwind('mb-2')}
          onPress={togglePicking}
          disabled={isFileLoading || isPickingOption}
          loading={isFileLoading}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
            paddingHorizontal: 6,
          }}
        >
          {title}
        </Button>
      }
    >
      {kind !== 'video' ? (
        <Menu.Item
          leadingIcon="image"
          title={i18n.t('actions.pick-camera-image')}
          titleStyle={{ includeFontPadding: false }}
          onPress={() =>
            openCamera('image')
              .then(togglePicking)
              .catch(() => {})
          }
        />
      ) : null}

      {kind !== 'image' ? (
        <Menu.Item
          leadingIcon="video"
          title={i18n.t('actions.pick-camera-video')}
          titleStyle={{ includeFontPadding: false }}
          onPress={() =>
            openCamera('video')
              .then(togglePicking)
              .catch(() => {})
          }
        />
      ) : null}

      {kind === 'image' ? (
        <Menu.Item
          leadingIcon="camera"
          title={i18n.t('actions.pick-image')}
          titleStyle={{ includeFontPadding: false }}
          onPress={() =>
            pick('image')
              .then(togglePicking)
              .catch(() => {})
          }
        />
      ) : null}
      {kind === 'video' ? (
        <Menu.Item
          leadingIcon="camera"
          title={i18n.t('actions.pick-video')}
          titleStyle={{ includeFontPadding: false }}
          onPress={() =>
            pick('video')
              .then(togglePicking)
              .catch(() => {})
          }
        />
      ) : null}
      {kind === 'either' ? (
        <Menu.Item
          leadingIcon="camera"
          title={i18n.t('actions.pick-file')}
          titleStyle={{ includeFontPadding: false }}
          onPress={() =>
            pick('either')
              .then(togglePicking)
              .catch(() => {})
          }
        />
      ) : null}
    </Menu>
  );
}

function kindToMediaTypes(kind: 'image' | 'video' | 'either') {
  switch (kind) {
    case 'image': {
      return MediaTypeOptions.Images;
    }

    case 'video': {
      return MediaTypeOptions.Videos;
    }

    default: {
      return MediaTypeOptions.All;
    }
  }
}

type PickedResult = {
  content: string;
  preview: string;
  type: 'image' | 'video' | null;
};
function pickResult(result: ImagePickerSuccessResult): PickedResult {
  const file = result.assets[0];

  if (file.base64) {
    return {
      content: `data:image/jpeg;base64,${file.base64}`,
      preview: file.uri,
      type: 'image',
    };
  }

  if (Platform.OS === 'web') {
    return {
      content: file.uri,
      preview: file.uri,
      type: file.type || null,
    };
  }

  return {
    content: file.uri,
    preview: file.uri,
    type: file.type || null,
  };
}

async function processResult(
  picked: PickedResult,
  {
    onChanged,
    isMountedRef,
    formDataName,
    formDataKey,
    defaultType,
  }: {
    onChanged(next: null | FormData): void;
    isMountedRef: RefObject<boolean>;
    formDataName: string;
    formDataKey: string;
    defaultType: 'image' | 'video';
  }
) {
  const { content, type } = picked || {};
  if (!content) {
    onChanged(null);
    return null;
  }

  // It's base64, so we need to convert it
  if (
    typeof content === 'string' &&
    (content!.startsWith('data:image') || content!.startsWith('data:video'))
  ) {
    try {
      const blobFile = await convertToBlobFile(content);
      if (!isMountedRef.current) {
        return blobFile;
      }

      const formData = new FormData();
      formData.append(formDataKey, blobFile, formDataName);
      onChanged(formData);
      return formData;
    } catch (error) {
      return null;
    }
  }

  if (typeof content === 'string' && content.startsWith('file:')) {
    const uriParts = content.split('.');
    const fileType = uriParts[uriParts.length - 1];

    const formData = new FormData();
    formData.append(formDataKey, {
      uri: content,
      name: `${formDataKey}.${fileType}`,
      type: `${type || defaultType}/${fileType}`,
    } as any);

    onChanged(formData);
    return formData;
  }

  return null;
}
