import { fetchMedia, fetchMediaWrapped } from 'fetch-media';
import { FetchMediaError } from 'fetch-media/FetcMediaError';
import { useCallback } from 'react';
import {
  MutationFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseService = {
  _links: {
    self: {
      href: string;
      category: ApplauseServiceCategory['slug'];
      slug: string;
      search?: string;
      name?: string;
    };
    booking?: {
      href: string;
    };
    brand?: {
      href: string;
      name: string;
    };
    category?: {
      href: string;
      slug?: string;
      name: string;
      order?: number;
    };
    listing_image?: {
      href: string;
    };
    detail_images?: {
      href: string;
    };
    property?: {
      href: string;
    };
    request: {
      href: string;
    };
  };

  servicers_required: number;
  tasks: string;
} & (ApplauseServiceV4Additions | object);

export type ApplauseServiceV4Additions = {
  name: string;
  description: string | null;
  price_cents: number;
  price_currency: string;
  needs_guest_count: boolean;
  needs_quantity: boolean;
  needs_date: boolean;
  needs_time: boolean;
  fixed_price: boolean;
};

type ApplauseServiceResponse = {
  service: ApplauseService;
};

type ApplauseServiceCategory = {
  slug: 'cleaning' | 'housework' | 'reservation' | 'service';
  name?: string;
  href?: string;
};

export type ApplauseServicesResponse = {
  services: {
    _links: {
      self: {
        href: string;
      };
    };
    categories?: ApplauseServiceCategory[];
    _embedded: { service: ApplauseService }[];
  };
};

export type ApplauseServiceRequest = {
  request: {
    date?: string;
    minimal_date?: string | null;
    quantity: number;
    notes?: string;
  };
};

export function useServices(
  href: string | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseServicesResponse, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(cacheKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.service.v5.collection+json',
            'application/vnd.bnbbutler.service.v4.collection+json; q=0.9',
            'application/vnd.bnbbutler.service.v2.collection+json; q=0.8',
            'application/vnd.bnbbutler.service.v1.collection+json; q=0.7',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      }).then((response) => response as ApplauseServicesResponse),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 30 * 1000, // 30 seconds
    ...options,
  });
}

export function useService(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseService, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseService, FetchMediaError>(cacheKey, {
    queryFn: async () =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.service.v5+json',
            'application/vnd.bnbbutler.service.v4+json; q=0.9',
            'application/vnd.bnbbutler.service.v2+json; q=0.8',
            'application/vnd.bnbbutler.service.v1+json; q=0.7',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
      })
        .then((response) => response as ApplauseServiceResponse)
        .then(({ service }) => service),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...options,
  });
}

export function useRequestService(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    onSettled,
    ...options
  }: UseMutationOptions<
    string | undefined,
    FetchMediaError,
    ApplauseServiceRequest,
    void
  > = {}
) {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const locale = useLocale();

  const mutationFn: MutationFunction<
    string | undefined,
    ApplauseServiceRequest
  > = useCallback(
    async (variables) => {
      if (!href) {
        throw new Error('No href for service request');
      }

      if (!auth.current?.['access-token']) {
        throw new Error('Not authenticated');
      }

      const result = await fetchMediaWrapped(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.assignment.v4+json'].join(', '),

          contentType: 'application/vnd.bnbbutler.assignment.v1.request+json',
          ...(auth.current as Record<string, string>),
        },
        method: 'POST',
        body: variables,
        debug: __DEV__,
      });

      const linkHeader = result.response.headers.get('Link');
      if (linkHeader) {
        const deeplink = linkHeader
          .split(',')
          .find((value) => value.includes('rel="deeplink"'));
        if (deeplink) {
          return deeplink.split(';')[0].slice(1, -1);
        }
      }
    },
    [href, auth]
  );

  return useMutation(cacheKey, mutationFn, {
    ...options,
    onSettled: async (data, error, variables, context) => {
      await Promise.all([
        queryClient.cancelQueries([locale, 'event', 'list']),
        queryClient.cancelQueries(cacheKey),
      ]);

      await Promise.all([
        queryClient.invalidateQueries([locale, 'event', 'list']),
        queryClient.invalidateQueries(cacheKey),
      ]);

      onSettled && onSettled(data, error, variables, context);
    },
  });
}
