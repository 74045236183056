import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseCategory = {
  _links: {
    self: {
      href: string;
      name: string;
    };
    icon?: {
      href: string;
    };
  };

  name: string;

  hide_brands?: boolean;
  hide_services?: boolean;
};

export type ApplauseCategoryResponse = {
  category: ApplauseCategory;
};

export function useCategory(
  href: string | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseCategoryResponse, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(cacheKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.category.v3+json',
            'application/vnd.bnbbutler.category.v2+json; q=0.9',
            'application/vnd.bnbbutler.category.v1+json; q=0.8',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      }).then((response) => response as ApplauseCategoryResponse),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...options,
  });
}

export function categoryCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'category', `${href?.split('/').pop() || '-'}`];
}
