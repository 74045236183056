// Do no re-order
import 'expo-dev-client';
import 'react-native-gesture-handler';
import 'react-native-reanimated';

// Do not move above the section above, or below the section below
import { StatusBar } from 'expo-status-bar';
import { setDefaultAccept, setDefaultHeaders } from 'fetch-media';
import { Component, ErrorInfo } from 'react';
import { View } from 'react-native';
import { Text, Title } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';

// Imports with side-effects
import { defineTranslations, i18n } from './src/locale';
import './src/matchMedia';
import './src/screens';
import { Sentry } from './src/sentry';
import './src/storage';
import './src/warnings';

// Do not move above the side-effects
// eslint-disable-next-line import/order
import { TailwindProvider } from 'tailwind-rn';
import { Root } from './src/components/Root';
import { APPLAUSE_APP_NAME, EXPERIENCE_SLUG, USER_AGENT } from './src/config';

import utilities from './tailwind.json';

setDefaultHeaders({
  DNT: '1',
  'User-Agent': USER_AGENT,
  'Applause-Environment': EXPERIENCE_SLUG,
});

setDefaultAccept(
  'application/vnd.bnbbutler.errors.v1+json; q=0.2',
  'application/problem+json; q=0.1'
);

defineTranslations({
  en: {
    app: {
      loading: {
        title: APPLAUSE_APP_NAME,
        body: 'Loading & connecting to the discovery service.',

        error: {
          title: APPLAUSE_APP_NAME,
          body: 'Cannot start the experience right now.',
        },
      },

      errors: {
        connect:
          'Failed to connect. Are you connected to the internet? We may be performing maintenance.',
      },

      error: {
        title: 'Something went wrong',
        body: 'This failure was unexpected, but we have been notified. You can try again by force closing Sounders Music. If the problem persists, it will keep showing up in our notifications.',
      },
    },
  },

  nl: {
    app: {
      loading: {
        title: APPLAUSE_APP_NAME,
        body: 'Laden & verbinden met onze service.',

        error: {
          title: APPLAUSE_APP_NAME,
          body: 'Kan de omgeving nu niet starten.',
        },
      },

      errors: {
        connect:
          'Verbinding maken mislukt. Ben je verbonden met het internet? Het kan zijn dat wij momenteel onderhoud plegen.',
      },

      error: {
        title: 'Er is iets misgegaan',
        body: 'Deze fout is onverwacht, maar we zijn op de hoogte gesteld. Je kan het opnieuw proberen door Sounders Music helemaal af te sluiten. Als het probleem zich blijft voordoen, blijft het ook zichtbaar in onze notificaties.',
      },
    },
  },
});

export default function App() {
  return (
    <RootErrorBoundary>
      {/* @ts-ignore */}
      <TailwindProvider utilities={utilities}>
        <Root />
      </TailwindProvider>
    </RootErrorBoundary>
  );
}

type RootErrorBoundaryState = {
  error?: Error;
};

class RootErrorBoundary extends Component<
  { children: React.ReactNode },
  RootErrorBoundaryState
> {
  static getDerivedStateFromError(
    error: Error
  ): Partial<RootErrorBoundaryState> {
    return {
      error,
    };
  }

  constructor(props: { children: React.ReactNode }) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error, {
      level: 'error',
      extra: {
        componentStack: errorInfo.componentStack,
      },
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorPanel error={this.state.error} />;
    }

    return this.props.children;
  }
}

function ErrorPanel({
  title,
  body,
  error,
}: {
  title?: string;
  body?: string;
  error: unknown;
}) {
  const message =
    typeof error === 'object'
      ? error === null
        ? 'No information on the error'
        : error instanceof Error
        ? `${error.name}: ${error.message}`
        : 'message' in error
        ? (error.message as string)
        : 'No information on the error'
      : String(error);

  return (
    <SafeAreaProvider>
      <StatusBar translucent style="dark" backgroundColor="#FFFFFF00" />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Title
          style={{
            textAlign: 'center',
            paddingHorizontal: 16,
            marginHorizontal: 'auto',
            color: '#FFFFFF',
            display: 'flex',
          }}
        >
          {title ?? i18n.translate('app.error.title')}
        </Title>
        <Text
          style={{
            textAlign: 'center',
            paddingHorizontal: 16,
            marginHorizontal: 'auto',
            color: '#FFFFFFAA',
            marginTop: 12,
            maxWidth: 600,
            display: 'flex',
          }}
        >
          {body ?? i18n.translate('app.error.body')}
        </Text>
        <Text
          style={{
            color: '#FFFFFFAA',
            textAlign: 'center',
            marginTop: 24,
            minHeight: 200,
            paddingHorizontal: 16,
            marginHorizontal: 'auto',
            maxWidth: 400,
            display: 'flex',
          }}
        >
          {humanizeError(message)}
        </Text>
      </View>
    </SafeAreaProvider>
  );
}

function humanizeError(message: string): string {
  if (message.includes('Failed to fetch')) {
    return i18n.translate('app.errors.connect');
  }

  return message;
}
