import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';

export type ApplauseAssignment = {
  _links: {
    self: {
      href: string;
      status: string;
      category: string;
      slug: string;
      search: string;
    };
    booking?: {
      href: string;
      reference?: string | null;
    };
    brand?: {
      href: string;
      name: string;
    };
    category: {
      href: string;
      slug: string;
      name: string;
    };
    owner?: {
      href: string;
      display_name: string | null;
      phone_number?: string | null;
    };
    report?: {
      href: string;
    };
    reviews?: {
      href: string;
    };
    property?: {
      href: string;
      reference: string | null;
      location?: string;
    };
    service: {
      href: string;
      slug: string;
      name: string;
    };
    servicers: {
      href: string;
      requested: number;
      assigned: number;
    };
    thumbnail?: {
      href: string;
    };

    claim?: {
      href: string;
    };
    start?: {
      href: string;
    };
    pause?: {
      href: string;
    };
    finish?: {
      href: string;
    };
    cancel?: {
      href: string;
    };
    recreate?: {
      href: string;
    };
    deeplink?: {
      href: string;
    };
  };

  date?: string | null;
  time?: string | null;
  quantity?: number | null;
  note?: string | null;

  created_at: string;
  status: string;
  status_updated_at: string | null;

  price_cents?: number | null;
  price_currency: 'EUR';

  tasks: string | string[];
};

type ApplauseAssignmentResponse = {
  assignment: ApplauseAssignment;
};

export type ApplauseAssignmentsIndex = {
  assignments: {
    _links: {
      self: { href: string };
    };
    _index: ApplauseAssignment['_links']['self'][];
  };
};

export function useBookingAssignments(
  href: string | null | undefined,
  options: UseQueryOptions<ApplauseAssignmentsIndex, FetchMediaError> = {}
) {
  const locale = useLocale();
  const parts = (href || '').split('/');
  return useScopedAssignments(
    href,
    [
      locale,
      'assignment',
      'list',
      parts ? parts[parts.length - 2] ?? '-' : '-',
    ],
    options
  );
}

export function usePropertyAssignments(
  href: string | null | undefined,
  options: UseQueryOptions<ApplauseAssignmentsIndex, FetchMediaError> = {}
) {
  const locale = useLocale();
  const parts = (href || '').split('/');

  return useScopedAssignments(
    href,
    [
      locale,
      'assignment',
      'list',
      parts ? parts[parts.length - 2] ?? '-' : '-',
    ],
    options
  );
}

export function useAssignments(
  options: UseQueryOptions<ApplauseAssignmentsIndex, FetchMediaError> = {}
) {
  const locale = useLocale();
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_assignments');
  return useScopedAssignments(href, [locale, 'assignment', 'list'], options);
}

function useScopedAssignments(
  href: string | null | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseAssignmentsIndex, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  const statusMapping = i18n.t('assignments.states.status') as any;
  const serviceMapping = i18n.t('services.slugs') as any;
  const statusList = Object.keys(statusMapping || {});
  const serviceList = Object.keys(serviceMapping || {});

  return useQuery(cacheKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.assignment.v4.index+json'].join(
            ','
          ),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),
          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseAssignmentsIndex)
        .then((value) => ({
          assignments: {
            _links: value.assignments._links,
            _index: value.assignments._index.map((index) => ({
              ...index,
              search: statusList.reduce(
                (s, status) => s.replace(status, statusMapping[status]),
                serviceList.reduce(
                  (s, service) =>
                    s.replace(service, serviceMapping[service].label),
                  index.search
                )
              ),
            })),
          },
        })),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 30 * 1000,
    ...options,
  });
}

export function assignmentCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'assignment', `${href?.split('/').pop() || '-'}`];
}

export function useAssignment(
  href: string | null | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseAssignment, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(assignmentCacheKey(href, locale), {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.assignment.v7.fused+json',
            'application/vnd.bnbbutler.assignment.v6.fused+json; q=0.9',
            'application/vnd.bnbbutler.assignment.v5.fused+json; q=0.8',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseAssignmentResponse)
        .then(({ assignment }) => assignment),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 30 * 1000,
    ...options,
  });
}
