import React, { Fragment } from 'react';
import { View } from 'react-native';
import { Card, Divider, List, Text, Title } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { fontFamily500, fontFamily700 } from '../constants/Theming';
import { i18n } from '../locale';
import { ApplauseService, ApplauseServiceV4Additions } from './useServices';

export function ServiceDetails({
  quantity,
  priceQuantity,
  service,
  withTitle,
  title,
  children,
}: React.PropsWithChildren<{
  quantity?: null | number;
  priceQuantity: number;
  withTitle?: boolean;
  title?: string;
  service: ApplauseService & ApplauseServiceV4Additions;
}>) {
  const tailwind = useTailwind();

  if (
    !service.description &&
    service.price_cents === 0 &&
    !children &&
    (!withTitle || (!title && !service.name))
  ) {
    return null;
  }

  return (
    <Card style={tailwind('py-2')} elevation={1}>
      {withTitle ? (
        <Title
          style={[
            tailwind('px-4 pt-1'),
            { includeFontPadding: false, textAlignVertical: 'center' },
          ]}
        >
          {title || service.name}
          {typeof quantity === 'number' ? ` ${quantity}x` : null}
        </Title>
      ) : undefined}
      {service.description ? (
        <Fragment>
          <View style={[tailwind('flex-row px-4 pb-2'), { marginBottom: 4 }]}>
            <View style={tailwind('flex-1')}>
              <Text
                variant="bodyMedium"
                style={{
                  includeFontPadding: false,
                  textAlignVertical: 'center',
                }}
              >
                {service.description}
              </Text>
            </View>
          </View>
          {service.price_cents === 0 ? null : <Divider />}
        </Fragment>
      ) : null}

      {service.price_cents === 0 ? null : (
        <List.Item
          title={i18n.t('services.request.fields.price.label')}
          titleStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
            fontFamily: fontFamily700,
            fontWeight: '700',
          }}
          description={`${i18n.numberToCurrency(
            (service.price_cents * priceQuantity) / 100.0,
            { precision: 2, stripInsignificantZeros: false, unit: '' }
          )} ${service.price_currency}`}
          descriptionStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
            marginTop: 6,
            fontFamily: fontFamily500,
            fontWeight: '500',
          }}
        />
      )}
      {children}
    </Card>
  );
}
