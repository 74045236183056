import { useEffect, useState } from 'react';

export type Searchable = { search?: string | null };

const EMPTY: unknown[] = [];

export function useSearchResults<
  TDataKey extends string,
  TData extends Record<TDataKey, { _index: Searchable[] }>
>(data: TData | undefined, key: TDataKey) {
  const [query, setQuery] = useState('');
  const [filteredData, setFilteredData] = useState<TData[TDataKey]['_index']>(
    EMPTY as TData[TDataKey]['_index']
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    const parts = query
      .toLocaleUpperCase()
      .split(' ')
      .map((part) => part.trim());

    const debounce = setTimeout(() => {
      const nextIndex = data[key]._index.filter((item) => {
        if (!item.search) {
          return false;
        }

        const search = item.search.toLocaleUpperCase();
        return parts.every((part) => search.includes(part));
      });
      setFilteredData(nextIndex);
    }, 200);
    return () => clearTimeout(debounce);
  }, [data, query]);

  if (query) {
    return { data: filteredData, query, setQuery };
  }

  return {
    data: (data ? data[key]._index : EMPTY) as TData[TDataKey]['_index'],
    query,
    setQuery,
  };
}
