import React from 'react';
import Svg, {
  Defs,
  Ellipse,
  G,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

export const EmptyState = (props: SvgProps = {}) => (
  <Svg
    viewBox="0 0 400 300"
    style={{ width: '100%', height: '100%', flex: 1 }}
    {...props}
  >
    <Defs>
      <LinearGradient
        id="a"
        x1={231.82}
        x2={200.75}
        y1={174.35}
        y2={199.4}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#f15a24" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <LinearGradient id="b" x1={109.34} x2={85.82} y1={187.85} y2={210.22} />
      <LinearGradient id="c" x1={189.03} x2={161.2} y1={178.54} y2={205} />
      <LinearGradient id="d" x1={298.58} x2={272.73} y1={164.9} y2={189.48} />
    </Defs>
    <G data-name="Layer 2">
      <Path
        fill="#ffbf41"
        d="M347.56,166,232.06,98.87a5.46,5.46,0,0,0-5.46,0L53.88,197.72a5.46,5.46,0,0,0,0,9.47L164.31,271a17.83,17.83,0,0,0,17.83,0l165.4-95.46A5.46,5.46,0,0,0,347.56,166Z"
      />
      <Path
        fill="url(#a)"
        d="M193.91,205.84q2.07,1.3,4.15,2.56l30.16-17.59-15-11.15-28.91,19.45A102,102,0,0,0,193.91,205.84Z"
      />
      <Path
        fill="#8c6864"
        d="M202.77,185.31c1.54,1,2.7,1.62,2.7,1.62,1.23,0,3.51.27,2.81-1.34a14.92,14.92,0,0,0,3,3.56c1.33,1.15,8.8,2.22,6.23,6.66-1.32,2.29-14.28-4.27-14.6-5.83A25.41,25.41,0,0,1,202.77,185.31Z"
      />
      <Path
        fill="#f7931e"
        d="M208.37,177.76a25.73,25.73,0,0,0-.19,6.71c.07.48.14,1,.23,1.53l-5.68-1v-7.28Z"
      />
      <Path
        fill="#56403d"
        d="M202.77,183.74c1.54,1,2.7,1.62,2.7,1.62,1.23,0,3.51.27,2.81-1.34a14.92,14.92,0,0,0,3,3.56c1.33,1.15,8.83,4.84,5.7,6.78-2.33,1.45-13.75-4.4-14.07-6A25.41,25.41,0,0,1,202.77,183.74Z"
      />
      <Path
        fill="#56403d"
        d="M205.18,185.45c-.06-.41-.88-2.49-.21-3s3.54-1.19,3.82-.38a15,15,0,0,1,0,3.63"
      />
      <Path
        fill="#8c6864"
        d="M213.47,180.32c1.54,1,2.7,1.62,2.7,1.62,1.23,0,3.51.27,2.81-1.34a14.92,14.92,0,0,0,3,3.56c1.33,1.15,8.8,2.22,6.23,6.66-1.32,2.29-14.28-4.27-14.6-5.83A25.41,25.41,0,0,1,213.47,180.32Z"
      />
      <Path
        fill="#f7931e"
        d="M219.07,172.76a25.73,25.73,0,0,0-.19,6.71c.07.48.14,1,.23,1.53l-5.68-1v-7.28Z"
      />
      <Path
        fill="#56403d"
        d="M213.47,178.75c1.54,1,2.7,1.62,2.7,1.62,1.23,0,3.51.27,2.81-1.34a14.92,14.92,0,0,0,3,3.56c1.33,1.15,8.83,4.84,5.7,6.78-2.33,1.45-13.75-4.4-14.07-6A25.41,25.41,0,0,1,213.47,178.75Z"
      />
      <Path
        fill="#56403d"
        d="M215.88,180.46c-.06-.41-.88-2.49-.21-3s3.54-1.19,3.82-.38a15,15,0,0,1,0,3.63"
      />
      <Path
        fill="#f7931e"
        d="M254.94,71.2a1.58,1.58,0,0,0-.08-1.25,1.32,1.32,0,0,0-1.49-.64,1,1,0,0,0,0-.1,2.15,2.15,0,0,0-.08-.51,1.62,1.62,0,0,0-.54-.82,1.36,1.36,0,0,0-.31-.18c.51-.64,1-1.29,1.46-2s.92-1.87,0-2.28-1.65,1-2.11,1.53q-1.23,1.42-2.43,2.87a2.42,2.42,0,0,0-2.44.52,40,40,0,0,0-3.79,3.87.66.66,0,0,0,0,.86.69.69,0,0,0-.08.47c.05.3.12.59.19.88a11.29,11.29,0,0,0,.4,1.42.67.67,0,0,0,.75.45.6.6,0,0,0,.6.84c2-.19,4-.45,6-.79,1.56-.27,3.35-.63,3.82-2.38a.65.65,0,0,0,0-.32,1.82,1.82,0,0,0,.58-1A1.56,1.56,0,0,0,254.94,71.2Z"
      />
      <Path
        fill="#42210b"
        d="M253,69.21a8.65,8.65,0,0,0-1.94.87,6.87,6.87,0,0,0-1.4-2.31c-.21-.24-.57.11-.35.35a5.16,5.16,0,0,1,1.52,3c-.05,1.22-1.19,1.3-1.87.44-.36-.45-.65-1-1-1.45-.18-.26-.61,0-.43.25l1,1.45a2.07,2.07,0,0,0,.75.83,1.81,1.81,0,0,0,1.74-.43,1.62,1.62,0,0,0,.19-1.68l.06,0a8.11,8.11,0,0,1,1.85-.84C253.42,69.6,253.29,69.12,253,69.21Z"
      />
      <Path
        fill="#42210b"
        d="M251.73,72.18a3.71,3.71,0,0,1,1.27-.47l1.38-.41a.25.25,0,0,0-.13-.48,22.36,22.36,0,0,0-2.43.74,1.28,1.28,0,0,0-.9,1.15.81.81,0,0,0,.52.78,1.27,1.27,0,0,0-.4.91.78.78,0,0,0,.57.71.25.25,0,0,0,.13-.48c-.46-.12-.05-.69.11-.87a.24.24,0,0,0,.07-.16,7,7,0,0,0,2.29-.31.25.25,0,0,0-.13-.48,7.47,7.47,0,0,1-1.49.26,4.07,4.07,0,0,1-.61,0C251.41,73.05,251.33,72.51,251.73,72.18Z"
      />
      <Path
        fill="#311e69"
        d="M222.86,106.71s1.78,48.31-1.74,68.61c0,0-.7,2.26-9.1.95l-.56,4.23a17.71,17.71,0,0,1-11.48-.8s-2.33-46.92,4.66-74.18"
      />
      <Path
        fill="#68e1fd"
        d="M245,77.63l-2.26-8.4s-12,12.93-26.5,11c-2.07.21-4.21.28-6.25.64a9.29,9.29,0,0,0-2.93,1.06c-2.87,4-3.87,9-4.17,13.92-.17,2.69-.15,5.4-.14,8.09a40,40,0,0,0,.29,5.13,1.32,1.32,0,0,0,.65,1,7.41,7.41,0,0,0,3.35,1c5.06.21,11.19,0,15.14-3.29a11.89,11.89,0,0,0,.69-1.07l-.65-14.86S238.7,86.68,245,77.63Z"
      />
      <Path
        fill="#42210b"
        d="M218.25,91.1s-.35-2.61,0-2.93,7.19-3.55,7.78-3.29.43,3.1.43,3.1,1.6.45,1.6,1.08v3.15a4,4,0,0,1-1.83.35A18.24,18.24,0,0,1,226,95l-6,1.24"
      />
      <Path
        fill="#68e1fd"
        d="M210,80.86s-7,.75-9.29,8.9c-2.7,9.55-.92,11.7,2.05,14.18"
      />
      <Path
        fill="#f7931e"
        d="M218.44,72.52s2.23,8.13-1.45,9.91-6.25-.87-7.37-3.3-.69-8.11,4.06-8.69C217.82,69.93,218.44,72.52,218.44,72.52Z"
      />
      <Path
        fill="#935542"
        d="M219.16,71c.39-1,.55-2.76-.61-3.31a2.54,2.54,0,0,0-2.62.48,3.62,3.62,0,0,0-1.41-.67,5.72,5.72,0,0,0-5.37,1.16.81.81,0,0,1-.22.16,1,1,0,0,1-.34,0c-2.89.05-3.51,3.32-3,5.54a11.15,11.15,0,0,0,3.49,6,6.92,6.92,0,0,0,2.62,1.33.22.22,0,0,0,.28-.23.27.27,0,0,0,.09-.38,2.6,2.6,0,0,1-.25-1.58.24.24,0,0,0-.07-.21.26.26,0,0,0-.21-.43,2,2,0,0,1-1.7-.59c-.27-.28-.49-.83,0-1a.63.63,0,0,1,.57.15,2.08,2.08,0,0,0,.28.37.26.26,0,0,0,.15.08c.2.07.45-.1.32-.32a2.13,2.13,0,0,0-.32-.43,1.49,1.49,0,0,1,.27-1.87c.68-.6,1.3-.27,2.08-.24a1,1,0,0,0,1.09-.71,3.71,3.71,0,0,0-.12-1,1.18,1.18,0,0,1,.73-1.14c.82-.32,1.36.38,2,.79a2.13,2.13,0,0,0,1.77.36,1.46,1.46,0,0,0,.78-2.33A.25.25,0,0,0,219.16,71Zm-5,1.15a.82.82,0,0,1,.38-.32l.15-.09h.05l.08,0A1.7,1.7,0,0,0,214.16,72.13Zm4.65-1.72,0,0a2.34,2.34,0,0,0,.1-.27C218.85,70.22,218.83,70.32,218.81,70.41Z"
      />
      <Path
        fill="#fff"
        d="M215.15,122.23q-.63,22.47-2.27,44.91-.46,6.3-1,12.6c0,.36.53.36.56,0q1.93-22.4,2.85-44.87.26-6.31.43-12.63A.28.28,0,0,0,215.15,122.23Z"
      />
      <Path
        fill="#f7931e"
        d="M225,89.85a1.4,1.4,0,0,0,.65-1.47c-.15-1-1.56-.54-1.56-.54s-.11-1.44-1.16-1.48-4.63,2.36-4.63,2.36l.07-1.69c0-.3-.23-.36-.58-.26-1,.29-1.11,1.7-1.16,2.54a2.51,2.51,0,0,0,.1.93c.08.23.29.52.13.75a12.2,12.2,0,0,1-2.17,1l.2.39.58.71a13.06,13.06,0,0,1,2,2.95l1.75-.67s6.12-1,6.41-1.76a2.75,2.75,0,0,0,0-1.61l.35-.91C226.33,90.23,225,89.85,225,89.85Z"
      />
      <Path
        fill="#42210b"
        d="M219.31 89.58a31.8 31.8 0 004.72-2c.16-.08.26.18.1.26a31.8 31.8 0 01-4.72 2C219.24 89.89 219.14 89.63 219.31 89.58zM219.75 91.36a24.2 24.2 0 005.45-1.52.14.14 0 01.1.26 24.52 24.52 0 01-5.52 1.54C219.6 91.66 219.57 91.39 219.75 91.36zM220.7 93A28.1 28.1 0 00225.64 92a.14.14 0 01.1.26 28.41 28.41 0 01-5 1.08C220.56 93.35 220.53 93.07 220.7 93z"
      />
      <Path
        fill="#311e69"
        d="M218.57 98.1l.5-.45a33.81 33.81 0 00-4.59-6.55c-.28-.31-.74.15-.46.46A33.1 33.1 0 01218.57 98.1zM213.28 91.3q-2.85 2.19-5.79 4.24a25.22 25.22 0 01.64-3.65.33.33 0 00-.63-.17 26.19 26.19 0 00-.7 4.3l-1.23.85c-.35.23 0 .8.33.57q3.94-2.67 7.72-5.57C213.94 91.61 213.61 91 213.28 91.3z"
      />
      <Path
        fill="#311e69"
        d="M219.07,97.65s-7.47,10.52-16.32,6.28l.12,3.51S214.11,107.68,219.07,97.65Z"
      />
      <Path
        fill="#68e1fd"
        d="M200.44,75.89s1.26,2.28,10-.28S224.83,68,224.1,66.38,198.64,71.67,200.44,75.89Z"
      />
      <Path
        fill="#68e1fd"
        d="M217.1,70.1s.1-6.59-6.86-4.59c-4.32,1.25-6,3.22-3.75,9.93"
      />
      <Path
        fill="#42210b"
        d="M209.11 81.93a3.23 3.23 0 01-.47-1 14.75 14.75 0 00-1.56.9v0l5.85 10.48.7-.53 1 .18S210.31 83.69 209.11 81.93zM217.94 81.7l1.13 6.5 2-1.14-1.4-6.65h-1A6 6 0 01217.94 81.7z"
      />
      <Path
        fill="#41c1ba"
        d="M158,267.29s-14-35.91,16.49-42.57,40.09,16,50.94-3.33c9.87-17.55,18.51-37.1,46.27-35.58s44.09,7.83,44.09,7.83L182.14,271S171.38,278.14,158,267.29Z"
      />
      <Path
        fill="url(#b)"
        d="M86.55,225.61l24.7-23.31-6.61-14.47L64.16,214.56C70.2,218.69,80.6,221.32,86.55,225.61Z"
      />
      <Path
        fill="url(#c)"
        d="M160.94,226.56,200.08,196l-28.39-17.17-40.48,26.72C137.26,209.69,155,222.28,160.94,226.56Z"
      />
      <Path
        fill="url(#d)"
        d="M268.15,208.41l33.14-35.24L291,169.26,250.57,196C256.61,200.11,262.2,204.12,268.15,208.41Z"
      />
      <Path
        fill="#00408d"
        d="M199.61,191.59l-2.42-1.14c-.42-.2-1.44.53-1.71.74a3.35,3.35,0,0,0-1.15,1.12c-.52,1.08-1.14,2.72-.55,3.88a3.43,3.43,0,0,0,3,1.84"
      />
      <Ellipse
        cx={197.82}
        cy={194.7}
        fill="#8530ff"
        rx={3.58}
        ry={2.61}
        transform="rotate(-60 197.81638498 194.69197064)"
      />
      <Ellipse
        cx={197.82}
        cy={194.87}
        fill="#0027de"
        rx={2.26}
        ry={1.64}
        transform="matrix(.5 -.87 .87 .5 -69.85 268.75)"
      />
      <Path
        fill="#68e1fd"
        d="M175.51,137.47l-12.77,7.44a3,3,0,0,0-1.5,2.61v39.05l19.92,12.53a6.27,6.27,0,0,0,6.42.15l11.18-6.33Z"
      />
      <Path
        fill="#68e1fd"
        d="M179.1,137.5,195.88,147a9.48,9.48,0,0,1,4.82,8.26v34.11a4.29,4.29,0,0,1-6.54,3.64L175.58,181.5a4.29,4.29,0,0,1-2-3.64V140.74A3.72,3.72,0,0,1,179.1,137.5Z"
      />
      <Path
        fill="#662d91"
        d="M175.57,144.92V132.35c0-1-.65-5.47,0-6.27s3.23,1.35,4.23,2c1.71,1,4.18,1.95,4.73,4,.44,1.65,0,4.11,0,5.79v12.69a1.13,1.13,0,0,0,2.25,0V134.21c0-2.22.1-3.78-1.6-5.48s-4.26-2.65-6.18-3.83c-3.66-2.25-5.68-1.52-5.68,2.89v17.14a1.13,1.13,0,0,0,2.25,0Z"
      />
      <Path
        fill="none"
        stroke="#662d91"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M166.38,146.14l16.47,9a8.92,8.92,0,0,1,4.73,7.81v32.27a4.21,4.21,0,0,1-6.42,3.45l-18.24-10.87a4,4,0,0,1-2-3.45V149.2A3.65,3.65,0,0,1,166.38,146.14Z"
      />
      <Path fill="#6a9546" d="M97.07 89L101.41 56.16 101.41 85.83" />
      <Path
        fill="#6a9546"
        d="M91.72,63.18c12.46,7.08,8,31.48,6.61,38-.23,1-.38,1.61-.38,1.61"
      />
      <Path
        fill="#6a9546"
        d="M77.24,68.69l18.64,16-.46-12.15s1.38-10-8.86-11S77.24,68.69,77.24,68.69Z"
      />
      <Path
        fill="#d8876a"
        d="M100.43,203.57a13.22,13.22,0,0,1-8.87-5C97.47,161.48,93.4,90.61,93.4,90.61l5-.66c9.67,27.81,12.38,98.34,12.83,112.35C108.16,204,103.85,204.08,100.43,203.57Z"
      />
      <Path
        fill="#85b23b"
        d="M123.24 63.06c15.32-.68 25.32 16.29 25.32 16.29l-49.92 19L96.57 86M100.26 83.41s7.66-19.68 23-20.35"
      />
      <Path
        fill="#6a9546"
        d="M152.52 94.95q-2.69 0-5.38.12l-3.44-6.74L145 95.15c-4.89.18-9.77.43-14.65.71l-4.4.27a19.53 19.53 0 00-2-5.84c-.21-.29-.05 3 .13 6a143.68 143.68 0 00-25.5 3.8l.85-2.59s3.21-15.53 29.38-21.56 42.66 19.67 42.66 19.67l-.52.05C164.9 94.64 158.69 94.87 152.52 94.95zM91.72 63.18c-14.42-8.2-19 8.49-19 8.49"
      />
      <Path
        fill="#85b23b"
        d="M98.65,98.39s3.58-12.82-14-25.66-37.19-1.36-37.19-1.36L59.62,81.1l8.9-3.61L63.58,84l10.34,6.94s6.68-4.45,8.87-5.69c.5-.28-4.57,8.13-4.57,8.13l19.73,9.45Z"
      />
      <Path
        fill="#6a9546"
        d="M86.63,101.89,86,96.18l-1.65,6.28c-5.11,1.32-10.15,2.87-15.21,4.52l-1.41-5-.41,5.61c-2.73.89-5.45,1.77-8.11,2.74l-1-6.23-1,7a53.42,53.42,0,0,0-9.33,4.6c-1-13.32,12.58-29.19,27.41-31.16,15-2,23.35,13.89,23.35,13.89l-3.93,1.8C92,100.67,89.3,101.25,86.63,101.89Z"
      />
      <Path
        fill="#85b23b"
        d="M127.41,105.82l.8-3.11-1.33,2.91a111.79,111.79,0,0,0-11.25-3.42l.19-3.89-1.32,3.61a141.77,141.77,0,0,0-16.43-2.87c1.05-1.68,5.58-7.78,16.93-9.83C127.42,87,137.9,101.35,138.15,110l-1.51-.23A95.34,95.34,0,0,0,127.41,105.82Z"
      />
      <Path
        fill="#d3894c"
        d="M297.18 38.89L312.55 30.77 315.74 32.33 312.55 42.41 301.86 44.8"
      />
      <Path
        fill="#c66b28"
        d="M296.55 41.91L315.74 32.33 319.58 35.13 318.11 40.96 299.47 51.49"
      />
      <Path
        fill="#e5955a"
        d="M290.4 32.09L295.97 29.06 301.54 32.21 295.97 37.05"
      />
      <Path
        fill="#d3894c"
        d="M303.23 63.33L321.64 74.54 328.1 70.81 325.45 62.5 299.75 47.56 303.23 63.33z"
      />
      <Path
        fill="#c66b28"
        d="M299.82 49.61L323.8 63.9 326.68 71.63 321.64 74.54 296.87 59.46 299.82 49.61z"
      />
      <Path
        fill="#d3894c"
        d="M294.14 37.76L278.76 29.64 275.86 31.19 278.76 41.28 289.45 43.66"
      />
      <Path
        fill="#c66b28"
        d="M295.05 40.77L275.86 31.19 272.02 33.99 273.5 39.83 292.13 50.35"
      />
      <Path
        fill="#d3894c"
        d="M297.78,35.34a3.44,3.44,0,0,1-.73-.22c-2.23-1-4.5-1.89-6.64-3V173c3,4.05,7.75,4,10.89.17.08-.16.17-.31.25-.47V38.32Q299.65,36.86,297.78,35.34Z"
      />
      <Path
        fill="#d3894c"
        d="M295.97 57.52L277.56 68.72 271.11 65 273.75 56.69 291.59 46.32 295.97 57.52z"
      />
      <Path
        fill="#c66b28"
        d="M291.59 48.44L275.4 58.09 272.52 65.81 277.56 68.72 291.3 60.36 291.59 48.44zM296.86 35L296.55 176a7.4 7.4 0 004.74-2.81c.08-.16.17-.3.25-.47V32.21C300 33.15 298.42 34.08 296.86 35z"
      />
    </G>
  </Svg>
);
