import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { Appbar } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { COLOR_APPBAR_SURFACE } from '../config';

export function ScreenHeader({
  title,
  showBack,
  hideSettings,
  children,
}: {
  title: string;
  showBack?: boolean;
  hideSettings?: boolean;
  children?: React.ReactNode;
}) {
  const tailwind = useTailwind();
  const { navigate, canGoBack, goBack } = useNavigation<any>();

  return (
    <Appbar.Header
      style={[
        tailwind('z-10 justify-center self-center w-full flex-row'),
        {
          elevation: 2,
          maxWidth: 786,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: COLOR_APPBAR_SURFACE,
        },
      ]}
    >
      {showBack ? (
        <Appbar.BackAction
          onPress={() =>
            canGoBack()
              ? goBack()
              : navigate('Tabs', { screen: 'Dashboard', replace: true })
          }
        />
      ) : null}
      <Appbar.Content
        title={title}
        titleStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
        }}
      />
      {children}
      {hideSettings ? null : (
        <Appbar.Action
          icon="cog"
          onPress={() => {
            navigate('Settings');
          }}
        />
      )}
    </Appbar.Header>
  );
}
