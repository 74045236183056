import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Snackbar, useTheme } from 'react-native-paper';
import { AA_THRESHOLD_CONTRAST, useContrast } from 'use-color-luminance';

export type SnackbarContent = {
  action?: {
    label: string;
    onPress: () => void;
  };
} & (
  | { content: string; children?: null }
  | React.PropsWithChildren<{ content?: null }>
);

export function ThemedSnackbar({
  active,
  onDismiss,
  action,
  content,
  children,
  style,
  wrapperStyle,
}: SnackbarContent & {
  active: boolean;
  onDismiss(): void;
  style?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
}) {
  const theme = useTheme();

  const {
    dark,
    colors: { primary, secondary: accent },
  } = theme;
  
  if (accent === undefined || primary === undefined)
  {
    console.warn(JSON.stringify(theme.colors))
    throw "[ThemedSnackbar.tsx] Theme primary or accent not defined, unable to continue."
  }

  const accentHasContrast =
    useContrast(accent, dark ? '#fff' : '#111') >= AA_THRESHOLD_CONTRAST;
  const primaryHasContrast =
    useContrast(primary, dark ? '#fff' : '#111') >= AA_THRESHOLD_CONTRAST;

  const actionColor = accentHasContrast
    ? accent
    : primaryHasContrast
    ? primary
    : dark
    ? '#111'
    : '#fff';

  return (
    <Snackbar
      visible={active}
      onDismiss={onDismiss}
      action={action}
      wrapperStyle={[{ maxWidth: 720, alignSelf: 'center' }, wrapperStyle]}
      style={style}
      theme={{ ...theme, colors: { ...theme.colors, secondary: actionColor } }}
    >
      {content || children}
    </Snackbar>
  );
}
