import { Image, View } from 'react-native';
import { List } from 'react-native-paper';

export function AssignmentThumbnailIcon({
  icon,
  image,
  props,
}: {
  icon: string;
  image: string | undefined;
  props: { color: string };
}) {
  if (!image) {
    return <List.Icon icon={icon} color={props.color} />;
  }

  return (
    <View
      style={{
        marginTop: 8,
        marginLeft: 8,
        marginBottom: 0,
        marginRight: 8,
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <Image
        source={{ uri: image, height: 200, width: 200 }}
        style={{ width: 48, height: 48 }}
      />
    </View>
  );
}
