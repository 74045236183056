import React from 'react';

export function ReviewVideo({ href }: { href: string }) {
  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: '160%',
        height: 0,
        marginBottom: 8,
      }}
    >
      <video
        controls
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: '#222',
        }}
      >
        <source type="video/mp4" src={href} />
      </video>
    </div>
  );
}
