import { useIsFocused } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { FlatList, ListRenderItemInfo, RefreshControl } from 'react-native';
import { List } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { decode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { selfAsKeyExtractor } from '../utils/selfAsKeyExtractor';
import { BrandsGrid } from './BrandsGrid';
import { ServiceRequestListItem } from './ServiceRequestListItem';
import { categoryCacheKey, useCategory } from './useCategory';
import { ApplauseService, useServices } from './useServices';

type ListItemData =
  | ApplauseService
  | { type: 'header' }
  | {
      type: 'brands';
      brands: NonNullable<ApplauseService['_links']['brand']>[];
    };

export function UpgradeCategoryScreen({
  href: encodedHref,
  category: encodedCategory,
}: {
  href: string;
  category: string;
}) {
  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const locale = useLocale();

  const href = decode(encodedHref);
  const categoryHref = decode(encodedCategory);

  const { data: category, isLoading: isLoadingCategory } = useCategory(
    categoryHref,
    categoryCacheKey(categoryHref, locale),
    {
      notifyOnChangeProps: ['data', 'isLoading'],
      enabled: isFocused,
    }
  );

  const { data, refetch, isFetching, isLoading } = useServices(
    href,
    [locale, href, 'service', 'list'],
    {
      enabled: isFocused,
      notifyOnChangeProps: ['data', 'isFetching', 'isLoading'],
    }
  );

  const services = data?.services;

  const categoryServices = useMemo(() => {
    if (!services || !category) {
      return null;
    }

    return services._embedded
      .map(({ service }) => service)
      .filter(({ _links: { category } }) => category?.href === categoryHref);
  }, [services, category]);

  const categoryBrands = useMemo(() => {
    if (!categoryServices) {
      return null;
    }

    return categoryServices.reduce<
      Record<string, NonNullable<ApplauseService['_links']['brand']>>
    >((result, item) => {
      if (item._links.brand) {
        result[item._links.brand.href] = item._links.brand;
      }

      return result;
    }, {});
  }, [categoryServices]);

  const {
    category: {
      hide_brands: hideBrands = false,
      hide_services: hideServices = false,
    } = {},
  } = category ?? {};

  const items = useMemo<ListItemData[]>(() => {
    if (!category || !categoryBrands || !categoryServices) {
      return [];
    }

    const result: ListItemData[] = [];
    if (!hideServices) {
      result.push(...categoryServices);
    }

    if (!hideBrands) {
      const brands = Object.values(categoryBrands);
      if (brands.length > 0) {
        result.push({ type: 'header' });
        result.push({ type: 'brands', brands });
      }
    }

    return result;
  }, [category, categoryBrands, categoryServices]);

  const renderService = useMemo(
    () => makeRenderService(hideServices ? 0 : categoryServices?.length ?? 0),
    [categoryServices?.length, hideServices]
  );

  return (
    <Screen>
      <ScreenHeader
        title={category?.category.name ?? i18n.t('service-request.title')}
        showBack
      />

      <FlatList
        style={tailwind('flex-1')}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full p-4 pb-6'
        )}
        data={items}
        renderItem={renderService}
        keyExtractor={extractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
      />
    </Screen>
  );
}

function extractor<TItem extends ListItemData>(item: TItem, index: number) {
  if ('type' in item) {
    if (item.type === 'header') {
      return 'brands-header';
    }

    return 'brands';
  }

  return selfAsKeyExtractor(item, index);
}

function makeRenderService(count: number) {
  return function renderService(item: ListRenderItemInfo<ListItemData>) {
    if ('type' in item.item) {
      if (item.item.type === 'header') {
        return (
          <List.Subheader
            style={{
              marginTop: 8,
              paddingLeft: 0,
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
          >
            {i18n.t('services.headers.brands')}
          </List.Subheader>
        );
      }

      return <BrandsGrid brands={item.item.brands} />;
    }

    return (
      <ServiceRequestListItem
        isFirst={item.index === 0}
        isLast={item.index === count - 1}
        service={item.item}
      />
    );
  };
}
