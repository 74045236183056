import { CommonActions } from '@react-navigation/core';
import { getStateFromPath, useRoute } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Image,
  Keyboard,
  TextInput as NativeTextInput,
  Platform,
  View,
} from 'react-native';
import { Button, Card, HelperText, TextInput } from 'react-native-paper';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useTailwind } from 'tailwind-rn';
import { COLOR_PRIMARY } from '../config';
import { useIsAuthenticated } from '../hooks/useAuth';
import { useLogin } from '../hooks/useAuthenticate';
import { i18n } from '../locale';
import { config } from '../navigation/useLinking';
import { useNavigation } from '@react-navigation/core';

export function LoginScreen({
  navigation: { dispatch },
}: StackScreenProps<any>) {
  const tailwind = useTailwind();
  const authenticated = useIsAuthenticated();

  const { error: passwordError, isLoading, mutateAsync: doLogin } = useLogin();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [navigatingAway, setNavigatingAway] = useState(false);
  const [offset, setOffset] = useState(0);

  const emailFieldRef = useRef<NativeTextInput | null>(null);
  const passwordFieldRef = useRef<NativeTextInput | null>(null);

  const params = useRoute().params;
  const returnTo = (params as any)?.returnTo as string | undefined;

  console.debug('LoginScreen');

  useLayoutEffect(() => {
    if (!authenticated) {
      return;
    }

    console.debug('useLayoutEffect navigating away');

    setNavigatingAway(true);
    const timer = setTimeout(() => {
      console.debug('useLayoutEffect timeout done');

      dispatch(
        CommonActions.reset(
          returnTo
            ? getStateFromPath(returnTo, config)
            : { index: 0, routes: [{ name: 'Tabs' }] }
        )
      );
    }, 500);

    return () => {
      console.debug('useLayoutEffect cancel navigating away');

      clearTimeout(timer);
    };
  }, [dispatch, authenticated, returnTo]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      emailFieldRef.current?.focus();
    }
  }, []);
  
  const { navigate } = useNavigation<any>();

  const doSubmit = () => doLogin({ email, password }).catch(() => {});
  const goToRegistration = () => navigate('Onboarding', undefined);
  const doFocusPassword = () => passwordFieldRef.current?.focus();

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardWillShow', (e) => {
      setOffset(e.endCoordinates.height / 3);
    });
    const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setOffset(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const fadeStyle = useAnimatedStyle(() => {
    return {
      opacity: navigatingAway ? 0 : 1,
    };
  }, [navigatingAway]);

  const animateMove = useSharedValue(1);
  useEffect(() => {
    animateMove.value = navigatingAway ? 1 : 0;
  }, [navigatingAway]);

  const moveStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: /*withTiming(animateMove.value * -200, {
            easing: Easing.out(Easing.cubic),
            duration: 500,
          })*/ -280,
        },
      ],
      opacity: /*
        (0.5 +
        withTiming(navigatingAway ? -0.5 : 0.5, {
          easing: Easing.out(Easing.cubic),
          duration: 500,
        })) * 1*/ 1,
    };
  }, [navigatingAway]);

  const boxStyle = useAnimatedStyle(() => {
    /**
   from={{ scale: 0.8 }}
    animate={{
      scale: navigatingAway ? 0.5 : 1,
      opacity: navigatingAway ? 0 : 1,
      translateY: -offset,
    }}
    transition={{
      type: 'timing',
      easing: navigatingAway
        ? Easing.in(Easing.cubic)
        : Easing.out(Easing.cubic),
      duration: 200,
    }}
    */
    return {};
  }, [navigatingAway]);

  return (
    <Fragment>
      <Animated.View
        style={[
          tailwind('absolute top-0 left-0 bottom-0 right-0'),
          { backgroundColor: COLOR_PRIMARY },
          fadeStyle,
        ]}
      />

      <View style={tailwind('justify-center items-center p-4 flex-1')}>
        <Animated.View style={[{ position: 'absolute' }, moveStyle]}>
          <Image
            source={require('../../assets/images/logo.png')}
            style={{ width: 280, minHeight: 100 }}
            resizeMode="contain"
          />
        </Animated.View>

        <Animated.View
          style={[
            tailwind('w-full h-full'),
            {
              minWidth: 200,
              minHeight: 200,
              maxWidth: 400,
              maxHeight: 260,
              transform: [{
                translateY: -80,
              }]
            },
            boxStyle,
          ]}
        >
          <Card
            style={[tailwind('p-4 w-full h-full'), { borderRadius: 4 }]}
            elevation={1}
          >
            <Animated.View style={fadeStyle}>
              <FormWrapper onSubmit={doSubmit}>
                <TextInput
                  ref={emailFieldRef}
                  keyboardType="email-address"
                  returnKeyType="next"
                  textContentType="emailAddress"
                  autoComplete="email"
                  autoFocus
                  mode="outlined"
                  label={i18n.t('login.fields.email.label')}
                  style={tailwind('mb-2')}
                  value={email}
                  onChangeText={setEmail}
                  onSubmitEditing={doFocusPassword}
                  disabled={navigatingAway || isLoading}
                />
                <TextInput
                  ref={passwordFieldRef}
                  keyboardType="default"
                  secureTextEntry
                  returnKeyType="go"
                  textContentType="password"
                  autoComplete="password"
                  mode="outlined"
                  label={i18n.t('login.fields.password.label')}
                  value={password}
                  onChangeText={setPassword}
                  onSubmitEditing={doSubmit}
                  disabled={navigatingAway || isLoading}
                />
                <HelperText type="error" style={tailwind('mb-4')}>
                  {passwordError?.message || ' '}
                </HelperText>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 'auto',
                  }}
                >
                  <Button
                    mode="contained"
                    style={{
                      width: 'auto',
                      marginEnd: 'auto',
                      position: 'relative',
                    }}
                    uppercase={false}
                    disabled={navigatingAway || isLoading}
                    icon={returnTo ? 'lock-open' : 'lock'}
                    loading={navigatingAway || isLoading}
                    onPress={doSubmit}
                  >
                    {i18n.t('login.actions.login')}
                  </Button>
                  <Button
                    mode="outlined"
                    style={{
                      width: 'auto',
                      marginStart: 'auto',
                      position: 'relative',
                    }}
                    uppercase={false}
                    disabled={navigatingAway || isLoading}
                    loading={navigatingAway || isLoading}
                    onPress={goToRegistration}
                  >
                    {i18n.t('login.actions.register')}
                  </Button>
                </View>
              </FormWrapper>
            </Animated.View>
          </Card>
        </Animated.View>
      </View>
    </Fragment>
  );
}

function FormWrapper({
  onSubmit,
  children,
}: {
  onSubmit(): void;
  children: React.ReactNode;
}): JSX.Element {
  if (Platform.OS === 'web') {
    return (
      <Fragment>
        <style>
          {/**
           * This fixes the issue of chrome's autofill background overlaying
           * the field completely. This fix only needs to be applied on the Web
           * where the autofill is applied on render, and not on first type.
           */}
          {`
          #login-form input {
            height: 48px !important;
            margin: 4px;
            outline: none;
            border-radius: 4px;
          }`}
        </style>
        <form
          id="login-form"
          action="#"
          method="POST"
          onSubmit={onSubmit}
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </form>
      </Fragment>
    );
  }

  return <Fragment>{children}</Fragment>;
}
