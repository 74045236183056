import { CommonActions, useNavigation } from '@react-navigation/core';
import { getPathFromState } from '@react-navigation/native';
import {
  AnyValue,
  SecureStoredMemoryValue,
  useMemoryValue,
} from 'expo-use-memory-value';
import {
  createContext,
  createElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { QUERY_CLIENT } from '../components/QueryCache';
import { i18n } from '../locale';
import { dispatch } from '../navigation/refs';
import { config } from '../navigation/useLinking';

export type Auth = {
  'access-token': string | null;
  'token-type': string | null;
  client: string | null;
  uid: string | null;
  expiry: string | null;
  root_path: string | null;
};

type AuthContextValue = {
  current: AnyValue<Auth | null>;
  set: (next: AnyValue<Auth | null>) => Promise<void>;
};

const AUTH = new SecureStoredMemoryValue<Auth>('auth.v2');
const AuthContext = createContext<AuthContextValue>({
  current: undefined,
  set: async () =>
    Promise.reject(
      new Error(
        'Can only call useAuth and related when inside an AuthProvider.'
      )
    ),
});

export function useAuth() {
  return useContext(AuthContext);
}

export function useIsAuthenticated(): boolean | undefined {
  const auth = useAuth();
  const value = auth.current;

  if (value === undefined) {
    return undefined;
  }

  return (
    !!value &&
    !!value['access-token'] &&
    !!value.expiry &&
    new Date().getTime() / 1000 < Number(value.expiry)
  );
}

export function useAuthRootPage(): null | string {
  const auth = useAuth();
  const value = auth.current;

  if (value === undefined || value === null) {
    return null;
  }

  return value!.root_path;
}

export function useForceAuthenticated() {
  const isAuthenticated = useIsAuthenticated();
  const navigation = useNavigation();

  useLayoutEffect(() => {
    if (isAuthenticated !== false) {
      return;
    }

    const path = getPathFromState(navigation.getState(), config);

    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Login', params: { returnTo: path } }],
      })
    );
  }, [isAuthenticated, navigation]);
}

export function useAuthHeaders():
  | Pick<Auth, 'access-token' | 'client' | 'uid'>
  | null
  | undefined {
  const auth = useAuth();
  const value = auth.current;

  return useMemo(() => {
    if (!value) {
      return value;
    }

    return {
      'access-token': value['access-token'],
      client: value.client,
      uid: value.uid,
    };
  }, [value]);
}

export function useMutableAuth() {
  return useAuth();
}

const AUTHENTICATION_LISTENERS: ((next: AnyValue<Auth | null>) => void)[] = [
  () =>
    Promise.all([
      QUERY_CLIENT.cancelQueries(['configuration']).then(() =>
        QUERY_CLIENT.invalidateQueries(['configuration'])
      ),
      QUERY_CLIENT.cancelQueries([i18n.locale, 'configuration']).then(() =>
        QUERY_CLIENT.invalidateQueries([i18n.locale, 'configuration'])
      ),
    ]),
];

function useProvideAuth(): AuthContextValue {
  const current = useMemoryValue(AUTH);
  const lastSet = useRef(current);

  const set = useCallback((next: AnyValue<Auth | null>) => {
    lastSet.current = next;

    return AUTH.emit(next, true, false).then((next) =>
      AUTHENTICATION_LISTENERS.forEach((listener) => listener(next))
    );
  }, []);

  if (current !== lastSet.current) {
    set(current);
  }

  return useMemo((): AuthContextValue => ({ current, set }), [current, set]);
}

export function imperativeLogout() {
  return AUTH.emit(null, true, false).then(() => {
    QUERY_CLIENT.clear();
    dispatch(CommonActions.reset({ index: 0, routes: [{ name: 'Login' }] }));
  });
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const value = useProvideAuth();
  return createElement(AuthContext.Provider, { value }, children);
}
