import { Platform } from 'react-native';
import {
  configureFonts,
  MD3LightTheme,
  MD3DarkTheme,
  MD2LightTheme,
  MD2DarkTheme,
  ThemeBase,
} from 'react-native-paper';

export const fontFamily300 = Platform.select({
  web: 'Insonsolate_300, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  ios: 'System',
  default: 'sans-serif',
});

export const fontFamily400 = Platform.select({
  web: 'Insonsolate_400, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  ios: 'System',
  default: 'sans-serif',
});

export const fontFamily500 = Platform.select({
  web: 'Insonsolate_500, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  ios: 'System',
  default: 'sans-serif',
});

export const fontFamily700 = Platform.select({
  web: 'Insonsolate_700, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
  ios: 'System',
  default: 'sans-serif',
});

const fonts = configureFonts({
  isV3: true,
  config: {
    displaySmall: {
      fontFamily: fontFamily400,
      fontSize: 36,
      fontWeight: '400',
      letterSpacing: 0,
      lineHeight: 44,
    },
    displayMedium: {
      fontFamily: fontFamily400,
      fontSize: 45,
      fontWeight: '400',
      letterSpacing: 0,
      lineHeight: 52,
    },
    displayLarge: {
      fontFamily: fontFamily400,
      fontSize: 57,
      fontWeight: '400',
      letterSpacing: 0,
      lineHeight: 64,
    },
    headlineSmall: {
      fontFamily: fontFamily400,
      fontSize: 24,
      fontWeight: '400',
      letterSpacing: 0,
      lineHeight: 32,
    },
    titleSmall: {
      fontFamily: fontFamily500,
      fontSize: 14,
      fontWeight: '500',
      letterSpacing: 0.1,
      lineHeight: 20,
    },
    titleMedium: {
      fontFamily: fontFamily500,
      fontSize: 16,
      fontWeight: '500',
      letterSpacing: 0.15,
      lineHeight: 24,
    },
    titleLarge: {
      fontFamily: fontFamily700,
      fontSize: 16,
      fontWeight: '700',
      letterSpacing: 0,
      lineHeight: 28,
    },
    labelSmall: {
      fontFamily: fontFamily300,
      fontSize: 12,
      fontWeight: '300',
      letterSpacing: 0.5,
      lineHeight: 16,
    },
    labelMedium: {
      fontFamily: fontFamily500,
      fontSize: 12,
      fontWeight: '500',
      letterSpacing: 0.5,
      lineHeight: 16,
    },
    labelLarge: {
      fontFamily: fontFamily500,
      fontSize: 14,
      fontWeight: '500',
      letterSpacing: 0.1,
      lineHeight: 20,
    },
    bodySmall: {
      fontFamily: fontFamily300,
      fontSize: 12,
      fontWeight: '300',
      letterSpacing: 0.4,
      lineHeight: 16,
    },
    bodyMedium: {
      fontFamily: fontFamily400,
      fontSize: 14,
      fontWeight: '400',
      letterSpacing: 0.25,
      lineHeight: 20,
    },
    bodyLarge: {
      fontFamily: fontFamily400,
      fontSize: 16,
      fontWeight: '400',
      letterSpacing: 0.15,
      lineHeight: 24,
    },
  },
});

export const BASE_THEME = {
  ...MD3LightTheme,
  colors: {
    primary: 'rgb(140, 80, 0)',
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(255, 220, 191)',
    onPrimaryContainer: 'rgb(45, 22, 0)',
    secondary: 'rgb(115, 89, 66)',
    onSecondary: 'rgb(255, 255, 255)',
    secondaryContainer: 'rgb(255, 220, 191)',
    onSecondaryContainer: 'rgb(41, 24, 6)',
    tertiary: 'rgb(89, 99, 57)',
    onTertiary: 'rgb(255, 255, 255)',
    tertiaryContainer: 'rgb(221, 232, 179)',
    onTertiaryContainer: 'rgb(23, 30, 0)',
    error: 'rgb(186, 26, 26)',
    onError: 'rgb(255, 255, 255)',
    errorContainer: 'rgb(255, 218, 214)',
    onErrorContainer: 'rgb(65, 0, 2)',
    background: 'rgb(255, 251, 255)',
    onBackground: 'rgb(32, 27, 22)',
    surface: 'rgb(255, 251, 255)',
    onSurface: 'rgb(32, 27, 22)',
    surfaceVariant: 'rgb(242, 223, 209)',
    onSurfaceVariant: 'rgb(81, 68, 58)',
    outline: 'rgb(131, 116, 105)',
    outlineVariant: 'rgb(213, 195, 182)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(53, 47, 43)',
    inverseOnSurface: 'rgb(250, 239, 231)',
    inversePrimary: 'rgb(255, 184, 115)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(249, 242, 242)',
      level2: 'rgb(246, 237, 235)',
      level3: 'rgb(242, 232, 227)',
      level4: 'rgb(241, 231, 224)',
      level5: 'rgb(239, 227, 219)',
    },
    surfaceDisabled: 'rgba(32, 27, 22, 0.12)',
    onSurfaceDisabled: 'rgba(32, 27, 22, 0.38)',
    backdrop: 'rgba(57, 46, 37, 0.4)',
  },
};

export const DARK_BASE_THEME = {
  ...MD3DarkTheme,

  colors: {
    primary: 'rgb(255, 184, 115)',
    onPrimary: 'rgb(74, 40, 0)',
    primaryContainer: 'rgb(106, 59, 0)',
    onPrimaryContainer: 'rgb(255, 220, 191)',
    secondary: 'rgb(226, 192, 164)',
    onSecondary: 'rgb(65, 44, 24)',
    secondaryContainer: 'rgb(89, 66, 45)',
    onSecondaryContainer: 'rgb(255, 220, 191)',
    tertiary: 'rgb(193, 204, 153)',
    onTertiary: 'rgb(43, 52, 15)',
    tertiaryContainer: 'rgb(65, 75, 36)',
    onTertiaryContainer: 'rgb(221, 232, 179)',
    error: 'rgb(255, 180, 171)',
    onError: 'rgb(105, 0, 5)',
    errorContainer: 'rgb(147, 0, 10)',
    onErrorContainer: 'rgb(255, 180, 171)',
    background: 'rgb(32, 27, 22)',
    onBackground: 'rgb(235, 224, 217)',
    surface: 'rgb(32, 27, 22)',
    onSurface: 'rgb(235, 224, 217)',
    surfaceVariant: 'rgb(81, 68, 58)',
    onSurfaceVariant: 'rgb(213, 195, 182)',
    outline: 'rgb(158, 142, 129)',
    outlineVariant: 'rgb(81, 68, 58)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(235, 224, 217)',
    inverseOnSurface: 'rgb(53, 47, 43)',
    inversePrimary: 'rgb(140, 80, 0)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(43, 35, 27)',
      level2: 'rgb(50, 40, 29)',
      level3: 'rgb(57, 44, 32)',
      level4: 'rgb(59, 46, 33)',
      level5: 'rgb(63, 49, 35)',
    },
    surfaceDisabled: 'rgba(235, 224, 217, 0.12)',
    onSurfaceDisabled: 'rgba(235, 224, 217, 0.38)',
    backdrop: 'rgba(57, 46, 37, 0.4)',
  },
};

export const MD2_BASE_THEME = {
  ...MD2LightTheme,
  colors: {
    primary: 'rgb(140, 80, 0)',
    onPrimary: 'rgb(255, 255, 255)',
    primaryContainer: 'rgb(255, 220, 191)',
    onPrimaryContainer: 'rgb(45, 22, 0)',
    secondary: 'rgb(115, 89, 66)',
    onSecondary: 'rgb(255, 255, 255)',
    secondaryContainer: 'rgb(255, 220, 191)',
    onSecondaryContainer: 'rgb(41, 24, 6)',
    tertiary: 'rgb(89, 99, 57)',
    onTertiary: 'rgb(255, 255, 255)',
    tertiaryContainer: 'rgb(221, 232, 179)',
    onTertiaryContainer: 'rgb(23, 30, 0)',
    error: 'rgb(186, 26, 26)',
    onError: 'rgb(255, 255, 255)',
    errorContainer: 'rgb(255, 218, 214)',
    onErrorContainer: 'rgb(65, 0, 2)',
    background: '#e6e6e6',
    onBackground: 'rgb(32, 27, 22)',
    surface: 'rgb(255, 251, 255)',
    onSurface: 'rgb(32, 27, 22)',
    surfaceVariant: 'rgb(242, 223, 209)',
    onSurfaceVariant: 'rgb(81, 68, 58)',
    outline: 'rgb(131, 116, 105)',
    outlineVariant: 'rgb(213, 195, 182)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(53, 47, 43)',
    inverseOnSurface: 'rgb(250, 239, 231)',
    inversePrimary: 'rgb(255, 184, 115)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(249, 242, 242)',
      level2: 'rgb(246, 237, 235)',
      level3: 'rgb(242, 232, 227)',
      level4: 'rgb(241, 231, 224)',
      level5: 'rgb(239, 227, 219)',
    },
    surfaceDisabled: 'rgba(32, 27, 22, 0.12)',
    onSurfaceDisabled: 'rgba(32, 27, 22, 0.38)',
    backdrop: 'rgba(57, 46, 37, 0.4)',
    placeholder: '#808080',
  },
};

export const MD2_DARK_BASE_THEME = {
  ...MD2DarkTheme,

  colors: {
    primary: 'rgb(255, 184, 115)',
    onPrimary: 'rgb(74, 40, 0)',
    primaryContainer: 'rgb(106, 59, 0)',
    onPrimaryContainer: 'rgb(255, 220, 191)',
    secondary: 'rgb(226, 192, 164)',
    onSecondary: 'rgb(65, 44, 24)',
    secondaryContainer: 'rgb(89, 66, 45)',
    onSecondaryContainer: 'rgb(255, 220, 191)',
    tertiary: 'rgb(193, 204, 153)',
    onTertiary: 'rgb(43, 52, 15)',
    tertiaryContainer: 'rgb(65, 75, 36)',
    onTertiaryContainer: 'rgb(221, 232, 179)',
    error: 'rgb(255, 180, 171)',
    onError: 'rgb(105, 0, 5)',
    errorContainer: 'rgb(147, 0, 10)',
    onErrorContainer: 'rgb(255, 180, 171)',
    background: 'rgb(68, 63, 59)',
    onBackground: 'rgb(235, 224, 217)',
    surface: 'rgb(32, 27, 22)',
    onSurface: 'rgb(235, 224, 217)',
    surfaceVariant: 'rgb(81, 68, 58)',
    onSurfaceVariant: 'rgb(213, 195, 182)',
    outline: 'rgb(158, 142, 129)',
    outlineVariant: 'rgb(81, 68, 58)',
    shadow: 'rgb(0, 0, 0)',
    scrim: 'rgb(0, 0, 0)',
    inverseSurface: 'rgb(235, 224, 217)',
    inverseOnSurface: 'rgb(53, 47, 43)',
    inversePrimary: 'rgb(140, 80, 0)',
    elevation: {
      level0: 'transparent',
      level1: 'rgb(43, 35, 27)',
      level2: 'rgb(50, 40, 29)',
      level3: 'rgb(57, 44, 32)',
      level4: 'rgb(59, 46, 33)',
      level5: 'rgb(63, 49, 35)',
    },
    surfaceDisabled: 'rgba(235, 224, 217, 0.12)',
    onSurfaceDisabled: 'rgba(235, 224, 217, 0.38)',
    backdrop: 'rgba(57, 46, 37, 0.4)',
    placeholder: 'rgb(235, 224, 217)',
    text: 'rgb(235, 224, 217)',
  },
};
