import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { Image, View } from 'react-native';
import { Card } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { encode } from '../base';
import { useWindowWidth } from '../hooks/useDimensions';
import { useLocale } from '../hooks/useLocale';
import { RouteParamList } from '../navigation/routes';
import { brandCacheKey, useBrand } from './useBrand';

type UpgradeRoute = RouteProp<RouteParamList, 'Upgrade'>;
type UpgradeNavigation = StackNavigationProp<RouteParamList, 'Upgrade'>;

export type BrandItem = {
  href: string;
  name: string;
};

export function BrandsGrid({ brands }: { brands: BrandItem[] }) {
  const tailwind = useTailwind();
  const windowWidth = useWindowWidth();
  const columns = 3;
  const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);

  const count = brands.length;
  const empty = useMemo((): string[] => {
    const result: string[] = [];
    const remainder = columns - ((count % columns) % columns);

    if (remainder === columns) {
      return result;
    }

    for (let i = 0; i < remainder; i++) {
      result.push(Math.random().toString(36));
    }
    return result;
  }, [count, columns]);

  return (
    <View style={tailwind('flex-wrap justify-between flex-row  w-full mb-8')}>
      {brands.map((brand) => (
        <BrandButton key={brand.href} {...brand} size={width} />
      ))}
      {empty.map((none) => (
        <View style={{ width, height: width }} key={none} />
      ))}
    </View>
  );
}

function BrandButton({
  href,
  name,
  size,
}: BrandItem & {
  size: number;
}) {
  const isFocused = useIsFocused();
  const locale = useLocale();

  const { push } = useNavigation<UpgradeNavigation>();
  const { href: encodedHref } = useRoute<UpgradeRoute>().params;
  const { data } = useBrand(href, brandCacheKey(href, locale), {
    notifyOnChangeProps: ['data'],
    enabled: isFocused,
  });

  const brand = data?.brand;
  const logo = brand?._links.logo?.href;

  return (
    <Card
      style={[
        {
          width: size,
          height: size,
          borderRadius: 8,
          marginBottom: 16,
          overflow: 'hidden',
        },
      ]}
      onPress={() =>
        push('Upgrade', { href: encodedHref, brand: encode(href) })
      }
      accessibilityLabel={`Services by ${brand?.name ?? name}`}
      accessible
    >
      <Image
        source={{ uri: logo, width: 256, height: 256 }}
        style={{ width: size, height: size, borderRadius: 8 }}
        resizeMode="contain"
      />
    </Card>
  );
}
