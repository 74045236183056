import React, { Fragment, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ProgressBar } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';

export function BookingExperience({ url }: { url: string }) {
  const tailwind = useTailwind();
  const [hasLoaded, setHasLoaded] = useState(false);
  const onFinishedLoad = () => setHasLoaded(true);

  return (
    <Fragment>
      <iframe
        src={url}
        style={{ flex: 1, width: '100%', display: hasLoaded ? 'flex' : 'none' }}
        onLoad={onFinishedLoad}
        frameBorder={0}
        seamless
      />
      {hasLoaded ? null : (
        <View
          style={[
            StyleSheet.absoluteFill,
            { flex: 1, justifyContent: 'center', alignItems: 'center' },
          ]}
        >
          <View style={tailwind('max-w-3xl w-full')}>
            <ProgressBar indeterminate />
          </View>
        </View>
      )}
    </Fragment>
  );
}
