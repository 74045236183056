import { useIsFocused, useNavigation } from '@react-navigation/core';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Image } from 'react-native';
import { Card, Title } from 'react-native-paper';
import { encode } from '../base';
import { ApplauseProperty, useProperty } from './useProperties';

export function PropertyCard({
  href,
  placeholderTitle,
}: {
  href: string | null | undefined;
  placeholderTitle?: string;
}) {
  const enabled = useIsFocused();
  const { navigate } = useNavigation<any>();

  const { data } = useProperty(href, {
    enabled,
    notifyOnChangeProps: ['data'],
  });

  const name = display(data, placeholderTitle);
  const description = data?.description;
  const image = data?.images.large[0] || data?.images.square[0];

  const reference = data ? data._links.self.reference : null;
  const address = data?.address;

  return (
    <Card
      elevation={1}
      onPress={
        href
          ? () => {
              navigate('Property', { href: encode(href) });
            }
          : undefined
      }
      style={{
        flex: 1,
        width: '100%',
        maxWidth: '100%',
        height: 300,
        flexBasis: 300,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#b3aea7',
      }}
      contentStyle={{ flex: 1, height: 300 }}
    >
      <Image
        source={
          image
            ? { uri: image, width: 400, height: 300 }
            : require('../../assets/images/placeholders/property.jpg')
        }
        style={{ width: '100%', height: '100%' }}
        resizeMode="cover"
      />
      <LinearGradient
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          overflow: 'hidden',
          borderRadius: 8,
        }}
        colors={['#22222200', image ? '#22222288' : '#222222AA']}
        locations={image ? [0.4, 0.8] : [0.4, 0.7]}
        pointerEvents="none"
      />
      <Card.Title
        title={name}
        style={{ position: 'absolute', bottom: 0 }}
        titleVariant='titleMedium'
        titleStyle={{
          color: '#f5f5f5',
          textShadowColor: '#222',
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 1,
        }}
        subtitleStyle={{
          color: '#f0f0f0',
          textShadowColor: '#222',
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 1,
          marginBottom: 2,
          marginRight: 12,
        }}
        titleNumberOfLines={2}
        subtitle={(description || '')?.replace(/\\u0026/g, '&')}
        subtitleNumberOfLines={3}
        pointerEvents="none"
      />

      {reference ? (
        <Title
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 6,
            paddingBottom: 6,
            lineHeight: 18,
            backgroundColor: '#22222250',
            borderBottomRightRadius: 8,
            minHeight: 32,
            minWidth: undefined,
            flexShrink: 1,
            alignSelf: 'flex-start',
            color: '#f5f5f5',
            textShadowColor: '#222',
            textShadowOffset: { width: 1, height: 1 },
            textShadowRadius: 1,
            includeFontPadding: false,
            textAlignVertical: 'center',
            fontSize: 12,
          }}
        >
          {[reference, address].filter(Boolean).join(' - ')}
        </Title>
      ) : null}
    </Card>
  );
}

function display(
  data: ApplauseProperty | undefined,
  placeholderTitle?: string
) {
  if (!data) {
    return placeholderTitle;
  }

  const { title, city } = data;

  const right = [title, city].filter(Boolean).join(' - ');
  return right || placeholderTitle;
}
