import {
  StoredMemoryValue,
  useMemoryValue,
  useMutableMemoryValue,
} from 'expo-use-memory-value';

export type RemoteUser = {
  id: number;
  email: string;
  role: 'customer';
  name: null | string;
  is_upgraded: boolean;
  money_made: number;
};

const CURRENT_USER = new StoredMemoryValue<RemoteUser>('user');

export function useCurrentUser() {
  return useMemoryValue(CURRENT_USER);
}

export function useMutableCurrentUser() {
  return useMutableMemoryValue(CURRENT_USER);
}
