import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type ApplauseReservation = {
  _links: {
    self: {
      href: string;
    };
    property?: {
      href: string;
    };
    services: {
      href: string;
    };
  };
  dates: {
    from: string | null;
    to: string | null;
  };
};

type ApplauseReservationResponse = {
  reservation: ApplauseReservation;
};

export type ApplauseReservationsIndex = {
  reservations: {
    _links: {
      self: { href: string };
    };
    _index: ApplauseReservation['_links']['self'][];
  };
};

export function useReservations({
  enabled = true,
  ...options
}: UseQueryOptions<ApplauseReservationsIndex, FetchMediaError> = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_reservations');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseReservationsIndex, FetchMediaError>(
    [locale, 'reservation', 'list'],
    {
      queryFn: async () =>
        fetchMedia(href!, {
          headers: {
            accept: 'application/vnd.bnbbutler.reservation.v1.index+json',
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
        }).then((response) => response as ApplauseReservationsIndex),
      enabled: !!auth.current?.['access-token'] && enabled && !!href,
      staleTime: 30 * 1000,
      ...options,
    }
  );
}

export function reservationCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'reservation', `${href?.split('/').pop() || '-'}`];
}

export function useReservation(
  href: string | null | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseReservation, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(reservationCacheKey(href, locale), {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: 'application/vnd.bnbbutler.reservation.v1+json',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseReservationResponse)
        .then(({ reservation }) => reservation),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 30 * 1000,
    ...options,
  });
}
