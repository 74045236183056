import { useIsFocused, useRoute } from '@react-navigation/core';
import * as Clipboard from 'expo-clipboard';
import { StatusBar } from 'expo-status-bar';
import { fetchMedia } from 'fetch-media';
import React, { Fragment, useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import {
  Button,
  Caption,
  Card,
  Divider,
  IconButton,
  List,
  Paragraph,
  useTheme,
} from 'react-native-paper';
import { modeAppbarHeight } from 'react-native-paper/src/components/Appbar/utils';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { useTailwind } from 'tailwind-rn';
import { decode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { COLOR_PRIMARY, COLOR_PRIMARY_DARK } from '../config';
import { useAuth, useForceAuthenticated } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { PropertyCard } from '../properties/PropertyCard';
import { ServiceDetails } from '../services/ServiceDetails';
import { ServiceHeaderImage } from '../services/ServiceHeaderImage';
import {
  BookingReference,
  PropertyReference,
} from '../services/ServiceReference';
import { StickyScreenHeader } from '../services/StickyScreenHeader';
import { brandCacheKey, useBrand } from '../services/useBrand';
import { useService } from '../services/useServices';
import { datetime, humanizeTime } from '../utils/date';
import { openExternalUrl } from '../utils/useOpenUrl';
import { AssignmentAction } from './AssignmentAction';
import {
  ApplauseAssignment,
  assignmentCacheKey,
  useAssignment,
} from './useAssignments';

const PARALLAX_SPEED = 0.5;
const HEADER_DELAY = 0.8;
const HEADER_HEIGHT = 360;

export function AssignmentScreen() {
  useForceAuthenticated();

  const isFocused = useIsFocused();
  const { href: encodedHref } = useRoute().params as {
    href: string;
  };
  const href = decode(encodedHref);
  const locale = useLocale();

  const { data: assignment, isLoading } = useAssignment(href, {
    enabled: isFocused,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
  });

  const cacheKey = [locale, 'service', assignment?._links.service?.slug];
  const enabled = useIsFocused();

  const { data: service } = useService(
    assignment?._links.service?.href,
    cacheKey,
    {
      enabled: enabled && Boolean(assignment?._links.service?.href),
      notifyOnChangeProps: ['data'],
    }
  );

  const hasTasks =
    assignment &&
    Array.isArray(assignment.tasks) &&
    assignment.tasks.filter(Boolean).length > 0;

  const title =
    service && 'name' in service
      ? service.name
      : service?._links.self.name ?? i18n.t('service-request.title');
  const image = service?._links.listing_image?.href;

  const { top: safeAreaTop } = useSafeAreaInsets();
  const translationY = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler((event) => {
    translationY.value = event.contentOffset.y;
  });

  const parallaxStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: -translationY.value * PARALLAX_SPEED,
        },
      ],
    };
  });

  const headerStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: Math.max(
            HEADER_HEIGHT - modeAppbarHeight.small - translationY.value,
            safeAreaTop
          ),
        },
      ],
    };
  }, [safeAreaTop]);

  const headerBackgroundStyles = useAnimatedStyle(() => {
    const offset = HEADER_HEIGHT * HEADER_DELAY;
    const remainder = HEADER_HEIGHT - offset;
    const opacity = Math.min(
      1,
      Math.max(0, translationY.value - offset) / remainder
    );
    return {
      opacity,
    };
  }, [safeAreaTop]);

  const tailwind = useTailwind();
  const primary = COLOR_PRIMARY!;

  return (
    <Screen>
      <StatusBar translucent backgroundColor="#00000060" style="light" />
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: 360,
          },
          parallaxStyles,
        ]}
      >
        <ServiceHeaderImage image={image} />
      </Animated.View>

      <Animated.ScrollView
        style={{ flex: 1, backgroundColor: 'transparent' }}
        contentContainerStyle={[
          { paddingTop: 360 },
          tailwind('justify-center self-center max-w-3xl w-full pb-4'),
        ]}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        nativeID="request-service"
      >
        {assignment?._links.booking ? (
          <BookingReference href={assignment._links.booking.href} />
        ) : assignment?._links.property ? (
          <PropertyReference href={assignment._links.property.href} />
        ) : null}

        <Card style={[tailwind('bg-gray-50 px-4 pb-4'), { borderRadius: 0 }]} elevation={2}>
          <AssignmentAction
            data={assignment}
            style={{ marginTop: 16 }}
            withReviews
          />
          <List.Subheader
            style={[
              tailwind('px-0 mt-4'),
              { includeFontPadding: false, textAlignVertical: 'center' },
            ]}
          >
            {i18n.t('assignments.sections.what.title')}
          </List.Subheader>
          {service && 'description' in service ? (
            <ServiceDetails
              withTitle
              service={{
                ...service,
                price_cents: assignment?.price_cents ?? service.price_cents,
              }}
              quantity={
                assignment?.quantity !== undefined
                  ? assignment?.quantity
                  : undefined
              }
              priceQuantity={1}
            >
              {hasTasks ? <Tasks tasks={assignment!.tasks!} /> : null}
            </ServiceDetails>
          ) : null}

          {assignment?._links.brand?.href ? (
            <BrandDetails {...assignment?._links.brand} />
          ) : null}

          {assignment?._links.owner?.display_name ? (
            <Fragment>
              <List.Subheader
                style={[
                  tailwind('px-0 mt-4'),
                  { includeFontPadding: false, textAlignVertical: 'center' },
                ]}
              >
                {i18n.t('assignments.sections.who_requested.title')}
              </List.Subheader>
              <Card style={tailwind('py-0')} elevation={1}>
                <List.Item
                  title={assignment?._links.owner?.display_name}
                  titleStyle={{
                    includeFontPadding: false,
                    textAlignVertical: 'center',
                  }}
                  description={i18n.t(
                    'assignments.sections.who_requested.description'
                  )}
                  descriptionStyle={{
                    includeFontPadding: false,
                    textAlignVertical: 'center',
                  }}
                  right={
                    assignment?._links.owner?.phone_number
                      ? (props) => (
                          <IconButton
                            iconColor={props.color}
                            icon="phone"
                            onPress={() =>
                              openExternalUrl(
                                `tel:${assignment?._links.owner?.phone_number}`,
                                primary
                              )
                            }
                          />
                        )
                      : undefined
                  }
                />
              </Card>
            </Fragment>
          ) : null}

          {assignment?._links.servicers?.href ? (
            <Fragment>
              <List.Subheader
                style={[
                  tailwind('px-0 mt-4'),
                  { includeFontPadding: false, textAlignVertical: 'center' },
                ]}
              >
                {i18n.t('assignments.sections.who_assigned.title')}
              </List.Subheader>
              <Card style={tailwind('py-0')} elevation={1}>
                <ServicersList
                  {...assignment?._links.servicers}
                  assignmentHref={assignment._links.self.href}
                />
              </Card>
            </Fragment>
          ) : null}
          <List.Subheader
            style={[
              tailwind('px-0 mt-4'),
              { includeFontPadding: false, textAlignVertical: 'center' },
            ]}
          >
            {i18n.t('assignments.sections.when.title')}
          </List.Subheader>
          <Card style={tailwind('py-2')} elevation={1}>
            <Card.Title
              titleStyle={[
                tailwind('pr-4 -mt-1'),
                { color: COLOR_PRIMARY_DARK },
              ]}
              title={
                assignment
                  ? datetime(assignment.date ?? assignment.created_at)
                  : ''
              }
              titleVariant='titleMedium'
              titleNumberOfLines={3}
              subtitle={
                assignment?.created_at
                  ? i18n.t('assignments.sections.when.created_at', {
                      moment: datetime(assignment.created_at),
                    })
                  : undefined
              }
              subtitleNumberOfLines={2}
            />

            <Divider />
            <Caption style={{ paddingHorizontal: 16, marginTop: 8 }}>
              {i18n.t('assignments.sections.when.status', {
                status: statusFor(assignment),
              })}{' '}
              <Caption style={{ fontWeight: 'bold' }}>
                {datetime(
                  assignment?.status_updated_at ?? assignment?.created_at
                )}
              </Caption>
            </Caption>
          </Card>

          {!assignment || assignment._links.property ? (
            <Fragment>
              <List.Subheader
                style={[
                  tailwind('px-0 mt-4'),
                  { includeFontPadding: false, textAlignVertical: 'center' },
                ]}
              >
                {i18n.t('assignments.sections.where.title')}
              </List.Subheader>
              <PropertyCard href={assignment?._links.property?.href} />
            </Fragment>
          ) : null}

          <DeepLink assignment={assignment} />
        </Card>
      </Animated.ScrollView>
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: modeAppbarHeight.small,
            top: 0,
          },
          headerStyles,
        ]}
      >
        <StickyScreenHeader title={title} />
      </Animated.View>
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: 56 + safeAreaTop + 2,
            top: 0,
          },
          headerBackgroundStyles,
        ]}
      >
        <ScreenHeader title={title || 'Retrieving...'} showBack />
      </Animated.View>
    </Screen>
  );
}

function BrandDetails({ href, name }: { href: string; name: string }) {
  const tailwind = useTailwind();
  const locale = useLocale();

  const { data } = useBrand(href, brandCacheKey(href, locale));
  const logoHref = data?.brand._links.logo?.href;
  const rating = data?.brand.rating;

  return (
    <Card style={tailwind('mt-4')}>
      <List.Item
        left={
          logoHref
            ? () => (
                <View
                  style={{
                    width: 72,
                    height: 72,
                    borderRadius: 4,
                    backgroundColor: '#222',
                    overflow: 'hidden',
                  }}
                >
                  <Image
                    source={{ uri: logoHref, width: 128, height: 128 }}
                    style={{
                      width: 72,
                      height: 72,
                    }}
                  />
                </View>
              )
            : undefined
        }
        title={`${name} ${rating ? `(⭐ ${Number(rating).toFixed(1)})` : ''}`}
        titleStyle={{ includeFontPadding: false }}
        description={
          data?.brand.reaction_time
            ? i18n.t('assignments.servicers.reaction_time', {
                time: humanizeTime(data.brand.reaction_time),
              })
            : undefined
        }
        descriptionStyle={{ includeFontPadding: false }}
      />
    </Card>
  );
}

function statusFor(assignment: ApplauseAssignment | undefined): string {
  if (!assignment) {
    return 'unknown';
  }

  const {
    _links: {
      self: { status: statusAlt },
    },

    status,
  } = assignment;

  return i18n.t(`assignments.states.status.${status ?? statusAlt ?? 'new'}`);
}

function Tasks({ tasks }: { tasks: string | string[] }) {
  const tasksArr = Array.isArray(tasks) ? tasks : [tasks];

  return (
    <Card.Content style={{ marginTop: 6 }}>
      {tasksArr.map((task, i, self) => (
        <Paragraph key={i}>
          {self.length > 1 ? '* ' : ''}
          {task}
        </Paragraph>
      ))}
    </Card.Content>
  );
}

type ServicersResponse = {
  readonly _links: {
    readonly self: {
      readonly href: string;
    };
  };
  readonly servicers: {
    readonly _embedded: readonly { readonly servicer: Servicer }[];
  };
};

type Servicer = {
  _links: {
    self: {
      href: string;
    };
  };
  display_name: string;
  reaction_time: null | number;
  rating?: null | number;
  phone_number?: null | string;
};

function ServicersList({
  assignmentHref,
  href,
  requested,
  assigned,
}: {
  assignmentHref: string;
  href: string;
  requested: number;
  assigned: number;
}) {
  const auth = useAuth();
  const locale = useLocale();
  const missing = Math.max(requested - assigned, 0);

  const key = assignmentCacheKey(assignmentHref, locale);
  const primary = useTheme().colors.primary;

  const { data } = useQuery(
    Array.isArray(key) ? [...key, 'servicers'] : `${key}.servicers`,
    ({ signal }) =>
      fetchMedia(href, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.servicer.v3.collection+json',
            'application/vnd.bnbbutler.servicer.v2.collection+json; q=0.9',
            'application/vnd.bnbbutler.servicer.v1.collection+json; q=0.8',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        signal,
      }),
    {
      notifyOnChangeProps: ['data'],
    }
  );

  const servicers = (data as ServicersResponse)?.servicers._embedded;

  if (!servicers) {
    return null;
  }

  return (
    <Fragment>
      {servicers.map(({ servicer }) => (
        <List.Item
          key={servicer._links.self.href}
          left={(props) => (
            <List.Icon
              {...props}
              icon="account"
              style={{ marginLeft: 16, marginRight: 8 }}
            />
          )}
          title={`${servicer.display_name} ${
            servicer.rating ? `(⭐ ${Number(servicer.rating).toFixed(1)})` : ''
          }`}
          titleStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
          description={
            servicer.reaction_time
              ? i18n.t('assignments.servicers.reaction_time', {
                  time: humanizeTime(servicer.reaction_time),
                })
              : undefined
          }
          descriptionStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
          right={
            servicer.phone_number
              ? (props) => (
                  <IconButton
                    iconColor={props.color}
                    icon="phone"
                    onPress={() =>
                      openExternalUrl(`tel:${servicer.phone_number}`, primary)
                    }
                  />
                )
              : undefined
          }
        />
      ))}

      {new Array(missing).fill(null).map((_, index) => (
        <List.Item
          key={index}
          left={(props) => (
            <List.Icon
              {...props}
              icon="account-question-outline"
              style={{ marginLeft: 16, marginRight: 8 }}
            />
          )}
          title={i18n.t('assignments.servicers.unclaimed_spot')}
        />
      ))}
    </Fragment>
  );
}

function DeepLink({
  assignment,
}: {
  assignment: ApplauseAssignment | null | undefined;
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied]);

  if (!assignment || !assignment._links.deeplink) {
    return null;
  }

  return (
    <View>
      <Button
        style={{ marginVertical: 16, marginRight: 'auto' }}
        labelStyle={{ includeFontPadding: false }}
        icon={copied ? 'check' : 'content-copy'}
        mode="contained"
        disabled={copied}
        onPress={() =>
          Clipboard.setStringAsync(assignment!._links.deeplink!.href)
            .then(() => setCopied(true))
            .catch(() => {})
        }
      >
        {copied ? 'Copied share link' : 'Share link'}
      </Button>
    </View>
  );
}
