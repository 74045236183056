import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Image, View } from 'react-native';
import { Surface } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';

export function ServiceHeaderImage({ image }: { image: string | undefined }) {
  const tailwind = useTailwind();

  return (
    <Surface
      style={{
        width: '100%',
        height: 360,
        elevation: 0,
        backgroundColor: '#222',
      }}
    >
      {image ? (
        <Image
          source={{ uri: image, width: 400, height: 300 }}
          style={{ width: '100%', height: '100%', position: 'absolute' }}
          resizeMode="cover"
        />
      ) : null}
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: '#22222240',
        }}
      />

      <View
        style={tailwind(
          'justify-center self-center max-w-4xl w-full flex-row flex-1'
        )}
      >
        {image ? (
          <Image
            source={{ uri: image, width: 400, height: 300 }}
            style={{ width: '100%', height: '100%' }}
            resizeMode="cover"
          />
        ) : undefined}
        <LinearGradient
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            overflow: 'hidden',
          }}
          colors={['#22222200', image ? '#22222299' : '#222222AA']}
          locations={image ? [0.82, 0.9] : [0.8, 1]}
        />
      </View>
    </Surface>
  );
}
