import { FetchMediaError } from 'fetch-media';
import { UseQueryResult } from 'react-query';
import { ApplausePropertiesIndex, useProperties } from './useProperties';

export type ApplauseSelectedPropertyIndice =
  ApplausePropertiesIndex['properties']['_index'][number];

export function useOwnedProperty(
  href: string,
  { enabled = true } = {}
): UseQueryResult<ApplauseSelectedPropertyIndice | false, FetchMediaError> {
  const result = useProperties({
    enabled,

    // No good way to infer this type change
    select: (data): any => {
      return data.properties._index.find((item) => item.href === href) || false;
    },
  });

  return result as UseQueryResult<
    ApplauseSelectedPropertyIndice | false,
    FetchMediaError
  >;
}
