import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { Fragment } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Card, Divider } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { encode } from '../base';
import { fontFamily700 } from '../constants/Theming';
import { RouteParamList } from '../navigation/routes';
import { ApplauseService } from './useServices';

type UpgradeNavigation = StackNavigationProp<RouteParamList, 'Upgrade'>;

export function ServiceRequestListItem({
  isFirst,
  isLast,
  service,
}: {
  isFirst: boolean;
  isLast: boolean;
  service: ApplauseService;
}) {
  const tailwind = useTailwind();

  const roundTop = isFirst;
  const roundBottom = isLast;
  const roundness = 8;
  const { push } = useNavigation<UpgradeNavigation>();

  const href = service._links.self.href;
  const image = service._links.listing_image?.href;

  const name = 'name' in service ? service.name : service._links.self.name;
  const description =
    'description' in service ? service.description : undefined;

  return (
    <Fragment>
      <Card
        style={[
          tailwind('p-4'),
          {
            borderTopEndRadius: roundTop ? roundness : 0,
            borderTopStartRadius: roundTop ? roundness : 0,
            borderBottomEndRadius: roundBottom ? roundness : 0,
            borderBottomStartRadius: roundBottom ? roundness : 0,
          },
        ]}
        elevation={1}
        onPress={() => href && push('RequestService', { href: encode(href) })}
      >
        <View style={{ flexDirection: 'row' }}>
          {image ? (
            <Image
              source={{
                uri: image,
                width: 400,
                height: 300,
              }}
              resizeMode="cover"
              style={{ borderRadius: 6, width: 80, height: 80 }}
            />
          ) : (
            <View
              style={{
                width: 80,
                height: 80,
                borderRadius: 6,
                backgroundColor: '#222',
              }}
            />
          )}

          <View style={[tailwind('-mt-1 flex-1')]}>
            <Card.Title
              title={name}
              titleStyle={[
                {
                  includeFontPadding: false,
                  fontSize: 16,
                  lineHeight: 22,
                  fontWeight: '700',
                  fontFamily: fontFamily700,
                },
              ]}
              subtitle={description}
              titleNumberOfLines={3}
              subtitleNumberOfLines={4}
            />
          </View>
        </View>
      </Card>
      {isLast ? null : <Divider style={{ height: StyleSheet.hairlineWidth }} />}
    </Fragment>
  );
}
