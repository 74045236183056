import Color from 'color';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Snackbar, useTheme } from 'react-native-paper';

interface GlobalSnackbar {
  active: boolean;
  persistent: string | null;
  content: string;
  duration?: number;
  action: { label: string; type: string; onPress(): void } | null;
  show: (
    content: string,
    action?: GlobalSnackbar['action'] | undefined,
    duration?: number
  ) => void;
  persistentShow: (
    id: string,
    content: string,
    action?: GlobalSnackbar['action'] | undefined
  ) => void;
  hide: () => void;
}

const DEFAULT: Pick<
  GlobalSnackbar,
  'action' | 'active' | 'content' | 'duration' | 'persistent'
> = {
  active: false,
  content: '',
  action: null,
  persistent: null,
};
const SnackbarContext = createContext<GlobalSnackbar>({
  ...DEFAULT,
  show: () => {},
  persistentShow: () => {},
  hide: () => {},
});

export function useSnackbar() {
  const { show, hide } = useContext(SnackbarContext);
  return useMemo(() => ({ show, hide }), [show, hide]);
}

export function usePersistentSnackbar(id: string) {
  const {
    persistentShow,
    hide: persistentHide,
    persistent,
  } = useContext(SnackbarContext);
  const persistentRef = useRef(persistent);

  // Update right away
  persistentRef.current = persistent;

  const show = useCallback(
    (content: string, action?: GlobalSnackbar['action'] | undefined) =>
      persistentShow(id, content, action),
    [id, persistentShow]
  );

  const hide = useCallback(
    () => persistentRef.current === id && persistentHide(),
    [id, persistentHide]
  );

  return useMemo(
    () => ({
      show,
      hide,
    }),
    [show, hide]
  );
}

function useProvideSnackbar() {
  const [snackbar, setSnackbar] = useState(DEFAULT);

  const hide = useCallback(
    () => setSnackbar((prev) => ({ ...prev, persistent: null, active: false })),
    []
  );

  const show = useCallback(
    (
      content: string,
      action?: GlobalSnackbar['action'] | undefined,
      duration?: number
    ) => {
      setSnackbar({
        persistent: null,
        action: action ?? { label: 'Ok', type: 'info', onPress: hide },
        duration,
        content,
        active: true,
      });
    },
    [hide]
  );

  const persistentShow = useCallback(
    (
      id: string,
      content: string,
      action?: GlobalSnackbar['action'] | undefined
    ) => {
      setSnackbar({
        persistent: id,
        action: action ?? null,
        content,
        active: true,
      });
    },
    [hide]
  );

  return { ...snackbar, show, persistentShow, hide };
}

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const value = useProvideSnackbar();

  const theme = useTheme();
  const {
    dark,
    colors: { primary, secondary },
  } = theme;
  const accentHasContrast = new Color(secondary).isDark() === dark;
  const primaryHasContrast = new Color(primary).isDark() === dark;

  const actionColor = [
    accentHasContrast && secondary,
    primaryHasContrast && primary,
    dark && '#121212',
    '#fff',
  ].find(Boolean) as string;

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        visible={value.active}
        onDismiss={value.hide}
        action={value.action ?? undefined}
        duration={value.duration}
        wrapperStyle={{ maxWidth: 800 }}
        theme={{
          ...theme,
          colors: { ...theme.colors, secondary: actionColor },
        }}
      >
        {value.content}
      </Snackbar>
    </SnackbarContext.Provider>
  );
}
