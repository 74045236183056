import Color from 'color';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Platform } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { COLOR_APPBAR_SURFACE, COLOR_PRIMARY } from '../config';
import { BASE_THEME, DARK_BASE_THEME, MD2_BASE_THEME, MD2_DARK_BASE_THEME } from '../constants/Theming';
import { AuthProvider } from '../hooks/useAuth';
import useCachedResources from '../hooks/useCachedResources';
import useColorScheme from '../hooks/useColorScheme';
import Navigation from '../navigation';
import { OtaUpdater } from './OtaUpdater';
import { QueryCacheProvider } from './QueryCache';

export function Root() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const primary = COLOR_PRIMARY!;
  const accent = COLOR_PRIMARY!;

  if (!isLoadingComplete && Platform.OS !== 'web') {
    return null;
  }

  // const theme = colorScheme === 'dark' ? DARK_BASE_THEME : BASE_THEME;
  const theme = colorScheme === 'dark' ? MD2_DARK_BASE_THEME : MD2_BASE_THEME;
  const bar = new Color(COLOR_APPBAR_SURFACE);

  if (primary === undefined || accent === undefined) {
    throw "[Root.tsx] Unable to create root with undefined primary or accent colors."
  }

  return (
    <AuthProvider>
      <QueryCacheProvider>
        <SafeAreaProvider>
          <PaperProvider
            theme={{
              ...theme,
              colors: {
                ...theme.colors,
                primary,
                secondary: accent,
              },
            }}
          >
            {isLoadingComplete ? <Navigation /> : null}
            {Platform.OS !== 'web' && <OtaUpdater />}
            <StatusBar
              translucent
              style={bar.isDark() ? 'light' : 'dark'}
              backgroundColor="#00000000"
            />
          </PaperProvider>
        </SafeAreaProvider>
        {/*<Splash isVisible={!isLoadingComplete} />*/}
      </QueryCacheProvider>
    </AuthProvider>
  );
}
