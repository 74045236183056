import React from 'react';
import Svg, {
  Defs,
  G,
  LinearGradient,
  Path,
  Polygon,
  Polyline,
  Stop,
  SvgProps,
} from 'react-native-svg';

export const EmptyState = (props: SvgProps = {}) => (
  <Svg viewBox="0 0 400 300" {...props}>
    <Defs>
      <LinearGradient
        id="linear-gradient"
        x1={146.86}
        y1={269.23}
        x2={279.46}
        y2={120.84}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#55823d" />
        <Stop offset={1} stopColor="#603813" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-2"
        x1={156.87}
        y1={205.23}
        x2={171.31}
        y2={186.15}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#ffc49e" />
        <Stop offset={1} stopColor="#dd6f31" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-3"
        x1={124.97}
        y1={132.41}
        x2={141.29}
        y2={121.29}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#fff" stopOpacity={0} />
        <Stop offset={0.81} stopColor="#262626" stopOpacity={0.57} />
        <Stop offset={1} stopOpacity={0.67} />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-4"
        x1={248.2}
        y1={156.29}
        x2={267.18}
        y2={146.07}
      />
      <LinearGradient
        id="linear-gradient-5"
        x1={215.61}
        y1={171.23}
        x2={219.07}
        y2={163.82}
      />
      <LinearGradient
        id="linear-gradient-6"
        x1={184.66}
        y1={156.87}
        x2={188.08}
        y2={149.56}
      />
      <LinearGradient
        id="linear-gradient-7"
        x1={153.38}
        y1={139.75}
        x2={157.18}
        y2={131.62}
      />
    </Defs>
    <G>
      <G id="Layer_2" data-name="Layer 2">
        <Path
          d="M194.06,284.3,14.34,178.08c-5.72-3.21-10.63-8.49-5.05-11.94l180.37-104c2.71-1.68,6.82-2.78,9.57-1.18L387.88,170.5c5.72,3.32,6.75,12.6,1,15.93L217.75,284.13A23.9,23.9,0,0,1,194.06,284.3Z"
          fill="#55823d"
        />
        <Polyline
          points="290 194.4 164.48 266.82 104.45 231.34 246.13 154.27 297.97 137.38 237.86 199.34"
          fill="url(#linear-gradient)"
        />
        <Path
          d="M100.12,172.59s4-10.13,17.18-19.82S153,140,158.71,129,181.81,77.43,191,75.23s7.83,11.89,4.31,22.91-12.78,27.31-7.93,29.08,22.47-18.94,25.11-26.87S222.59,77,231.4,80.51s7.49,9.25,0,23.35S206.73,138.23,210.69,140s23.26-12.34,26.83-19.82,23.39-30,30.88-23.79,5.29,10.57,0,17.62-22,26.43-28.2,32.6l-14.1,14.1s28.2-10.57,38.77-21.15,22.47-18.29,30.4-15.75,5.29,5.18,4.41,10.47-24.23,24.67-30.4,30.4-42.76,25.26-42.76,25.26,34.23-12,48.93-10,14.59,3.44,17.43,9.75-35.49,16.44-46.06,23.05-27.31,14.1-37.45,16.3-58.15,7-64.32,9.25-12.6,9.66-12.6,9.66L56,202.73Z"
          fill="#ffc49e"
        />
        <Path
          d="M203.07,168.74c-3.25-8.2-10.42-13.34-17.91-17.44-8.67-4.75-18.23-8-28.23-6.95-1.53.17-1.55,2.59,0,2.42,9.11-1,17.76,1.74,25.76,5.94,7.3,3.83,14.89,8.69,18.06,16.67C201.31,170.81,203.64,170.18,203.07,168.74Z"
          fill="#af7146"
        />
        <Path
          d="M117.3,152.76s-10.57,31.07,4.41,52.43l-21.59-32.6S109.48,157.4,117.3,152.76Z"
          fill="#f2ad86"
        />
        <Path
          d="M268,201.73a373.5,373.5,0,0,1-38,13.14c-12.45,3.57-25.33,7-38.21,8.55-14.23,1.72-28.58,1.78-42.87,2.75-12.33.84-18.78,7.19-28.18,14.77l11.74,6.94s6.16-7,12.33-9.18,54.19-7,64.32-9.25,26.87-9.69,37.45-16.3c10.32-6.45,47.1-16.26,46.19-22.6Q280.57,196.61,268,201.73Z"
          fill="#f2ad86"
        />
        <Path
          d="M282.38,145.48c-14.27,10.21-30.1,17.83-46.17,24.73-8.19,3.52-16.33,7.18-24.55,10.62-2.52,1.05-5.12,1.94-7.73,2.82,5.57,1.37,9.71,6.35,15.05,8.45,1.19-.11,2.33-.27,3.41-.47,6.11-1.17,14.37-7.38,14.37-7.38,11.31-6.23,28.53-16,32.59-19.73,6.17-5.73,29.52-25.11,30.4-30.4.32-1.94.88-3.52,1.07-4.87A115.15,115.15,0,0,1,282.38,145.48Z"
          fill="#f2ad86"
        />
        <Path
          d="M295.21,189c-3.54-10.8-16-11.84-25.64-11.16-13.25.93-26,5.21-38.2,10.11q-2.43,1-4.85,2c-1.11.46-2.9,1.67-4.13,1.72-2.73.11-5.78-3-8.19-4.31a76.84,76.84,0,0,0-16.07-6.73,77.88,77.88,0,0,0-34.59-2c-1.53.25-.88,2.58.64,2.33a75.09,75.09,0,0,1,33.31,2,74.22,74.22,0,0,1,15.5,6.49c2.31,1.3,4.49,2.76,6.68,4.24s2.89.52,5.43-.54c11-4.59,22.13-9,33.83-11.39,10.15-2.05,29.68-5.14,33.95,7.9C293.36,191.1,295.7,190.47,295.21,189Z"
          fill="#af7146"
        />
        <Path
          d="M272.26,197.06a20.13,20.13,0,0,0,1.58,4.93s18.37-6,19-11.32a6.52,6.52,0,0,0,.06-1.78A65.5,65.5,0,0,0,272.26,197.06Z"
          fill="#fff"
          opacity={0.5}
        />
        <Path
          d="M282.09,153.34,166.35,225.27,90.78,179l9.34-6.39s7.58-13.4,17.18-19.82"
          fill="url(#linear-gradient-2)"
        />
        <Polyline
          points="92.93 145.44 92.93 139.22 283.98 144.42 283.96 150.13 277.02 151.32"
          fill="#974c13"
        />
        <Path
          d="M281.58,145.46l-93.4-54.21c-1.36-.79-3.4-.25-4.74.58l-65.37,37.67-15.49,8.93-8.44,4.86c-2.76,1.71-.33,4.32,2.5,5.91l89,52.59a11.83,11.83,0,0,0,11.73-.08l14.33-8.18,9.84-5.62,60.56-34.57C284.92,151.7,284.41,147.1,281.58,145.46Z"
          fill="#974c13"
        />
        <Path
          d="M281.66,139.73l-93.4-54.21c-1.36-.79-3.4-.25-4.74.58l-65.37,37.67h0l103.45,58.41h0l60.56-34.57C285,146,284.5,141.38,281.66,139.73Z"
          fill="#f9ba53"
        />
        <Polygon
          points="183.65 129.02 183.65 70.31 196.45 77.46 196.45 136.4 183.65 129.02"
          fill="#68e1fd"
        />
        <Polygon
          points="183.65 129.02 183.65 70.31 196.45 77.46 196.45 136.4 183.65 129.02"
          fill="#1e4549"
          opacity={0.33}
        />
        <Polygon
          points="196.45 77.46 209.45 70.31 209.45 128.77 196.45 136.4 196.45 77.46"
          fill="#68e1fd"
        />
        <Polygon
          points="183.65 70.31 196.45 62.46 209.45 70.31 196.45 77.46 183.65 70.31"
          fill="#ccc"
        />
        <Polygon
          points="209.45 70.31 196.45 77.46 183.65 70.31 183.65 72.05 196.45 79.09 209.45 71.93 209.45 70.31"
          fill="#1e4549"
        />
        <Polygon
          points="207.9 77.88 207.9 75.14 206.27 76.03 206.27 78.66 207.9 77.88"
          fill="#e0e0e0"
        />
        <Polygon
          points="203.84 80.07 203.84 77.33 202.21 78.22 202.21 80.86 203.84 80.07"
          fill="#e0e0e0"
        />
        <Polygon
          points="199.62 82.66 199.62 79.92 197.99 80.81 197.99 83.45 199.62 82.66"
          fill="#e0e0e0"
        />
        <Polygon
          points="207.9 87.71 207.9 84.97 206.27 85.86 206.27 88.49 207.9 87.71"
          fill="#e0e0e0"
        />
        <Polygon
          points="203.84 89.9 203.84 87.16 202.21 88.05 202.21 90.69 203.84 89.9"
          fill="#e0e0e0"
        />
        <Polygon
          points="199.62 92.49 199.62 89.75 197.99 90.64 197.99 93.28 199.62 92.49"
          fill="#e0e0e0"
        />
        <Polygon
          points="207.9 98.95 207.9 96.21 206.27 97.11 206.27 99.74 207.9 98.95"
          fill="#e0e0e0"
        />
        <Polygon
          points="203.84 101.15 203.84 98.41 202.21 99.3 202.21 101.93 203.84 101.15"
          fill="#e0e0e0"
        />
        <Polygon
          points="199.62 103.74 199.62 101 197.99 101.89 197.99 104.52 199.62 103.74"
          fill="#e0e0e0"
        />
        <Polygon
          points="207.9 110.09 207.9 107.35 206.27 108.24 206.27 110.88 207.9 110.09"
          fill="#e0e0e0"
        />
        <Polygon
          points="203.84 112.28 203.84 109.54 202.21 110.44 202.21 113.07 203.84 112.28"
          fill="#e0e0e0"
        />
        <Polygon
          points="199.62 114.87 199.62 112.13 197.99 113.03 197.99 115.66 199.62 114.87"
          fill="#e0e0e0"
        />
        <Polygon
          points="207.9 121.23 207.9 118.48 206.27 119.38 206.27 122.01 207.9 121.23"
          fill="#e0e0e0"
        />
        <Polygon
          points="203.84 123.42 203.84 120.68 202.21 121.57 202.21 124.2 203.84 123.42"
          fill="#e0e0e0"
        />
        <Polygon
          points="199.62 126.01 199.62 123.27 197.99 124.16 197.99 126.8 199.62 126.01"
          fill="#e0e0e0"
        />
        <Polygon
          points="184.39 77.91 184.39 75.17 186.02 76.06 186.02 78.69 184.39 77.91"
          fill="#e0e0e0"
        />
        <Polygon
          points="188.45 80.1 188.45 77.36 190.08 78.25 190.08 80.89 188.45 80.1"
          fill="#e0e0e0"
        />
        <Polygon
          points="192.67 82.69 192.67 79.95 194.3 80.84 194.3 83.48 192.67 82.69"
          fill="#e0e0e0"
        />
        <Polygon
          points="184.39 87.74 184.39 85 186.02 85.89 186.02 88.52 184.39 87.74"
          fill="#e0e0e0"
        />
        <Polygon
          points="188.45 89.93 188.45 87.19 190.08 88.08 190.08 90.72 188.45 89.93"
          fill="#e0e0e0"
        />
        <Polygon
          points="192.67 92.52 192.67 89.78 194.3 90.67 194.3 93.31 192.67 92.52"
          fill="#e0e0e0"
        />
        <Polygon
          points="184.39 98.98 184.39 96.24 186.02 97.14 186.02 99.77 184.39 98.98"
          fill="#e0e0e0"
        />
        <Polygon
          points="188.45 101.18 188.45 98.44 190.08 99.33 190.08 101.96 188.45 101.18"
          fill="#e0e0e0"
        />
        <Polygon
          points="192.67 103.77 192.67 101.03 194.3 101.92 194.3 104.55 192.67 103.77"
          fill="#e0e0e0"
        />
        <Polygon
          points="184.39 110.12 184.39 107.38 186.02 108.27 186.02 110.91 184.39 110.12"
          fill="#e0e0e0"
        />
        <Polygon
          points="188.45 112.31 188.45 109.57 190.08 110.47 190.08 113.1 188.45 112.31"
          fill="#e0e0e0"
        />
        <Polygon
          points="192.67 114.9 192.67 112.16 194.3 113.06 194.3 115.69 192.67 114.9"
          fill="#e0e0e0"
        />
        <Polygon
          points="184.39 121.26 184.39 118.51 186.02 119.41 186.02 122.04 184.39 121.26"
          fill="#e0e0e0"
        />
        <Polygon
          points="188.45 123.45 188.45 120.71 190.08 121.6 190.08 124.23 188.45 123.45"
          fill="#e0e0e0"
        />
        <Polygon
          points="192.67 126.04 192.67 123.3 194.3 124.19 194.3 126.83 192.67 126.04"
          fill="#e0e0e0"
        />
        <Polygon
          points="209.45 90.67 196.34 97.45 183.65 90.79 183.65 93.5 196.45 100.16 209.45 93.5 209.45 90.67"
          fill="#2e5b5a"
        />
        <Polygon
          points="209.45 101.93 196.34 108.71 183.65 102.05 183.65 104.76 196.45 111.42 209.45 104.76 209.45 101.93"
          fill="#2e5b5a"
        />
        <Polygon
          points="209.45 113.07 196.34 119.85 183.65 113.19 183.65 115.9 196.45 122.56 209.45 115.9 209.45 113.07"
          fill="#2e5b5a"
        />
        <Polygon
          points="209.35 123.3 196.24 130.07 183.55 123.41 183.55 126.13 196.35 132.78 209.35 126.13 209.35 123.3"
          fill="#2e5b5a"
        />
        <Polygon
          points="209.45 79.92 196.34 86.7 183.65 80.03 183.65 82.75 196.45 89.4 209.45 82.75 209.45 79.92"
          fill="#2e5b5a"
        />
        <Polygon
          points="153.63 131.09 153.63 72.73 166.35 79.84 166.35 138.42 153.63 131.09"
          fill="#68e1fd"
        />
        <Polygon
          points="153.63 131.09 153.63 72.73 166.35 79.84 166.35 138.42 153.63 131.09"
          fill="#1e4549"
          opacity={0.33}
        />
        <Polygon
          points="166.35 79.84 179.28 72.73 179.28 130.84 166.35 138.42 166.35 79.84"
          fill="#68e1fd"
        />
        <Polygon
          points="153.63 72.73 166.35 64.92 179.28 72.73 166.35 79.84 153.63 72.73"
          fill="#9d9ca1"
        />
        <Polyline
          points="156.68 69.86 156.68 72.05 166.83 77.92 176.99 72.05 176.99 69.86"
          fill="#cc9825"
        />
        <Polygon
          points="156.68 69.88 166.76 63.7 176.99 69.88 166.76 75.51 156.68 69.88"
          fill="#f9e1aa"
        />
        <Polygon
          points="179.28 76.84 166.24 83.58 153.63 76.96 153.63 77.98 166.24 84.6 179.28 77.86 179.28 76.84"
          fill="#ccc"
        />
        <Polygon
          points="179.28 81.07 166.24 87.81 153.63 81.19 153.63 82.21 166.24 88.83 179.28 82.09 179.28 81.07"
          fill="#ccc"
        />
        <Polygon
          points="179.28 85.68 166.19 92.45 153.63 85.85 153.63 86.87 166.19 93.47 179.28 86.7 179.28 85.68"
          fill="#ccc"
        />
        <Polygon
          points="179.28 89.86 166.09 96.68 153.63 90.14 153.63 91.16 166.08 97.7 179.28 90.88 179.28 89.86"
          fill="#ccc"
        />
        <Polygon
          points="179.28 93.93 166.29 100.64 153.63 93.99 153.63 95.01 166.29 101.66 179.28 94.95 179.28 93.93"
          fill="#ccc"
        />
        <Polygon
          points="179.28 98.11 166.19 104.87 153.63 98.28 153.63 99.3 166.19 105.89 179.28 99.12 179.28 98.11"
          fill="#ccc"
        />
        <Polygon
          points="179.28 102.23 166.34 108.92 153.63 102.24 153.63 103.26 166.34 109.94 179.28 103.25 179.28 102.23"
          fill="#ccc"
        />
        <Polygon
          points="179.28 106.55 166.24 113.29 153.63 106.67 153.63 107.69 166.24 114.31 179.28 107.57 179.28 106.55"
          fill="#ccc"
        />
        <Polygon
          points="179.28 110.86 166.14 117.65 153.63 111.08 153.63 112.1 166.14 118.67 179.28 111.88 179.28 110.86"
          fill="#ccc"
        />
        <Polygon
          points="179.28 115.03 166.04 121.88 153.63 115.36 153.63 116.39 166.03 122.9 179.28 116.05 179.28 115.03"
          fill="#ccc"
        />
        <Polygon
          points="179.28 119.66 166.34 126.34 153.63 119.67 153.63 120.69 166.34 127.36 179.28 120.67 179.28 119.66"
          fill="#ccc"
        />
        <Polygon
          points="179.28 123.83 166.24 130.57 153.63 123.95 153.63 124.97 166.24 131.59 179.28 124.85 179.28 123.83"
          fill="#ccc"
        />
        <Polygon
          points="179.28 127.89 166.19 134.66 153.63 128.06 153.63 129.09 166.19 135.68 179.28 128.91 179.28 127.89"
          fill="#ccc"
        />
        <Polygon
          points="184.96 149.11 184.96 96.37 196.46 102.8 196.46 155.74 184.96 149.11"
          fill="#68e1fd"
        />
        <Polygon
          points="184.96 149.11 184.96 96.37 196.46 102.8 196.46 155.74 184.96 149.11"
          fill="#1e4549"
          opacity={0.33}
        />
        <Polygon
          points="196.46 102.8 208.14 96.37 208.14 148.89 196.46 155.74 196.46 102.8"
          fill="#68e1fd"
        />
        <Polygon
          points="184.96 96.37 196.46 89.32 208.14 96.37 196.46 102.8 184.96 96.37"
          fill="#9d9ca1"
        />
        <Polyline
          points="187.71 93.78 187.71 95.76 196.89 101.06 206.07 95.76 206.07 93.78"
          fill="#cc9825"
        />
        <Polygon
          points="187.71 93.8 196.82 88.21 206.07 93.8 196.82 98.89 187.71 93.8"
          fill="#f9e1aa"
        />
        <Polygon
          points="208.14 100.09 196.36 106.18 184.96 100.19 184.96 101.12 196.35 107.1 208.14 101.01 208.14 100.09"
          fill="#ccc"
        />
        <Polygon
          points="208.14 103.91 196.36 110 184.96 104.02 184.96 104.94 196.35 110.92 208.14 104.83 208.14 103.91"
          fill="#ccc"
        />
        <Polygon
          points="208.14 108.08 196.31 114.19 184.96 108.23 184.96 109.15 196.31 115.11 208.14 109 208.14 108.08"
          fill="#ccc"
        />
        <Polygon
          points="208.14 111.85 196.22 118.02 184.96 112.1 184.96 113.03 196.21 118.94 208.14 112.77 208.14 111.85"
          fill="#ccc"
        />
        <Polygon
          points="208.14 115.53 196.4 121.6 184.96 115.59 184.96 116.51 196.4 122.52 208.14 116.45 208.14 115.53"
          fill="#ccc"
        />
        <Polygon
          points="208.14 119.31 196.31 125.42 184.96 119.46 184.96 120.38 196.31 126.34 208.14 120.22 208.14 119.31"
          fill="#ccc"
        />
        <Polygon
          points="208.14 123.04 196.45 129.08 184.96 123.05 184.96 123.97 196.45 130 208.14 123.96 208.14 123.04"
          fill="#ccc"
        />
        <Polygon
          points="208.14 126.94 196.36 133.03 184.96 127.04 184.96 127.96 196.35 133.95 208.14 127.86 208.14 126.94"
          fill="#ccc"
        />
        <Polygon
          points="208.14 130.83 196.26 136.97 184.96 131.03 184.96 131.96 196.26 137.89 208.14 131.75 208.14 130.83"
          fill="#ccc"
        />
        <Polygon
          points="208.14 134.6 196.17 140.79 184.96 134.9 184.96 135.83 196.17 141.71 208.14 135.52 208.14 134.6"
          fill="#ccc"
        />
        <Polygon
          points="208.14 138.78 196.45 144.83 184.96 138.79 184.96 139.71 196.45 145.75 208.14 139.7 208.14 138.78"
          fill="#ccc"
        />
        <Polygon
          points="208.14 142.56 196.36 148.65 184.96 142.66 184.96 143.59 196.35 149.57 208.14 143.48 208.14 142.56"
          fill="#ccc"
        />
        <Polygon
          points="208.14 146.23 196.31 152.34 184.96 146.38 184.96 147.3 196.31 153.26 208.14 147.15 208.14 146.23"
          fill="#ccc"
        />
        <Polygon
          points="215.24 163.04 215.24 104.33 228.04 111.49 228.04 170.42 215.24 163.04"
          fill="#68e1fd"
        />
        <Polygon
          points="215.24 163.04 215.24 104.33 228.04 111.49 228.04 170.42 215.24 163.04"
          fill="#1e4549"
          opacity={0.33}
        />
        <Polygon
          points="228.04 111.49 241.04 104.33 241.04 162.79 228.04 170.42 228.04 111.49"
          fill="#68e1fd"
        />
        <Polygon
          points="215.24 104.33 228.04 96.48 241.04 104.33 228.04 111.49 215.24 104.33"
          fill="#ccc"
        />
        <Polygon
          points="241.04 104.33 228.04 111.49 215.24 104.33 215.24 106.07 228.04 113.11 241.04 105.95 241.04 104.33"
          fill="#1e4549"
        />
        <Polygon
          points="239.49 111.9 239.49 109.16 237.86 110.05 237.86 112.69 239.49 111.9"
          fill="#e0e0e0"
        />
        <Polygon
          points="235.43 114.09 235.43 111.35 233.8 112.24 233.8 114.88 235.43 114.09"
          fill="#e0e0e0"
        />
        <Polygon
          points="231.22 116.68 231.22 113.94 229.59 114.83 229.59 117.47 231.22 116.68"
          fill="#e0e0e0"
        />
        <Polygon
          points="239.49 121.73 239.49 118.99 237.86 119.88 237.86 122.52 239.49 121.73"
          fill="#e0e0e0"
        />
        <Polygon
          points="235.43 123.92 235.43 121.18 233.8 122.07 233.8 124.71 235.43 123.92"
          fill="#e0e0e0"
        />
        <Polygon
          points="231.22 126.51 231.22 123.77 229.59 124.66 229.59 127.3 231.22 126.51"
          fill="#e0e0e0"
        />
        <Polygon
          points="239.49 132.98 239.49 130.23 237.86 131.13 237.86 133.76 239.49 132.98"
          fill="#e0e0e0"
        />
        <Polygon
          points="235.43 135.17 235.43 132.43 233.8 133.32 233.8 135.96 235.43 135.17"
          fill="#e0e0e0"
        />
        <Polygon
          points="231.22 137.76 231.22 135.02 229.59 135.91 229.59 138.54 231.22 137.76"
          fill="#e0e0e0"
        />
        <Polygon
          points="239.49 144.11 239.49 141.37 237.86 142.26 237.86 144.9 239.49 144.11"
          fill="#e0e0e0"
        />
        <Polygon
          points="235.43 146.3 235.43 143.56 233.8 144.46 233.8 147.09 235.43 146.3"
          fill="#e0e0e0"
        />
        <Polygon
          points="231.22 148.89 231.22 146.15 229.59 147.05 229.59 149.68 231.22 148.89"
          fill="#e0e0e0"
        />
        <Polygon
          points="239.49 155.25 239.49 152.5 237.86 153.4 237.86 156.03 239.49 155.25"
          fill="#e0e0e0"
        />
        <Polygon
          points="235.43 157.44 235.43 154.7 233.8 155.59 233.8 158.23 235.43 157.44"
          fill="#e0e0e0"
        />
        <Polygon
          points="231.22 160.03 231.22 157.29 229.59 158.18 229.59 160.82 231.22 160.03"
          fill="#e0e0e0"
        />
        <Polygon
          points="215.98 111.93 215.98 109.19 217.61 110.08 217.61 112.72 215.98 111.93"
          fill="#e0e0e0"
        />
        <Polygon
          points="220.05 114.12 220.05 111.38 221.68 112.27 221.68 114.91 220.05 114.12"
          fill="#e0e0e0"
        />
        <Polygon
          points="224.26 116.71 224.26 113.97 225.89 114.86 225.89 117.5 224.26 116.71"
          fill="#e0e0e0"
        />
        <Polygon
          points="215.98 121.76 215.98 119.02 217.61 119.91 217.61 122.55 215.98 121.76"
          fill="#e0e0e0"
        />
        <Polygon
          points="220.05 123.95 220.05 121.21 221.68 122.1 221.68 124.74 220.05 123.95"
          fill="#e0e0e0"
        />
        <Polygon
          points="224.26 126.54 224.26 123.8 225.89 124.69 225.89 127.33 224.26 126.54"
          fill="#e0e0e0"
        />
        <Polygon
          points="215.98 133.01 215.98 130.26 217.61 131.16 217.61 133.79 215.98 133.01"
          fill="#e0e0e0"
        />
        <Polygon
          points="220.05 135.2 220.05 132.46 221.68 133.35 221.68 135.99 220.05 135.2"
          fill="#e0e0e0"
        />
        <Polygon
          points="224.26 137.79 224.26 135.05 225.89 135.94 225.89 138.57 224.26 137.79"
          fill="#e0e0e0"
        />
        <Polygon
          points="215.98 144.14 215.98 141.4 217.61 142.29 217.61 144.93 215.98 144.14"
          fill="#e0e0e0"
        />
        <Polygon
          points="220.05 146.33 220.05 143.59 221.68 144.49 221.68 147.12 220.05 146.33"
          fill="#e0e0e0"
        />
        <Polygon
          points="224.26 148.92 224.26 146.18 225.89 147.08 225.89 149.71 224.26 148.92"
          fill="#e0e0e0"
        />
        <Polygon
          points="215.98 155.28 215.98 152.53 217.61 153.43 217.61 156.06 215.98 155.28"
          fill="#e0e0e0"
        />
        <Polygon
          points="220.05 157.47 220.05 154.73 221.68 155.62 221.68 158.26 220.05 157.47"
          fill="#e0e0e0"
        />
        <Polygon
          points="224.26 160.06 224.26 157.32 225.89 158.21 225.89 160.85 224.26 160.06"
          fill="#e0e0e0"
        />
        <Polygon
          points="241.04 124.69 227.93 131.47 215.24 124.81 215.24 127.52 228.04 134.18 241.04 127.52 241.04 124.69"
          fill="#2e5b5a"
        />
        <Polygon
          points="241.04 135.96 227.93 142.73 215.24 136.07 215.24 138.78 228.04 145.44 241.04 138.78 241.04 135.96"
          fill="#2e5b5a"
        />
        <Polygon
          points="241.04 147.09 227.93 153.87 215.24 147.21 215.24 149.92 228.04 156.58 241.04 149.92 241.04 147.09"
          fill="#2e5b5a"
        />
        <Polygon
          points="240.94 157.32 227.83 164.1 215.14 157.43 215.14 160.15 227.94 166.8 240.94 160.15 240.94 157.32"
          fill="#2e5b5a"
        />
        <Polygon
          points="241.04 113.94 227.93 120.72 215.24 114.06 215.24 116.77 228.04 123.42 241.04 116.77 241.04 113.94"
          fill="#2e5b5a"
        />
        <Path
          d="M119.64,132.74c4.18,4.64,1.32,10.66,7.27,13.59s16.51-4.4,22.9-.29.88,18.23,6.39,20.31,12.79-.34,18.94,3.18,5,9.69,10.08,11.67,12.68-5.72,20.45-2.42c3.52,1.5,5.44,5.16,6.11,9l9.84-5.62L118.16,123.78h0l-15.49,8.93S115.45,128.1,119.64,132.74Z"
          fill="#f9ba53"
        />
        <Path
          d="M96.73,143.49l89,52.59a11.83,11.83,0,0,0,11.73-.08l14.33-8.18c-.67-3.87-2.59-7.53-6.11-9-7.78-3.3-15.34,4.4-20.45,2.42s-3.92-8.15-10.08-11.67-13.44-1.1-18.94-3.18,0-16.2-6.39-20.31-17,3.23-22.9.29-3.08-9-7.27-13.59-17,0-17,0l-8.44,4.86C91.47,139.29,93.9,141.9,96.73,143.49Z"
          fill="#68e1fd"
        />
        <Path
          d="M134.52,129.26l-22.91,5.34,7.9,7.11,17.56-10.62C136.55,130.28,135.17,130,134.52,129.26Z"
          fill="url(#linear-gradient-3)"
        />
        <Path
          d="M135,73.5s0,0,0,0v-.09c-.1-.78,1-.83,1.28-.18a.55.55,0,0,1,0,.09.66.66,0,0,1,0,.09,133.23,133.23,0,0,1,1,17.25.68.68,0,0,1-1.26.31.71.71,0,0,1-1.08-.53A112.78,112.78,0,0,1,135,73.5Z"
          fill="#ab9e5a"
        />
        <Path
          d="M122,86.54c0-.09.12-.15.17-.23a.56.56,0,0,1,.1-.41c3.23-4.6,9.3-2.72,12.07,1.22a5.66,5.66,0,0,1,1.4,3.15c.1.8-1.06.83-1.29.14a.63.63,0,0,1-.16-.29,6.8,6.8,0,0,0-1.14-2.35A7.62,7.62,0,0,0,129.31,86c-1.77-.36-5.13-.71-6.19,1.25C122.71,88,121.56,87.29,122,86.54Z"
          fill="#575c1c"
        />
        <Path
          d="M120.51,82.05c5.48-3.74,14.35,1.19,15.11,7.51a.55.55,0,0,1-.6.63.53.53,0,0,1-.77-.27c-2.29-5.31-6.86-9.1-12.85-6.78a.6.6,0,0,1-.13,0l-.09,0C120.47,83.69,119.81,82.53,120.51,82.05Z"
          fill="#636d27"
        />
        <Path
          d="M123.76,78.46a.71.71,0,0,1,.11-.11,1.4,1.4,0,0,1,1.12-.26c6.46-.61,11.74,5.89,11.8,12a.63.63,0,0,1-.74.63.69.69,0,0,1-1.08-.32c-.89-2.73-1.42-5.7-3.48-7.84a9.57,9.57,0,0,0-7.5-2.85C123.21,79.78,123.14,78.7,123.76,78.46Z"
          fill="#746927"
        />
        <Path
          d="M127,75.42a.66.66,0,0,1,.44-.08c6.51,1.12,9.24,7.17,9.42,13.22,0,.15,0,.29,0,.44,0,.86-1.29.86-1.34,0v0a.62.62,0,0,1,0-.09c0-.1,0-.19,0-.29-.44-5.38-3.22-10.37-8.72-11.85A.66.66,0,0,1,127,75.42Z"
          fill="#7d7a33"
        />
        <Path
          d="M136.27,73.5s0,0,0,0v-.09c.1-.78-1-.83-1.28-.18a.55.55,0,0,0,0,.09.66.66,0,0,0,0,.09,133.23,133.23,0,0,0-1,17.25.68.68,0,0,0,1.26.31.71.71,0,0,0,1.08-.53A112.78,112.78,0,0,0,136.27,73.5Z"
          fill="#ab9e5a"
        />
        <Path
          d="M149.3,86.54c0-.09-.12-.15-.17-.23a.56.56,0,0,0-.1-.41c-3.23-4.6-9.3-2.72-12.07,1.22a5.66,5.66,0,0,0-1.4,3.15c-.1.8,1.06.83,1.29.14a.63.63,0,0,0,.16-.29,6.8,6.8,0,0,1,1.14-2.35A7.62,7.62,0,0,1,142,86c1.77-.36,5.13-.71,6.19,1.25C148.56,88,149.71,87.29,149.3,86.54Z"
          fill="#575c1c"
        />
        <Path
          d="M150.76,82.05c-5.48-3.74-14.35,1.19-15.11,7.51a.55.55,0,0,0,.6.63.53.53,0,0,0,.77-.27c2.29-5.31,6.86-9.1,12.85-6.78a.6.6,0,0,0,.13,0l.09,0C150.79,83.69,151.46,82.53,150.76,82.05Z"
          fill="#636d27"
        />
        <Path
          d="M147.5,78.46a.71.71,0,0,0-.11-.11,1.4,1.4,0,0,0-1.12-.26c-6.46-.61-11.74,5.89-11.8,12a.63.63,0,0,0,.74.63.69.69,0,0,0,1.08-.32c.89-2.73,1.42-5.7,3.48-7.84a9.57,9.57,0,0,1,7.5-2.85C148.06,79.78,148.13,78.7,147.5,78.46Z"
          fill="#746927"
        />
        <Path
          d="M144.3,75.42a.66.66,0,0,0-.44-.08c-6.51,1.12-9.24,7.17-9.42,13.22,0,.15,0,.29,0,.44,0,.86,1.29.86,1.34,0v0a.62.62,0,0,0,0-.09c0-.1,0-.19,0-.29.44-5.38,3.22-10.37,8.72-11.85A.66.66,0,0,0,144.3,75.42Z"
          fill="#7d7a33"
        />
        <Path
          d="M133.93,130.93a3.33,3.33,0,0,0,1.5.51,3.53,3.53,0,0,0,1.64-.35.32.32,0,0,0,.19-.29c-.06-8.3-.44-42.21-.44-42.21h-2.56s.15,3.07.32,5.89C134.64,95.35,134,123,133.93,130.93Z"
          fill="#974c13"
        />
        <Polygon
          points="265.54 149.74 237.86 156.2 245.74 163.28 266.2 150.87 265.54 149.74"
          fill="url(#linear-gradient-4)"
        />
        <Path
          d="M263.94,93.56s0,0,0,0v-.09c-.09-.78,1-.83,1.27-.18a.55.55,0,0,1,0,.09.66.66,0,0,1,0,.09,132.81,132.81,0,0,1,1,17.2.67.67,0,0,1-1.26.31.7.7,0,0,1-1.08-.53A112.43,112.43,0,0,1,263.94,93.56Z"
          fill="#ab9e5a"
        />
        <Path
          d="M251,106.56c0-.09.12-.15.17-.23a.56.56,0,0,1,.1-.41c3.22-4.59,9.27-2.71,12,1.21a5.65,5.65,0,0,1,1.4,3.14c.1.8-1.06.83-1.29.14a.62.62,0,0,1-.16-.29,6.78,6.78,0,0,0-1.14-2.35,7.6,7.6,0,0,0-3.8-1.79c-1.77-.35-5.11-.71-6.17,1.24C251.69,108,250.54,107.31,251,106.56Z"
          fill="#575c1c"
        />
        <Path
          d="M249.5,102.09c5.47-3.73,14.31,1.18,15.07,7.48a.54.54,0,0,1-.6.63.53.53,0,0,1-.77-.27c-2.29-5.29-6.84-9.07-12.81-6.76a.6.6,0,0,1-.13,0l-.09,0C249.46,103.72,248.8,102.57,249.5,102.09Z"
          fill="#636d27"
        />
        <Path
          d="M252.74,98.51a.7.7,0,0,1,.11-.11,1.4,1.4,0,0,1,1.12-.26c6.44-.61,11.71,5.87,11.76,12a.63.63,0,0,1-.74.63.69.69,0,0,1-1.08-.32c-.89-2.72-1.42-5.68-3.47-7.82A9.54,9.54,0,0,0,253,99.78C252.19,99.82,252.12,98.75,252.74,98.51Z"
          fill="#746927"
        />
        <Path
          d="M255.93,95.48a.66.66,0,0,1,.44-.08c6.49,1.11,9.21,7.15,9.39,13.18,0,.15,0,.29,0,.44,0,.86-1.28.85-1.33,0v0a.62.62,0,0,1,0-.09c0-.1,0-.19,0-.29-.44-5.36-3.21-10.34-8.7-11.81A.66.66,0,0,1,255.93,95.48Z"
          fill="#7d7a33"
        />
        <Path
          d="M265.21,93.56s0,0,0,0v-.09c.09-.78-1-.83-1.27-.18a.55.55,0,0,0,0,.09.66.66,0,0,0,0,.09,132.81,132.81,0,0,0-1,17.2.67.67,0,0,0,1.26.31.7.7,0,0,0,1.08-.53A112.43,112.43,0,0,0,265.21,93.56Z"
          fill="#ab9e5a"
        />
        <Path
          d="M278.2,106.56c0-.09-.12-.15-.17-.23a.56.56,0,0,0-.1-.41c-3.22-4.59-9.27-2.71-12,1.21a5.65,5.65,0,0,0-1.4,3.14c-.1.8,1.06.83,1.29.14a.62.62,0,0,0,.16-.29,6.78,6.78,0,0,1,1.14-2.35,7.6,7.6,0,0,1,3.8-1.79c1.77-.35,5.11-.71,6.17,1.24C277.46,108,278.61,107.31,278.2,106.56Z"
          fill="#575c1c"
        />
        <Path
          d="M279.65,102.09c-5.47-3.73-14.31,1.18-15.07,7.48a.54.54,0,0,0,.6.63.53.53,0,0,0,.77-.27c2.29-5.29,6.84-9.07,12.81-6.76a.6.6,0,0,0,.13,0l.09,0C279.69,103.72,280.35,102.57,279.65,102.09Z"
          fill="#636d27"
        />
        <Path
          d="M276.41,98.51a.7.7,0,0,0-.11-.11,1.4,1.4,0,0,0-1.12-.26c-6.44-.61-11.71,5.87-11.76,12a.63.63,0,0,0,.74.63.69.69,0,0,0,1.08-.32c.89-2.72,1.42-5.68,3.47-7.82a9.54,9.54,0,0,1,7.48-2.84C277,99.82,277,98.75,276.41,98.51Z"
          fill="#746927"
        />
        <Path
          d="M273.22,95.48a.66.66,0,0,0-.44-.08c-6.49,1.11-9.21,7.15-9.39,13.18,0,.15,0,.29,0,.44,0,.86,1.28.85,1.33,0v0a.62.62,0,0,0,0-.09c0-.1,0-.19,0-.29.44-5.36,3.21-10.34,8.7-11.81A.66.66,0,0,0,273.22,95.48Z"
          fill="#7d7a33"
        />
        <Path
          d="M262.87,150.81a3.32,3.32,0,0,0,1.5.5A3.51,3.51,0,0,0,266,151a.32.32,0,0,0,.19-.29c-.06-8.28-.43-42.08-.43-42.08h-2.55s.15,3.06.32,5.87C263.58,115.35,263,142.86,262.87,150.81Z"
          fill="#974c13"
        />
        <Path
          d="M215.24,163l-10.49,5.64,12.77,7.64,10.52-5.9C227.73,169.94,215.62,163.46,215.24,163Z"
          fill="url(#linear-gradient-5)"
        />
        <Path
          d="M184.89,149.07l-10.49,5.64,11.48,6.87,10.58-5.83C196.15,155.27,185.28,149.49,184.89,149.07Z"
          fill="url(#linear-gradient-6)"
        />
        <Path
          d="M153.64,131.08,142,137.35,154.74,145l11.76-6.49C166.16,138,154.06,131.54,153.64,131.08Z"
          fill="url(#linear-gradient-7)"
        />
      </G>
    </G>
  </Svg>
);
