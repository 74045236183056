import { useIsFocused, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useMemo, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import {
  Button,
  Card,
  HelperText,
  List,
  Portal,
  TextInput,
} from 'react-native-paper';
import { modeAppbarHeight } from 'react-native-paper/src/components/Appbar/utils';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTailwind } from 'tailwind-rn';
import { decode } from '../base';
import { DatePicker } from '../components/DatePicker';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useSnackbar } from '../components/SnackbarProvider';
import { TimePicker } from '../components/TimePicker';
import { COLOR_PRIMARY_DARK } from '../config';
import { useForceAuthenticated } from '../hooks/useAuth';
import {
  ConfirmationDialogContent,
  useConfirmationDialog,
} from '../hooks/useConfirmationDialog';
import { useWindowWidth } from '../hooks/useDimensions';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { RouteParamList } from '../navigation/routes';
import { capitalize } from '../utils/capitalize';
import { ServiceDetails } from './ServiceDetails';
import { ServiceHeaderImage } from './ServiceHeaderImage';
import { BookingReference, PropertyReference } from './ServiceReference';
import { StickyScreenHeader } from './StickyScreenHeader';
import { useServiceImages } from './useServiceImages';
import { ApplauseService, useRequestService, useService } from './useServices';

const PARALLAX_SPEED = 0.5;
const HEADER_DELAY = 0.8;
const HEADER_HEIGHT = 360;

export function RequestServiceScreen() {
  useForceAuthenticated();

  const locale = useLocale();
  const tailwind = useTailwind();
  const { navigate, canGoBack, goBack, popToTop, push } =
    useNavigation<StackNavigationProp<RouteParamList, 'RequestService'>>();
  const { show } = useSnackbar();

  const { href: encodedHref } = useRoute().params as { href: string };
  const href = decode(encodedHref);
  const cacheKey = [locale, 'service', href.split('/').pop()];
  const enabled = useIsFocused();
  const { data: service } = useService(href, cacheKey, {
    enabled,
    notifyOnChangeProps: ['data'],
  });

  const [date, setDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);

  const [quantity, setQuantity] = useState<number>(1);
  const [notes, setNotes] = useState<string | undefined>(undefined);

  const {
    mutateAsync: request,
    error,
    isLoading,
  } = useRequestService(
    service?._links.request.href,
    [...cacheKey, 'request'],
    {
      onSuccess: (deeplink) => {
        show(
          `${i18n.t(`services.request.success`, {
            slug: service ? label(service) : '...',
          })}`
        );

        if (deeplink) {
          setTimeout(() => {
            popToTop();
            setTimeout(() => {
              push('Assignment', { href: deeplink.split('/').pop()! });
            }, 0);
          }, 0);
        } else if (canGoBack()) {
          goBack();
        } else {
          popToTop();
          navigate('Tabs', { screen: 'Dashboard' });
        }
      },
    }
  );

  const needsDate =
    service && (!('needs_date' in service) || service.needs_date);
  const needsTime =
    service && (!('needs_time' in service) || service.needs_time);
  const needsGuestCount =
    service && (!('needs_guest_count' in service) || service.needs_guest_count);
  const needsQuantity =
    service && (!('needs_quantity' in service) || service.needs_quantity);
  const fixedPrice = service && 'fixed_price' in service && service.fixed_price;

  const [isConfirming, confirm, Dialog] = useConfirmationDialog(
    i18n.t(
      'services.request.confirmations.create'
    ) as unknown as ConfirmationDialogContent,
    {
      onConfirm: useCallback(() => {
        if (needsDate || needsTime) {
          const realDate = new Date(
            `${date || new Date().toISOString().split('T')[0]}T14:00:00Z`
          );
          const [hours, minutes] = (time ?? '14:00').split(':');
          realDate.setHours(Number(hours));
          realDate.setMinutes(Number(minutes));

          return request({
            request: { date: realDate.toISOString(), quantity, notes },
          })
            .then(() => {})
            .catch(() => {});
        } else {
          return request({
            request: { quantity, notes },
          })
            .then(() => {})
            .catch(() => {});
        }
      }, [needsDate, needsTime, date, time, quantity, notes]),
    }
  );

  const title =
    service && 'name' in service
      ? service.name
      : service?._links.self.name ?? i18n.t('service-request.title');
  const image = service?._links.listing_image?.href;

  const { top: safeAreaTop } = useSafeAreaInsets();
  const translationY = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler((event) => {
    translationY.value = event.contentOffset.y;
  });

  const parallaxStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: -translationY.value * PARALLAX_SPEED,
        },
      ],
    };
  });

  const headerStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: Math.max(
            HEADER_HEIGHT - modeAppbarHeight.small - translationY.value,
            safeAreaTop
          ),
        },
      ],
    };
  }, [safeAreaTop]);

  const headerBackgroundStyles = useAnimatedStyle(() => {
    const offset = HEADER_HEIGHT * HEADER_DELAY;
    const remainder = HEADER_HEIGHT - offset;
    const opacity = Math.min(
      1,
      Math.max(0, translationY.value - offset) / remainder
    );
    return {
      opacity,
    };
  }, [safeAreaTop]);

  return (
    <Screen>
      <StatusBar translucent backgroundColor="#00000060" style="light" />
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: 360,
          },
          parallaxStyles,
        ]}
      >
        <ServiceHeaderImage image={image} />
      </Animated.View>

      {Platform.OS === 'web' ? (
        <style>
          {`
          #request-service input {
            outline: none;
          }`}
        </style>
      ) : null}

      <Animated.ScrollView
        style={{ flex: 1, backgroundColor: 'transparent' }}
        contentContainerStyle={[
          { paddingTop: 360 },
          tailwind('justify-center self-center max-w-3xl w-full pb-4'),
        ]}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        nativeID="request-service"
      >
        {service?._links.booking ? (
          <BookingReference href={service._links.booking.href} />
        ) : null}
        {service?._links.property ? (
          <PropertyReference href={service._links.property.href} />
        ) : null}

        <Card
          style={[
            tailwind('bg-gray-50 pt-4'),
            {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            },
          ]}
          elevation={0}
        >
          {service ? (
            <ServiceImages href={service._links.detail_images?.href} />
          ) : null}

          {service && 'description' in service ? (
            <ServiceDetails
              service={service}
              priceQuantity={fixedPrice ? 1 : quantity}
            />
          ) : null}

          <List.Subheader
            style={{ includeFontPadding: false, textAlignVertical: 'center' }}
          >
            {service?._links.category?.name ??
              i18n.t('services.headers.request')}
          </List.Subheader>

          <View style={tailwind('mx-4')}>
            {needsDate ? (
              <DatePicker
                label={i18n.t('services.request.fields.date.label')}
                value={date}
                onChange={setDate}
              />
            ) : null}
            {needsTime ? (
              <TimePicker
                label={i18n.t('services.request.fields.time.label')}
                value={time}
                onChange={setTime}
              />
            ) : null}
            {needsGuestCount || needsQuantity ? (
              <TextInput
                label={
                  needsQuantity
                    ? i18n.t('services.request.fields.quantity.label')
                    : i18n.t('services.request.fields.guestCount.label')
                }
                keyboardType="number-pad"
                style={[tailwind('mt-2 mb-2 w-56'), { maxWidth: '100%' }]}
                value={quantity.toString()}
                mode="outlined"
                onChangeText={(next) =>
                  setQuantity(
                    isNaN(parseInt(next, 10)) ? 0 : parseInt(next, 10)
                  )
                }
                theme={{ colors: { primary: COLOR_PRIMARY_DARK } }}
                {...(Platform.OS === 'web' ? { type: 'number' } : {})}
              />
            ) : null}

            <TextInput
              label={i18n.t('services.request.fields.notes.label')}
              placeholder={i18n.t('services.request.fields.notes.placeholder')}
              keyboardType="ascii-capable"
              style={tailwind('mt-2 mb-2 w-full')}
              value={notes || ''}
              mode="outlined"
              onChangeText={(next) => setNotes(next)}
              theme={{ colors: { primary: COLOR_PRIMARY_DARK } }}
            />
            {error ? (
              <HelperText type="error">{error.message}</HelperText>
            ) : null}
          </View>
          <Button
            mode="contained"
            icon="check"
            loading={isLoading}
            disabled={
              !service ||
              isLoading ||
              (needsDate && !date) ||
              (needsTime && !time) ||
              isConfirming
            }
            style={tailwind('self-end mt-4 mx-4 mb-4')}
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
              paddingHorizontal: 6,
            }}
            onPress={confirm}
          >
            {i18n.t('service-request.actions.request')}
          </Button>
        </Card>
      </Animated.ScrollView>

      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: modeAppbarHeight.small,
            top: 0,
          },
          headerStyles,
        ]}
      >
        <StickyScreenHeader title={title} />
      </Animated.View>

      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: 56 + safeAreaTop + 2,
            top: 0,
          },
          headerBackgroundStyles,
        ]}
      >
        <ScreenHeader title={title || 'Retrieving...'} showBack />
      </Animated.View>

      <Portal>
        <Dialog />
      </Portal>
    </Screen>
  );
}

function GridImage({ href, size }: { href: string; size: number }) {
  return (
    <Card
      style={[
        {
          width: size,
          height: size,
          borderRadius: 8,
          marginBottom: 16,
          overflow: 'hidden',
        },
      ]}
    >
      <Image
        source={{ uri: href, width: 256, height: 256 }}
        style={{ width: size, height: size, borderRadius: 8 }}
        resizeMode="cover"
      />
    </Card>
  );
}

function ServiceImagesGrid({ images }: { images: string[] }) {
  const tailwind = useTailwind();
  const windowWidth = useWindowWidth();
  const columns = 3;
  const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);

  const count = images.length;
  const empty = useMemo((): string[] => {
    const result: string[] = [];
    const remainder = columns - ((count % columns) % columns);

    if (remainder === columns) {
      return result;
    }

    for (let i = 0; i < remainder; i++) {
      result.push(Math.random().toString(36));
    }
    return result;
  }, [count, columns]);

  return (
    <View style={tailwind('flex-wrap justify-between flex-row px-4 w-full')}>
      {images.map((image) => (
        <GridImage key={image} href={image} size={width} />
      ))}
      {empty.map((none) => (
        <View style={{ width, height: width }} key={none} />
      ))}
    </View>
  );
}

function ServiceImages({ href }: { href: string | undefined }) {
  const { data: images } = useServiceImages(href, [href], {
    notifyOnChangeProps: ['data', 'isLoading'],
  });

  if (!href || !images || images?.images._index.length === 0) {
    return null;
  }

  return (
    <ServiceImagesGrid
      images={images.images._index.map((image) => image.href)}
    />
  );
}

function label(service: ApplauseService) {
  if ('name' in service) {
    return service.name;
  }

  const slug = service._links.self.slug;
  const all = i18n.t('services.slugs') as unknown as Record<
    string,
    { label: string }
  >;

  if (all[slug]) {
    return all[slug].label;
  }

  return capitalize(slug.replace(/_/g, ' '));
}
