import * as Sentry from 'sentry-expo';
import { SENTRY_DSN } from './config';

Sentry.init({
  debug: __DEV__,
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
});

const Browser = Sentry.Browser;
export { Browser as Sentry };
