import React, { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTailwind } from 'tailwind-rn';

// yesterday
const MIN_DATE = new Date(new Date().getTime() - 1000 * 60 * 60 * 24);
// 2 years
const MAX_DATE = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30 * 24);

export function DatePicker({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string | null;
  onChange(next: string): void;
}) {
  const tailwind = useTailwind();
  const [date, setDate] = useState<Date | null>(
    value ? new Date(value.split('T').shift()!) : null
  );

  useEffect(() => {
    if (!value) {
      return;
    }

    setDate(new Date(value));
  }, [value]);

  return (
    <Fragment>
      <style>{`
        input[type="date"] {
          color: rgb(0, 0, 0);
          font-family: NotoSansKR_400Regular, Arial, sans-serif;
          font-size: 16px;
          height: 52px;
          padding-top: 12px;
          padding-left: 12px;
          padding-right: 12px;
          vertical-align: middle;
          background-color: #e7e7e7;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom-color: rgba(0, 0, 0, 0.26);
          border-bottom-width: 2px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        input[type="date"] + label {
          color: rgb(243, 150, 44);
          font-family: NotoSansKR_400Regular, Arial, sans-serif;
          font-size: 16px;
          opacity: 1;
          padding-left: 9px;
          padding-right: 9px;
          top: 20px;
          transform: translateX(0px) translateY(-15.5px) scale(0.75);
          direction: ltr;
          position: absolute;
        }
      `}</style>
      <View style={{ position: 'relative' }}>
        <input
          type="date"
          min={MIN_DATE.toISOString().split('T').shift()}
          max={MAX_DATE.toISOString().split('T').shift()}
          value={date ? date.toISOString().split('T').shift() : undefined}
          onChange={(e) =>
            onChange((e.nativeEvent.target as HTMLInputElement).value)
          }
          pattern="\d{4}-\d{2}-\d{2}"
          style={tailwind('mb-2')}
        />
        <label>{label}</label>
      </View>
    </Fragment>
  );
}
