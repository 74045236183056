import { useIsFocused } from '@react-navigation/core';
import React from 'react';
import { View } from 'react-native';
import { Card, List, Text } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { ApplauseBooking, useBooking } from '../bookings/useBookings';
import { useColor } from '../hooks/useColor';
import {
  ApplauseConfiguration,
  useConfiguration,
} from '../hooks/useConfiguration';
import { ApplauseProperty, useProperty } from '../properties/useProperties';
import { date } from '../utils/date';

export function PropertyReference({ href }: { href: string }) {
  const tailwind = useTailwind();
  const enabled = useIsFocused();
  const gray100 = useColor('text-gray-100');

  const { data: property } = useProperty(href, {
    notifyOnChangeProps: ['data'],
    enabled,
  });
  const { data: configuration } = useConfiguration({
    enabled,
    notifyOnChangeProps: ['data'],
  });

  if (!property) {
    return null;
  }

  return (
    <Card elevation={1} style={[tailwind('bg-gray-800'), { borderRadius: 0 }]}>
      <Card.Title
        left={(props) => (
          <List.Icon
            icon="home-city"
            {...props}
            color={gray100}
            style={{ marginLeft: 0 }}
          />
        )}
        title={titleFor(property, undefined)}
        titleNumberOfLines={3}
        titleStyle={[
          tailwind('text-white'),
          { fontSize: 14, lineHeight: 22, marginLeft: 8 },
        ]}
        subtitle={subtitleFor(property, undefined, configuration)}
        subtitleStyle={[tailwind('text-gray-100'), { marginLeft: 8 }]}
        subtitleNumberOfLines={3}
      />
    </Card>
  );
}

export function BookingReference({ href }: { href: string }) {
  const tailwind = useTailwind();
  const enabled = useIsFocused();
  const gray100 = useColor('text-gray-100');

  const { data: booking } = useBooking(href, {
    notifyOnChangeProps: ['data'],
    enabled,
  });
  const { data: property } = useProperty(booking?._links.property?.href, {
    notifyOnChangeProps: ['data'],
    enabled: enabled && !!booking?._links.property?.href,
  });
  const { data: configuration } = useConfiguration({
    enabled,
    notifyOnChangeProps: ['data'],
  });

  if (!booking) {
    return null;
  }

  return (
    <Card elevation={1} style={[tailwind('bg-gray-800'), { borderRadius: 0 }]}>
      <Card.Title
        left={(props) => (
          <List.Icon
            icon="credit-card"
            {...props}
            color={gray100}
            style={{ marginLeft: 0 }}
          />
        )}
        title={titleFor(property, booking)}
        titleNumberOfLines={3}
        titleStyle={[
          tailwind('text-white'),
          { fontSize: 14, lineHeight: 22, marginLeft: 8 },
        ]}
        subtitle={subtitleFor(property, booking, configuration)}
        subtitleStyle={[tailwind('text-gray-100'), { marginLeft: 8 }]}
        subtitleNumberOfLines={3}
      />

      <View style={tailwind('pl-4 pb-4')}>
        <Text style={tailwind('text-gray-50')}>
          {date(booking?.dates.check_in)} - {date(booking?.dates.check_out)}
        </Text>
      </View>
    </Card>
  );
}

function titleFor(
  property: ApplauseProperty | undefined,
  booking: ApplauseBooking | undefined
): string {
  if (!booking) {
    if (!property) {
      return '<unknown>';
    }

    if (!property?._links.self.reference) {
      return property?.title || ' ';
    }

    return `[${property._links.self.reference}] ${property.title}`;
  }

  if (!property) {
    return booking._links.self.reference || ' ';
  }

  if (!booking._links.self.reference) {
    return property.title;
  }

  return `[${property._links.self.reference}] ${property.title}`;
}

function subtitleFor(
  property: ApplauseProperty | undefined,
  booking: ApplauseBooking | undefined,
  configuration: ApplauseConfiguration | undefined
): string {
  const isManager = configuration?.capabilities.can_manage;

  if (!property) {
    return ' ';
  }

  return [
    isManager ? booking?._links.booking_user?.display_name : null,
    property.city,
  ]
    .filter(Boolean)
    .join(' @ ');
}
