import React, { useEffect, useState } from 'react';
import { Keyboard, View } from 'react-native';
import { Portal, ThemeProvider } from 'react-native-paper';
import { COLOR_PRIMARY } from '../config';
import { BASE_THEME, DARK_BASE_THEME, MD2_BASE_THEME, MD2_DARK_BASE_THEME } from '../constants/Theming';
import useColorScheme from '../hooks/useColorScheme';

export interface ScreenProps {
  children: React.ReactNode;
}

export function Screen({ children }: ScreenProps): JSX.Element {
  const [offset, setOffset] = useState(0);
  const colorScheme = useColorScheme();

  const primary = COLOR_PRIMARY!;
  const accent = COLOR_PRIMARY;

  // const theme = colorScheme === 'dark' ? DARK_BASE_THEME : BASE_THEME;
  const theme = colorScheme === 'dark' ? MD2_DARK_BASE_THEME : MD2_BASE_THEME;

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardWillShow', (e) => {
      setOffset(e.endCoordinates.height);
    });
    const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setOffset(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return (
    // @ts-ignore
    <ThemeProvider
      theme={{
        ...theme,
        colors: { ...theme.colors, primary, secondary: accent },
      }}
    >
      <View style={{ flex: 1, position: 'relative', paddingBottom: offset }}>
        {children}
      </View>
    </ThemeProvider>
  );
}

export function TabbedScreen({ children }: ScreenProps): JSX.Element {
  const [offset, setOffset] = useState(0);
  const colorScheme = useColorScheme();

  const primary = COLOR_PRIMARY!;
  const accent = COLOR_PRIMARY;

    // const theme = colorScheme === 'dark' ? DARK_BASE_THEME : BASE_THEME;
    const theme = colorScheme === 'dark' ? MD2_DARK_BASE_THEME : MD2_BASE_THEME;

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardWillShow', (e) => {
      setOffset(e.endCoordinates.height);
    });
    const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setOffset(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return (
    // @ts-ignore
    <ThemeProvider
      theme={{
        ...theme,
        colors: { ...theme.colors, primary, secondary: accent },
      }}
    >
      <View style={{ flex: 1, position: 'relative', paddingBottom: offset }}>
        {children}
      </View>
    </ThemeProvider>
  );
}
