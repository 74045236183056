import { useCallback, useEffect, useState } from 'react';
import { i18n } from '../locale';

export function useLocale() {
  const [locale, setLocale] = useState(() => i18n.locale);

  useEffect(() => {
    return i18n.onChange((nextI18n) => setLocale(nextI18n.locale));
  }, []);

  return locale;
}

export function useSetLocale() {
  return useCallback((locale: string) => {
    i18n.locale = locale;
  }, []);
}

export function useSupportedLocales() {
  return i18n.availableLocales;
}
