import { useIsFocused, useNavigation } from '@react-navigation/core';
import Color from 'color';
import React, { Fragment, useEffect } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  RefreshControl,
  StyleSheet,
  View,
} from 'react-native';
import {
  Button,
  Caption,
  Card,
  Divider,
  List,
  Searchbar,
  Surface,
  Title,
} from 'react-native-paper';
import Animated, {
  runOnUI,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useTailwind } from 'tailwind-rn';
import { TabbedScreen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import {
  APPLAUSE_APP_NAME,
  COLOR_APPBAR_SURFACE,
  COLOR_PRIMARY_DARK,
} from '../config';
import { useConfiguration } from '../hooks/useConfiguration';
import { useWindowWidth } from '../hooks/useDimensions';
import { useSearchResults } from '../hooks/useSearchResults';
import { i18n } from '../locale';
import { useProperty } from '../properties/useProperties';
import { date } from '../utils/date';
import { hrefAsKeyExtractor } from '../utils/hrefAsKeyExtractor';
import { BookingCard } from './BookingCard';
import { EmptyState } from './EmptyState';
import { ApplauseBooking, useBookings } from './useBookings';
import { useReservation, useReservations } from './useReservations';

const EMPTY_DATA: ApplauseBooking['_links']['self'][] = [
  Object.freeze({ href: '#', status: 'empty' }),
];

export function BookingsScreen() {
  const isFocused = useIsFocused();
  const tailwind = useTailwind();

  const {
    data: allData,
    refetch,
    isFetching,
    isLoading,
  } = useBookings({
    enabled: isFocused,
  });

  const columnCount = useWindowWidth() > 720 ? 2 : 1;
  const { data, query, setQuery } = useSearchResults(allData, 'bookings');

  return (
    <TabbedScreen>
      <ScreenHeader title={i18n.t('bookings.list.title')} />
      <Surface
        style={[
          tailwind(
            'z-10 justify-center self-center w-full flex-row bg-white pl-2'
          ),
          { elevation: 2 },
        ]}
      >
        <Searchbar
          value={query}
          onChangeText={setQuery}
          style={[
            tailwind('justify-center self-center max-w-3xl w-full'),
            {
              elevation: 0,
              borderRadius: 0,
              width: '100%',
              includeFontPadding: false,
              minHeight: 54,
              display:
                (allData?.bookings._index.length || 0) > 6 ? 'flex' : 'none',
            },
          ]}
          placeholder={i18n.t('bookings.fields.search.placeholder')}
        />
      </Surface>
      <FlatList
        key={`columns-${columnCount}`}
        style={tailwind('flex-1')}
        contentContainerStyle={[
          { minHeight: 300 },
          tailwind('max-w-3xl self-center w-full p-3'),
        ]}
        ListFooterComponent={BookingsFooter}
        data={data.length === 0 ? EMPTY_DATA : data}
        renderItem={renderBooking}
        keyExtractor={hrefAsKeyExtractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
        horizontal={false}
        numColumns={columnCount}
      />
      <NewBooking />
    </TabbedScreen>
  );
}

function NewBooking(): JSX.Element | null {
  const { data } = useConfiguration();
  const { navigate } = useNavigation<any>();
  const focused = useIsFocused();
  const tailwind = useTailwind();

  const translate = useSharedValue(Platform.OS === 'web' ? 0 : 72);
  const translateInner = useSharedValue(Platform.OS === 'web' ? 24 : 72 + 24);

  useEffect(() => {
    runOnUI(() => {
      translate.value = withSpring(0);
      translateInner.value = withSpring(24);
    })();
  }, []);

  const boxStyle = useAnimatedStyle(() => {
    return { transform: [{ translateY: translate.value }] };
  }, [translate]);

  const innerStyle = useAnimatedStyle(() => {
    return { transform: [{ translateY: translateInner.value }] };
  }, [translateInner]);

  if (!data?.capabilities.can_book || !focused) {
    return null;
  }

  return (
    <Animated.View style={[tailwind('relative'), { height: 72 }, boxStyle]}>
      <Animated.View
        style={[
          tailwind('absolute bottom-0 left-0 right-0'),
          { height: 72 + 24, backgroundColor: COLOR_APPBAR_SURFACE },
          innerStyle,
        ]}
      >
        <Divider
          style={{
            height: StyleSheet.hairlineWidth,
            backgroundColor: new Color(COLOR_APPBAR_SURFACE).isDark()
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, .12)',
          }}
        />
        <View style={tailwind('py-4')}>
          <Button
            mode="contained"
            compact
            uppercase={false}
            style={{
              alignSelf: 'center',
            }}
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
              paddingHorizontal: 6,
            }}
            icon="beach"
            onPress={() => {
              navigate('BookingAreas');
            }}
          >
            {i18n.t('bookings.actions.new', { app: APPLAUSE_APP_NAME })}
          </Button>
        </View>
        <Divider
          style={{
            height: StyleSheet.hairlineWidth,
            backgroundColor: new Color(COLOR_APPBAR_SURFACE).isDark()
              ? 'rgba(255, 255, 255, 0.1)'
              : 'rgba(0, 0, 0, .12)',
          }}
        />
      </Animated.View>
    </Animated.View>
  );
}

function BookingsFooter(): JSX.Element | null {
  const { data } = useConfiguration();

  if (!data?.capabilities.can_own) {
    return null;
  }

  return <MyReservations />;
}

function MyReservations() {
  const tailwind = useTailwind();
  const { data } = useReservations({ notifyOnChangeProps: ['data'] });
  if (!data || data.reservations._index.length === 0) {
    return null;
  }

  const isLarge = true;

  return (
    <Fragment>
      <List.Subheader
        style={[
          tailwind('px-6'),
          { includeFontPadding: false, textAlignVertical: 'center' },
        ]}
      >
        {i18n.t('bookings.sections.reservations.title')}
      </List.Subheader>
      <Card
        style={[
          tailwind('w-full pb-2 mb-4'),
          isLarge ? {} : { borderRadius: 0 },
        ]}
      >
        {data.reservations._index.map((reservation) => (
          <ReservationListItem key={reservation.href} href={reservation.href} />
        ))}
      </Card>
    </Fragment>
  );
}

function ReservationListItem({ href }: { href: string }) {
  const tailwind = useTailwind();
  const { data: reservation } = useReservation(href, {
    notifyOnChangeProps: ['data'],
  });
  const { data: property } = useProperty(reservation?._links.property?.href, {
    notifyOnChangeProps: ['data'],
  });

  return (
    <Fragment>
      <View style={{ flexDirection: 'row', minHeight: 92 }}>
        <View style={{ flex: 1 }}>
          <View style={tailwind('mt-4 pl-6 pr-3')}>
            <Caption style={{ includeFontPadding: false }}>
              {i18n.t('bookings.fields.from.label')}
            </Caption>
            <Title>
              {date(reservation?.dates.from || undefined) ||
                i18n.t('bookings.states.tbd')}{' '}
            </Title>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <View style={tailwind('mt-4 pl-6 pr-3')}>
            <Caption style={{ includeFontPadding: false }}>
              {i18n.t('bookings.fields.to.label')}
            </Caption>
            <Title>
              {date(reservation?.dates.to || undefined) ||
                i18n.t('bookings.states.tbd')}{' '}
            </Title>
          </View>
        </View>
      </View>
      {property ? (
        <View style={tailwind('pl-6 pr-3 mb-2')}>
          <Caption
            style={[{ includeFontPadding: false, color: COLOR_PRIMARY_DARK }]}
          >
            @ {property.title}
          </Caption>
        </View>
      ) : null}
    </Fragment>
  );
}

function renderBooking({
  item,
}: ListRenderItemInfo<ApplauseBooking['_links']['self']>) {
  if (item === EMPTY_DATA[0]) {
    return (
      <View
        style={[
          {
            flex: 1,
            width: '100%',
            height: '100%',
            minHeight: 300,
            flexShrink: 0,
            alignItems: 'center',
          },
        ]}
      >
        <EmptyState />
      </View>
    );
  }

  return (
    <View style={[{ flex: 1, padding: 4, marginBottom: 4 }]}>
      <BookingCard href={item.href} placeholderTitle={item.search} />
    </View>
  );
}
