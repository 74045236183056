import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { FetchMediaError } from 'fetch-media';
import * as React from 'react';
import { useEffect, useReducer } from 'react';
import { View } from 'react-native';
import { ActivityIndicator, Caption, HelperText } from 'react-native-paper';
import { AssignmentScreen } from '../assignments/AssignmentScreen';
import { BookingAssignmentsScreen } from '../assignments/BookingAssignmentsScreen';
import { PropertyAssignmentsScreen } from '../assignments/PropertyAssignmentsScreen';
import { LoginScreen } from '../auth/LoginScreen';
import { OnboardingScreen } from '../auth/OnboardingScreen';
import { BookingAreasScreen } from '../bookings/BookingAreasScreen';
import { BookingScreen } from '../bookings/BookingScreen';
import { BookingExperienceScreen } from '../bookings/BookingsExperienceScreen';
import { InAppNotifications } from '../components/InAppNotifications';
import { SnackbarProvider } from '../components/SnackbarProvider';
import { APPLAUSE_APP_NAME } from '../config';
import { useAuth, useAuthRootPage, useIsAuthenticated } from '../hooks/useAuth';
import { useEndpoint } from '../hooks/useEndpoint';
import { i18n } from '../locale';
import { PropertyScreen } from '../properties/PropertyScreen';
import { ReservePropertyScreen } from '../properties/ReservePropertyScreen';
import { RequestServiceScreen } from '../services/RequestServiceScreen';
import { UpgradeScreen } from '../services/UpgradeScreen';
import { SettingsScreen } from '../settings/SettingsScreen';
import BottomTabNavigator from './BottomTabNavigator';
import { navigationConfigRef, navigationRef } from './refs';
import { useLinking } from './useLinking';

const RootStack = createStackNavigator();

export default function Navigation() {
  const href = useEndpoint('api');
  const isAuthenticated = useIsAuthenticated();
  const rootPath = useAuthRootPage();
  const { set } = useAuth();
  const linking = useLinking();
  const [waited, setWaited] = useReducer(() => {
    console.debug('Navigation has waited');
    return true;
  }, false);

  console.debug('Navigation render');

  useEffect(() => {
    console.debug('Navigation useEffect');

    const timeout = setTimeout(setWaited, 1250);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (linking instanceof FetchMediaError) {
      if (
        isAuthenticated !== undefined &&
        (linking.response.status === 401 || linking.response.status === 403)
      ) {
        set(null);
      }
    }
  }, [isAuthenticated, linking, set]);

  if ((isAuthenticated === undefined && !waited) || linking === undefined) {
    console.debug('Navigation render loading');

    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <ActivityIndicator />
        {waited ? (
          <Caption
            style={{
              textAlign: 'center',
              maxWidth: '80%',
              marginHorizontal: 'auto',
            }}
          >{`auth: ${isAuthenticated}, linking: ${linking}, href: ${href}`}</Caption>
        ) : null}
      </View>
    );
  }

  if (linking instanceof FetchMediaError) {
    console.debug('Navigation render error');

    return (
      <View
        style={{ flex: 1, justifyContent: 'center', marginHorizontal: 'auto' }}
      >
        <HelperText
          type="error"
          style={{
            includeFontPadding: false,
            textAlign: 'center',
            marginHorizontal: 'auto',
          }}
        >
          {linking.message}
          <Caption
            style={{
              textAlign: 'center',
              maxWidth: '80%',
              marginHorizontal: 'auto',
            }}
          >{`auth: ${isAuthenticated}, linking: ${linking}, href: ${href}`}</Caption>
        </HelperText>
      </View>
    );
  }

  navigationConfigRef.current = linking.config;

  return (
    <View style={{ flex: 1 }}>
      <SnackbarProvider>
        <NavigationContainer linking={linking} ref={navigationRef}>
          <RootNavigator defaultIsAuthenticated={Boolean(isAuthenticated)} authenticatedRootPage={rootPath} />
          <InAppNotifications />
        </NavigationContainer>
      </SnackbarProvider>
    </View>
  );
}

function RootNavigator({
  defaultIsAuthenticated,
  authenticatedRootPage,
}: {
  defaultIsAuthenticated: boolean;
  authenticatedRootPage: null | string;
}) {
  console.debug('Navigation render RootNavigator');

  return (
    <RootStack.Navigator
      initialRouteName={defaultIsAuthenticated ? authenticatedRootPage || 'Tabs' : 'Login'}
      screenOptions={{ headerShown: false }}
    >
      <RootStack.Screen
        name="Tabs"
        component={BottomTabNavigator}
        options={{ title: `${i18n.t('dashboard.title')} · James BnBButler` }}
      />
      <RootStack.Screen
        name="Assignment"
        component={AssignmentScreen}
        options={{
          title: `${i18n.t('assignment.show.title', {
            defaultValue: 'Your request',
          })} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="Booking"
        component={BookingScreen}
        options={{
          title: `${i18n.t('bookings.show.title')} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="BookingAreas"
        component={BookingAreasScreen}
        options={{
          title: `${i18n.t('bookings.pick.title', {
            app: APPLAUSE_APP_NAME,
          })} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="BookingExperience"
        component={BookingExperienceScreen}
        options={{
          title: `${i18n.t('bookings.pick.title', {
            app: APPLAUSE_APP_NAME,
          })} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="BookingAssignments"
        component={BookingAssignmentsScreen}
        options={{
          title: `${i18n.t('bookings.assignments.title')} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="Property"
        component={PropertyScreen}
        options={{
          title: `${i18n.t('properties.title')} Details · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="PropertyAssignments"
        component={PropertyAssignmentsScreen}
        options={{
          title: `${i18n.t('properties.assignments.title')} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="Upgrade"
        component={UpgradeScreen}
        options={{
          title: `${i18n.t('services.request.title', {
            slug: 'service',
          })} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="RequestService"
        component={RequestServiceScreen}
        options={{
          title: `${i18n.t('services.request.title', {
            slug: 'service',
          })} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="ReserveProperty"
        component={ReservePropertyScreen}
        options={{
          title: `${i18n.t('property-reservation.title')} · James BnBButler`,
        }}
      />
      <RootStack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{ title: `${i18n.t('settings.title')} · James BnBButler` }}
      />
      <RootStack.Screen
        name="Onboarding"
        component={OnboardingScreen}
        options={{ title: `James BnBButler` }}
      />
      <RootStack.Screen
        name="Login"
        component={LoginScreen}
        options={{ title: `James BnBButler` }}
      />
    </RootStack.Navigator>
  );
}
