import iframe from '@native-html/iframe-plugin';
import table, {
  IGNORED_TAGS as IGNORED_TAGS_TABLE,
} from '@native-html/table-plugin';
import React, { useCallback } from 'react';
import HTML, { ContainerProps, IGNORED_TAGS } from 'react-native-render-html';
import WebView from 'react-native-webview';
import { useDimensions } from '../hooks/useDimensions';

const RENDERERS = {
  iframe,
  table,
};

function HTMLFullWidth_({
  children,
  maxWidth = 720,
  ...props
}: { children: string; maxWidth?: number } & Omit<
  ContainerProps,
  'source' | 'contentWidth' | 'computeEmbeddedMaxWidth'
>) {
  const contentWidth = useDimensions().width;
  const computeEmbeddedMaxWidth = useCallback(
    (availableWidth: number) => {
      return Math.min(availableWidth, maxWidth);
    },
    [maxWidth]
  );

  return (
    <HTMLContent
      contentWidth={contentWidth}
      computeEmbeddedMaxWidth={computeEmbeddedMaxWidth}
      {...props}
    >
      {children}
    </HTMLContent>
  );
}

export const HTMLFullWidth = React.memo(HTMLFullWidth_);

export function HTMLContent({
  children: htmlContent,
  renderers = {},
  ...props
}: {
  children: string;
} & Omit<ContainerProps, 'source'>) {
  return (
    <HTML
      renderers={{ ...RENDERERS, ...renderers }}
      WebView={WebView}
      ignoredTags={[...IGNORED_TAGS, ...IGNORED_TAGS_TABLE]}
      source={{ html: htmlContent }}
      defaultWebViewProps={{}}
      renderersProps={{
        table: {},
        iframe: { scalesPageToFit: true, webViewProps: {} },
      }}
      {...props}
    />
  );
}
