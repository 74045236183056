import { APPLAUSE_ENDPOINT } from '../config';

export function useEndpoint(path: string, params?: Record<string, string>) {
  const url = [APPLAUSE_ENDPOINT, path].join('/');
  if (!params) {
    return url;
  }

  const query = Object.keys(params)
    .reduce(
      (result: string[], key) =>
        result.concat(
          [encodeURIComponent(key), encodeURIComponent(params[key])].join('=')
        ),
      []
    )
    .join('&');

  return [url, query].join('?');
}
