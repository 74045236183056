import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseUserSummary = {
  _links: {
    self: {
      href: string;
    };
  };
  display_name: string;
};

type ApplauseUserSummaryResponse = {
  user: ApplauseUserSummary;
};

export type ApplauseBookingUsersIndex = {
  guests: {
    _links: {
      self: { href: string };
    };
    _index: ApplauseUserSummary['_links']['self'][];
  };
};

export function useGuests(
  href: string | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseBookingUsersIndex, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseBookingUsersIndex, FetchMediaError>(
    [locale, 'booking', href?.split('/')?.slice(-2).shift() || '-', 'guests'],
    {
      queryFn: async () =>
        fetchMedia(href!, {
          headers: {
            accept: 'application/vnd.bnbbutler.guest.v1.index+json',
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
        }).then((response) => response as ApplauseBookingUsersIndex),
      enabled: !!auth.current?.['access-token'] && enabled && !!href,
      staleTime: 60 * 1000,
    }
  );
}

export function bookingCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'booking', `${href?.split('/').pop() || '-'}`];
}

export function userCacheKey(
  href: string | null | undefined,
  locale: string
): QueryKey {
  return [locale, 'user', `${href?.split('/').pop() || '-'}`];
}

export function useUser(
  href: string | null | undefined,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseUserSummary, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(userCacheKey(href, locale), {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: 'application/vnd.bnbbutler.user.v1.summary+json',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      })
        .then((response) => response as ApplauseUserSummaryResponse)
        .then(({ user }) => user),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    ...options,
  });
}
