import { useIsFocused } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, Modal, Text } from 'react-native';
import { Button, Menu } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useLogout, useDeleteAccount } from '../hooks/useAuthenticate';
import {
  useLocale,
  useSetLocale,
  useSupportedLocales,
} from '../hooks/useLocale';
import { i18n } from '../locale';
import { useMe, useUpdateMe } from './useMe';

export function SettingsScreen() {
  useForceAuthenticated();

  const tailwind = useTailwind();
  const isFocused = useIsFocused();

  const { mutateAsync: doLogout, isLoading } = useLogout();
  const { mutateAsync: doDelete, DeleteIsLoading } = useDeleteAccount();
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);
  const locale = useLocale();
  const setLocale = useSetLocale();
  const [selectingLocale, setSelectingLocale] = useState(false);
  const supportedLocales = useSupportedLocales();
  const { mutateAsync: updateUser, isLoading: isUpdating } = useUpdateMe();
  const { data: user, isLoading: isLoadingMe } = useMe({
    enabled: isFocused,
    onSuccess: (data) => {
      if (locale !== data.user.locale) {
        setLocale(data.user.locale);
      }
    },
  });

  const handleDeleteConfirmation = () => {
    doDelete()
    setDeletionModalVisible(false);
  }

  return (
    <Screen>
      <ScreenHeader title={i18n.t('settings.title')} showBack hideSettings />
      <View
        style={[
          tailwind('flex-1'),
          tailwind('max-w-3xl self-center w-full pt-4'),
        ]}
      >
        <View style={tailwind('mt-8 mx-6')}>
          <Menu
            onDismiss={() => setSelectingLocale(false)}
            visible={selectingLocale}
            anchor={
              <Button
                mode="contained"
                disabled={selectingLocale || isLoadingMe || isUpdating}
                icon="translate"
                onPress={() => {
                  setSelectingLocale(true);
                }}
                uppercase={false}
                style={[{ minWidth: 100 }]}
                labelStyle={{
                  includeFontPadding: false,
                  paddingHorizontal: 6,
                  textAlignVertical: 'center',
                }}
              >
                {i18n.t('settings.fields.locale.label', {
                  locale: i18n.locale,
                })}
              </Button>
            }
          >
            {supportedLocales.map((locale) => (
              <Menu.Item
                key={locale}
                onPress={() => {
                  updateUser({ user: { locale } });
                  setSelectingLocale(false);
                  setLocale(locale);
                }}
                title={locale}
              />
            ))}
          </Menu>
        </View>

        <Button
          disabled={isLoading}
          loading={isLoading}
          onPress={() => doLogout()}
          uppercase={false}
          mode="contained"
          style={[
            tailwind('mt-2 mx-6'),
            { minWidth: 100 },
          ]}
          labelStyle={{
            includeFontPadding: false,
            paddingHorizontal: 6,
            textAlignVertical: 'center',
          }}
          icon="cancel"
        >
          {i18n.t('settings.actions.sign-out')}
        </Button>
      </View>
      <Button
          disabled={isLoading}
          loading={isLoading}
          onPress={() => setDeletionModalVisible(true)}
          uppercase={false}
          mode="contained-tonal"
          style={[
            tailwind('mb-8'),
            { minWidth: 100 },
          ]}
          labelStyle={{
            includeFontPadding: false,
            paddingHorizontal: 6,
            textAlignVertical: 'center',
          }}
          icon="delete"
        >
          {i18n.t('settings.actions.delete-account')}
      </Button>
      <Modal
        visible={deletionModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setDeletionModalVisible(false)}
      >
        <View style={[tailwind('flex-1'), tailwind('justify-center'), tailwind('items-center')]}>
          <View style={[tailwind('p-4'), tailwind('bg-white'), tailwind('rounded-lg')]}>
            <Text style={tailwind('text-lg font-bold mb-2')}>
              Confirm Account Deletion
            </Text>
            <Text style={tailwind('text-sm mb-4')}>
            Are you sure you want to delete your account? You will be permanently logged out, and your data will be deleted within 30 days - This process is irreversible.
            </Text>
            <Button
              mode="contained"
              onPress={() => {
                handleDeleteConfirmation();
              }}
            >
              Yes, Delete Account
            </Button>
            <Button
              mode="outlined"
              onPress={() => setDeletionModalVisible(false)}
              style={tailwind('mt-2')}
            >
              Cancel
            </Button>
          </View>
        </View>
      </Modal>
    
    </Screen>
  );
}
