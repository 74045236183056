import { useIsFocused, useNavigation } from '@react-navigation/core';
import React from 'react';
import { Image, View } from 'react-native';
import { Card, Text, useTheme } from 'react-native-paper';
import { localeDateString } from 'react-native-time-helpers';
import { useTailwind } from 'tailwind-rn';
import { encode } from '../base';
import { useColor } from '../hooks/useColor';
import {
  ApplauseConfiguration,
  useConfiguration,
} from '../hooks/useConfiguration';
import { i18n } from '../locale';
import { ApplauseProperty, useProperty } from '../properties/useProperties';
import { ApplauseBooking, useBooking } from './useBookings';

export function BookingListItem({
  href,
  roundTop,
  roundBottom,
}: {
  href: string | null | undefined;
  roundTop?: boolean;
  roundBottom?: boolean;
}) {
  const tailwind = useTailwind();
  const enabled = useIsFocused();
  const { roundness } = useTheme();

  const { navigate } = useNavigation<any>();
  const { data: configuration } = useConfiguration({
    enabled,
    notifyOnChangeProps: ['data'],
  });
  const { data: booking, error } = useBooking(href, {
    enabled,
    notifyOnChangeProps: ['data', 'error'],
  });
  const { data: property } = useProperty(booking?._links.property?.href, {
    enabled,
    notifyOnChangeProps: ['data'],
  });

  const fromDateString = booking?.dates.checked_in || booking?.dates.check_in;
  const toDateString = booking?.dates.checked_out || booking?.dates.check_out;

  const sameYear = true;
  const from = fromDateString
    ? localeDateString(new Date(fromDateString), false, !sameYear)
    : i18n.t('bookings.states.tbd');
  const to = toDateString
    ? localeDateString(new Date(toDateString), false, true)
    : i18n.t('bookings.states.tbd');

  const red700 = useColor('text-red-700');
  const red600 = useColor('text-red-600');

  if (error) {
    return (
      <Card
        style={[
          tailwind('p-4 bg-red-200'),
          {
            borderTopEndRadius: roundTop ? roundness : 0,
            borderTopStartRadius: roundTop ? roundness : 0,
            borderBottomEndRadius: roundBottom ? roundness : 0,
            borderBottomStartRadius: roundBottom ? roundness : 0,
          },
        ]}
        elevation={1}
      >
        <Card.Title
          title={i18n.t('bookings.states.errors.display')}
          titleVariant='titleMedium'
          subtitle={error.message}
          titleStyle={{ color: red700 }}
          subtitleStyle={{ color: red600, opacity: 0.8 }}
        />
      </Card>
    );
  }

  const image = property?.images.square[0] || property?.images.large[0];

  return (
    <Card
      style={[
        tailwind('p-4'),
        {
          borderTopEndRadius: roundTop ? roundness : 0,
          borderTopStartRadius: roundTop ? roundness : 0,
          borderBottomEndRadius: roundBottom ? roundness : 0,
          borderBottomStartRadius: roundBottom ? roundness : 0,
        },
      ]}
      elevation={1}
      onPress={() => href && navigate('Booking', { href: encode(href) })}
    >
      <View style={{ flexDirection: 'row' }}>
        {image ? (
          <Image
            source={{
              uri: image,
              width: 400,
              height: 300,
            }}
            resizeMode="cover"
            style={{ borderRadius: 12, width: 100, height: 100 }}
          />
        ) : (
          <View
            style={{
              width: 100,
              height: 100,
              borderRadius: 12,
              backgroundColor: '#222',
            }}
          />
        )}

        <View style={[tailwind('-mt-4 flex-1')]}>
          <Card.Title
            title={titleFor(property, booking)}
            titleVariant='titleMedium'
            subtitle={subtitleFor(property, booking, configuration)}
          />

          <View style={tailwind('pl-4')}>
            <Text>
              {from} - {to}
            </Text>
          </View>
        </View>
      </View>
    </Card>
  );
}

function titleFor(
  property: ApplauseProperty | undefined,
  booking: ApplauseBooking | undefined
): string {
  if (!booking) {
    return ' ';
  }

  if (!property) {
    return booking._links.self.reference || ' ';
  }

  if (!booking._links.self.reference) {
    if (!property._links.self.reference) {
      return property.city;
    }

    return `[${property._links.self.reference}] ${property.city}`;
  }

  return `[${
    property._links.self.reference || booking._links.self.reference
  }] ${property.city}`;
}

function subtitleFor(
  property: ApplauseProperty | undefined,
  booking: ApplauseBooking | undefined,
  configuration: ApplauseConfiguration | undefined
): string {
  const isManager =
    configuration?.capabilities.can_manage ||
    configuration?.capabilities.can_own;

  if (!property) {
    return ' ';
  }

  return [
    isManager ? booking?._links.booking_user?.display_name : null,
    property.city,
  ]
    .filter(Boolean)
    .join(' @ ');
}
