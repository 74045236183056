import React from 'react';
import Svg, {
  Defs,
  Ellipse,
  LinearGradient,
  Path,
  Stop,
  SvgProps,
} from 'react-native-svg';

export const EmptyState = (props: SvgProps = {}) => (
  <Svg viewBox="0 0 400 300" {...props}>
    <Defs>
      <LinearGradient
        id="a"
        x1={312.36}
        x2={323.31}
        y1={200.93}
        y2={187.31}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.02} stopColor="#da94ff" stopOpacity={0} />
        <Stop offset={1} stopColor="#9d3fff" />
      </LinearGradient>
      <LinearGradient id="b" x1={154.22} x2={190.07} y1={259.92} y2={215.34} />
      <LinearGradient id="c" x1={98.99} x2={119.63} y1={184.23} y2={164.77} />
    </Defs>
    <Path
      fill="#cb9bea"
      d="M196.24,288.6,23.74,186.65c-5.49-3.08-10.2-8.15-4.85-11.46L192,75.41c2.6-1.61,6.54-2.66,9.18-1.13L382.28,179.37c5.49,3.19,6.48,12.1,1,15.29L219,288.44A22.94,22.94,0,0,1,196.24,288.6Z"
    />
    <Path
      fill="url(#a)"
      d="M340.42,189.49l-9.73-10.59L301,191.61l18.06,10.46C322.2,199.92,337.4,191.83,340.42,189.49Z"
    />
    <Path
      fill="url(#b)"
      d="M253.56,219.45,161.8,201.08,113.94,227l64,37.06C189,256.39,242.89,227.73,253.56,219.45Z"
    />
    <Path
      fill="url(#c)"
      d="M135.14,181.8,100,163.56,66.22,183.47,102,205C113.11,197.42,124.47,190.08,135.14,181.8Z"
    />
    <Path
      fill="#68e1fd"
      d="M320.33,160s-2.11,25,11.22,29.4S351.54,181,351,159"
    />
    <Ellipse
      cx={335.68}
      cy={159.5}
      fill="#f2696a"
      rx={15.36}
      ry={8.88}
      transform="rotate(-1.82 336.25920238 159.624897)"
    />
    <Path
      fill="#8ccc00"
      d="M339 139.63s-6.3-8.51-3.87-14.61c0 0 2-3.93 5.1-4.65 0 0 0 8.26 3 7.11s-.27-8.63-.27-8.63 3.09-4.47 6.62-4.45.25 7.71 2.66 6.38-.09-7.71 2.65-8.56a58.06 58.06 0 017.64-1.47s2.85 7.44.27 8.6-6.4 1.65-4.51 3.25 7.06-.47 7.06-.47.46 4.22-1.26 6.11-12.29-1.83-10.33 1.75 8.81 1.54 8.81 1.54-3.37 5.16-7.44 7.6S339 139.63 339 139.63zM341.09 161.36s-4.49-4.16-3.75-7.88c0 0 .74-2.45 2.4-3.18 0 0 .86 4.69 2.46 3.72s-1.08-4.87-1.08-4.87 1.27-2.87 3.29-3.23 1 4.35 2.19 3.34-.87-4.37.59-5.14a33.54 33.54 0 014.18-1.65s2.41 3.92 1.07 4.85-3.46 1.62-2.21 2.32 4-1 4-1 .71 2.35-.06 3.6-7.17.27-5.68 2.1 5.17-.07 5.17-.07a16.35 16.35 0 01-3.42 5.11C348.14 161.17 341.09 161.36 341.09 161.36zM324.79 152.58s-10.56.75-14.4-4.59c0 0-2.22-3.84-1.18-6.8 0 0 6.94 4.47 7.6 1.29s-7.43-4.41-7.43-4.41-2.11-5-.19-8 6.64 3.94 6.81 1.18-6.55-4.06-5.8-6.84a58.06 58.06 0 012.86-7.23s7.81 1.59 7.4 4.39-2.05 6.29.32 5.55 3.39-6.21 3.39-6.21 3.81 1.88 4.48 4.34-8.15 9.38-4.08 9.65 6-6.61 6-6.61 2.54 5.62 2.41 10.36S324.79 152.58 324.79 152.58z"
    />
    <Path
      fill="#06577a"
      d="M360.32 114.16q-1.64 1.48-3.29 3c.08-1.2.18-2.4.33-3.6.08-.63-.89-.87-1-.24q-.3 2.4-.42 4.82c-2.88 2.69-5.74 5.51-8.38 8.48a35.69 35.69 0 01-.38-6.1c0-.64-1-.61-1 0a36.71 36.71 0 00.52 7A69.17 69.17 0 00342 133.7a19.81 19.81 0 01-3.68-7.87c-.14-.63-1.09-.33-1 .3a20.49 20.49 0 004 8.34.51.51 0 00.1.09c-4.2 6.37-7 13.45-7 21.56 0 .64 1 .61 1 0 0-7.8 2.68-14.65 6.71-20.81a38.56 38.56 0 0012.42.81c.64-.06.61-1.06 0-1a37.78 37.78 0 01-11.8-.71 72.53 72.53 0 016.7-8.31l.09 0c2.34.13 4.68.27 7 .28a8.55 8.55 0 004.33-.64c.57-.31 0-1.15-.53-.85-1.59.86-3.86.51-5.59.46-1.48 0-3-.12-4.45-.2 3.45-3.64 7.16-7 10.75-10.29C361.53 114.41 360.8 113.73 360.32 114.16zM330 134.6a.5.5 0 00-1-.24 17.71 17.71 0 01-6.12 9.42q-1.85-4.66-3.41-9.43a29.87 29.87 0 005.6-8.46c.26-.59-.62-1.07-.88-.48a29.1 29.1 0 01-5.08 7.83c-1.16-3.64-2.2-7.33-3.1-11.05-.15-.63-1.11-.33-1 .3q1.84 7.65 4.42 15.06a23.45 23.45 0 01-5.51-1.61c-.59-.25-1.07.62-.48.88a24.53 24.53 0 006.37 1.81h0c1.16 3.26 2.44 6.47 3.81 9.64a24.18 24.18 0 01-8.75-1.64c-.59-.23-1.08.65-.48.88a25.51 25.51 0 009.65 1.76q2.6 5.93 5.67 11.66c.31.57 1.15 0 .85-.53a156.06 156.06 0 01-7.35-15.64h0A18.81 18.81 0 00330 134.6zM348.48 146.95l-2.6 4.7a8.62 8.62 0 01-.92-1.41c-.3-.57-1.14 0-.85.53a9.53 9.53 0 001.21 1.78l0 0-2.82 5.09a2 2 0 01-.65-1.18c-.1-.63-1.1-.6-1 0a3.15 3.15 0 001.16 2l-3.53 6.37a.5.5 0 00.88.48l4.65-8.4a7.47 7.47 0 002.42-.44c.6-.22.31-1.18-.3-1a6.65 6.65 0 01-1.54.34l4.71-8.51A.5.5 0 00348.48 146.95z"
    />
    <Ellipse
      cx={191.23}
      cy={124.08}
      fill="#5d5e63"
      rx={11.45}
      ry={8.3}
      transform="matrix(.26 -.97 .97 .26 21.98 276.78)"
    />
    <Path
      fill="#2b415d"
      d="M253.56,219.45s-1.39-22.3-1.91-27.81c-.47-5-2.13-26.9-15.84-33.82-17.32-8.75-37.29-20.1-55.73-14.81-15,4.29-20.05,56-19.89,59.49C160.49,208.69,206.87,241.47,253.56,219.45Z"
    />
    <Path
      fill="#68e1fd"
      d="M218.14,160.48c0,1.18-1.37,2.95-1.9,3.95-4.45,8.46-8.34,17.41-12.59,26-4.15,8.45-4.92,32.19-5.41,33.74,0,.16-17-6.17-17.13-6.08s-1.4-12.28-1.42-13c-.2-13.54-1.19-20.45,1.29-30.87,1.76-7.4,5-12.41,6.68-19.8.81-3.53,1.37-7.75,3.14-11C192.78,139.87,218.14,152.56,218.14,160.48Z"
    />
    <Path
      fill="#17283a"
      d="M225.77 226.84c-.46 0-.92.06-1.39.07q.2-14 .08-28a1.53 1.53 0 013.06 0q.13 13.89-.07 27.77zM175.23 215.15h0a2 2 0 00-.32-.15l-.83-.45c-.32-.19-.65-.36-1-.53l-.81-.45q.06-16.61 1-33.2c.11-2 3.17-2 3.06 0q-1 17.09-1 34.21A1.53 1.53 0 01175.23 215.15z"
    />
    <Path
      fill="#2b415d"
      d="M190.81,143.46s-3.32,22.66-1.43,25l7.76-6.08,4.62,9.65,19.3-16.76Z"
    />
    <Path
      fill="#ffac73"
      d="M187.41,108.63s.07,14.48,0,19.87c-.08,5.67,2.32,21,11.65,20.51s18.89-11.34,18.89-13.82c0,0,9.43-5.87,4.1-10.38s-5.74,3.12-5.74,3.12-2.89-4.2-3.09-15.47C213.22,112.45,199.91,114.55,187.41,108.63Z"
    />
    <Path
      fill="#ffac73"
      d="M217.52,150.21c-4.76,4.39-12.5,5.2-19.65,4.18a2.33,2.33,0,0,1-2.15-2c-.12-2.19-.25-4.38-.44-6.57l24.6-12L218,149.44S217.81,149.74,217.52,150.21Z"
    />
    <Path
      fill="#e5894a"
      d="M200.64,154.68c-1-.07-1.91-.17-2.86-.31a2.25,2.25,0,0,1-2.07-2c-.06-1.14-.11-2.28-.19-3.42,5.21,0,14.48-1.23,19.37-10.87A21,21,0,0,1,200.64,154.68Z"
    />
    <Path
      fill="#4b1b13"
      d="M249.56,149c-8.71-9.62-12.9-28.74-12.9-28.74l-.11,0c-2.46-21-17.45-27.39-30.57-28.13-13.3-.75-23.05,4.23-23.75,13s5.21,12.54,5.21,12.54,22.22,6.43,23.68,3.69,1.75-15.43,3.05,1.19c.43,5.49.32,11,.28,16.5a119.14,119.14,0,0,0,1.69,20c.8,4.95,1.54,10.46,6.36,13.15,7.3,4.09,17.78,4.1,25-.35a319,319,0,0,1,5.45,37.31s15.3-1,17-23.74S258.26,158.64,249.56,149Z"
    />
    <Path
      fill="#5d5e63"
      d="M219.87,119.37s5-15.85-6.2-22.91c-10.28-6.51-18.15-3.53-18.15-3.53s6.47-5.75,18.13-2,14.93,15.18,12.48,29.17"
    />
    <Path
      fill="#2b2d33"
      d="M215.6,134.25c-1.68,2.86-3.41,5.66-6.62,6.93a16.7,16.7,0,0,1-6.8.87,2.86,2.86,0,0,0-4.59-1.87,1.19,1.19,0,0,0-.72.46,3,3,0,0,0,2.31,4.8,2.91,2.91,0,0,0,2.58-1.42,18.5,18.5,0,0,0,8.6-1.24c3.28-1.52,5.19-4.52,7-7.53A1,1,0,0,0,215.6,134.25Z"
    />
    <Ellipse
      cx={221.06}
      cy={128.1}
      fill="#5d5e63"
      rx={11.45}
      ry={8.3}
      transform="rotate(-75.03 221.07068355 128.10285198)"
    />
    <Path
      fill="#5d5e63"
      d="M218.1,139.17a11.3,11.3,0,0,0,13.08-7.93C234.68,121.17,224,117,224,117"
    />
    <Ellipse
      cx={224.38}
      cy={129.5}
      fill="#2b2d33"
      rx={5.62}
      ry={4.31}
      transform="rotate(-64.16 224.37086822 129.4942023)"
    />
    <Path
      fill="#68e1fd"
      d="M170.88,82.09,107.08,45.51a2.69,2.69,0,0,0-4,2.33V71.42a6.44,6.44,0,0,0,3.3,5.62l61.89,34.64a2.69,2.69,0,0,0,4-2.34V84.42A2.69,2.69,0,0,0,170.88,82.09Z"
    />
    <Path
      fill="#68e1fd"
      d="M166.34 106.66L166.34 120.48 154.8 103.28M111.44 57.98L145.31 76.87"
    />
    <Path
      fill="#f2f2f2"
      d="M110.69,59.28l33.86,18.89c1.69.94,3.2-1.65,1.51-2.59L112.2,56.69c-1.69-.94-3.2,1.65-1.51,2.59Z"
    />
    <Path fill="#68e1fd" d="M109.97 67.43L161.15 95.97" />
    <Path
      fill="#f2f2f2"
      d="M109.21,68.72l51.19,28.55c1.69.94,3.2-1.65,1.51-2.59L110.72,66.13c-1.69-.94-3.2,1.65-1.51,2.59Z"
    />
    <Path
      fill="#bc6608"
      d="M133.74,182.45l1.82-1V157.81s-15.73-27.37-16.13-27.1-18.75,9.3-18.75,9.3v21.51"
    />
    <Path
      fill="#ffcd5e"
      d="M100.68 140L100.68 163.85 111.76 159.16 100.68 140zM135.56 181.47V157.81l-15.16 7.05 13.33 17.59C133.84 182.4 135.45 181.52 135.56 181.47z"
    />
    <Path
      fill="#ffe4a7"
      d="M100.68 163.85L133.74 182.45 120.4 164.86 111.76 159.16 100.68 163.85z"
    />
    <Path
      fill="#68e1fd"
      d="M103.07 144.14L103.07 130.7 131.76 146.15 131.76 160.06 120.4 164.86 111.76 159.16 103.07 144.14z"
    />
    <Path
      fill="#fff"
      d="M122.81,149.4a9.15,9.15,0,0,1,.5,3.48,4.89,4.89,0,0,1-.51,2.09,2.2,2.2,0,0,1-1.16,1.09,1.85,1.85,0,0,1-1.49-.15,2.87,2.87,0,0,1-1.1-1,2.75,2.75,0,0,1-.44-1.49,1.64,1.64,0,0,1-.92.76,1.76,1.76,0,0,1-1.28-.22,3.22,3.22,0,0,1-1.11-1,4.43,4.43,0,0,1-.71-1.44,5.09,5.09,0,0,1-.21-1.71,3.32,3.32,0,0,1,0-.41,3.41,3.41,0,0,1,.58-1.72,1.85,1.85,0,0,1,1.21-.77,2.51,2.51,0,0,1,1.6.31,4.29,4.29,0,0,1,2.33,4,5.34,5.34,0,0,1,0,.55l-.22,1.29c0,.14,0,.24,0,.29a1.08,1.08,0,0,0,.11.59.88.88,0,0,0,.39.35.69.69,0,0,0,.68,0,1.31,1.31,0,0,0,.55-.73,4.43,4.43,0,0,0,.27-1.48,7,7,0,0,0-.36-2.58,6.68,6.68,0,0,0-1.29-2.26,7.11,7.11,0,0,0-2.18-1.68,4.25,4.25,0,0,0-2.66-.57,2.54,2.54,0,0,0-1.75,1.28,5.82,5.82,0,0,0-.7,2.72,7.24,7.24,0,0,0,4.12,7.06l-.33,1.32a10,10,0,0,1-2.94-2.29,9.14,9.14,0,0,1-1.77-3.1,10.21,10.21,0,0,1-.49-3.71,6.08,6.08,0,0,1,1-3.44,3.39,3.39,0,0,1,2.44-1.4,6,6,0,0,1,3.33.77,9.56,9.56,0,0,1,2.8,2.14A8.78,8.78,0,0,1,122.81,149.4Zm-4.38,2.87a2.68,2.68,0,0,0,.29-1.22,2.62,2.62,0,0,0-.3-1.41,2.12,2.12,0,0,0-.91-.9,1.07,1.07,0,0,0-.81-.12,1,1,0,0,0-.62.53,2.83,2.83,0,0,0-.27,1.19,2.75,2.75,0,0,0,.3,1.44,2.09,2.09,0,0,0,.88.9,1.16,1.16,0,0,0,.81.12,1.06,1.06,0,0,0,.64-.54"
    />
    <Path
      fill="#68e1fd"
      d="M273.97 37.67L272.68 53.65 267.73 46.34 264.96 42.24 259.81 34.61 273.97 37.67z"
    />
    <Path
      fill="#68e1fd"
      d="M286.64,41.76c12.82,7,22.53,23.84,22,38.82-.53,15.81-12.26,23.64-26.14,17.46s-24.74-24.09-24.21-39.9c.24-7.08,2.72-12.56,6.64-15.9l2.77,4.1c-3.31,2.84-5.42,7.48-5.62,13.49-.45,13.44,8.78,28.64,20.57,33.9s21.75-1.4,22.2-14.83c.42-12.6-7.66-26.75-18.38-32.8Z"
    />
    <Path
      fill="#ed1c24"
      d="M272 58.31a5.54 5.54 0 012.53 2.13 5.26 5.26 0 01.8 3 4.07 4.07 0 01-.75 2.28 5.82 5.82 0 01-1.77 1.63 8 8 0 01-2.22.91l4.72 2.1-.07 2L267.63 69l.06-1.83c3.54-1.18 5.34-2.68 5.4-4.48a2.85 2.85 0 00-.3-1.43 2.12 2.12 0 00-1.06-.95q-1.51-.67-1.68 1.63L268 61c.11-1.42.53-2.35 1.27-2.78A2.93 2.93 0 01272 58.31zM292.27 63.36L287.47 81.14 285.4 80.22 290.19 62.44 292.27 63.36zM283.92 71.7l1.41.63-.07 2.12-1.41-.63-.08 2.47-2.17-1 .08-2.47-5.3-2.36.07-2 5.42-5.62 2.3 1zm-2-.89l.17-5-3.43 3.5 3.26 1.46M299.34 70.67L299.28 72.49 294.98 81.29 292.75 80.3 297.09 71.8 292.09 69.57 292.16 67.47 299.34 70.67z"
    />
  </Svg>
);
