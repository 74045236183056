import { Locale } from 'expo-localization';
import { fetchMedia, FetchMediaError } from 'fetch-media';
import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
  useConfiguration,
  useConfigurationEndpoint,
} from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

type ApplauseUser = {
  _links: {
    self: {
      href: string;
    };
  };
  display_name: string;
  email: string;
  locale: string;
};

type ApplauseUserResponse = {
  user: ApplauseUser;
};

export function useMe({
  enabled = true,
  ...options
}: UseQueryOptions<ApplauseUserResponse, FetchMediaError> = {}) {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_user');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery([locale, 'me'] as QueryKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.user.v1+json'].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      }).then((response) => response as ApplauseUserResponse),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    ...options,
  });
}

type ApplauseUserUpdate = {
  user: {
    locale: string;
  };
};

export function useUpdateMe() {
  const { data: configuration } = useConfiguration();
  const href = useConfigurationEndpoint(configuration, 'my_user');
  const auth = useAuth();
  const locale = useLocale();
  const queryClient = useQueryClient();

  return useMutation<ApplauseUserResponse, FetchMediaError, ApplauseUserUpdate>(
    (data: ApplauseUserUpdate) => {
      return fetchMedia(href!, {
        headers: {
          accept: ['application/vnd.bnbbutler.user.v1+json'].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          contentType: 'application/vnd.bnbbutler.user.v1.update+json',
          ...(auth.current as Record<string, string>),
        },
        method: 'PUT',
        debug: __DEV__,
        body: data,
      }).then((response) => response as ApplauseUserResponse);
    },
    {
      async onSuccess(data, variables, context) {
        await queryClient.cancelQueries(['me']);
        queryClient.setQueryData(['me'], data);
      },
    }
  );
}
