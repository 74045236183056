import { CommonActions } from '@react-navigation/core';
import { NavigationContainer } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack';
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Image,
  Keyboard,
  TextInput as NativeTextInput,
  Platform,
  ScrollView,
  View,
} from 'react-native';
import { Button, Card, RadioButton, TextInput, Text } from 'react-native-paper';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useTailwind } from 'tailwind-rn';
import { COLOR_PRIMARY } from '../config';
import { useAuth, useIsAuthenticated } from '../hooks/useAuth';
import { useRegister } from '../hooks/useAuthenticate';
import { i18n } from '../locale';
import { config } from '../navigation/useLinking';
import { useNavigation } from '@react-navigation/core';
import { Native } from 'sentry-expo';
import { useEndpoint } from '../hooks/useEndpoint';
import { useLocale } from '../hooks/useLocale';
import { fetchMedia } from 'fetch-media';

export function OnboardingScreen({
  navigation: { dispatch },
}: StackScreenProps<any>) {
  const tailwind = useTailwind();
  const authenticated = useIsAuthenticated();

  const [phoneNumber, setPhoneNumber] = useState('')
  const [accountType, setAccountType] = useState('unknown')
  const [referrer, setReferrer] = useState('unknown')
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [navigatingAway, setNavigatingAway] = useState(false);
  const [offset, setOffset] = useState(0);
  const [passwordValid, setPasswordValid] = useState(true)
  const [emailValid, setEmailValid] = useState(true)
  const [passwordconfirmationValid, setPasswordConfirmationValid] = useState(true)
  const [registrationDataValid, setRegistrationDataValid] = useState(false)
  const {error: passwordError, isLoading: isLoadingRegistration, mutateAsync: doRegister } = useRegister();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const phoneNumberRef = useRef<NativeTextInput | null>(null);
  const nameFieldRef = useRef<NativeTextInput | null>(null);
  const scrollViewRef = useRef<ScrollView | null>(null)
  const passwordFieldRef = useRef<NativeTextInput | null>(null);
  const passwordConfirmationFieldRef = useRef<NativeTextInput | null>(null);

  console.debug('OnboardingScreen');
  
  const auth = useAuth();
  const { navigate } = useNavigation<any>();
  const href = useEndpoint('api/onboarding');
  const locale = useLocale();

  const checkRegistrationDataValidation = (createErrors: boolean, email: string, password: string, passwordConfirmation: string) => {
    var isOk = true;

    if (email.trim().length == 0) {
      isOk = false;
      setEmailValid(true);
    } else {
      // Must contain @ and at least one dot in domain
      let emailParts = email.split('@')
      if (emailParts.length == 2) {
        let domainParts = emailParts[1].split('.')
        if (domainParts.length > 1) {
          setEmailValid(true)
        } else {
          isOk = false;
          if (createErrors)
            setEmailValid(false);
        }
      } else {
        isOk = false;
        if (createErrors)
          setEmailValid(false)
      }
    }

    if (password.length == 0)
    {
      isOk = false;
      setPasswordValid(true)
    } else {
      if (password.length >= 6) {
        setPasswordValid(true)
      } else {
        isOk = false;
        if (createErrors)
          setPasswordValid(false)
      }
    }

    if (passwordConfirmation.length == 0)
    {
      isOk = false;
      setPasswordConfirmationValid(true)
    } else {
      if (passwordConfirmation == password && passwordConfirmation.length >= 6) {
        setPasswordConfirmationValid(true)
      } else {
        isOk = false;
        if (createErrors)
          setPasswordConfirmationValid(false)
      }
    }

    setRegistrationDataValid(isOk);
  }

  const goToLogin = () => {
    // Skip if nothing filled in
    setNavigatingAway(true);
    const timer = setTimeout(() => {

      dispatch(
        CommonActions.reset({ index: 0, routes: [{ name: 'Login' }] })
      );
    }, 500);
  }

  const doSubmit = () => {
    console.log('Registering 1')
    const nullifiedAccountType = accountType.trim() == 'unknown' ? null : accountType.trim()
    const nullifiedReferrer = referrer.trim() == 'unknown' ? null : referrer.trim()
    const nullifiedPhoneNumber = phoneNumber.trim().length == 0 ? null : phoneNumber.trim()
    const nullifiedName = name.trim().length == 0 ? null : name.trim()

    const register = () => doRegister({
      email,
      password,
      passwordConfirmation,
      account_type: nullifiedAccountType,
      referrer: nullifiedReferrer,
      phone_number: nullifiedPhoneNumber,
      name: nullifiedName,
    })
    .catch((e) => {
      //setLastError(e.data.errors.full_messages[0])
      console.error(JSON.stringify(e.data.errors.full_messages));
    }).finally(() => {
      setIsLoading(false);
    }).then(() => {
      setNavigatingAway(true);
      const timer = setTimeout(() => {
  
        dispatch(
          CommonActions.reset({ index: 0, routes: [{ name: 'Tabs' }] })
        );
      }, 500);
  
      return () => {  
        clearTimeout(timer);
      };
    })

    console.log('Registering 2')
    setIsLoading(true);
    register();
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardWillShow', (e) => {
      setOffset(e.endCoordinates.height / 3);
    });
    const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
      setOffset(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const fadeStyle = useAnimatedStyle(() => {
    return {
      opacity: navigatingAway ? 0 : 1,
    };
  }, [navigatingAway]);

  useLayoutEffect(() => {
    if (!authenticated) {
      return;
    }

    console.debug('useLayoutEffect navigating away');

    setNavigatingAway(true);
    const timer = setTimeout(() => {
      console.debug('useLayoutEffect timeout done');

      dispatch(
        CommonActions.reset({ index: 0, routes: [{ name: 'Tabs' }] })
      );
    }, 500);

    return () => {
      console.debug('useLayoutEffect cancel navigating away');

      clearTimeout(timer);
    };
  }, [dispatch, authenticated]);


  const boxStyle = useAnimatedStyle(() => {
    /**
   from={{ scale: 0.8 }}
    animate={{
      scale: navigatingAway ? 0.5 : 1,
      opacity: navigatingAway ? 0 : 1,
      translateY: -offset,
    }}
    transition={{
      type: 'timing',
      easing: navigatingAway
        ? Easing.in(Easing.cubic)
        : Easing.out(Easing.cubic),
      duration: 200,
    }}
    */
    return {};
  }, [navigatingAway]);

  const goTo = (page: number) => {
    nameFieldRef.current?.blur();
    phoneNumberRef.current?.blur();
    console.log("current: " + scrollViewRef.current)
    scrollViewRef.current?.scrollTo({x: page * (340 - 16), animated: true})
  }
  
  return (
    <Fragment>
      <Animated.View
        style={[
          tailwind('absolute top-0 left-0 bottom-0 right-0'),
          { backgroundColor: COLOR_PRIMARY },
          fadeStyle,
        ]}
      />

      <View style={tailwind('justify-center items-center p-4 flex-1')}>
        <Animated.View style={[{ position: 'absolute', transform: [{ translateY: -280 - offset / 4 }] }]}>
          <Image
            source={require('../../assets/images/logo.png')}
            style={{ width: 280, minHeight: 100 }}
            resizeMode="contain"
          />
        </Animated.View>

        <Animated.View
          style={[
            tailwind('w-full h-full'),
            {
              minWidth: 200,
              maxWidth: 400,
              height: 460 - offset,
              width: 340,
              transform: [{
                translateY: - offset,
              }]
            },
            boxStyle,
          ]}
        >
          <FormWrapper onSubmit={doSubmit}>
            <Animated.View style={fadeStyle}>
              <Card
                style={[tailwind('p-4 h-full'), { borderRadius: 4, paddingLeft: 0, paddingRight: 0, backgroundColor: 'transparent', shadowColor: 'transparent' }]}
                elevation={1}
              >
                <ScrollView
                  style={tailwind('w-full h-full')}
                  horizontal={true}
                  pagingEnabled={true}
                  scrollEnabled={false}
                  ref={scrollViewRef}
                >
                  <View style={{ width: 340 - 2 * 16, marginLeft: 16, height: '100%' }}>
                    <Text variant="titleMedium" style={{ marginTop: 24, textAlign: 'center' }}>{ ' ' }</Text>
                    <TextInput
                      ref={nameFieldRef}
                      keyboardType="default"
                      returnKeyType="next"
                      onSubmitEditing={() => goTo(1)}
                      textContentType="name"
                      autoComplete="name"
                      mode="outlined"
                      outlineColor='#fff2f6'
                      activeOutlineColor='#fff2f6'
                      selectionColor={COLOR_PRIMARY}
                      placeholder={i18n.t('onboarding.questions.name')}
                      style={[{marginTop: 'auto', marginBottom: 'auto', textAlign: 'center', backgroundColor: '#fff2f6'}]}
                      textAlign="center"
                      textColor='black'
                      value={name}
                      onChangeText={setName}
                      disabled={navigatingAway || isLoading}
                    />
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        paddingTop: 16,
                      }}
                    >
                      <Button
                        mode="outlined"
                        style={{
                          width: '110px',
                          marginEnd: 'auto',
                          position: 'relative',
                          borderColor: '#fff2f6'
                        }}
                        uppercase={false}
                        textColor='#fff2f6'
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goToLogin()}
                      >
                        {i18n.t('actions.back')}
                      </Button>
                      <Button
                        mode="contained-tonal"
                        style={{
                          width: '110px',
                          marginStart: 'auto',
                          position: 'relative',
                          backgroundColor: '#fff2f6',
                        }}
                        textColor={COLOR_PRIMARY}
                        uppercase={false}
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(1)}
                      >
                        {i18n.t('actions.continue')}
                      </Button>
                    </View>
                  </View>
                  <View style={{ width: 340 - 2 * 16, marginLeft: 16, height: '100%' }}>
                    <Text variant="titleMedium" style={{ marginTop: 24, textAlign: 'center' }}>{ ' ' }</Text>
                    <TextInput
                      keyboardType="email-address"
                      returnKeyType="next"
                      textContentType="emailAddress"
                      autoComplete="email"
                      mode="outlined"
                      placeholder={i18n.t('login.fields.email.label')}
                      style={[tailwind('mb-2'), { marginTop: 'auto', textAlign: 'center', backgroundColor: '#fff2f6' }]}
                      outlineColor='#fff2f6'
                      activeOutlineColor='#fff2f6'
                      selectionColor={COLOR_PRIMARY}
                      textAlign='center'
                      textColor='black'
                      value={email}
                      onChangeText={(v) => {
                        setEmail(v)
                        checkRegistrationDataValidation(false, v, password, passwordConfirmation)
                      }}
                      error={!emailValid}
                      onBlur={() => checkRegistrationDataValidation(true, email, password, passwordConfirmation)}
                      onSubmitEditing={() => passwordFieldRef.current?.focus()}
                      disabled={navigatingAway || isLoading}
                    />
                    <TextInput
                      ref={passwordFieldRef}
                      keyboardType="default"
                      secureTextEntry
                      returnKeyType="next"
                      textContentType="newPassword"
                      autoComplete="password-new"
                      mode="outlined"
                      placeholder={i18n.t('login.fields.password.label')}
                      style={[tailwind('mb-2'), { textAlign: 'center', backgroundColor: '#fff2f6' }]}
                      outlineColor='#fff2f6'
                      activeOutlineColor='#fff2f6'
                      selectionColor={COLOR_PRIMARY}
                      textAlign='center'
                      textColor='black'
                      value={password}
                      onChangeText={(v) => {
                        setPassword(v)
                        checkRegistrationDataValidation(false, email, v, passwordConfirmation)
                      }}
                      error={!passwordValid}
                      onBlur={() => checkRegistrationDataValidation(true, email, password, passwordConfirmation)}
                      onSubmitEditing={() => passwordConfirmationFieldRef.current?.focus()}
                      disabled={navigatingAway || isLoading}
                    />
                    <TextInput
                      ref={passwordConfirmationFieldRef}
                      keyboardType="default"
                      secureTextEntry
                      returnKeyType="go"
                      textContentType="newPassword"
                      autoComplete="password-new"
                      mode="outlined"
                      placeholder={i18n.t('login.fields.password-confirm.label')}
                      style={[{ marginBottom: 'auto', textAlign: 'center', backgroundColor: '#fff2f6' }]}
                      outlineColor='#fff2f6'
                      activeOutlineColor='#fff2f6'
                      selectionColor={COLOR_PRIMARY}
                      textAlign='center'
                      textColor='black'
                      value={passwordConfirmation}
                      onChangeText={(v) => {
                        setPasswordConfirmation(v)
                        checkRegistrationDataValidation(false, email, password, v)
                      }}
                      error={!passwordconfirmationValid}
                      onBlur={() => checkRegistrationDataValidation(true, email, password, passwordConfirmation)}
                      onSubmitEditing={() => {
                        checkRegistrationDataValidation(true, email, password, passwordConfirmation);
                        if (registrationDataValid)
                          goTo(2)
                      }}
                      disabled={navigatingAway || isLoading}
                    />
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        paddingTop: 16,
                      }}
                    >
                      <Button
                        mode="outlined"
                        style={{
                          width: '110px',
                          marginEnd: 'auto',
                          position: 'relative',
                          borderColor: '#ffffff'
                        }}
                        uppercase={false}
                        textColor='#ffffff'
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(0)}
                      >
                        {i18n.t('actions.back')}
                      </Button>
                      <Button
                        mode="contained-tonal"
                        style={{
                          width: '110px',
                          marginStart: 'auto',
                          position: 'relative',
                          backgroundColor: registrationDataValid ? '#fff2f6' : '#ccc'
                        }}
                        textColor={COLOR_PRIMARY}
                        uppercase={false}
                        disabled={!registrationDataValid || navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => {
                          checkRegistrationDataValidation(true, email, password, passwordConfirmation);
                          if (registrationDataValid)
                            goTo(2)
                        }}
                      >
                        {i18n.t('actions.continue')}
                      </Button>
                    </View>
                  </View>

                  <View style={{ width: 340 - 2 * 16, marginLeft: 16, height: '100%' }}>
                    <Text variant="titleMedium" style={{ marginTop: 24, textAlign: 'center' }}>{ ' ' }</Text>
                    <TextInput
                      ref={phoneNumberRef}
                      keyboardType="phone-pad"
                      returnKeyType="next"
                      onSubmitEditing={() => goTo(4)}
                      textContentType="telephoneNumber"
                      autoComplete="tel"
                      mode="outlined"
                      outlineColor='#fff2f6'
                      activeOutlineColor='#fff2f6'
                      selectionColor={COLOR_PRIMARY}
                      placeholder={i18n.t('onboarding.questions.phone_number')}
                      style={[{marginTop: 'auto', marginBottom: 'auto', textAlign: 'center', backgroundColor: '#fff2f6' }]}
                      value={phoneNumber}
                      textColor='black'
                      onChangeText={setPhoneNumber}
                      disabled={navigatingAway || isLoading}
                    />
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        paddingTop: 16,
                      }}
                    >
                      <Button
                        mode="outlined"
                        style={{
                          width: '110px',
                          marginEnd: 'auto',
                          position: 'relative',
                          borderColor: '#fff2f6'
                        }}
                        uppercase={false}
                        textColor='#ffffff'
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(1)}
                      >
                        {i18n.t('actions.back')}
                      </Button>
                      <Button
                        mode="contained-tonal"
                        style={{
                          width: '110px',
                          marginStart: 'auto',
                          position: 'relative',
                          backgroundColor: '#fff2f6',
                        }}
                        textColor={COLOR_PRIMARY}
                        uppercase={false}
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(3)}
                      >
                        {i18n.t('actions.continue')}
                      </Button>
                    </View>
                  </View>
                  <View style={{ width: 340 - 2 * 16 - 10, marginLeft: 20, height: '100%' }}>
                    <Card style={{ paddingTop: 0, paddingBottom: '8px', marginTop: 'auto', marginBottom: 'auto', }}>
                      <Text variant="titleMedium" style={[{marginTop: 24, textAlign: 'center'}]}>{ i18n.t('onboarding.questions.account_type') }</Text>
                      <RadioButton.Group onValueChange={ value => setAccountType(value) } value={ accountType }>
                        <RadioButton.Item
                          value="guest"
                          label={ i18n.t('onboarding.account_type.guest') }
                        />
                        <RadioButton.Item
                          value="owner"
                          label={ i18n.t('onboarding.account_type.owner') }
                        />
                        <RadioButton.Item
                          value="service_provider"
                          label={ i18n.t('onboarding.account_type.service_provider') }
                        />
                        <RadioButton.Item
                          value="giveaway"
                          label={ i18n.t('onboarding.account_type.giveaway') }
                        />
                        <RadioButton.Item
                          value="whitelabel"
                          label={ i18n.t('onboarding.account_type.whitelabel') }
                        />
                        <RadioButton.Item
                          value="unknown"
                          label={ i18n.t('onboarding.account_type.unknown') }
                        />
                      </RadioButton.Group>
                    </Card>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        paddingTop: 16,
                      }}
                    >
                      <Button
                        mode="outlined"
                        style={{
                          width: '110px',
                          marginEnd: 'auto',
                          position: 'relative',
                          borderColor: '#fff2f6'
                        }}
                        uppercase={false}
                        textColor='#fff2f6'
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(2)}
                      >
                        {i18n.t('actions.back')}
                      </Button>
                      <Button
                        mode="contained-tonal"
                        style={{
                          width: '110px',
                          marginStart: 'auto',
                          position: 'relative',
                          backgroundColor: '#fff2f6'
                        }}
                        textColor={COLOR_PRIMARY}
                        uppercase={false}
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(4)}
                      >
                        {i18n.t('actions.continue')}
                      </Button>
                    </View>
                  </View>
                  <View style={{ width: 340 - 2 * 16 - 4, marginLeft: 26, marginRight: 20, height: '100%' }}>
                    <Card style={{ paddingTop: 0, paddingBottom: '8px', marginTop: 'auto', marginBottom: 'auto' }}>
                      <Text variant="titleMedium" style={[{ marginTop: 24, textAlign: 'center', marginBottom: 28 }]}>{ i18n.t('onboarding.questions.referrer') }</Text>
                      <RadioButton.Group onValueChange={ value => setReferrer(value) } value={ referrer }>
                        <RadioButton.Item
                          value="instagram"
                          label={ i18n.t('onboarding.referrer.instagram') }
                        />
                        <RadioButton.Item
                          value="google"
                          label={ i18n.t('onboarding.referrer.google') }
                        />
                        <RadioButton.Item
                          value="youtube"
                          label={ i18n.t('onboarding.referrer.youtube') }
                        />
                        <RadioButton.Item
                          value="friend"
                          label={ i18n.t('onboarding.referrer.friend') }
                        />
                        <RadioButton.Item
                          value="unknown"
                          label={ i18n.t('onboarding.referrer.unknown') }
                        />
                      </RadioButton.Group>
                    </Card>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 'auto',
                        paddingTop: 16,
                      }}
                    >
                      <Button
                        mode="outlined"
                        style={{
                          width: '110px',
                          marginEnd: 'auto',
                          position: 'relative',
                          borderColor: '#fff2f6',
                        }}
                        textColor='#fff2f6'
                        uppercase={false}
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={() => goTo(3)}
                      >
                        {i18n.t('actions.back')}
                      </Button>
                      <Button
                        mode="contained-tonal"
                        style={{
                          width: '110px',
                          marginStart: 'auto',
                          position: 'relative',
                          backgroundColor: '#fff2f6',
                        }}
                        textColor={COLOR_PRIMARY}
                        uppercase={false}
                        disabled={navigatingAway || isLoading}
                        loading={navigatingAway || isLoading}
                        onPress={doSubmit}
                      >
                        {i18n.t('onboarding.actions.setup')}
                      </Button>
                    </View>
                  </View>
                </ScrollView>
              </Card>
            </Animated.View>
          </FormWrapper>
        </Animated.View>
      </View>
    </Fragment>
  );
}

function FormWrapper({
  onSubmit,
  children,
}: {
  onSubmit(): void;
  children: React.ReactNode;
}): JSX.Element {
  if (Platform.OS === 'web') {
    return (
      <Fragment>
        <style>
          {/**
           * This fixes the issue of chrome's autofill background overlaying
           * the field completely. This fix only needs to be applied on the Web
           * where the autofill is applied on render, and not on first type.
           */}
          {`
          #login-form input {
            height: 48px !important;
            margin: 4px;
            outline: none;
            border-radius: 4px;
          }`}
        </style>
        <form
          id="registration-form"
          action="#"
          method="POST"
          onSubmit={onSubmit}
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </form>
      </Fragment>
    );
  }

  return <Fragment>{children}</Fragment>;
}
