import { useIsFocused } from '@react-navigation/core';
import { useRoute } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  View,
} from 'react-native';
import { Appbar, Searchbar, Surface } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { decode } from '../base';
import { useBooking } from '../bookings/useBookings';
import { TabbedScreen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useSearchResults } from '../hooks/useSearchResults';
import { i18n } from '../locale';
import { RouteProp } from '../navigation/routes';
import { BookingReference } from '../services/ServiceReference';
import { hrefAsKeyExtractor } from '../utils/hrefAsKeyExtractor';
import { AssignmentListItem } from './AssignmentListItem';
import { EmptyState } from './EmptyState';
import {
  ApplauseAssignment,
  ApplauseAssignmentsIndex,
  useBookingAssignments,
} from './useAssignments';

const EMPTY: ApplauseAssignmentsIndex['assignments']['_index'] = [
  Object.freeze({
    href: '#',
    status: 'empty',
    slug: 'empty',
    search: '#',
    category: 'empty',
  }),
];

export function BookingAssignmentsScreen() {
  useForceAuthenticated();

  const tailwind = useTailwind();
  const [doneEnabled, setDoneEnabled] = useState(false);
  const isFocused = useIsFocused();

  const { href: encodedHref } = useRoute<RouteProp<''>>().params as {
    href: string;
  };
  const href = decode(encodedHref);

  const { data: booking } = useBooking(href, {
    enabled: isFocused,
    notifyOnChangeProps: ['data'],
  });
  const {
    data: allData,
    refetch,
    isFetching,
    isLoading,
  } = useBookingAssignments(booking?._links.assignments?.href, {
    enabled: isFocused,
    notifyOnChangeProps: ['data', 'error', 'isLoading', 'isFetching'],
  });

  const { data, query, setQuery } = useSearchResults(allData, 'assignments');
  const visibleData = useMemo(() => {
    return data.filter(
      (value) =>
        doneEnabled ||
        (value.status !== 'finished' &&
          value.status !== 'cancelled' &&
          value.status !== 'cancelled')
    );
  }, [data, doneEnabled]);

  return (
    <TabbedScreen>
      <ScreenHeader title={i18n.t('bookings.assignments.title')} showBack>
        <Appbar.Action
          icon={doneEnabled ? 'check-bold' : 'check-outline'}
          onPress={() => setDoneEnabled((prev) => !prev)}
          accessibilityLabel="Toggle finished and cancelled items"
        />
      </ScreenHeader>
      <Surface
        style={[
          tailwind(
            'z-10 justify-center self-center w-full flex-row bg-white pl-2'
          ),
          { elevation: 2 },
        ]}
      >
        <Searchbar
          value={query}
          onChangeText={setQuery}
          style={[
            tailwind('justify-center self-center max-w-3xl w-full'),
            {
              elevation: 0,
              borderRadius: 0,
              width: '100%',
              includeFontPadding: false,
              minHeight: 54,
              display:
                (allData?.assignments._index.length || 0) > 6 ? 'flex' : 'none',
            },
          ]}
          placeholder={i18n.t('services.fields.search.placeholder')}
        />
      </Surface>
      <FlatList
        style={tailwind('flex-1')}
        ListHeaderComponent={BookingReferenceHeader}
        contentContainerStyle={[
          { minHeight: 300 },
          tailwind('max-w-3xl self-center w-full pb-4'),
        ]}
        data={visibleData.length === 0 ? EMPTY : visibleData}
        renderItem={renderAssignment}
        keyExtractor={hrefAsKeyExtractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
      />
    </TabbedScreen>
  );
}

function BookingReferenceHeader() {
  const tailwind = useTailwind();
  const { href: encodedHref } = useRoute<RouteProp<''>>().params as {
    href: string;
  };
  const href = decode(encodedHref);

  return (
    <View style={tailwind('mb-4')}>
      <BookingReference href={href} />
    </View>
  );
}

export function renderAssignment({
  item,
}: ListRenderItemInfo<ApplauseAssignment['_links']['self']>) {
  if (item === EMPTY[0]) {
    return (
      <View
        style={[
          {
            flex: 1,
            width: '100%',
            height: '100%',
            minHeight: 300,
            flexShrink: 0,
            alignItems: 'center',
          },
        ]}
      >
        <EmptyState />
      </View>
    );
  }

  return (
    <View style={{ marginBottom: 8 }}>
      <AssignmentListItem href={item.href} roundBottom roundTop />
    </View>
  );
}
