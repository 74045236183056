import { useNavigation } from '@react-navigation/core';
import { LinearGradient } from 'expo-linear-gradient';
import { FetchMediaError } from 'fetch-media';
import React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  View,
} from 'react-native';
import { Card, HelperText } from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { encode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { APPLAUSE_APP_NAME } from '../config';
import { useForceAuthenticated } from '../hooks/useAuth';
import { i18n } from '../locale';
import { selfAsKeyExtractor } from '../utils/selfAsKeyExtractor';
import { EmptyState } from './EmptyState';
import { ApplauseArea, useAreas } from './useAreas';

const EMPTY: ApplauseArea[] = [];

export function BookingAreasScreen() {
  useForceAuthenticated();

  const tailwind = useTailwind();
  const { data, error, refetch, isFetching, isLoading } = useAreas();

  return (
    <Screen>
      <ScreenHeader
        title={i18n.t('bookings.pick.title', { app: APPLAUSE_APP_NAME })}
        showBack
      />
      <FlatList
        style={tailwind('flex-1')}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full p-4 pb-6'
        )}
        data={data?.areas._embedded || EMPTY}
        renderItem={renderHostArea}
        keyExtractor={selfAsKeyExtractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
      />
      {!isLoading && !data && error ? <ErrorPanel error={error} /> : null}
      {!isLoading && data?.areas._embedded.length === 0 && !error ? (
        <EmptyState />
      ) : null}
    </Screen>
  );
}

function ErrorPanel({ error }: { error: Error | FetchMediaError }) {
  if (error instanceof FetchMediaError) {
    return <HelperText type="error">{error.message}</HelperText>;
  }

  return <HelperText type="error">{error.message}</HelperText>;
}

function renderHostArea({ item }: ListRenderItemInfo<ApplauseArea>) {
  return <Area {...item} />;
}

function Area({ processed_image, name, url }: ApplauseArea) {
  const { navigate } = useNavigation<any>();
  return (
    <Card
      style={{
        position: 'relative',
        overflow: 'hidden',
        marginBottom: 12,
        backgroundColor: '#222',
        borderRadius: 12,
      }}
      onPress={() =>
        navigate('BookingExperience', { url: encode(url), name: encode(name) })
      }
    >
      {processed_image ? (
        <Card.Cover
          source={{ uri: processed_image }}
          style={{
            borderRadius: 8,
          }}
        />
      ) : (
        <View style={{ height: 200, borderRadius: 8 }} />
      )}
      <LinearGradient
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          overflow: 'hidden',
          borderRadius: 12,
        }}
        colors={['#22222200', '#22222288']}
        locations={[0.5, 1]}
      />
      <Card.Title
        title={name}
        style={{ position: 'absolute', bottom: 0 }}
        titleVariant='titleMedium'
        titleStyle={{
          color: '#f5f5f5',
          textShadowColor: '#222',
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 1,
        }}
      />
    </Card>
  );
}
