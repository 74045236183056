import { createURL as makeUrl } from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { NotificationContent } from 'expo-notifications';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Platform, ScrollView } from 'react-native';
import {
  Button,
  Card,
  Dialog,
  Paragraph,
  Portal,
  useTheme,
} from 'react-native-paper';
import { INTERNAL_PREFIXES } from '../config';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { i18n } from '../locale';
import { linkTo, navigate } from '../navigation/refs';
import { openExternalUrl } from '../utils/useOpenUrl';

if (Platform.OS === 'android') {
  Notifications.setNotificationChannelAsync('priority', {
    name: 'Priority Pushes',
    description: 'Critical pushes regarding a booking, property, or service',
    sound: 'defaultCritical',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
  });

  Notifications.setNotificationChannelAsync('kaching', {
    name: 'Kaching',
    description:
      'Important pushes regarding new bookings or new service requests',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
    sound: 'kaching.wav',
  });

  Notifications.setNotificationChannelAsync('bookings', {
    name: 'Bookings',
    description: 'Important pushes regarding bookings',
    sound: 'defaultCritical',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
  });

  Notifications.setNotificationChannelAsync('properties', {
    name: 'Properties',
    description: 'Important pushes regarding properties',
    sound: 'defaultCritical',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
  });

  Notifications.setNotificationChannelAsync('services', {
    name: 'Services',
    description: 'Important pushes regarding services',
    sound: 'defaultCritical',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
  });

  Notifications.setNotificationChannelAsync('chat', {
    name: 'Chat',
    description: 'A new chat message was received when you were away',
    sound: 'defaultCritical',
    vibrationPattern: [0, 250, 250, 250],
    importance: Notifications.AndroidImportance.MAX,
  });

  Notifications.setNotificationChannelAsync('other', {
    name: 'Other, less important pushes',
    sound: null,
    importance: Notifications.AndroidImportance.DEFAULT,
  });
}

Notifications.setNotificationHandler({
  handleError: console.error,
  handleSuccess: (id) => {},
  handleNotification: async (notification) => {
    return {
      shouldShowAlert: false,
      shouldPlaySound: notification.request.content.sound !== null,
      shouldSetBadge: false,
    };
  },
});

export function sendLocalNotification(title: string, message: string) {
  Notifications.scheduleNotificationAsync({
    content: { title, body: message },
    trigger: null,
  });
}

export function InAppNotifications() {
  return (
    <Portal>
      <InAppNotifications_ />
    </Portal>
  );
}

const FINAL_INTERNAL_PREFIXES = [
  makeUrl('/'),

  // This is fine because by this time it's a push notification handled inside
  // this app. We add all the known schemes here so these work even when they're
  // received in the "wrong" app.
  `com.bnbbutler.james.test://`,
  `com.bnbbutler.james://`,
  `james://`,
  `jms://`,
].concat(INTERNAL_PREFIXES);

function InAppNotifications_() {
  const {
    colors: { primary },
  } = useTheme();

  const lastFollowedOrDismissed = useRef<string | null>(null);
  const lastReceivedNotification = useRef<string | null>(null);
  const lastNotification = Notifications.useLastNotificationResponse();
  const [notification, setNotification] =
    useState<null | Notifications.NotificationRequest>(
      lastNotification?.notification?.request || null
    );

  if (notification) {
    lastReceivedNotification.current = notification.identifier;
  }

  const forceUpdate = useForceUpdate();
/*
  useEffect(() => {
    const listener = Notifications.addNotificationsDroppedListener(() =>
      console.log('[dropped] notification')
    );
    return () => listener.remove();
  }, []);
*/
  useEffect(() => {
    const listener = Notifications.addNotificationReceivedListener(
      (notification) => {
        console.log('[received] notification', notification);
        const identifier = notification.request.identifier;

        if (
          !lastReceivedNotification.current ||
          lastReceivedNotification.current !== identifier
        ) {
          if (
            !lastFollowedOrDismissed.current ||
            lastFollowedOrDismissed.current !== identifier
          ) {
            setNotification(notification?.request);
          }
        }
      }
    );
    return () => listener.remove();
  }, []);

  useEffect(() => {
    const listener = Notifications.addNotificationResponseReceivedListener(
      (notification) => {
        if (
          notification.actionIdentifier !==
          Notifications.DEFAULT_ACTION_IDENTIFIER
        ) {
          return;
        }

        if (
          !lastReceivedNotification.current ||
          lastReceivedNotification.current !==
            notification.notification.request.identifier
        ) {
          lastFollowedOrDismissed.current = null;
          setNotification(notification.notification.request);
        }
      }
    );

    return () => {
      listener.remove();
    };
  }, []);

  const identifier = notification?.identifier || null;
  const deeplink = (notification?.content.data?.url as string) || null;

  const doHideNotification = () => {
    lastFollowedOrDismissed.current = identifier;
    lastReceivedNotification.current = null;
    forceUpdate();
  };

  const internalPath = useMemo((): string | null => {
    if (!deeplink) {
      return null;
    }

    const internalPrefix = FINAL_INTERNAL_PREFIXES.find((prefix) => {
      return deeplink.indexOf(prefix) === 0;
    });

    const result = internalPrefix
      ? deeplink.substring(internalPrefix.length)
      : null;
    if (result && result[0] !== '/') {
      return `/${result}`;
    }

    return result;
  }, [deeplink]);

  return (
    <PushNotificationDialog
      identifier={identifier || undefined}
      push={notification?.content}
      visible={lastFollowedOrDismissed.current !== identifier}
      onClose={doHideNotification}
      onFollow={() => {
        doHideNotification();

        if (deeplink) {
          if (internalPath || deeplink[0] === '/') {
            console.log(internalPath || deeplink);
            try {
              linkTo(internalPath || deeplink) ||
                openExternalUrl(deeplink, primary);
            } catch (_) {
              openExternalUrl(deeplink, primary);
            }
          } else {
            openExternalUrl(deeplink, primary);
          }
        } else {
          const { screen, params } = notification?.content.data || {};
          if (screen) {
            navigate(screen as string, (params as any) || {});
          }
        }
      }}
    />
  );
}

function PushNotificationDialog({
  identifier,
  push,
  visible,
  onClose: doClose,
  onFollow: doFollow,
}: {
  identifier: string | undefined;
  push: NotificationContent | undefined;
  visible: boolean;
  onClose(): void;
  onFollow(): void;
}) {
  const title = (push?.data?.title || push?.title || '') as string;
  const body = (push?.data?.body || push?.body || '') as string;
  const imageUrl = (push?.data?.image || null) as string | null;
  const action = (push?.data?.action ||
    i18n.t('push-notifications.actions.go')) as string;
  const canActivate = !!(
    (push?.data && (push.data.screen || push.data.url)) ||
    false
  );

  return (
    <Dialog
      key={identifier}
      visible={visible}
      onDismiss={doClose}
      style={{
        alignSelf: 'center',
        maxWidth: 720,
        maxHeight: '40%',
        width: '90%',
      }}
    >
      <Card>
        <Card.Title title={title} titleNumberOfLines={3} titleVariant='titleMedium' />
        {(imageUrl && <Card.Cover source={{ uri: imageUrl }} />) || null}
        <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
          <ScrollView
            contentContainerStyle={{ margin: 0, paddingHorizontal: 16 }}
          >
            <Paragraph style={{ paddingVertical: 16 }}>{body}</Paragraph>
          </ScrollView>
        </Dialog.ScrollArea>
        <Card.Actions>
          <Button
            onPress={doClose}
            style={{ marginLeft: 'auto' }}
            labelStyle={{
              includeFontPadding: false,
              textAlignVertical: 'center',
            }}
          >
            {i18n.t('actions.close')}
          </Button>
          {(canActivate && (
            <Button
              onPress={doFollow}
              style={{ marginLeft: 8 }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              mode="contained"
            >
              {action}
            </Button>
          )) ||
            null}
        </Card.Actions>
      </Card>
    </Dialog>
  );
}
