import React, { Fragment, useState } from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';
import { Button, Dialog, HelperText, Portal } from 'react-native-paper';
import { QueryKey } from 'react-query';
import { useTailwind } from 'tailwind-rn';
import { useIsMounted } from 'use-is-mounted';
import { DocumentAttachment } from '../components/DocumentAttachment';
import { useSnackbar } from '../components/SnackbarProvider';
import { COLOR_PRIMARY } from '../config';
import { i18n } from '../locale';
import { useFinish } from './useFinish';

export function FinishAssignment({
  href,
  baseCacheKey,
  style,
}: {
  href: string;
  baseCacheKey: QueryKey;
  style?: StyleProp<ViewStyle>;
}) {
  const tailwind = useTailwind();
  const { show } = useSnackbar();
  const { mutateAsync: finish, isLoading } = useFinish(href, baseCacheKey, {
    onSuccess: () => show('Merci voor uw service'),
  });
  const [isFinishing, setFinishing] = useState(false);
  const isMountedRef = useIsMounted();
  const [formData, setFormData] = useState<FormData | null>(null);

  return (
    <Fragment>
      <Button
        disabled={isLoading || isFinishing}
        loading={isLoading}
        mode="contained"
        style={[tailwind('self-start mb-4 mt-4'), style]}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 0,
        }}
        onPress={() => setFinishing(true)}
      >
        {i18n.t('assignments.actions.finish')}
      </Button>

      <Portal>
        <Dialog
          visible={isFinishing}
          onDismiss={() => setFinishing(false)}
          dismissable={!isLoading}
          style={tailwind('max-w-xl self-center w-full')}
        >
          <Dialog.Title>{i18n.t('assignments.finish.title')}</Dialog.Title>
          <Dialog.ScrollArea style={{ maxHeight: 400, paddingHorizontal: 0 }}>
            <ScrollView
              contentContainerStyle={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <DocumentAttachment
                onChanged={setFormData}
                title={
                  formData
                    ? i18n.t('assignments.actions.completion_video.change')
                    : i18n.t('assignments.actions.completion_video.add')
                }
                icon="video"
                formDataKey="complete_video"
                formDataName="completion_video.mp4"
                kind="video"
              />
              <HelperText type="info">
                {i18n.t('assignments.fields.completion_video.helper')}
              </HelperText>
            </ScrollView>
          </Dialog.ScrollArea>
          <Dialog.Actions style={tailwind('py-4')}>
            <Button
              mode="text"
              color={COLOR_PRIMARY}
              disabled={isLoading}
              loading={isLoading}
              onPress={() => {
                setFinishing(false);
              }}
              style={tailwind('mr-2')}
            >
              {i18n.t('actions.cancel')}
            </Button>
            <Button
              mode="contained"
              loading={isLoading}
              icon="check"
              onPress={() => {
                finish(formData!)
                  .then(() => isMountedRef.current && setFinishing(false))
                  .catch(() => {});
              }}
              style={tailwind('mr-1')}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
                paddingHorizontal: 6,
              }}
            >
              {i18n.t('assignments.actions.finish')}
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </Fragment>
  );
}
