import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from './useAuth';
import { useEndpoint } from './useEndpoint';
import { useLocale } from './useLocale';

export interface ApplauseConfiguration {
  _links: {
    self: {
      href: string;
    };
    activate: {
      href: string;
    };
    areas: {
      href: string;
    };
    my_assignments: {
      href: string;
    };
    my_bookings: {
      href: string;
    };
    my_events: {
      href: string;
    };
    my_messages: {
      href: string;
    };
    my_properties: {
      href: string;
    };
    my_push_tokens: {
      href: string;
    };
    my_reservations: {
      href: string;
    };
    my_user: {
      href: string;
    };
  };
  capabilities: {
    can_book: true;
    can_own: true;
    can_service: false;
    can_manage_servicers: false;
    can_manage: true;
  };
}

type ApplauseConfigurationResponse = {
  configuration: ApplauseConfiguration;
};

export function useConfiguration({
  enabled = true,
  ...options
}: UseQueryOptions<ApplauseConfiguration, FetchMediaError> = {}) {
  const href = useEndpoint('api');
  const auth = useAuth();
  const locale = useLocale();

  return useQuery<ApplauseConfiguration, FetchMediaError>(
    [locale, 'configuration', auth['current']?.uid] as QueryKey,
    {
      queryFn: async ({ signal }) =>
        fetchMedia(href, {
          headers: {
            accept: 'application/vnd.bnbbutler.configuration.v1+json',
            acceptLanguage: [locale, 'en; q=0.1'].join(', '),

            ...(auth.current as Record<string, string>),
          },
          method: 'GET',
          debug: __DEV__,
          signal,
        })
          .then((response) => response as ApplauseConfigurationResponse)
          .then(({ configuration }) => configuration),
      staleTime: 30 * 1000,
      enabled,
      ...options,
    }
  );
}

export function useConfigurationEndpoint<
  TData extends { _links: { [key: string]: { href: string } } },
  TLink extends keyof TData['_links']
>(data: TData | undefined, link: TLink): string | undefined {
  if (!data) {
    return undefined;
  }

  if (!data._links[link as string]) {
    throw new Error(
      `Expected ${String(link)} in _links. Actual: ${Object.keys(data._links)}`
    );
  }

  return data._links[link as string].href;
}
