import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import { useLocale } from '../hooks/useLocale';

export type ApplauseServiceImage = {
  href: string;
};

export type ApplauseServiceImageResponse = {
  images: {
    _index: ApplauseServiceImage[];
  };
};

export function useServiceImages(
  href: string | undefined,
  cacheKey: QueryKey,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<ApplauseServiceImageResponse, FetchMediaError> = {}
) {
  const auth = useAuth();
  const locale = useLocale();

  return useQuery(cacheKey, {
    queryFn: async ({ signal }) =>
      fetchMedia(href!, {
        headers: {
          accept: [
            'application/vnd.bnbbutler.service.image.v1.index+json',
          ].join(', '),
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),

          ...(auth.current as Record<string, string>),
        },
        method: 'GET',
        debug: __DEV__,
        signal,
      }).then((response) => response as ApplauseServiceImageResponse),
    enabled: !!auth.current?.['access-token'] && enabled && !!href,
    staleTime: 10 * 60 * 1000, // 10 minutes
    ...options,
  });
}
