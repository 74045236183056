import {
  Inconsolata_300Light,
  Inconsolata_400Regular,
  Inconsolata_500Medium,
  Inconsolata_700Bold,
} from '@expo-google-fonts/inconsolata';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();
        // Load fonts
        const fonts = Font.loadAsync({
          ...MaterialCommunityIcons.font,

          Insonsolate_300: Inconsolata_300Light,
          Insonsolate_400: Inconsolata_400Regular,
          Insonsolate_500: Inconsolata_500Medium,
          Insonsolate_700: Inconsolata_700Bold,
        });

        await Promise.all([fonts]);
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
