// import 'react-native-url-polyfill/auto';
// import 'fast-text-encoding';
// import multibase from 'multibase';
import utf8 from 'utf8';
import base64 from 'base-64';

export function encode(
  text: string,
  encoding = 'base64url' ///multibase.BaseNameOrCode = 'base64url'
): string {
  if (encoding !== 'base64url') {
    throw new Error(`Only base64url (u) is supported, given ${encoding}`);
  }

  return `u${base64
    .encode(utf8.encode(text))
    .replace(/\//g, '_')
    .replace(/\+/g, '-')
    .replace(/=*$/, '')}`;
}

export function decode(encoded: string): string {
  const [encoding, text] = [encoded[0], encoded.slice(1)];

  if (encoding !== 'u') {
    throw new Error(`Only base64url (u) is supported, given ${encoding}`);
  }

  return base64
    .decode(text.replace(/_/g, '/').replace(/-/g, '+').replace(/%3D+$/, '='))
    .replace(/Ã´/g, 'ô');
}
