import { useRoute } from '@react-navigation/core';
import React from 'react';
import { decode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { APPLAUSE_APP_NAME } from '../config';
import { useForceAuthenticated } from '../hooks/useAuth';
import { i18n } from '../locale';
import { BookingExperience } from './BookingExperience';

export function BookingExperienceScreen() {
  useForceAuthenticated();

  const { url: encodedUrl, name: encodedName } = (useRoute().params as {
    url: string;
    name: string;
  }) || {
    url: '',
    name: '',
  };
  const url = decode(encodedUrl);
  const name = decode(encodedName);

  return (
    <Screen>
      <ScreenHeader
        title={i18n.t('bookings.new.title', { name, app: APPLAUSE_APP_NAME })}
        showBack
      />
      <BookingExperience url={url} />
    </Screen>
  );
}
