import { fetchMedia, FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { imperativeLogout, useMutableAuth } from './useAuth';
import { RemoteUser, useMutableCurrentUser } from './useCurrentUser';
import { useEndpoint } from './useEndpoint';
import { useLocale } from './useLocale';
import { navigate } from '../navigation/refs';
import { i18n } from '../locale';

export type ResetPasswordData = {
  email: string;
};

export type RegisterData = {
  email: string;
  password: string;
  passwordConfirmation: string;
  account_type: string | null,
  phone_number: string | null,
  referrer: string | null,
  name: string | null,
};

export type LoginData = {
  email: string;
  password: string;
};

export type AuthResponse<TData> =
  | AuthSuccessResponse<TData>
  | AuthFailureResponse;

type AuthSuccessResponse<TData> = {
  success?: true;
  data: TData;
};

type AuthFailureResponse = {
  success: false;
  errors: string[];
};

export function useVerifyToken() {
  const href = useEndpoint('auth/validate_token');
  const locale = useLocale();
  const { current: auth, set: setAuth } = useMutableAuth();
  const [, setCurrentUser] = useMutableCurrentUser();

  const doVerifyToken = ({ signal }: { signal?: AbortSignal }) => {
    const query = [
      `access-token=${encodeURIComponent(auth?.['access-token'] || '')}`,
      `client=${encodeURIComponent(auth?.client || '')}`,
      `uid=${encodeURIComponent(auth?.uid || '')}`,
    ].join('&');

    const response = fetchMedia(`${href}?${query}`, {
      headers: {
        accept: 'application/json',
        acceptLanguage: [locale, 'en; q=0.1'].join(', '),
        contentType: 'application/json',
      },
      method: 'GET',
      signal,
    });

    const typed = response as unknown as AuthSuccessResponse<RemoteUser>;
    return typed.data;
  };

  return useQuery([href], {
    enabled: !!auth,
    staleTime: 5 * 60 * 1000,
    queryFn: doVerifyToken,
    onSuccess: (data) => {
      setCurrentUser(data);
    },
    onError: (error) => {
      if (error instanceof FetchMediaError) {
        if (error.response.status >= 400 && error.response.status <= 500) {
          // force logout
          setAuth(null);
          setCurrentUser(null);
        }
      }
    },
  });
}

export function useLogin() {
  const { set: setAuth } = useMutableAuth();
  const [, setCurrentUser] = useMutableCurrentUser();
  const locale = useLocale();

  const href = useEndpoint('auth/sign_in');

  const doLogin = useCallback(
    async ({ email, password }: LoginData) => {
      const response = await fetchMedia(href, {
        headers: {
          accept: 'application/json',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),
          contentType: 'application/json',
        },
        method: 'POST',
        body: {
          email,
          password,
        },
        hooks: {
          after: ({ headers }) =>
            setAuth({
              'access-token': headers.get('access-token'),
              'token-type': headers.get('token-type'),
              client: headers.get('client'),
              expiry: headers.get('expiry'),
              uid: headers.get('uid'),
              root_path: 'Tabs',
            }),
        },
      });

      return response as AuthSuccessResponse<RemoteUser>;
    },
    [href, locale]
  );

  return useMutation<
    AuthSuccessResponse<RemoteUser>,
    FetchMediaError,
    LoginData
  >(doLogin, {
    onSuccess: (result, variables, context) => {
      setCurrentUser(result.data);
    },
  });
}

export function useLogout() {
  const { current: auth, set: setAuth } = useMutableAuth();
  const [, setCurrentUser] = useMutableCurrentUser();
  const queryClient = useQueryClient();
  const locale = useLocale();

  const href = useEndpoint('auth/sign_out');

  const doLogOut = useCallback(async () => {
    await fetchMedia(href, {
      headers: {
        accept: 'application/json',
        acceptLanguage: [locale, 'en; q=0.1'].join(', '),
        contentType: 'application/json',
        ...(auth as Record<string, string>),
      },
      method: 'DELETE',
      body: {
        'access-token': auth?.['access-token'],
        client: auth?.client,
        uid: auth?.uid,
      },
    });
  }, [href, auth?.['access-token'], auth?.client, auth?.uid, locale]);

  return useMutation<void, FetchMediaError, void>(doLogOut, {
    onSettled: async (result, variables, context) => {
      setCurrentUser(null);

      console.log('logout');

      await setAuth(null);
      // await imperativeLogout();
    },
  });
}

export function useDeleteAccount() {
  const { current: auth, set: setAuth } = useMutableAuth();
  const [, setCurrentUser] = useMutableCurrentUser();

  const locale = useLocale();

  const href = useEndpoint('api/users/deletion_requests');

  const doDeleteAccount = useCallback(async () => {
    await fetchMedia(href, {
      headers: {
        accept: 'application/json',
        acceptLanguage: [locale, 'en; q=0.1'].join(', '),
        contentType: 'application/json',
        ...(auth as Record<string, string>),
      },
      method: 'POST',
      body: {
      },
    });
  }, [href, auth?.['access-token'], auth?.client, auth?.uid, locale]);

  return useMutation<void, FetchMediaError, void>(doDeleteAccount, {
    onSuccess: async (result, variables, context) => {
      console.log('delete-account');
      setCurrentUser(null);

      await setAuth(null);
    },
  });
}	

export function useRegister() {
  const { set: setAuth } = useMutableAuth();
  const [, setCurrentUser] = useMutableCurrentUser();
  const locale = useLocale();
  

  const href = useEndpoint('auth');

  const doRegister = useCallback(
    async ({ email, password, passwordConfirmation, account_type, phone_number, referrer, name }: RegisterData) => {
      const response = await fetchMedia(href, {
        headers: {
          accept: 'application/json',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),
          contentType: 'application/json',
        },
        method: 'POST',
        body: {
          email,
          password,
          password_confirmation: passwordConfirmation,
          locale: i18n.locale,
          account_type: account_type,
          phone_number: phone_number,
          referrer: referrer,
          name: name,
        },
        hooks: {
          after: ({ status, headers }) =>
          {
            if (status >= 200 && status <= 299) {
              setAuth({
                'access-token': headers.get('access-token'),
                client: headers.get('client'),
                expiry: headers.get('expiry'),
                'token-type': headers.get('token-type'),
                uid: headers.get('uid'),
                root_path: 'Tabs',
              })
            }
          }
        },
      });

      return response as AuthSuccessResponse<RemoteUser>;
    },
    [href, locale]
  );

  return useMutation<
    AuthSuccessResponse<RemoteUser>,
    FetchMediaError,
    RegisterData
  >(doRegister, {
    onSuccess: (result, variables, context) => {
      setCurrentUser(result.data);
    },
  });
}

export function useResetPassword() {
  const href = useEndpoint('auth/password');
  const locale = useLocale();

  const doResetPassword = useCallback(
    async ({ email }: ResetPasswordData) => {
      await fetchMedia(href, {
        headers: {
          accept: 'application/json',
          acceptLanguage: [locale, 'en; q=0.1'].join(', '),
        },
        method: 'POST',
        body: {
          email,
          redirectUrl: 'https://app.bnbbutler.be/choosePassword',
        },
      });

      return null;
    },
    [href, locale]
  );

  return useMutation<null, FetchMediaError, ResetPasswordData>(
    doResetPassword,
    {}
  );
}
