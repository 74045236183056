import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { LinearGradient } from 'expo-linear-gradient';
import { StatusBar } from 'expo-status-bar';
import React, { Fragment, useMemo } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import {
  Appbar,
  Button,
  Caption,
  Card,
  FAB,
  List,
  Surface,
  useTheme,
} from 'react-native-paper';
import { modeAppbarHeight } from 'react-native-paper/src/components/Appbar/utils';
import Animated, {
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTailwind } from 'tailwind-rn';
import { usePropertyAssignments } from '../assignments/useAssignments';
import { decode, encode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { COLOR_PRIMARY } from '../config';
import { useForceAuthenticated } from '../hooks/useAuth';
import { useColor } from '../hooks/useColor';
import { useWindowWidth } from '../hooks/useDimensions';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { RouteParamList } from '../navigation/routes';
import { useOwnedProperty } from './useOwnedProperty';
import {
  ApplauseProperty,
  propertyCacheKey,
  useProperty,
} from './useProperties';

const PARALLAX_SPEED = 0.5;
const HEADER_DELAY = 0.8;
const HEADER_HEIGHT = 360;

export function PropertyScreen() {
  useForceAuthenticated();

  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const { href: encodedHref } = useRoute().params as {
    href: string;
    image?: string;
  };
  const locale = useLocale();
  const href = decode(encodedHref);
  const baseCacheKey = propertyCacheKey(href, locale);

  const { data: ownedProperty } = useOwnedProperty(href, {
    enabled: isFocused,
  });
  const { data } = useProperty(href, {
    enabled: isFocused,
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
  });

  const title = data?.title;
  const image = data?.images.large[0] || data?.images.square[0];

  const {
    top: safeAreaTop,
    bottom: insetsBottom,
    right: insetsRight,
  } = useSafeAreaInsets();
  const translationY = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler((event) => {
    translationY.value = event.contentOffset.y;
  });

  const parallaxStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: -translationY.value * PARALLAX_SPEED,
        },
      ],
    };
  });

  const headerStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: Math.max(
            HEADER_HEIGHT - modeAppbarHeight.small - translationY.value,
            safeAreaTop
          ),
        },
      ],
    };
  }, [safeAreaTop]);

  const headerBackgroundStyles = useAnimatedStyle(() => {
    const offset = HEADER_HEIGHT * HEADER_DELAY;
    const remainder = HEADER_HEIGHT - offset;
    const opacity = Math.min(
      1,
      Math.max(0, translationY.value - offset) / remainder
    );
    return {
      opacity,
    };
  }, [safeAreaTop]);

  return (
    <Screen>
      <StatusBar translucent backgroundColor="#00000060" style="light" />
      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: 360,
          },
          parallaxStyles,
        ]}
      >
        <PropertyHeaderImage image={image} />
      </Animated.View>

      <Animated.ScrollView
        style={{ flex: 1, backgroundColor: 'transparent' }}
        contentContainerStyle={{ paddingTop: 360 }}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
      >
        <Surface
          style={{
            backgroundColor: '#f6f6f6',
            paddingBottom: 64,
            elevation: 0,
          }}
        >
          <Surface style={{ marginBottom: 8, elevation: 1 }}>
            <View style={tailwind('max-w-3xl w-full self-center')}>
              {!data || data.details.room_raccoon_id ? (
                <PropertyListItem
                  icon="pound-box"
                  title={data?.details.room_raccoon_id || '-'}
                />
              ) : null}
              <PropertyListItem icon="home" title={`${data?.address || '-'}`} />

              {data?.details.name_residence ? (
                <PropertyListItem
                  icon="home-outline"
                  title={`${data?.details.name_residence} ${i18n.t(
                    'properties.sections.home.residence'
                  )}`}
                />
              ) : null}
              {data?.details.name_doorbell ? (
                <PropertyListItem
                  icon="bell-outline"
                  title={`${data?.details.name_doorbell} ${i18n.t(
                    'properties.sections.home.doorbell'
                  )}`}
                />
              ) : null}
              <Card.Title
                title={data?.title}
                titleNumberOfLines={3}
                titleVariant='titleLarge'
                subtitle={(data?.description || '').replace(/\\u0026/g, '&')}
                subtitleNumberOfLines={12}
                style={{
                  paddingTop: 0,
                  paddingRight: 8,
                  paddingBottom: 0,
                  paddingLeft: 72 + 8,
                  marginTop: 8,
                  marginRight: 8,
                  marginBottom: 24,
                  marginLeft: 0,
                }}
              />
            </View>
          </Surface>

          {ownedProperty ? <ReserveProperty data={data} /> : null}

          <PropertyWifi data={data} />
          <PropertyNumbers data={data} />
          <PropertyFeatures data={data} />
        </Surface>
      </Animated.ScrollView>

      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: modeAppbarHeight.small,
            top: 0,
          },
          headerStyles,
        ]}
      >
        <StickyScreenHeader title={title} />
      </Animated.View>

      <Animated.View
        style={[
          {
            position: 'absolute',
            width: '100%',
            height: 56 + safeAreaTop + 2,
            top: 0,
          },
          headerBackgroundStyles,
        ]}
      >
        <ScreenHeader title={title || 'Retrieving...'} showBack />
      </Animated.View>
      <Animated.View
        style={[
          {
            position: 'absolute',
            bottom: 16 + insetsBottom,
            right: 16 + insetsRight,
          },
        ]}
      >
        {ownedProperty ? (
          <PropertyUpgradeAction href={data?._links.services.href} />
        ) : null}
      </Animated.View>
    </Screen>
  );
}

function StickyScreenHeader({ title }: { title: string | undefined }) {
  const { canGoBack, goBack, navigate } = useNavigation<any>();
  const tailwind = useTailwind();

  return (
    <Appbar
      style={[
        tailwind('justify-center self-center w-full flex-row'),
        {
          elevation: 0,
          maxWidth: 786,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: 'transparent',
        },
      ]}
    >
      <Appbar.BackAction
        color="white"
        onPress={() =>
          canGoBack()
            ? goBack()
            : navigate('Tabs', { screen: 'Dashboard', replace: true })
        }
      />
      <Appbar.Content
        title={title || ''}
        titleStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          color: 'white',
          textShadowColor: '#222',
          textShadowOffset: { width: 1, height: 1 },
          textShadowRadius: 1,
        }}
      />
    </Appbar>
  );
}

function PropertyHeaderImage({ image }: { image: string | undefined }) {
  const tailwind = useTailwind();

  return (
    <Surface
      style={{
        width: '100%',
        height: 360,
        elevation: 0,
        backgroundColor: '#222',
      }}
    >
      <Image
        source={
          image
            ? { uri: image, width: 400, height: 300 }
            : require('../../assets/images/placeholders/property.jpg')
        }
        style={{ width: '100%', height: '100%', position: 'absolute' }}
        resizeMode="cover"
      />
      <View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: '#22222240',
        }}
      />

      <View
        style={tailwind(
          'justify-center self-center max-w-4xl w-full flex-row flex-1'
        )}
      >
        <Image
          source={
            image
              ? { uri: image, width: 400, height: 300 }
              : require('../../assets/images/placeholders/property.jpg')
          }
          style={{ width: '100%', height: '100%' }}
          resizeMode="cover"
        />
        <LinearGradient
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            overflow: 'hidden',
          }}
          colors={['#22222200', image ? '#22222299' : '#222222AA']}
          locations={image ? [0.82, 0.9] : [0.8, 1]}
        />
      </View>
    </Surface>
  );
}

function PropertyUpgradeAction({ href }: { href: string | undefined }) {
  const { push } =
    useNavigation<StackNavigationProp<RouteParamList, 'Property'>>();

  return (
    <Fragment>
      <FAB
        icon="arrow-up"
        theme={{
          colors: {
            accent: COLOR_PRIMARY,
          },
        }}
        style={[href ? {} : { opacity: 0.5 }]}
        disabled={!href}
        onPress={() => push('Upgrade', { href: encode(href!) })}
        label={i18n.t('properties.actions.upgrade')}
        uppercase={false}
      />
    </Fragment>
  );
}

function ReserveProperty({ data }: { data: ApplauseProperty | undefined }) {
  const tailwind = useTailwind();
  const {
    colors: { secondary: accent },
  } = useTheme();
  const { navigate } = useNavigation<any>();

  if (!data?._links.reserve) {
    return null;
  }

  return (
    <View style={tailwind('max-w-xl w-full self-center my-2 px-4')}>
      <Button
        mode="contained-tonal"
        icon="calendar"
        uppercase={false}
        labelStyle={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingHorizontal: 6,
        }}
        onPress={() =>
          navigate('ReserveProperty', { href: encode(data._links.self.href) })
        }
      >
        {i18n.t('properties.actions.stay')}
      </Button>
    </View>
  );
}

function PropertyWifi({ data }: { data: ApplauseProperty | undefined }) {
  const tailwind = useTailwind();

  if (!data?.details.wifi_name) {
    return null;
  }
  return (
    <View style={tailwind('max-w-3xl w-full self-center')}>
      <List.Subheader
        style={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingBottom: 12,
          paddingHorizontal: 24,
        }}
      >
        {i18n.t('properties.sections.wifi.title')}
      </List.Subheader>
      <Card
        elevation={0}
        style={{ marginBottom: 8 }}
        contentStyle={{ flexDirection: 'column' }}
      >
        <PropertyListItem icon="wifi" title={data?.details.wifi_name} />
        {data?.details.wifi_password ? (
          <PropertyListItem
            icon="wifi-strength-3-lock"
            title={data?.details.wifi_password}
          />
        ) : null}
      </Card>
    </View>
  );
}

function PropertyNumbers({ data }: { data: ApplauseProperty | undefined }) {
  const tailwind = useTailwind();

  return (
    <View style={tailwind('max-w-3xl w-full self-center')}>
      <List.Subheader
        style={{
          includeFontPadding: false,
          textAlignVertical: 'center',
          paddingBottom: 12,
          paddingHorizontal: 24,
        }}
      >
        {i18n.t('properties.sections.numbers.title')}
      </List.Subheader>
      <Card
        elevation={0}
        style={{ marginBottom: 8 }}
        contentStyle={{ flexDirection: 'column' }}
      >
        <View style={tailwind('max-w-3xl w-full self-center')}>
          <PropertyListItem
            icon="bed"
            title={i18n.t(
              'properties.sections.amenities.fields.bedrooms.display',
              {
                count: Number(data?.features.bedrooms || '0'),
              }
            )}
          />
          <PropertyListItem
            icon="shower"
            title={i18n.t(
              'properties.sections.amenities.fields.bathrooms.display',
              {
                count: Number(data?.features.bathrooms || '0'),
              }
            )}
          />
          {!data || data.features.key_count ? (
            <PropertyListItem
              icon="key-variant"
              title={i18n.t(
                'properties.sections.amenities.fields.keys.display',
                {
                  count: Number(data?.features.key_count || '0'),
                }
              )}
            />
          ) : null}
          {!data || data.features.square_meters ? (
            <PropertyListItem
              icon="ruler-square"
              title={`${data?.features.square_meters || '-'} m²`}
            />
          ) : null}
          <PropertyAssignments data={data} />
        </View>
      </Card>
    </View>
  );
}

function PropertyFeatures({ data }: { data: ApplauseProperty | undefined }) {
  const tailwind = useTailwind();

  return (
    <Fragment>
      <List.Subheader
        key="header"
        style={[
          tailwind('max-w-3xl w-full self-center'),
          {
            includeFontPadding: false,
            textAlignVertical: 'center',
            paddingBottom: 12,
            paddingHorizontal: 24,
          },
        ]}
      >
        {i18n.t('properties.sections.amenities.title')}
      </List.Subheader>
      <View key="features" style={tailwind('max-w-3xl w-full self-center')}>
        <IconGrid>
          {({ size }) => [
            <IconCard
              size={size}
              icon="room-service"
              label={i18n.t('properties.sections.amenities.states.service')}
              key="room-service"
            />,
            <IconCard
              size={size}
              icon="sunglasses"
              label={i18n.t(
                `properties.sections.amenities.states.${
                  data?.features.has_terras ? '' : 'no-'
                }terrace`
              )}
              disabled={!data?.features.has_terras}
              key="terras"
            />,
            <IconCard
              size={size}
              icon="flower-tulip"
              label={i18n.t(
                `properties.sections.amenities.states.${
                  data?.features.has_garden ? '' : 'no-'
                }garden`
              )}
              disabled={!data?.features.has_garden}
              key="garden"
            />,
            <IconCard
              size={size}
              icon="parking"
              label={i18n.t(
                `properties.sections.amenities.states.${
                  data?.features.has_parking ? '' : 'no-'
                }parking`
              )}
              disabled={!data?.features.has_parking}
              key="parking"
            />,
            <IconCard
              size={size}
              icon="pool"
              label={i18n.t(
                `properties.sections.amenities.states.${
                  data?.features.has_pool ? '' : 'no-'
                }pool`
              )}
              disabled={!data?.features.has_pool}
              key="pool"
            />,
            <IconCard
              size={size}
              icon="radiator"
              label={i18n.t(
                `properties.sections.amenities.states.${
                  data?.features.has_sauna ? '' : 'no-'
                }sauna`
              )}
              disabled={!data?.features.has_sauna}
              key="sauna"
            />,
            <IconCard
              size={size}
              icon="trash-can"
              label={i18n.t(
                `properties.sections.amenities.states.${
                  data?.features.has_garbage_room ? '' : 'no-'
                }garbage`
              )}
              disabled={!data?.features.has_garbage_room}
              key="garbage"
            />,
          ]}
        </IconGrid>
      </View>
    </Fragment>
  );
}

function PropertyListItem({ icon, title }: { icon: string; title: string }) {
  return (
    <List.Item
      left={({ style: { marginVertical, ...style }, color }) => (
        <List.Icon
          icon={icon as any}
          color={color}
          style={[style, { marginLeft: 0, paddingLeft: 16 }]}
        />
      )}
      title={title}
      titleNumberOfLines={3}
      titleStyle={{ marginLeft: 8, paddingLeft: Platform.OS === 'web' ? 8 : 0 }}
      style={{ flex: 1, width: '100%', paddingHorizontal: 8 }}
    />
  );
}

function IconGrid({
  children,
}: {
  children: ({ size }: { size: number }) => React.ReactNode;
}) {
  const tailwind = useTailwind();
  const windowWidth = useWindowWidth();
  const columns = 3;
  const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);

  const actualChildren = children({ size: width });
  const count = React.Children.count(actualChildren);
  const empty = useMemo((): string[] => {
    const result: string[] = [];
    const remainder = columns - ((count % columns) % columns);

    if (remainder === columns) {
      return result;
    }

    for (let i = 0; i < remainder; i++) {
      result.push(Math.random().toString(36));
    }
    return result;
  }, [count, columns]);

  return (
    <View
      style={tailwind('flex-wrap justify-between flex-row px-4 w-full mb-8')}
    >
      {actualChildren}
      {empty.map((none) => (
        <View style={{ width, height: width }} key={none} />
      ))}
    </View>
  );
}

export function IconCard({
  size,
  label,
  icon,
  disabled,
}: {
  size: number;
  label: string;
  icon: string;
  disabled?: boolean;
}) {
  const tailwind = useTailwind();

  const {
    colors: {
      errorContainer,
      error,
      onErrorContainer,
      surface: primaryContainer,
      primary,
      onSurface: onPrimaryContainer,
    },
  } = useTheme();

  return (
    <Card
      elevation={0}
      style={[
        {
          width: size,
          height: size,
          borderRadius: 12,
          marginBottom: 16,
          padding: 4,
        },
        {
          backgroundColor: disabled ? errorContainer : primaryContainer,
          borderStyle: 'solid',
          borderWidth: StyleSheet.hairlineWidth,
          borderColor: disabled ? error : primary,
        },
      ]}
      contentStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <View style={tailwind('flex justify-center items-center h-full')}>
        <MaterialCommunityIcons
          name={icon as any}
          size={48}
          style={{
            opacity: disabled ? 0.5 : 0.9,
          }}
          color={disabled ? error : onPrimaryContainer}
        />
        <Caption
          style={{
            textAlign: 'center',
            lineHeight: 14,
            color: disabled ? onErrorContainer : onPrimaryContainer,
            textAlignVertical: 'center',
            minHeight: 28,
          }}
        >
          {label}
        </Caption>
      </View>
    </Card>
  );
}

function PropertyAssignments({ data }: { data: ApplauseProperty | undefined }) {
  const { navigate } = useNavigation<StackNavigationProp<RouteParamList>>();
  const { data: assignments } = usePropertyAssignments(
    data?._links.assignments.href,
    { notifyOnChangeProps: ['data'] }
  );

  if (!assignments?.assignments._index.length) {
    return null;
  }

  return (
    <List.Item
      left={({ style: { marginVertical, ...style }, color }) => (
        <List.Icon
          icon="bed-king"
          color={color}
          style={[style, { marginLeft: 0, paddingLeft: 16 }]}
        />
      )}
      title={i18n.t('properties.sections.assignments.label', {
        count: assignments.assignments._index.length,
      })}
      titleNumberOfLines={3}
      titleStyle={{ marginLeft: 8, paddingLeft: Platform.OS === 'web' ? 8 : 0 }}
      style={{ flex: 1, width: '100%', paddingHorizontal: 8 }}
      onPress={() =>
        navigate('PropertyAssignments', {
          href: encode(data!._links.self.href),
        })
      }
      right={(props) => (
        <Button
          style={{ marginTop: 12 }}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
          onPress={() =>
            navigate('PropertyAssignments', {
              href: encode(data!._links.self.href),
            })
          }
        >
          View
        </Button>
      )}
    />
  );
}
