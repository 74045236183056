import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import React, { Fragment, useMemo } from 'react';
import {
  FlatList,
  Image,
  ListRenderItemInfo,
  RefreshControl,
  StyleSheet,
  View,
} from 'react-native';
import {
  Caption,
  Card,
  Divider,
  Text,
  Paragraph,
  useTheme,
} from 'react-native-paper';
import { useTailwind } from 'tailwind-rn';
import { decode } from '../base';
import { Screen } from '../components/Screen';
import { ScreenHeader } from '../components/ScreenHeader';
import { useLocale } from '../hooks/useLocale';
import { i18n } from '../locale';
import { RouteParamList } from '../navigation/routes';
import { humanizeTime } from '../utils/date';
import { selfAsKeyExtractor } from '../utils/selfAsKeyExtractor';
import { ServiceRequestListItem } from './ServiceRequestListItem';
import { brandCacheKey, useBrand } from './useBrand';
import { ApplauseService, useServices } from './useServices';
import Color from 'color';

type UpgradeRoute = RouteProp<RouteParamList, 'Upgrade'>;

export function UpgradeBrandScreen({
  href: encodedHref,
  brand: encodedBrand,
}: {
  href: string;
  brand: string;
}) {
  const tailwind = useTailwind();
  const isFocused = useIsFocused();
  const locale = useLocale();

  const href = decode(encodedHref);
  const brandHref = decode(encodedBrand);

  const { data: brandData } = useBrand(
    brandHref,
    brandCacheKey(brandHref, locale),
    {
      enabled: isFocused,
      notifyOnChangeProps: ['data'],
    }
  );
  const { data, refetch, isFetching, isLoading } = useServices(
    href,
    [locale, href, 'service', 'list'],
    {
      enabled: isFocused,
      notifyOnChangeProps: ['data', 'isFetching', 'isLoading'],
    }
  );

  const services = data?.services;

  const brandServices = useMemo(() => {
    if (!services) {
      return null;
    }

    return services._embedded
      .map(({ service }) => service)
      .filter(({ _links: { brand } }) => brand?.href === brandHref);
  }, [services]);

  const renderService = useMemo(
    () => makeRenderService(brandServices?.length ?? 0),
    [brandServices?.length]
  );

  return (
    <Screen>
      <ScreenHeader
        title={brandData?.brand.name || i18n.t('service-request.title')}
        showBack
      />

      <FlatList
        style={[tailwind('flex-1'), { borderRadius: 8, overflow: 'hidden' }]}
        contentContainerStyle={tailwind(
          'max-w-3xl self-center w-full p-4 pb-6'
        )}
        ListHeaderComponent={BrandServiceHeader}
        data={brandServices}
        renderItem={renderService}
        keyExtractor={selfAsKeyExtractor}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isFetching && !isLoading}
          />
        }
      />
    </Screen>
  );
}

function BrandServiceHeader() {
  const tailwind = useTailwind();
  const locale = useLocale();
  const {
    colors: { surface, onSurface },
  } = useTheme();

  const { brand: encodedBrand } = useRoute<UpgradeRoute>().params;
  const brandHref = decode(encodedBrand!);
  const { data } = useBrand(brandHref, brandCacheKey(brandHref, locale), {
    notifyOnChangeProps: ['data'],
  });

  if (!data) {
    return null;
  }

  const brand = data.brand;

  return (
    <Card
      elevation={0}
      style={[
        tailwind('pt-2 mb-4'),
        {
          backgroundColor: surface,
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: StyleSheet.hairlineWidth,
          borderColor: new Color(onSurface).alpha(0.32).toString(),
        },
      ]}
    >
      <View style={tailwind('flex-row px-4 pb-4')}>
        {brand._links.logo?.href ? (
          <Image
            source={{
              uri: brand._links.logo.href,
              width: 160,
              height: 160,
            }}
            style={{
              width: 80,
              height: 80,
              overflow: 'hidden',
              borderRadius: 4,
              marginRight: 8,
              marginTop: 8,
            }}
          />
        ) : null}
        <View style={tailwind('flex-1 justify-center')}>
          <Text variant="headlineSmall" style={tailwind('pl-1 mt-1')}>
            {brand.name}
          </Text>
          <Text variant="bodySmall" style={tailwind('pl-1')}>
            {brand.about}
          </Text>
          {data.brand.reaction_time ? (
            <Fragment>
              <Divider style={tailwind('mt-1 mx-1')} />
              <Text variant="labelSmall" style={tailwind('my-1 mx-1')}>
                {i18n.t('assignments.servicers.reaction_time', {
                  time: humanizeTime(data.brand.reaction_time),
                })}
              </Text>
            </Fragment>
          ) : null}
        </View>
      </View>
    </Card>
  );
}

function makeRenderService(count: number) {
  return function renderService(item: ListRenderItemInfo<ApplauseService>) {
    return (
      <ServiceRequestListItem
        isFirst={item.index === 0}
        isLast={item.index === count - 1}
        service={item.item}
      />
    );
  };
}
