import React, { Fragment } from 'react';
import { View } from 'react-native';
import { useTailwind } from 'tailwind-rn';

export function TimePicker({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string | null;
  onChange(next: string): void;
}) {
  const tailwind = useTailwind();

  return (
    <Fragment>
      <style>{`
        input[type="time"] {
          color: rgb(0, 0, 0);
          font-family: NotoSansKR_400Regular, Arial, sans-serif;
          font-size: 16px;
          height: 52px;
          padding-top: 12px;
          padding-left: 12px;
          padding-right: 12px;
          vertical-align: middle;
          background-color: #e7e7e7;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom-color: rgba(0, 0, 0, 0.26);
          border-bottom-width: 2px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        input[type="time"] + label {
          color: rgb(243, 150, 44);
          font-family: NotoSansKR_400Regular, Arial, sans-serif;
          font-size: 16px;
          opacity: 1;
          padding-left: 9px;
          padding-right: 9px;
          top: 20px;
          transform: translateX(0px) translateY(-15.5px) scale(0.75);
          direction: ltr;
          position: absolute;
        }
      `}</style>
      <View style={{ position: 'relative' }}>
        <input
          type="time"
          value={value || ''}
          onChange={(e) =>
            onChange((e.nativeEvent.target as HTMLInputElement).value)
          }
          style={tailwind('mb-2')}
        />
        <label>{label}</label>
      </View>
    </Fragment>
  );
}
